import React from 'react';
import { DateField, TextField,ImageField,FunctionField } from 'react-admin';
import ListCustom from '../../Components/ListCustom/ListCustom';
import {ListActions} from '../../Components/ListActions/ListActions';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { COLORS } from '../../assets/constants/theme';
import { Typography } from '@mui/material';
import { TooltipTitle } from '../Talents/Form/TalentsListComponents/SimpleTalentForm';

const paramsList = {
  listParams: {
    hasCreate: true,
    actions: <ListActions from={'locations'} label={'Add'} searcheable={true} placeholder={'Search by name and description...'}/>
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'edit',
    sx: {
      padding:'0px 20px',
      '& .column-icon': {
        width: '20%'
      },
      '& .MuiTypography-root':{
        color:'#000000',
        fontWeight:400
      },
      '& .MuiTableSortLabel-root':{
        color:COLORS.table.headerSorteable,
        fontWeight:400
      },
      '& .column-icono': {
        width: '10%',
        textAlign:'center'
      },
      '& .column-name': {
        width: '22.5%'
      },
      '& .column-type': {
        width: '22.5%'
      },
      '& .column-location': {
        width: '22.5%'
      },
      '& .column-last_modified': {
        width: '22.5%'
      },
      '& .MuiTableCell-body': {
        borderBottom:'0px'
      },
    },
  }
};

const VenuesList = () => {
  return (
    <DndProvider backend={HTML5Backend} key={1}>
      <ListCustom  {...paramsList}>
        <FunctionField  source="picture.original" label='Picture' render={( record ) =>  <ImageField
          source="picture.original"
          label='Image'
          sx={{ '& img': { borderRadius: 200, maxWidth: 50, maxHeight: 45, objectFit: 'cover' } }}
        /> }/> 
        <TextField source="name" />
        <TextField source="type" />
        <FunctionField source="location" label={<>Geo-tagged<TooltipTitle
          tooltip="The element has a geographical tag and is visible on the map" /></>} render={( record ) => 
          <Typography>{record.location.geometry !== null ? 'YES' : 'NO'}</Typography>
        }/>
        <DateField source="last_modified" />
      </ListCustom>
    </DndProvider>
  );
};

export default VenuesList;