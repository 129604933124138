import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { TextInput, minValue, useInput,useGetOne,useGetList, useRecordContext } from 'react-admin';
import { INPUTS_VARIANT } from '../../assets/constants/Form';
import { Typography,InputAdornment } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
const InputText = ({startIcon, titleTransform, subtitle, switchTitle, watchField, title, sx, counter,required,videoGuide, ...rest }) => {
  const { watch, formState, getValues,setValue } = useFormContext();
  const watchLogic = useMemo(() => {
    if(switchTitle) return 'block';
    if(watch(watchField) === 'true' || watch(watchField)) return 'block';
    if(watch(watchField) === 'false' || !watch(watchField)) return 'none';
    return 'block';
  },[formState]);
  return (
    <>
      {title &&  
      <InputTitle textTransform={titleTransform} value={title} required={required}/>
      }
      {subtitle &&  
      <InputSubtitle {...rest}>{subtitle}</InputSubtitle> 
      }
      <TextInput
        sx={{
          display: watchLogic,
          ...sx, 
        }}
        InputProps={{
          sx: {
            borderRadius: '10px',
            paddingLeft:startIcon && 0,
            ...rest.inputSx
          },
          startAdornment:startIcon &&(
            <div style={{height:'2.4em',overflow:'hidden',display:'flex',justifyContent:'center',alignItems:'center',backgroundColor:'#EDEDED',width:'3em',borderRadius:'10px 0px 0px 10px',marginRight:'5px'}}>
              {startIcon}
            </div>
          ),
          endAdornment: counter &&(
            <InputAdornment position='' style={{ position: 'absolute', bottom: 15, right: 5 }}>
              /{counter}
            </InputAdornment>
          ),
        }}
        maxSize={200}
        size="small"
        variant={INPUTS_VARIANT}
        inputProps={{
          ...rest.inputProps,
          maxLength: counter ? counter : null
        }}
        // helperText={videoGuide}
        {...rest}
      />
    </>
  );
};

export default InputText;