import React, { useState } from 'react';
import { usePermissions } from 'react-admin';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import { ContactInformation } from '../../TalentForm/ContactInformation';
import { ProfileForm } from '../../TalentForm/ProfileForm';
import { MarketingForm } from '../../TalentForm/MarketingForm';
import TabsEventList from './TabsEventList';
import { Box } from '@mui/material';
import { EducationForm } from '../../TalentForm/EducationForm';
const EditTalents = () => {
  const [form, setForm] = useState('profile');
  return (
    <Box sx={{'.MuiPaper-root':{
      boxShadow:'none',
      elevation:0
    }}}>
      <EditFormCustom handleForm={setForm} >
        {
          form === 'info' ? 
            <ContactInformation /> :
            form === 'event' ? (
              <TabsEventList/>
            ) :
              form === 'marketing' ? 
                <MarketingForm /> :
                form === 'education' ? 
                  <EducationForm /> :
                  <ProfileForm isEdit/>
        }
      </EditFormCustom>
    </Box>
  );
};

export default EditTalents;