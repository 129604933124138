import { useEffect, useMemo, useState } from 'react';
import { useDataProvider, useInput, useRecordContext,email, useStore } from 'react-admin';
import {useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import moment from 'moment';
// Components
import { convertToCostaRicaTime, convertToUtcMinusSix, getDiffArray  } from '../../../helpers/date';
import { COLORS } from '../../../assets/constants/theme';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
// Material & Icons
import { Box, Skeleton, Typography, Button,FormHelperText,Tooltip, IconButton } from '@mui/material';
import { LocalizationProvider,TimePicker, renderTimeViewClock  } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { validateDate } from './validateDate';
import DeleteIcon from '@mui/icons-material/Delete';

const styledDates = {
  fontSize: '.9em',
  textAlign: 'center',
  // marginLeft: '10px',
  display: 'flex',
  flexDirection:'column',
  backgroundColor: '#D9D9D9', 
  textTransform: 'uppercase',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius:'100%',
  width:'70px',
  height:'70px'
};
    
export const InputTime = ({source,title,subtitle, checkStageTime}) => {

  const [days, setDays] = useState(null);
  const [minTime, setMinTime] = useState([]);
  
  const dataProvider = useDataProvider();
  const headers = {
    pagination: {perPage:9999, page: 1},
    filter: {}, 
    sort: {field: 'start_date', order: 'DESC'}
  };

  const memoedTime = useMemo(() => {
    return minTime;
  },[minTime]);
  
  const {data: {data: info} = {}, isLoading} = useQuery(['info'],
    async () => {
      const request = await dataProvider.getList('info', headers);
      return request.data;
    },{
      onSuccess: (data) => {
        setDays(getDiffArray(moment(data[0]?.start_date), moment(data[0]?.end_date)));}
    });


  return(
    <LocalizationProvider 
      dateAdapter={AdapterMoment}>
      {title &&  
      <InputTitle  value={title} />
      }
      {subtitle &&  
      <InputSubtitle>{subtitle}</InputSubtitle> 
      }
      {
        (days && !isLoading) ? (
          <Box
            sx={{
              width: '100%',
              height:'auto'
            }}
          >
            <TimeLineRowEvent setMinTime={setMinTime} minTime={memoedTime} source={source} days={days}/>
          </Box>
        ) : (
          <>
            <Skeleton sx={{width: '100%', height: '50px'}} />
            <Skeleton sx={{width: '100%', height: '50px'}} />
            <Skeleton sx={{width: '100%', height: '50px'}} />
          </>
        )
      }
    </LocalizationProvider>
  );
};

export const TimeLineRowEvent = ({days,minTime, setMinTime, source, validate}) => {
  const record = useRecordContext();
  // const [storedLocations] = useStore('locations');
  const [dayPicked, setDayPicked] = useState(record?.start_date?.split('T')[0]);
  const { formState: {defaultValues,errors}, trigger, register,setValue,getValues } = useFormContext();
  const valores = getValues();
  const duration = valores?.duration;
  // const selectedStage = useMemo(() => {
  //   const data = storedLocations?.find((loc) => {
  //     return loc.id === valores.stage_id;
  //   });
  //   return data?.open_times;
  // }, [valores]);
  
  const {
    field: openField,
    fieldState: openFieldState,
    setValue: setDayPickedOpen,
  } = useInput({
    source: source[0],
    format:(e) => convertToCostaRicaTime(e),
    parse:(e) => convertToUtcMinusSix(e),
    // validate: 
    //   [(e) => validateDate(selectedStage, e)]
      
    
  });
  // Configuramos el INPUT que modifica el campo end_date de EVENTS.
  // const [closeField, setCloseField] = useState(moment(record?.start_date).add(record?.duration, "minutes"))
  const {
    field: closeField,
    fieldState: closeFieldState,
    setValue: setDayPickedClose,
  } = useInput({
    source: source[1],
    defaultValue: moment(record?.start_date).add(record?.duration, 'minutes'),
    // Solo se convierte en HORARIO COSTA RICA, cuando es EDITABLE osea existe un RECORD, por que siempre guardamos en UTC,
    //  y mostramos en pantalla en CR Time.
    format: (e) =>convertToCostaRicaTime(e),
    parse:(e) => convertToUtcMinusSix(e),
    
  });  
  // Si se cambia el DIA, se modifican los horarios para que se igualen a la fecha pickeada.

  useEffect(() => {
    if(dayPicked !== record?.start_date?.split('T')[0]){
      console.log('entre');
      let openTimeTaked =openField?.value?.split('T')[1];
      console.log(openTimeTaked);
      if(openTimeTaked === 'undefined'){
        return;
      }else{
        openField.onChange(`${dayPicked}T${openTimeTaked}`);
      }
    }
  },[dayPicked]);

  const handlerLabel = () =>{
    // En caso de create entraria aqui.
    if(record === undefined || record === null){
      if(openField.value !== undefined && openField?.value?.split('T')[1] !=='undefined'){
        
        if(closeField?.value?.split('T')[0] !== openField?.value?.split('T')[0]){
          return `${moment(dayPicked).add(1, 'days').format('MMMM Do YYYY')} - End Time`;
        }
      }
      return '';
    }
    else{
      if(closeField?.value?.split('T')[0] !== openField?.value?.split('T')[0]){
        return `${moment(dayPicked).add(1, 'days').format('MMMM Do YYYY')} - End Time`;
      }
      return '';
    }
  };

  useEffect(() => {
    if(record === undefined || record === null){
      closeField.onChange(moment().format('YYYY-MM-DDTHH:mm:ss'));
    } else {
      closeField.onChange(moment(record?.start_date).add(record?.duration, 'minutes'));
    }
  },[record]);

  const handlerDays = (item) =>{
    if(dayPicked === item){
      setDayPicked(undefined);      
      openField.onChange(null);
      closeField.onChange(null);
      setValue(source[0],undefined);
      setValue(source[0],undefined);
    }else{
      setDayPicked(item);
    }
  };

  // const handleHours = () => {
  //   setAddHours([...addHours, 1]);
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1500);
  //   console.log(addHours.length);

  // };
  // const deleteHours = (index) => {
  //   console.log(addHours.length);
  //   console.log(index);
  //   addHours.splice(1,index);
  //   setIsLoading(true);
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1500);
  // };

  return(
    <div style={{display:'flex',flexDirection:'column',height:'100%',gap:30}}>
      <div style={{width:'100%',display:'flex',flexDirection:'row',gap:10}}>
        {days?.map((item,index) => (
          <Button key={index} onClick={() => handlerDays(item)} style={{...styledDates,backgroundColor: dayPicked === item ? COLORS.light.secondary.main :'#D9D9D9',color: dayPicked === item ? 'white':'black'}} disabled={duration === null || duration === undefined ? true : false}>
            <Typography fontSize={'12px'} sx={{textTransform: 'none'}}>{moment(item).format('MMMM')}</Typography>
            <Typography fontSize={'18px'} sx={{textTransform: 'none'}}>{moment(item).format('D')}</Typography>
          </Button>
        ))}
      </div>
            
      {/* {addHours.map((item,index) => ( */}
        
      <div style={{display:'flex',flexDirection:'row',gap:10}}>
        <div>
          <InputTitle sx={{marginLeft:'10px',marginBottom:'10px'}}>
              START TIME
          </InputTitle>
          <Tooltip title=''>
            <InputTimePicker
              row={dayPicked}
              // selectedStage={selectedStage}
              onChange={openField.onChange}
              //Esto se hace porque por alguna razon React admin no esta funcionando bien y no esta agarrando el error desde el field directamente.
              formStateError={errors?.[source[0]]?.message}
              value={valores?.start_date === undefined ? 'hh:mm:aa': openField.value}
              fieldState={openFieldState}
              variant="outlined"
              disabled={duration ? false : true}
              ampm={false}
              sx={{
                width: '100%',
                maxWidth: '220px',
                '& .css-1t7ezl-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline':{
                  border:'1px solid rgba(0, 0, 0, 0.26)'
                },
              }}
            />
          </Tooltip>
        </div>
        <div>
          <InputTitle sx={{marginLeft:'10px',marginBottom:'10px'}}>
              END TIME
          </InputTitle>
          <InputTimePicker
            row={dayPicked}
            onChange={closeField?.onChange}
            value={valores?.start_date === undefined ? 'hh:mm:aa':closeField.value}
            openValue={openField?.value}
            disabled={true}
            fieldState={closeFieldState}
            variant="outlined"
            label={handlerLabel()}
            end={true}
            ampm={false}
            sx={{
              width: '100%',
              maxWidth: '220px'
            }}
          />
        </div>
        {/* <IconButton onClick={()=>deleteHours(index)} size='small'><DeleteIcon/></IconButton> */}
      </div> 
      {/* ))
      } */}
      {/* <Button onClick={handleHours} sx={{width:'200px',color:COLORS.light.primary.main}}>+ Add Hours</Button> */}
    </div>
    
  );
};
export const InputTimePicker = ({
  value,
  end,
  onChange,
  handleMinTime,
  row,
  maxTime,
  state, 
  helperText,
  defaultValue,
  openValue,
  // selectedStage,
  formStateError,
  ...rest
}) => {
  const [error, setError] = useState(null);
  const { getValues, setValue} = useFormContext();
  let duration = getValues()?.duration;
  const handleChange = (e) => {
    const date = maxTime ? moment(row).add(1, 'days').format('YYYY-MM-DD') : moment(row).format('YYYY-MM-DD');
    
    const time = e.format('THH:mm:ss');
    const dateTime = date + time;
    onChange(dateTime);
    handleMinTime && handleMinTime(null, dateTime);
  };
  useEffect(() => {
    if(end){
      if(openValue?.split('T')[1] !== 'undefined' || openValue !== undefined ){
        if(duration !== null){
          onChange(moment(openValue).add(duration, 'minutes').format('YYYY-MM-DDTHH:mm:ss'));
        }
      }
    }
  },[openValue,duration]);
  return(
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 70,
      }}
    >
      <TimePicker
        maxTime={maxTime}
        value={moment(value)}
        onChange={handleChange}
        onError={(e,v) => {
          
          if(e === 'minTime'){
            return setError('Open time must be after the days before close time.');
          }
          if(e === 'maxTime'){
            return setError('Close time cant be greater than 7:00AM');
          }
          return setError(null);
        }}
        helperText="asd"
        slotProps={{
          textField: {
            inputProps: {
              style: {
                padding: '10px'
              }
            },
            variant: 'outlined'
          }
        }}
        // ampm={false}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }} 
        {...rest} />
      {error && <FormHelperText sx={{color: 'red'}}>{error ?? formStateError}</FormHelperText>}
      {formStateError && <FormHelperText sx={{color: 'red'}}>{formStateError.includes('@@react-admin@@') ? formStateError.split('@@"')[1] : formStateError}</FormHelperText>}
    </Box>
      
  );
};