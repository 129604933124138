import React from 'react';
import { DateField, NumberField, TextField,FunctionField } from 'react-admin';
import ListCustom from '../../Components/ListCustom/ListCustom';
import {ListActions} from '../../Components/ListActions/ListActions';
import { Chip } from '@mui/material';
import { COLORS } from '../../assets/constants/theme';
import moment from 'moment';
import { convertToCostaRicaTime } from '../../helpers/date';

const paramsList = {
  listParams: {
    hasCreate: true,
    actions: <ListActions  label={'Add'} from={'notification'} searcheable={true} filter={true} placeholder={'Search by title and body'}/>
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'edit',
  }
};

const NotificationsList = () => {
  return (
    <>
      <ListCustom {...paramsList} datagridParams={{
        sx: {
          padding:'0px 20px',
          zIndex:100,
          '& .MuiTypography-root':{
            color:'#000000',
            fontWeight:400
          },
          '& .MuiTableSortLabel-root':{
            color:COLORS.table.headerSorteable,
            fontWeight:400
          },
          '& .column-title': {
            width: '30%'
          },
          '& .column-body': {
            width: '30%'
          },
          '& .column-status': {
            width: '13%',
            textAlign: 'center',
          },
          '& .column-send_at': {
            width: '13%'
          },
          '& .column-notifications_sent': {
            width: '13%',
            textAlign: 'left',
          },
          '& .MuiTableCell-body': {
            borderBottom:'0px'
          },
        },
        ...paramsList.datagridParams,
      }}>
        <TextField source="title" />
        <TextField source="body" />
        <FunctionField label="Status" source="status" 
          render={(record) => <Chip sx={{width:'auto',paddingX:1}} label={record?.status.toUpperCase()} />}/>        
        {/* <DateField source="send_at" showTime /> */}
        <FunctionField  source="send_at" render={(record) => {
          if(record?.send_at) return moment(convertToCostaRicaTime(record.send_at)).format('DD/MM/YYYY, HH:mm:ss');
        }} />
        <NumberField source="notifications_sent" label={'Sent'} />
      </ListCustom>
    </>

  );
};

export default NotificationsList;