import React from 'react';
import { TextField,FunctionField,ImageField } from 'react-admin';
// Hooks & Utils
import { COLORS } from '../../assets/constants/theme';
import { TYPES } from '../../assets/constants/types';
// Components
import ListCustom from '../../Components/ListCustom/ListCustom';
import { ListActions } from '../../Components/ListActions/ListActions';
// Material & Icons
import { Chip } from '@mui/material';


const ActivitiesList = () => {
  const paramsList = {
    listParams: {
      hasCreate: true,
      actions: <ListActions from={'activities'} label={'Add'} searcheable={true} filter={true} categories={TYPES} placeholder={'Search by name and description...'}/>
    },
    datagridParams: {
      bulkActionButtons: false,
      rowClick: 'edit',
    }
  };
  return (
    <div>
      <ListCustom {...paramsList} datagridParams={{
        sx: {
          paddingX:3,
          '& .RaDatagrid-headerCell': {
            color: '#7C7C7C',
            fontWeight: 400,
            paddingX:0,
          },
          '& .MuiTableCell-body': {
            borderBottom:'0px',
            paddingX:0,
          },
          '& .MuiTableSortLabel-root':{
            color:COLORS.table.headerSorteable,
            fontWeight:400
          },
          '& .MuiTypography-root':{
            color:'#000000',
            fontWeight:400
          },
          '& .column-imagen': {
            width: '10%',
            textAlign:'center'
          },
          '& .column-title': {
            width: '30%'
          },
          '& .column-location': {
            width: '30%'
          },
          '& .column-type': {
            width: '30%',
            textAlign: 'center'
          }
        },
        ...paramsList.datagridParams,
      }}>
        <FunctionField  source="imagen" label='Picture' render={(record) => <ImageField
          source="picture.original"
          label='Picture'
          sx={{ '& img': { borderRadius: 200, maxWidth: 50, maxHeight: 45, objectFit: 'cover' } }}
        />}/>
       
        <TextField source="name" />
        <FunctionField source="type" label='Type' render={(record)=> <Chip sx={{width:'auto'}} label={record?.type} />}/>
        <TextField source="location" />
      </ListCustom>
    </div>
  );
};

export default ActivitiesList;