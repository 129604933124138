import React from 'react';
import FormVenues from './FormVenues';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import { transform } from '../transform/transform';

const EditVenues = () => {
  return (
    <EditFormCustom mode="onChange" redirect='list' transform={transform}>
      <FormVenues from={'venues'} isEdit={true} />
    </EditFormCustom>
  );
};

export default EditVenues;