import React,{useEffect} from 'react';
import { Layout,usePermissions,useRedirect, useResourceContext } from 'react-admin';
import {AppbarCustom,MyAppBar} from '../AppbarCustom/AppbarCustom';
import MenuCustom from './MenuCustom';
import ErrorCustom from '../ErrorCustom/ErrorCustom';
import { snippetBeacon } from '../../helpers/helpScout';
const LayoutCustom = (props) => {
  const redirect = useRedirect();
  const resource = useResourceContext();
  // KEVIN , rediseno de TM > a la espera de back >>>>
  // const [selectedTalent, setSelectedTalent] = useStore('selectedTalent', {selected:false, talent:''});
  // const redirect = useRedirect();

  // const { permissions } = usePermissions();
  // console.log(permissions)
  // useEffect(()=>{
  // },[])
  // React.useEffect(() => {
  //   if(permissions === 'talent_manager'){
  //     setSelectedTalent({
  //       selected: false,
  //       talent: ''
  //     })
  //     redirect('/talents')
  //   }
    
  // },[]);
  const { permissions } = usePermissions();

  /* Esto pasa porque el primer resource que existe es el de un menuParent en este caso 'Talent'.
   React admin no lee el resoruce que no tenga una list, por lo que no se peude acceder por hook y no renderiza nada siendo que no contiene nada pero igualmente mantiene la url.
   Esta interaccion solo ocurre cuando entras por primera vez al backoffice, y es necesario realizar un redirect al siguiente resource que si contiene una list (en este caso 'talents')
 */
  // useEffect(()=>{
  //   let wrongHref = window.location.href.split('/');
  //   if(wrongHref[wrongHref.length - 1] === 'Talent') return redirect('list', 'talents');
  // },[]);

  // Redirect de la homepage a la lista de talentos
  
  useEffect(()=>{
    let wrongHref = window.location.href.split('/');
    if(wrongHref[4] === 'FESTIVAL%20MANAGEMENT') return redirect('talents');
  },[]);
  
  return (
    <>
      {permissions !== 'talent_manager'?null : 
        snippetBeacon()
      }
      <Layout appBarAlwaysOn={false} sx={{
        '& .RaSidebar-fixed': {
          borderRight:'1px solid',
          borderColor: '#cdcdcd',
        },
        '& .RaLayout-appFrame': {
          marginTop:'0px'
        },
        '&  .RaLayout-content': {
          paddingLeft:'0px',
          paddingRight:'0px'
        },
        '&  .MuiDrawer-docked': {
          borderRight:'1px solid',
          borderColor: '#cdcdcd',
          height:'100vh',
        }
      }} {...props} error={ErrorCustom}  menu={MenuCustom}
      appBar={MyAppBar}  
      />
    </>
  
  );
};

export default LayoutCustom;