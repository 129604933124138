import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { styleAbsolute } from '../../Theme';
import { COLORS } from '../../assets/constants/theme';
const DeleteModal = ({ openModal, setOpenModal, confirmDelete,idToAction,textAction }) => {
  return (
    <Modal
      open={openModal}
      onClose={() => setOpenModal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleAbsolute}>
        <Typography id="modal-modal-title" component="h4">
          Are you sure you want to delete this {textAction}
        </Typography>
        <Box
          id="modal-modal-description"
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            mt: 6,
          }}
        >
          {idToAction !== null ? <Button
            sx={{
              backgroundColor: COLORS.light.primary.main,
              color: '#fff',
              '&:hover': { backgroundColor: COLORS.light.primary.main, color: '#fff' },
            }}
            variant="filled"
            onClick={()=>confirmDelete(idToAction)}
          >
            Confirm
          </Button> :  <Button
            sx={{
              backgroundColor: COLORS.light.primary.main,
              color: '#fff',
              '&:hover': { backgroundColor: COLORS.light.primary.main, color: '#fff' },
            }}
            variant="filled"
            onClick={confirmDelete}
          >
            Confirm
          </Button>}
          <Button
            sx={{
              backgroundColor: COLORS.light.primary.main,
              color: '#fff',
              '&:hover': { backgroundColor: COLORS.light.primary.main, color: '#fff' },
            }}
            variant="filled"
            onClick={() => setOpenModal(false)}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
