import { Typography,Box,Card} from '@mui/material';
import JoyForTM from '../../../Components/JoyComponent/JoyForTM';
import { List,Datagrid,FunctionField,useGetList } from 'react-admin';
import { COLORS } from '../../../assets/constants/theme';
import { MdCheckCircle } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { Indicator } from '../../../Components/RedesignComponents/Indicator';
const DashboardTM = () => {
  const { data:talents } = useGetList('talents', { filter: { } });
  const { data:events } = useGetList('events', { filter: { } });
  return(
    <div style={{width:'100%'}}>
      <JoyForTM />
      <Box sx={{paddingY:'3em',display:'flex',flexDirection:'row',justifyContent:'center',width:'100%',gap:8}}>

        <Card variant="outlined" raised={true} sx={{p:1,display: 'flex', flexDirection: 'column', bgcolor: 'rgba(0,0,0,0.03)',borderRadius:'10px',width:'40%',minHeight:'300px',maxHeight:'35em' }}>
          <Typography  
            fontSize={'18px'}
            fontWeight={600}
            alignSelf={'flex-start'}
            gutterBottom
          >
            Talent Submissions
          </Typography>
          <div style={{maxHeight:'35em',overflowY:'auto'}}>
            <List actions={null} pagination={false} resource='admins' sx={{width:'100%'}}>
              <Datagrid
                empty={<Typography sx={{backgroundColor:'#f7f7f7'}}>No submissions</Typography>}
                sx={{
                  '& .RaDatagrid-headerCell': {
                    color: '#7C7C7C',
                    fontWeight: 400,
                    backgroundColor: '#f7f7f7',
                    paddingX:0,
                  },
                  '& .MuiTypography-root':{
                    color:'#000000',
                    fontWeight:400
                  },
                  '& .MuiTableSortLabel-root':{
                    fontSize:'16px',
                    color:COLORS.table.headerSorteable,
                    fontWeight:400,
                  },
                  '& .column-name': {
                    width: '33.3%',
                  },
                  '& .column-status': {
                    width: '33.3%',
                    textAlign: 'right',
                  },
                  '& .column-completed': {
                    width: '33.3%',
                    textAlign: 'center',
                  },
                  '& .MuiTableCell-body': {
                    borderBottom:'0px',
                    backgroundColor: '#f7f7f7',
                    paddingX:0,
                  },
                }}
                data={talents}
                bulkActionButtons={false}
              >
                <FunctionField source="name" label='Talent Name' render={(record, index) => <Typography color={'#000000'} fontWeight={600} fontSize={'0.875rem'}>{record?.name}</Typography>}/>
                <FunctionField label="Complete?"  source="completed" render={(record) => 
                  <Typography
                    sx={{
                      fontSize: '.9em',
                      fontWeight: '600',
                      color: record?.completed ? '#009b00' : '#ffc990',
                      textTransform: 'uppercase',
                      textAlign: 'center',
                    }}
                  >
                    {record?.completed ? <MdCheckCircle color='#21B141' size={26}/> : <IoMdCloseCircle color='grey' size={26}/>}
                  </Typography> 
                } /> 
                <FunctionField label="Status" source="status" 
                  render={(record) => record?.submission?.status === 'draft'  || record?.submission === null  ? <div style={{display:'flex',justifyContent:'flex-end'}}><Indicator /></div> : (<div style={{display:'flex',justifyContent:'flex-end'}}><Indicator data={record?.submission} /></div>)}/>
              </Datagrid>
            </List>
          </div>
        </Card>

        <Card variant="outlined" raised={true} sx={{p:1,display: 'flex', flexDirection: 'column', bgcolor: 'rgba(0,0,0,0.03)',borderRadius:'10px',width:'40%',minHeight:'300px',maxHeight:'35em' }}>
          <Typography  
            fontSize={'18px'}
            fontWeight={600}
            alignSelf={'flex-start'}
            gutterBottom
          >
            Event Submissions
          </Typography>
          <div style={{maxHeight:'35em',overflowY:'auto'}}>
            <List actions={null} pagination={false} resource='admins' sx={{width:'100%'}}>
              <Datagrid
                empty={<Typography sx={{backgroundColor:'#f7f7f7'}}>No submissions</Typography>}
                sx={{
                  '& .RaDatagrid-headerCell': {
                    color: '#7C7C7C',
                    fontWeight: 400,
                    backgroundColor: '#f7f7f7',
                    paddingX:0,
                  },
                  '& .MuiTypography-root':{
                    color:'#000000',
                    fontWeight:400
                  },
                  '& .MuiTableSortLabel-root':{
                    fontSize:'16px',
                    color:COLORS.table.headerSorteable,
                    fontWeight:400,
                  },
                  '& .column-name': {
                    width: '33.3%',
                  },
                  '& .column-status': {
                    width: '33.3%',
                    textAlign: 'right',
                  },
                  '& .column-completed': {
                    width: '33.3%',
                    textAlign: 'center',
                  },
                  '& .MuiTableCell-body': {
                    borderBottom:'0px',
                    backgroundColor: '#f7f7f7',
                    paddingX:0,
                  },
                }}
                data={events}
                bulkActionButtons={false}
              >
                <FunctionField source="name" label='Event Name' render={(record, index) => <Typography color={'#000000'} fontWeight={600} fontSize={'0.875rem'}>{record?.name}</Typography>}/>
                <FunctionField label="Complete?"  source="completed" render={(record) => 
                  <Typography
                    sx={{
                      fontSize: '.9em',
                      fontWeight: '600',
                      color: record?.completed ? '#009b00' : '#ffc990',
                      textTransform: 'uppercase',
                    }}
                  >
                    {record?.completed ? <MdCheckCircle color='#21B141' size={26}/> : <IoMdCloseCircle color='grey' size={26}/>}
                  </Typography> 
                } /> 
                <FunctionField label="Status" source="status" 
                  render={(record) => record?.submission?.status === 'draft'  || record?.submission === null  ? <div style={{display:'flex',justifyContent:'flex-end'}}><Indicator /></div> : (<div style={{display:'flex',justifyContent:'flex-end'}}><Indicator data={record?.submission} /></div>)}/>
              </Datagrid>
            </List>
          </div>  
        </Card>

      </Box>
    </div>
  );
};
export default DashboardTM;