import { useInput } from 'react-admin';
import InputButtonAws from './InputButtonAws';
import { useRef, useState } from 'react';
import useUploadAws from '../../Hooks/useUploadAws';
import { Box, Button, FormHelperText, FormLabel, IconButton, Typography } from '@mui/material';
import { BsImageFill} from 'react-icons/bs';
import { BiCloudUpload } from 'react-icons/bi';
import { BiTrashAlt } from 'react-icons/bi';
import { useCustomToasty } from '../../Hooks/useCustomToasty';


export const InputLogos = ({inputSx, noImage, actionText, label, source,validate, upload, sx, inputLabelSx, maxSize}) => {
  const fileInputRef = useRef(null);
  const { id, field, fieldState } = useInput({ source, validate });
  const imageFieldSource = field?.value?.length > 0 ? { key: field.name, location: field.value } : null;
  const [image, setImage] = useState(imageFieldSource);
  const { uploadAWS } = useUploadAws();
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const customToasty = useCustomToasty();

  const onDelete = () => {
    setImage(null);
    field.onChange(null);
  };

  const handleClick = async (e) => {
    let value = e.target.files[0];
    const fileUpload = await uploadAWS(e);
    if(maxSize){
      if (value?.size > maxSize) {
        return customToasty('error', 'The image needs to be smaller than 4mb.');
      }
    }
    if (fileUpload.success) {
      setImage(fileUpload);
      field.onChange(fileUpload.location);
    }
  };
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 3 , width: upload ? '100%' : 'inherit', ...sx}}>
      {
        !image && (
          <Box  sx={{display: 'flex', flexDirection: 'column', alignItems: upload ? 'flex-start' : 'center', justifyContent: upload && 'center', width: upload ? '100%' : 'inherit',}}>
            {
              upload  ? (
                <UploadButton fileInputRef={fileInputRef} handleClick={handleClick} />
              ) : (
                <IconButton onClick={handleButtonClick} sx={{padding: '30px', ...inputSx}}>
                  <BsImageFill />
                  <input ref={fileInputRef} hidden accept="image/*" type="file" onChange={handleClick} />
                </IconButton>
              )
            }
            <FormLabel sx={{textTransform: 'uppercase', textAlign: 'center', fontSize: '.7em', maxWidth: '100px', ...inputLabelSx}}>{label}</FormLabel>
            {fieldState.invalid && <FormHelperText sx={{color:'red'}}>The Image is required</FormHelperText>}
          </Box>
        )
      }
      {/* {
        image && !noImage && (
          <img
            {...field}
            src={image?.location}
            alt={image?.key}
            loading="lazy"
            style={{ width: '100%', height: '100%', maxWidth: upload ? '200px' : '300px', maxHeight: upload ? '200px' : '300px', objectFit: 'contain', }}
          />
        )
      } */}
      {
        image && !noImage && (
          <Box sx={{
            width: '300px',
            height: '200px',
            display: 'flex',
            flexDirection: 'row',
            position:'relative',
          }}>
            <img
              src={image?.location}
              alt={image?.key}
              loading="lazy"
              style={{ width: '200px', height: '200px', objectFit: 'contain'}}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                padding: '10px',
                display: 'flex',
                textAlign: 'center',
                margin:'10px',
                alignItems: 'center',
                width: '50px',
                height: '50px',
                cursor: 'pointer',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                color: 'black',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: '#fff'
                }
              }}
              onClick={onDelete}
            >
              <BiTrashAlt
                size={'20px'}
              />
            </Box>
          </Box>
        )
      }
    </Box>
  );
};

const UploadButton = ({fileInputRef, handleClick}) => {
  const handleButtonClick = () => {
    // Trigger the click event of the hidden input element
    fileInputRef.current.click();
  };
 
  return (
    <Button
      variant="outlined"
      onClick={handleButtonClick}
      sx={{
        width: '100%',
        textTransform: 'none',
        height: 60,
        fontSize: '.9em',
        fontWeight: '600',
        gap: '10px',
        alignItems: 'center',
        textAlign: 'center',
        border: '1px solid #D7D7D7',
        marginBottom: '12px'
      }}
    >
      <BiCloudUpload size="20px" /> Upload a file
      <input ref={fileInputRef} hidden accept="image/*" type="file" onChange={handleClick} />

    </Button>
  );
};