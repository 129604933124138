import { useRecordContext } from 'react-admin';
import CardComponent from '../../../../Components/ShowComponents/CardComponent';
import BlankProfile from '../../../../assets/images/blank-profile-pic.png';
import { Box, Skeleton, Typography } from '@mui/material';
import { ImageButton } from '../../../../Components/ShowComponents/ImageButton';
import React, { useCallback, useMemo,useEffect } from 'react';
import { FieldTypo } from './FieldTypo';
import { SOCIALS } from './Socials';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import { mainColor } from '../../../../Theme';
import { OpenImage } from './ImagesGallery';
import { talentModel } from './TalentModel';
import { InputSubtitle } from '../../../../Components/StyledComponents/InputSubtitle';
import { SelectField } from './SelectField';
import { getToken } from '../../../../helpers/getToken';
import { convertToCostaRicaTime,dateFormat } from '../../../../helpers/date';
import { COLORS } from '../../../../assets/constants/theme';
export const findFields = (arr, body) => {
  return Object.keys(body)?.find(key => arr?.map(item => key == item));
};

export const findValue = (key, record) => {
    console.log(key,record,'recuerdos y key')
  if(!key.includes('.')) return record[key];
  key = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  key = key.replace(/^\./, '');           // strip a leading dot
  let string = key.split('.');
  for (let i = 0, n = string.length; i < n; ++i) {
    let obj = string[i];
    if (record && (obj in record)) {
      record = record[obj];
    } else {
      return;
    }
  }
  console.log(record,'recor new')
  return record;
};

Object.byString = function(record, key) {
  key = key.replace(/\[(\w+)\]/g, '.$1');
  key = key.replace(/^\./, '');           
  let string = key.split('.');
  for (let i = 0, n = string.length; i < n; ++i) {
    let obj = string[i];
    if (record && (obj in record)) {
      record = record[obj];
    } else {
      return;
    }
  }
  return record;
};

export const ProfileCard = ({editedFields, switchBody, sx}) => {
  useEffect(() => {
    localStorage.setItem('talents', 'pending');
  }, []);
  const record = useRecordContext();
  const token = getToken();

  const pendingBody = useMemo(() => {
    if(!switchBody) {
      return talentModel(record);
    } else {
      return record;
    }
  },[switchBody]);

  const socialsArr = useMemo(() => {
    const modifiedSocials = SOCIALS.map((social) => {
      if (pendingBody?.media?.[social.key]?.url) {
        const url = pendingBody?.media?.[social.key]?.url;
        if (url && url.length > 0) {
          return React.cloneElement(social, { pending: true, url: url });
        }
      } else {
        return React.cloneElement(social, { url: record?.media?.[social.key]?.url });
      }
    });
    return modifiedSocials;
  },[pendingBody]);

  const handleObj = (e, obj) => {

    if(typeof e === 'object') {
      if(obj){
        return handleObj(Object.values(e)[0], obj + '.' + Object.keys(e)[0]);
      } else {
        return handleObj(Object.values(e)[0], Object.keys(e)[0]);
      }
    }
    return obj ? obj : e;
  };

  console.log(pendingBody,'body pending') 
  return (
    <>
      {
        pendingBody && editedFields ? (
          <CardComponent actions route="talents" id={pendingBody.id} >
            <Box sx={{
              width: '100%',
              display:'flex',
              flexDirection:'row',
            }}>
              {/* <div style={{display:'flex', flexDirection:'row',justifyContent:'space-between'}}>
                <CardSubtitle arr={['pendingBody.picture.original']}>Profile Image</CardSubtitle>
                <InputTitle sx={{fontSize: '.9em', color: mainColor,display:'flex', flexDirection:'column',alignItems:'center'}} >Last Submission <span style={{fontSize:'13px',color:'grey'}}>{dateFormat(convertToCostaRicaTime(record?.submission?.last_modified))}</span></InputTitle>
              </div> */}
              <div style={{height:'100%',width:'20%',display:'flex',flexDirection:'column',gap:30}}>
                <Box>
                  <InputTitle>Profile Picture</InputTitle>
                  <Box 
                    style={{
                      position:'relative',
                      boxSizing: 'content-box', 
                      height: '150px',
                      width:'150px',
                      marginTop:'10px'
                    }}>
                    <img 
                      src={pendingBody?.picture?.original} 
                      style={{
                        verticalAlign: 'bottom',
                        height: '100%',
                        width:'100%',
                        objectFit: 'cover',
                      }} />
                    <ImageButton size="25px" sx={{color: !switchBody ? 'yellow' : '#fff', backgroundColor: switchBody ? '#606060' : null, padding: '20px', height: 'auto', width: 'auto'}} image={pendingBody?.picture?.original} />
                  </Box>
                </Box>
               
                <Box>
                  <InputTitle >Profile Logos</InputTitle>
                  <Box sx={{display: 'flex', flexDirection: 'row', gap: '20px',flexWrap:'wrap'}}>
                    {
                      pendingBody?.logo ?
                        Object.keys(pendingBody?.logo)?.map((item, i) => (
                          <OpenImage
                            switchBody={switchBody}
                            pendingBody={pendingBody}
                            item={item}
                            index={i}
                          />
                        ))
                        :
                        <Box sx={{display:'flex',alignItems:'center', textAlign:'center',justifyContent:'center',marginTop:2,padding:1,borderRadius:'10px',width:'214px', backgroundColor:'#F8F8F8' ,border:'1px solid #7C7C7C'}}>
                          <Typography fontWeight={600}>No profile logos were uploaded</Typography>
                        </Box>
                    }
                  </Box>
                </Box>
              </div>
              <div style={{width:'75%',display:'flex',flexDirection:'column',gap:30}}>
                <Box sx={{display:'flex',flexDirection:'row',width:'100%',flexWrap:'wrap',rowGap:2}}>
                  {/* <CardSubtitle arr={['name', 'main_category', 'country', 'cms_status', 'pillars', 'slogan']}>Profile Information</CardSubtitle> */}
                  <div style={{width:'33.3%'}}>
                    <InputTitle>Talent Name</InputTitle>
                    <FieldTypo switchBody={switchBody} switchLabel={editedFields.includes('name')} value={findValue('name', pendingBody)} field="name" longMsg />
                  </div>
                  <div style={{width:'33.3%'}}>
                    <InputTitle>Country</InputTitle>
                    <FieldTypo switchBody={switchBody} switchLabel={editedFields.includes('country')} value={findValue('country', pendingBody)} field="country" />
                  </div>
                  <div style={{width:'33.3%'}}>
                    <InputTitle>Category</InputTitle>
                    <div style={{width:'auto',maxWidth:'7em',marginTop:'10px'}}>
                      <FieldTypo type='pill' switchBody={switchBody} switchLabel={editedFields.includes('main_category')} value={findValue('main_category', pendingBody)} field="main_category" />
                    </div>
                  </div>
                  <div style={{width:'50%'}}>
                    <InputTitle>Pillars</InputTitle>
                    <div style={{width:'auto',marginTop:'10px'}}>
                      <FieldTypo type='pill' switchBody={switchBody} switchLabel={editedFields.includes('pillars')} value={findValue('pillars', pendingBody)} field="pillars" />
                    </div>
                  </div>  
                </Box>
                <Box sx={{width:'100%',display:'flex',flexDirection:'column',gap:5}}>
                  {/* <CardSubtitle arr={['message', 'short_bio', 'tagline']}>Profile Descriptive info</CardSubtitle> */}
                  <div>
                    <InputTitle>Message to our audience</InputTitle>
                    <FieldTypo switchLabel={editedFields.includes('message')} longMsg switchBody={switchBody} value={findValue('message', pendingBody)} sx={{maxWidth: null}} field="message" />
                  </div>
                  <div>
                    <InputTitle>Short Bio</InputTitle>
                    <FieldTypo switchLabel={editedFields.includes('short_bio')} longMsg switchBody={switchBody} value={findValue('short_bio', pendingBody)} sx={{maxWidth: null}} field="short_bio" />
                  </div>
                  <div>
                    <InputTitle>Tagline</InputTitle>
                    <FieldTypo switchLabel={editedFields.includes('tagline')} longMsg switchBody={switchBody} value={findValue('tagline', pendingBody)} field="tagline" />
                  </div>
                </Box>
                <Box>
                  {/* <CardSubtitle arr={['media']}>Profile Media</CardSubtitle> */}
                  <InputTitle sx={{color:COLORS.light.secondary.main}}>Social Media</InputTitle>
                  {Object.values(pendingBody?.media).some(url => url?.url !== null) ?
                    <Box sx={{display:'flex',flexDirection:'row',width:'100%',marginTop:'1.5em'}}>
                      { 
                        socialsArr.map((item, i) => (
                          <div key={i}>
                            <InputTitle>{item.key}</InputTitle>
                            <FieldTypo 
                              switchBody={switchBody}
                              urlSx={{textOverflow: 'ellipsis', whiteSpace: 'nowrap'}} 
                              switchLabel={editedFields.includes(item.key)}
                              value={item.props.url}
                              pending={item.props.pending}
                              field={item.key} 
                              url={item.props.url}
                              icon={item} 
                            />
                          </div>
                        ))   
                      }
                    </Box>:
                    <Box sx={{display:'flex',alignItems:'center', textAlign:'center',justifyContent:'center',marginTop:2,padding:1,borderRadius:'10px',width:'100%', backgroundColor:'#F8F8F8' ,border:'1px solid #7C7C7C'}}>
                      <Typography fontWeight={600}>No social media was uploaded</Typography>
                    </Box>
                  }
                </Box>
                <Box>
                  {/* <CardSubtitle arr={['media']}>Embed Media</CardSubtitle>
                   */}
                  <InputTitle sx={{color:COLORS.light.secondary.main}}>Embeded Media</InputTitle>
                  {Object.values(pendingBody?.media).some(url => url?.embed || url?.description !== null) ?
                    <Box sx={{display:'flex',flexDirection:'column',width:'100%',marginTop:'1.5em'}}>

                      {Object.values(pendingBody?.media?.youtube).some(url => url !== null) ?
                        <div style={{display:'flex',flexDirection:'row'}}>

                          {pendingBody?.media?.youtube?.embed && 
                          <div style={{width:'50%'}}>
                            <InputTitle>Youtube Embed</InputTitle>
                            <FieldTypo switchBody={switchBody} switchLabel={record?.submission?.media?.['youtube']?.['embed']} value={findValue('media.youtube.embed', pendingBody)} field="youtube_embed" />
                          </div>}
                          {pendingBody?.media?.youtube?.description && 
                     <div style={{width:'50%'}}>
                       <InputTitle>Youtube Description</InputTitle>
                       <FieldTypo switchBody={switchBody} longMsg switchLabel={record?.submission?.media?.['youtube']?.['description']} value={findValue('media.youtube.description', pendingBody)}field="youtube_description"/>
                     </div>
                          }
                        </div>
                        :null}
                      {Object.values(pendingBody?.media?.soundcloud).some(url => url !== null) &&
                    <div style={{display:'flex',flexDirection:'row'}}>
                      {pendingBody?.media?.soundcloud?.embed && 
                      <div style={{width:'50%'}}>
                        <InputTitle>SoundCloud Embed</InputTitle>
                        <FieldTypo switchBody={switchBody} switchLabel={record?.submission?.media?.['soundcloud']?.['embed']} value={findValue('media.soundcloud.embed', pendingBody)} field="soundcloud_embed" />
                      </div>}
                      {pendingBody?.media?.soundcloud?.description && 
                      <div style={{width:'50%'}}>
                        <InputTitle>SoundCloud Description</InputTitle>
                        <FieldTypo switchBody={switchBody} longMsg switchLabel={record?.submission?.media?.['soundcloud']?.['description']} value={findValue('media.soundcloud.description', pendingBody)} field="soundcloud_description" />
                      </div>
                      }
                    </div>}
                      {Object.values(pendingBody.media.spotify).some(url => url !== null)&& 
                    <div style={{display:'flex',flexDirection:'row'}}>
                      {pendingBody?.media?.spotify?.embed && 
                        <div style={{width:'50%'}}>
                          <InputTitle>Spotify Embed</InputTitle>
                          <FieldTypo switchBody={switchBody} switchLabel={record?.submission?.media?.['spotify']?.['embed']} value={findValue('media.spotify.embed', pendingBody)} field="spotify_embed" />
                        </div>}
                      {pendingBody?.media?.spotify?.description && 
                      <div style={{width:'50%'}}>
                        <InputTitle>Spotify Description</InputTitle>
                        <FieldTypo switchBody={switchBody} longMsg switchLabel={record?.submission?.media?.['spotify']?.['description']} value={findValue('media.spotify.description', pendingBody)} field="spotify_description" />
                      </div>
                      }
                    </div>}
                    </Box>
                    :
                    <Box sx={{display:'flex',alignItems:'center', textAlign:'center',justifyContent:'center',marginTop:2,padding:1,borderRadius:'10px',width:'100%', backgroundColor:'#F8F8F8' ,border:'1px solid #7C7C7C'}}>
                      <Typography fontWeight={600}>No embeded media was uploaded</Typography>
                    </Box>
                  }
                </Box>
               
                <Box>
                  {/* <CardSubtitle arr={['gallery']}>Profile image gallery</CardSubtitle> */}
                  <InputTitle>Gallery</InputTitle>
                  { pendingBody?.gallery !== null ?
                    <Box sx={{display: 'flex', flexDirection: 'row',flexWrap: 'wrap', gap: '20px',marginTop:'1em'}}>
                      {
                        pendingBody?.gallery?.map((item, i) => (
                          <OpenImage
                            switchBody={switchBody}
                            pendingBody={pendingBody}
                            item={item}
                            index={i}
                          />
                        ))
                      }
                    </Box>
                    :
                    <Box sx={{display:'flex',alignItems:'center', textAlign:'center',justifyContent:'center',marginTop:2,padding:1,borderRadius:'10px',width:'100%', backgroundColor:'#F8F8F8' ,border:'1px solid #7C7C7C'}}>
                      <Typography fontWeight={600}>No galery images were uploaded</Typography>
                    </Box>
                  }
                </Box>
              </div>
            </Box>
           
          </CardComponent>
        ) : (
          <CardComponent sx={{
            padding: '30px',
            display: 'flex',
            justifyItems: 'flex-start',
            justifyContent: 'flex-start',
            alignItems: 'flex-start'
    
          }}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'start', height: '600px', width: '100%'}}>
              <Skeleton sx={{bgcolor: '#9e9e9e', width: '100%', height: '100px'}} />
              <Skeleton sx={{bgcolor: '#bdbdbd', width: '100%', height: '100px'}} />
              <Skeleton sx={{bgcolor: '#808080', width: '100%', height: '100px'}} />
              <Skeleton sx={{bgcolor: '#eeeeee', width: '100%', height: '100px'}} />
              <Skeleton sx={{bgcolor: '#f5f5f5', width: '100%', height: '100px'}} />
            </Box>
          </CardComponent>
        )
      }
    </>
  );
};

export const CardSubtitle = ({children}) => {
  return (
    <Box sx={{display: 'flex', gap: '10px', flexDirection: 'row', marginBottom: '20px'}}>
      <InputTitle sx={{fontSize: '.9em', color: mainColor}}>{children}</InputTitle>
    </Box>
  );
};