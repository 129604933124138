import React, {useState,useEffect} from 'react';
import axios from 'axios';
import { required,useRedirect,useRecordContext } from 'react-admin';
// Hooks & Utils
import { VISIBILITY_TYPES } from '../../../../assets/constants/BlogsUtils';
import { COLORS } from '../../../../assets/constants/theme';
// Components
import {InputPillsSelect,InputRedesignImageAws,InputTabSingleSelector,InputDropdownSelector} from '../../../../Components/RedesignComponents/Inputs/index.js';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputRichText from '../../../../Components/InputsForm/InputRichText';
import InputText from '../../../../Components/InputsForm/InputText';
// Material & Icons
import {Box,Typography} from '@mui/material';
import { MdArrowBack } from 'react-icons/md';

const FormBlogs = ({ isEdit,from }) => {
  const redirect = useRedirect();
  const record = useRecordContext();
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_BASE_PATH}/posts/categories`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    })
      .then((res) => {
        setCategories(res.data.results);
      })
      .catch((err) => {
        throw err;
      });
  }, []);
  const data = [
    {
      title: '',
      inputs: [
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={COLORS.light.secondary.main}
                >
                  CONTENT
                </Typography>
              </Box>
            </div>
          ),
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <InputText
              required={true}
              title={'Title'}
              label=""
              subtitle=''
              source="title"
              fullWidth
              validate={[required()]}
            />
          ),
          breakpoints: { xs: 4 },
        },
        {
          field: (
            <InputText
              required={true}
              title={'Author'}
              label=""
              subtitle=''
              source="author"
              fullWidth
              validate={[required()]}
            />
          ),
          breakpoints: { xs: 4 },
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: (
            <InputText
              required={true}
              title={'Subtitle'}
              label=""
              subtitle=''
              source="subtitle"
              fullWidth
              validate={[required()]}
            />
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: (
            <>
              <InputTitle required={true}>MAIN IMAGE</InputTitle>
              <InputRedesignImageAws
                sx={{ marginTop: '10px' }}
                validate={[required()]}
                source="picture.original"
                label=""
              />
            </>
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: (
            <>
              <InputTitle required={true}>BODY</InputTitle>
              <InputRichText
                label=' '
                source="body"
                // validate={[required()]}
              />
            </>
          ),
          breakpoints: { xs: 8.5 },
        },
      ],
    },
    {
      title: '',
      inputs: [
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={COLORS.light.secondary.main}
                >
                  POST DETAILS
                </Typography>
              </Box>
            </div>
          ),
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <InputTabSingleSelector
              label=""
              title={
                <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                  VISIBILITY
                </Typography>
              }
              choices={VISIBILITY_TYPES}
              fullWidth
              source="visibility"
              validate={[required()]}
            />
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: (
            <InputPillsSelect
              choices={categories && categories}
              source="category"
              reference="posts/categories"
              label=""
              validate={[required()]}
              title={
                <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                  CATEGORY
                </Typography>
              }
            />
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: <InputDropdownSelector watchField="category" resource="posts" label='' title={
            <Typography fontWeight={600} fontSize={'16px'} color={'#000'} sx={{marginTop:'20px'}}>
              TAGS
            </Typography>
          }/>,
          // <ReferenceArrayInput source='tags' reference='posts/tags'>
          //   <InputAutocomplete isArray fullWidth optionText={'text'} optionValue={'text'} />
          // </ReferenceArrayInput>,
          breakpoints: { xs: 8.5 },
        },
      ],
    },
  ];
  const goBack = () => {
    redirect('/posts');
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          padding: '20px 16px',
          // height: '6.5%',
          gap: 1,
        }}
      >
        <button
          onClick={() => goBack()}
          style={{
            display: 'flex',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <MdArrowBack size={30} />
        </button>
        <Typography fontSize={'32px'} fontWeight={600}>
          {isEdit ?'Edit Post - '+ record?.title : 'New Post'}
        </Typography>
      </Box>
      <AdaptableFormRedesign
        isEdit={isEdit}
        data={data}
        from={from}
        icon={false}
        saveBackground={true}
        showDelete
        sx={{ width: '100px' }}
      />
    </>
  );
};

export default FormBlogs;