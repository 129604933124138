import React, { useState } from 'react';
import { regex, required, useRecordContext, ReferenceArrayInput,useRedirect } from 'react-admin';
// Hooks & Utils
import { ROLES } from '../../../assets/constants/permissions';
import { COLORS } from '../../../assets/constants/theme';
import { useNavigate} from 'react-router';
import { EVENTS_TOOLTIPS } from '../../TalentForm/Tooltips';
// Components
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';
import InputText from '../../../Components/InputsForm/InputText';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import { InputTabSingleSelector } from '../../../Components/RedesignComponents/Inputs/InputSelectorRedesign';
// Material & Icons
import { Box, Typography } from '@mui/material';
import { MdArrowBack } from 'react-icons/md';
import { InputTitle } from '../../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../../Components/StyledComponents/InputSubtitle';

const FormAdmins = ({ isEdit,from }) => {
  const record = useRecordContext();
  const navigate = useNavigate();
  const [showTalentId, setShowTalentId] = useState(record?.role == 'talent_manager');
  const emailRegex = /^(?:(?:[^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(?:"[^"]+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const goBack = () => {
    navigate(-1);
  };

  // const handleChange = (e) => {
  //   setShowTalentId(e.target.value == 'talent_manager');
  // };
  console.log(showTalentId);
  const data = [
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>INFORMATION</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 }
        },
        {
          field: <InputText required={true} fullWidth source='name' validate={[required()]} title={'Name'} label=''/>,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputText required={true} fullWidth source='email' validate={[required(), regex(emailRegex, 'The email inserted is not a valid format.')]} title={'Email'} label=''/>,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        },
        {
          field: 
          // <InputSelect fullWidth source='role' onChange={handleChange} choices={ROLES} validate={[required()]} />
          <InputTabSingleSelector
            label=""
            title='Role'
            choices={ROLES}
            fullWidth
            source="role"
            validate={[required()]}
          />
          ,
          breakpoints: { xs: 8.5 }
        },
        {
          field: <></>,
          breakpoints: { xs: 3 }
        },
        {
          field: <>
            <ReferenceArrayInput source="talent_id" reference='talents'>
              <InputAutocomplete   
                title={
                  <Typography fontWeight={600} fontSize={'16px'} color={'#000'} sx={{marginTop:'25px'}}>
                  Talent
                  </Typography>
                }
                subtitle={EVENTS_TOOLTIPS['talentNameCreate']}
                freeSolo={false}
                optionText="name"
                isArray
                label=""
                fullWidth
              />
            </ReferenceArrayInput>
          </>,
          breakpoints: { xs:4 }
        },
        {
          field: <></>,
          breakpoints: { xs:4 }
        },
      ]
    },
  ];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          padding: '20px 16px',
          gap: 1,
        }}
      >
        <button
          onClick={() => goBack()}
          style={{
            display: 'flex',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <MdArrowBack size={30} />
        </button>
        <Typography fontSize={'32px'} fontWeight={600}>
          {isEdit ?'Edit User - '+ record?.name : 'New User'}
        </Typography>
      </Box>
      <AdaptableFormRedesign
        isEdit={isEdit}
        data={data}
        from={from}
        icon={false}
        saveBackground={true}
        showDelete
      />
    </>
  );
};

export default FormAdmins;