import { COLORS } from '../../assets/constants/theme';
// Estilo del SWITCH tipo iOS
export const switchStyleIos = {
  zIndex: 9999,
  width: 60,
  height: 30,
  padding: 0,
  ':hover': {
    borderRadius: 30 / 2,
    border:'0px solid transparent',
    boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.5)',
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(30px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: 'grey',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 17,
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    backgroundColor: '#E9E9EA',
    transition: '#ffffff 500ms',
  },
};
// En caso de que necesite modificar el border de los inputs y el color del fondo/texto >
export const autoCompleteInput = {
  '& .MuiInputBase-root': {borderRadius:'10px'},
  '& .MuiAutocomplete-tag':{
    backgroundColor:COLORS.light.secondary.main,
    color:'white'
  },
  '& .css-1moytyy-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon':{
    color:'white'
  }
};