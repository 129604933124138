export const PROFILE_TOOLTIPS = {
  name: 'Enter the exact name you want to use on all our material',
  category: 'Select the category for your talent',
  country: 'Enter your country of origin',

  tagline: <span>Represent yourself in a simple tagline or sentence</span>,

  pillars: 'Select the pillars that most represent your performance',

  message: <span>Share a short message with our community about your excitement for the festival and what they can expect from your performance</span>,

  profile_picture: <span>Please choose ONE photo to be used for Marketing & Promotional purposes on our Envision App, Website & Program. <br /><br />Important:<br /> - Format: .JPG or .PNG.<br /> - If photo is vertical {'>'} Minimum 1000 pixels width <br />- If photo is horizontal {'>'} Minimum 1000 pixels height <br />- Avoid cropped pictures where part of your face is cut off. We suggest that your face is as centered as possible on the picture and that space is left on every margin.<br /><br /></span>,
  profile_logo: <span> Upload your logo (this one will be used in all communications).
  If possible, also upload your logos in Black and White.
  <br /><br />
  Important:<br />
  Format: .SVG (prefered). If not .PNG. (minimum 1000 pixels width)
  </span>,
  
  expect: <span>Give participants a head’s up over what to expect during your workshop/performance. Highlight how attendees will be interacting with you and one and other.
  </span>,


  main_category: 'Select the category for your participation as a talent.',

  media: {
    current_projects: 'If any, what are some current projects/releases/new content you\'re working on that we can promote?',
    instagram: 'Provide a description of the media you will show on your profile. E.g "My new album"',
    soundcloud: 'Provide a description of the media you will show on your profile. E.g "My new album"',
    soundcloudEmbed: <span><a target="_blank" style={{color:'pink'}} href={'https://www.loom.com/share/5041c07a77ee41d29c2be00016066c7c'} rel="noreferrer">Video Guide</a></span>,
    youtube: 'Provide a description of the media you will show on your profile. E.g "My new album"',
    spotify: <span><a target="_blank" style={{color:'pink'}} href={'https://www.loom.com/share/f8956d295c3d442fb21026a96e5c52eb'} rel="noreferrer">Video Guide</a></span>,
  },
  
  short_bio: 'Write a short bio capturing your background and essence of who you are'
};

export const EVENTS_TOOLTIPS = {

  description: <span>
   Provide a short description of your set/performance/class/workshop/talk. (Max. 200 characters).
    <br />
    E.g.1: &rdquo;Is your inner critique holding you back? Are you getting in your own way of freely expressing yourself? In this workshop, you will feel safe & confident in expressing yourself while leveling up your creative potential&rdquo;. Facilitated by @TalentName
    <br />
E.g.2: &rdquo;Opening our hearts, minds, and bodies in this practice of moving meditation, connecting deeply to ourselves and one another with dance, presence, love, and music!&rdquo;
  </span>,

  picture: <span>You can upload a custom image for your event. If you don&rdquo;t upload an image, your profile picture will be shown.</span>,

  what_to_expect: <span>Provide a brief summary of what attendees can expect from your set/performance/class/workshop talk</span>,

  good_to_know: <span>
    Is there anything you’d like the audience to know or to prepare for before attending your set/performance/class/workshop/talk? Something to make the most out of the expereince
  </span>,

  stage: <span>
    Upon confirmation of your set time, this is where you will see the stage or area where your offering is scheduled to take place. 
  </span>,

  newDescription1 : <span>Please complete the details for your Envision Festival performance. Your input helps guests discover and explore your act via our festival app.</span>,
  newDescription2 : <span>Complete all sections, especially tags and descriptions, to enhance visibility and attendance.</span>,

  eventName: <span>For music and performance talent please enter your stage/performer name</span>,
  talentName: <span>Select the talent/s involved in this event</span>,
  talentNameCreate: <span>Select the talents you will manage</span>,

  mainCategory: <span>Select the category for your talent</span>,
  eventImage: <span>You can upload a custom Image for your event.<strong style={{color:'black'}}>If you don’t upload an image, your profile picture will be shown</strong>.</span>,
  selectStage: <span>Select the stage where your event will happen</span>,
  selectDay: <span>Select the day for which you want to schedule the event</span>,
  calendarGuide : <div

>
  Helpful tips to help you navigate the calendar:<br /><br />
  <span >
  shift + mousewheel = move timeline left/right<br />
alt + mousewheel = zoom in/out<br />
ctrl + mousewheel = zoom in/out 10× faster<br />
meta + mousewheel = zoom in/out 3x faster (win or cmd + mousewheel)<br />
  </span>
</div>
};

export const EDUCATION_TOOLTIPS = {
  'online_education.online_content': 'Do you currently offer online content? (eBooks, Workshops, Courses, Online Live Events, In person retreats, etc)',
  'online_education.typeof_online_content': 'Select the type of content you offer',
  'online_education.links': 'Each row should include:link to content + description + price range (min + max)'
};

export const USERS_TOOLTIPS = {
  descriptionCreate: <span>Please complete all the information below to create your User, which
  will be shown both on our Website and App.</span>,
  descriptionEdit: <span>This section is used to edit the details of the User, which will
  be shown both on our Website and App.</span>
};
export const VENUES_TOOLTIPS = {
  descriptionCreate: <span>  Please complete all the information below to create your Venue,
  which will be shown both on our Website and App.</span>,
  descriptionEdit: <span>This section is used to edit the details of the Venue, which will
  be shown both on our Website and App.</span>
};
export const LOCATIONS_TOOLTIPS = {
  image: <span>Upload Location Image. Click to select an image that best represents the location</span>,
  icon: <span>Upload your Icon. This pin marks the exact location on the map.</span>
};
export const EXCURSIONS_TOOLTIPS = {
  image: <span>Upload Excursion Image. Click to select an image that best represents the excursion</span>,
  icon: <span>Upload your Icon. This pin marks the exact excursion on the map.</span>,
  book_url : <span>If your activity is booked in another platform enter the url</span>
};