import React,{useState,useEffect} from 'react';
import { usePermissions } from 'react-admin';
import { useParams } from 'react-router-dom';
import { Typography,Box,Button,Alert} from '@mui/material';
import { List,Datagrid,FunctionField,useGetList,useStore ,useRedirect} from 'react-admin';
import { Indicator } from '../../../Components/RedesignComponents/Indicator';
import { MdCheckCircle } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { COLORS } from '../../../assets/constants/theme';
import FormEvents from '../../Events/Form/FormEvents';
import { InputSubtitle } from '../../../Components/StyledComponents/InputSubtitle';
const TabsEventList = () => {
  const [selectedTalent] = useStore('selectedTalent', 'selectedTalent', {
    selected: false,
    talent: '',
  });
  const [viewTable, setViewTable] = useState(true);
  const [eventPicked, setEventPicked] = useState(null);

  const redirect = useRedirect();
  const params = useParams();
  const { permissions: permis } = usePermissions();
  const talent_id = params['*']?.split('/')[1] ?? params.id;
  const newTalent_id = selectedTalent?.talent;
  const {
    data: events,
    loading,
    isLoading,
    isFetching,
    error,
  } = useGetList('events', {
    filter: {
      talent_id: permis !== 'talent_manager' ? [talent_id] : [newTalent_id],
    },
  });
  const handleRedirect = () => {
    redirect('/events/create');
  };
  const handlerViewTable = (record) => {
    setViewTable(!viewTable);
    setEventPicked(record);
  };
  console.log(viewTable,'tabla?');
  return (
    <div style={{marginTop:'3em'}}>
      {viewTable && permis === 'talent_manager' &&
      <Box
        sx={{
          marginBottom: '20px',
          position: 'relative',
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Typography
            variant="h5"
            gutterBottom
            fontWeight={400}
            alignSelf={'flex-start'}
          >
            📅 Events
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight={400}
              sx={{ pb: 2, width: '70%' }}
              alignSelf={'flex-start'}
              gutterBottom
            >
                              This section allows you to edit multiple events
                              of your talent, please provide a
                              detailed description of each event. Bear in mind
                              this will help guests discover and explore your
                              act through the festival app.
            </Typography>
          </div>
          <Alert severity="warning">
            <Typography>
                              Complete all sections, especially tags and
                              descriptions, to enhance visibility and
                              attendance.
            </Typography>
          </Alert>
        </div>
      </Box>}           
      {viewTable && <List actions={null} pagination={false} resource='events' sx={{width:'100%'}}>
        <Datagrid
          empty={ 
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingY: '100px',
                gap: '20px',
              }}
            >
              <InputSubtitle>
                {permis !== 'talent_manager'? 'This talent has no events assigned yet, click here to create one': 'This talent has no events assigned yet.'}
                <br />
              </InputSubtitle>
              {permis !== 'talent_manager'&& <Button
                sx={{
                  backgroundColor: COLORS.light.primary.main,
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: COLORS.light.primary.main,
                    color: '#fff',
                  },
                }}
                variant="filled"
                onClick={handleRedirect}
              >
              Create Event
              </Button>}
            </Box>}
          sx={{
            padding:'0px 20px',
            '& .column-picture.original': {
              width: '15%'
            },
            '& .MuiTypography-root':{
              color:'#000000',
            },
            '& .MuiTableSortLabel-root':{
              color:COLORS.table.headerSorteable,
              fontWeight:400
            },
            '& .column-name': {
              width: '25%',
            },
            '& .column-completed': {
              width: '25%'
            },
            '& .column-status': {
              width: '25%'
            },
            '& .column-start_date': {
              width: '25%'
            } ,
            '& .MuiTableCell-body': {
              borderBottom:'0px'
            },
          }}
          data={events}
          bulkActionButtons={false}
          rowClick={(id,resource,record)=>handlerViewTable(record)}
        >
          <FunctionField source="name" render={(record) => <Typography fontSize={'0.875rem'} fontWeight={'600'}>{record?.name}</Typography>}/>
          <FunctionField label="Complete?" source="completed" render={(record) => 
            <Typography
              sx={{
                fontSize: '.9em',
                fontWeight: '600',
                color: record?.completed ? '#009b00' : '#ffc990',
                textTransform: 'uppercase',
              }}
            >
              {record?.completed ? <MdCheckCircle color='#21B141' size={26}/> : <IoMdCloseCircle color='grey' size={26}/>}
            </Typography> 
          } />
          <FunctionField label="Last Submission" source="status" 
            render={(record) => record?.submission?.status === 'draft'  || record?.submission === null  ? <Indicator /> : (<Indicator data={record?.submission} />)}/>
          <FunctionField label="Scheduled" source="start_date" 
            render={(record) => record?.start_date !== null && record?.end_date !== null && record?.stage_id !== null  ? 
              <Typography fontSize={'0.875rem'}>Scheduled</Typography>: <Typography fontSize={'0.875rem'}>Unscheduled</Typography>}/>

        </Datagrid>
      </List>}
      {!viewTable && <FormEvents from={'talents'}  isEdit={true} eventFromTalent={eventPicked} setViewTable={setViewTable} deleteResource={'events'}/>}
    </div>
  );
};

export default TabsEventList;