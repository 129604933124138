import { Typography } from '@mui/material';
import { FaLightbulb } from 'react-icons/fa';
import { EVENTS_TOOLTIPS } from '../../Resources/TalentForm/Tooltips';


export const AdaptableTooltips = ({from, permis, isEdit}) => {
  return(
    <>
      {/* {
        from === false ||
       from === 'events' && permis !== 'talent_manager' ||
        from === 'talents'  ||
         from === 'venues' ||
          from === 'admins' ? null: from !== 'talents' ||
            from !== 'home_redesign' ? <Typography
              fontWeight={600}
              fontSize={'.9em'}
              sx={{ paddingLeft: '30px', paddingY: 3 }}
            >
              <FaLightbulb color="#ffe600" /> Important
              {from === 'events' || from === 'talents' && permis === 'talent_manager' ? (
                isEdit === false ? (
                  <>
                    <li>
                      {EVENTS_TOOLTIPS['newDescription1']}
                      <br />
                    </li>
                    <li>
                      {EVENTS_TOOLTIPS['newDescription2']}
                    </li>
                  </>
                ) : permis !== 'talent_manager' ? (
                  <>
                    <li>
                      {EVENTS_TOOLTIPS['newDescription1']}
                      <br />
                    </li>
                    <li>
                      {EVENTS_TOOLTIPS['newDescription2']}
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      {EVENTS_TOOLTIPS['newDescription1']}
                      <br />
                    </li>
                    <li>
                      {EVENTS_TOOLTIPS['newDescription2']}
                    </li>
                  </>
                )
              )  : null
              }
            </Typography> :null}  */}
    </>
  );
};