import { Box,Typography } from '@mui/material';
import moment from 'moment';
import { useRecordContext,useRedirect } from 'react-admin';
import React from 'react';
import { MdArrowBack } from 'react-icons/md';

const styledHeaderSubmitter = {
  fontSize: '.9em',
  textAlign: 'start',
  fontColor: 'black',
  width: '100%',
  gap: '50px',
  marginBottom: '10px',
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  color: '#424242',
};
const styleSpan={
  fontWeight: '600',
  color: '#000000',
};


export const NavbarTalentEdit = ({sx, lastSubmitter, lastModified}) => {
  const record = useRecordContext();
  const message = 'Last edited by: ';
  const redirect = useRedirect();

  const goBack = () => {
    redirect('/talents');
  };
  return (
    <>
      <Box
        sx={{...styledHeaderSubmitter, ...sx}}
      >
        <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
          <button
            onClick={() => goBack()}
            style={{
              display: 'flex',
              border: 'none',
              background: 'none',
              cursor: 'pointer',
              alignItems: 'center',
            }}
          >
            <MdArrowBack size={30} />
          </button>
          <Typography fontSize={'32px'} fontWeight={600}>
        Edit Artist - {record?.name}
          </Typography>
        </div>
        {/* <PillInfo status={record?.submission?.status} sx={{gap: '10px', flexDirection: 'row-reverse'}} /> */}
        {lastSubmitter || record?.last_modified_by ? <Typography>{message}<span style={styleSpan}>{lastSubmitter ?? record?.last_modified_by}</span></Typography>:null}
        {lastModified || record?.last_modified ? <Typography>Last modified: <span style={styleSpan}>{lastModified ?? moment(record?.last_modified).format('MMMM Do YYYY')}</span></Typography>:null}
        {/* {(lastSubmitter || record?.last_modified_by) ? message + (lastSubmitter ?? record?.last_modified_by) : null}  */}
      
        {/* {(lastModified || record?.last_modified) ?  'Last modified: ' + (lastModified ?? moment(record?.last_modified).format('MMMM Do YYYY')) : null} */}
      </Box>
    </>
  );
};