import {
  Box,
  CircularProgress,
  Grid,
  Tooltip,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  SimpleForm,
  useEditContext,
  useGetOne,
  usePermissions,
} from 'react-admin';
import { AdaptableTooltips } from './AdaptableTooltips';
import { CompleteSticky } from '../../Resources/TalentForm/CompleteSticky';
import { AdaptableToolbarRedesign } from './AdaptableToolbarRedesign';
import { LineCompleteSticky } from '../RedesignComponents/LineCompleteSticky';
const AdaptableFormRedesign = ({
  simpleform,
  data,
  direction = 'row',
  rowSpacing = 1,
  index,
  from,
  isEdit,
  idToaction,
  progressBarFields,
  icon,
  sx,
  saveBackground,
  mapOn,
  record,
  formCount,
  indexFormCount,
  deleteResource,
  isHome,
  ...rest
}) => {
  const { isLoading } = useEditContext();
  const permis = usePermissions()?.permissions;
  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const defaultValor = useGetOne('events', {id: idToaction});
  const dataEditar= defaultValor?.data;

  return (
    <>
      {/* <AdaptableTooltips from={from} permis={permis} isEdit={isEdit} /> */}
      <SimpleForm
        id={`${index}'adaptable-simple-form'`}
        sx={{ position: 'relative'}}
        defaultValues={dataEditar}
        toolbar={<AdaptableToolbarRedesign isHome={isHome} deleteResource={deleteResource} isEdit={isEdit} {...rest} icon={icon} sx={sx} saveBackground={saveBackground} from={from}/>}
        // Le AGREGO RECORD, ya que lo necesita para renderizar el objeto .submission{}. Sin este mantiene los valores del talento active y no muestra los cambios (
        // Todo esto cuando cliqueamos Edit Submission)
        // record={record}
        sanitizeEmptyValues
        {...simpleform}

      >
        {progressBarFields && (
          <LineCompleteSticky
            elementId={`${index}'adaptable-simple-form'`}
            progressBarFields={progressBarFields}
          />
        )}
        <Box
          id={index}
          sx={{
            maxWidth: 1400,
            width: '100%',
            marginTop:5
          }}
        >
          {data?.map((item, index) => {
            if (item?.noShow) return <div></div>;
            if (item?.title === 'submission') return <div style={{height:'4rem',display:'flex',justifyContent:'flex-end',flexWrap:'wrap',alignContent:'flex-start'}}>{item?.inputs[0]?.field}</div>;
            return (
              <Grid
                container
                direction={'row'}
                sx={{
                  margin: '0px 0px 100px 0px',
                  display: 'flex',
                  minHeight: '200px',
                  justifyContent: 'space-between',
                  
                }}
                key={`AdaptableFormKey-${index}`}
              >

                {item?.inputs?.map((input, index,key) => {
                  // Esto es un condicional para no mostrar el boton de ADD en las home components en aquellos formularios que no sean los ultimos del total renderizado.
                  if (input?.title === 'add' && formCount - 1 !== indexFormCount) {
                    return null;
                  }
                  if (input?.noShow) return null;
                  return (
                    <Tooltip
                      placement={input?.placement ?? 'top'}
                      title={input?.tooltip ? input?.tooltip : ''}
                    >
                      {input?.field?.type === 'div' ? (
                        <Grid
                          key={key}
                          item
                          {...input?.breakpoints}
                        >
                          {input?.field}
                        </Grid>
                      ) : input?.title === 'add' && formCount -1 === indexFormCount ?<>{input?.field}</> : (
                        <Grid
                          key={key}
                          sx={{
                            padding: '0px 10px',
                          }}
                          item
                          {...input?.breakpoints}
                        >
                          {input?.field}
                        </Grid>
                      )}
                    </Tooltip>
                  );
                })}
              </Grid>
            );
          })}
        </Box>
      </SimpleForm>
    </>
  );
};

export default AdaptableFormRedesign;
