import { Box, Button, Modal, Typography } from '@mui/material';
import { mainColor } from '../../../Theme';
import { InputTitle } from '../../../Components/StyledComponents/InputTitle';
import { useRef, useState } from 'react';
import { styledBox } from '../../../Components/ReportButtons/ModalReportComponent';
import HomeImg from '../../../assets/images/Home-NUEVA.png';
import PopupBackgroundImage from '../../../assets/images/Envision2023_Gucci_DroneSunset.png';
import ActionImage from '../../../assets/images/Mask-group.png';
import FontColor from '../../../assets/images/Rectangle.png';
import Cross from '../../../assets/images/Frame-34.png';
import { useRecordContext } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { COLORS } from '../../../assets/constants/theme';

export const PopupShow = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef();
  return (
    <>
      <InputTitle>Preview the popup</InputTitle>
      <Button  
        onClick={() => setOpen(true)}
        sx={{
          marginTop: '15px',
          backgroundColor: mainColor,
          padding:'5px 15px',
          color: '#fff',
          '&:hover': {
            color: mainColor
          }
        }}>
    Preview
      </Button>
      <ModalPopup ref={ref} open={open} setOpen={setOpen} />
    </>
  );
};


export const ModalPopup = (props) => {
  const {
    open,
    setOpen,
    ref
  } = props;
  const record = useRecordContext();
  const { getValues } = useFormContext();
  console.log(getValues()?.popup);
  const styledModal = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    maxHeight: '700px',
    maxWidth: '300px',
    padding: '10px 20px',
    backgroundColor: '#fff',
    position: 'absolute',
    top: '50%',
    borderRadius: '10px',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '100%',
    backgroundImage: `url(${HomeImg})`,
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      borderRadius: '10px',
      top: 0,
      left: 0,
      width: 'inherit',
      height: 'inherit',
      backgroundColor: 'rgba(0, 0, 0, 0.3)', 
    }
  };

  return(
    <Modal
      onClose={() => setOpen(false)}
      open={open}
      ref={ref}
    >
      <Box
        sx={styledModal}
      >
        <Box
          sx={{
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '250px',
            height: '250px',
            maxHeight: '700px',
            maxWidth: '300px',
            borderRadius: '12px',
            backgroundColor: '#fff',
            backgroundImage: `url(${getValues()?.popup?.background_image ?? PopupBackgroundImage})`,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            justifyContent: 'center',

            '&::before':{
              content: '""',
              display: 'block',
              position: 'absolute',
              borderRadius: '10px',
              top: 0,
              left: 0,
              width: 'inherit',
              height: 'inherit',
              backgroundColor: getValues()?.popup?.background_image ? 'rgba(255, 255, 255, 0.8)' : '', 
            }
          }}
        >
          <img style={{position: 'absolute', top: 0, right: 0, width: '30px', height: '30px', padding:'5px'}} src={Cross} />

          <Box
            sx={{
              width: '200px',
              height: '50px',
              display: 'flex',
              textAlign: 'center',
              alignItems: 'center',
              zIndex: 9999
            }}
          >
            
            <Typography variant="h7" sx={{
              width: '100%',
              fontFamily: '"Moret-Book"',
              textTransform: 'uppercase',
              fontSize: 'calc(52px / 2)', 
              fontWeight: '600',
              textAlign: 'center',
              backgroundImage: `url(${FontColor})`,
              backgroundClip: 'text',
              '-webkit-background-clip': 'text',
              color: 'transparent',
            }}>{getValues()?.popup?.title ?? 'Title'}</Typography>
          </Box>
          <Box
            sx={{
              width: '200px',
              height: 'auto',
              display: 'flex',
              textAlign: 'center',
              zIndex: 9999

            }}
          >
            
            <Typography variant="h7" sx={{
              width: '100%',
              fontFamily: '"Moret-Book"',
              letterSpacing: '1px',
              color: COLORS.light.primary.main, 
              fontSize: 'calc(65px / 2)', 
              fontWeight: '600',
            }}>{getValues()?.popup?.subtitle ?? 'Subtitle'}</Typography>
          </Box>
          <Box
            sx={{
              width: '200px',
              height: '60px',
              textAlign: 'center',
              display: 'flex',
              zIndex: 9999

            }}
          >
            
            <Typography variant="h7" sx={{
              width: '100%',
              color: '#00000', 
              fontSize: 'calc(24px / 2)', 
              fontFamily: 'Open-Sans',
              fontWeight: '400',
            }}>{getValues()?.popup?.text??'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin iaculis, velit vel.'}</Typography>
          </Box>
          <Box
            sx={{
              backgroundImage: `url(${ActionImage})`,
              width: '200px',
              height: '40px',
              borderRadius: '12px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              display: 'flex',
              flexDirection: 'row',
              alignContent: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
              zIndex: 9999

            }}
          >
            
            <Typography variant="h7" sx={{
              width: '100%',
              // fontFamily: 'Hypatia-Sans',
              textTransform: 'uppercase',
              color: mainColor, 
              fontSize: '1.2em', 
              fontWeight: '500',
            }}>{getValues()?.popup?.call_to_action_text ??'Learn More'}</Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};