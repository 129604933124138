import { ReferenceArrayInput, required } from 'react-admin';
import InputAutocomplete from './InputAutocomplete';
import { EVENTS_TOOLTIPS } from '../../Resources/TalentForm/Tooltips';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AiOutlineWarning } from 'react-icons/ai';


export const MessageAutocomplete = (props) => {
  const { permis, setTalentMessage } = props;
  const { getValues } = useFormContext();
  return (
    <ReferenceArrayInput reference="talents" source="talents_ids">
      <InputAutocomplete
        title="Talent"
        required={true}
        subtitle={EVENTS_TOOLTIPS['talentName']}
        freeSolo={false}
        optionText="name"
        isArray
        label=""
        fullWidth
        disabled={permis !== 'talent_manager' ? false : true}
        validate={permis !== 'talent_manager' ? [required()] : null}
        onChange={(e, v) => setTalentMessage(v?.find(item => !item.completed))}
      />
    </ReferenceArrayInput>
  );
};

export const MessageInput = (props) => {

  const styleWarning = {
    'background-color': '#fff59d',
    'border-radius': '5px',
    'box-shadow': 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
    'width': '100%',
    'margin-bottom': '20px',
    'padding': '15px',
    'display': 'flex',
    'flex-direction': 'row',
    'justify-content': 'space-between',
    'align-items': 'center'
  };
      
  const styledType = {
    'margin-left': '30px',
    'font-size': '12px', 
    'font-weight': '300', 
    'width': '100%'
  };
      
  return(
    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative'}}>
      <Box style={{...styleWarning}}>
        <AiOutlineWarning size={30} />
        <Typography style={{...styledType}}>
        One of the talents of this event has either not been completed or is not visible yet. You will be able to schedule the event but this talent will not be visible in your app until it’s complete and visible. 
        </Typography>
      </Box>
    </Box>
  );
};