import { Box, Button, Typography } from '@mui/material';
import { useRecordContext, useRedirect, useResourceContext, useStore } from 'react-admin';
import { breakpoints,mainColor } from '../../Theme';
import { useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { COLORS } from '../../assets/constants/theme';

export const ReviewActions = ({switchBody, setSwitchBody, draft}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const setEditSubmission = useStore('edit_submission', false);
  const resource = useResourceContext();
  const redirectProfile = () => {
    setEditSubmission[1](true);
    return redirect('edit', resource, record?.id);
  };
  return(
    <Box sx={{marginBottom:'20px'}}>
      <RadioSelect switchBody={switchBody} setSwitchBody={setSwitchBody} />
      <div style={{display:'flex',width:'100%',justifyContent:'flex-end'}}>
        <Button sx={{  
          width: '200px',
          borderRadius:'10px',
          fontWeight: '700',
          fontSize:'16px',
          display: 'flex',
          height: '49px',
          alignItems: 'center',
          textAlign: 'center',
          cursor: 'pointer',
          justifyContent: 'center',
          backgroundColor: COLORS.light.primary.main,
          textTransform:'none',
          gap:'10px',
        }} variant="contained" onClick={redirectProfile}startIcon={<FaRegEdit size="20px" />} >Edit Submission</Button>
      </div>
    </Box>
  );
};

export const RadioSelect = ({ switchBody, setSwitchBody, ...props }) => {
  const [selectedValue, setSelectedValue] = useState(0); 
  
  const choices = ['Submission', 'Active Version'];
  
  const handleChange = (i) => {
    setSelectedValue(i);
    setSwitchBody(i === 1);
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        [breakpoints.breakpoints.down('tablet')]: {
          flexDirection: 'row',
        },
        width: '100%',
        justifyContent: 'center',
        borderRadius: '5px',
        float: 'left',
        height: '4%',
        marginBottom:'10px'
      }}
    >
      {choices &&
          choices.map((item, i) => (
            <Button
              key={i}
              type="button"
              multiple={false}
              variant="text"
              onClick={(e) => {
                handleChange(i);
              }}
              sx={{
                display:'flex',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                padding: '10px',
                borderRadius: 0,
                width:i === 0 ?'70%' :'50%', 
                borderBottom: `3px solid ${selectedValue === i ? mainColor : 'transparent'}`, 
                boxSizing: 'border-box',
              }}
            >
              <Typography
                sx={{
                  marginLeft: i === 1 ? '10px': 0,
                  color: selectedValue === i ? mainColor : 'grey',
                  fontWeight: '600',
                  fontSize: '.9em',
                  ...props.nameStyle,
                }}
              >
                {item}
              </Typography>
            </Button>
          ))}
    </Box>
  );
};