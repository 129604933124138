import { useState,useEffect } from 'react';
import axios from 'axios';
// Hooks & Utils
import { COLORS } from '../../../../assets/constants/theme';
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
//Components
import InputRedesignImageAws from '../../../../Components/RedesignComponents/Inputs/InputRedesignImageAws.jsx';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle.jsx';
import InputText from '../../../../Components/InputsForm/InputText.jsx';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import { switchStyleIos } from '../../../../Components/StyledComponents/Inputs';
import DeleteSectionHC from '../../../../Components/RedesignComponents/Inputs/DeleteSectionHC.jsx';
//Material & Icons
import {Typography,Box,Switch,IconButton,Collapse,Chip,Button,Skeleton} from '@mui/material';
import { FaTshirt } from 'react-icons/fa';
import {image4} from '../../../../assets/images/Carousel/index.js';
import { TiAnchorOutline } from 'react-icons/ti';
import { GiCampingTent } from 'react-icons/gi';
import { IoChevronDownSharp } from 'react-icons/io5';

export const QuickLinksForm = ({info,setInfo,setRefetch}) => {
  const [openSection,setOpenSection]= useState(true);
  const [showSection, setShowSection] = useState(undefined);
  const [formCount, setFormCount]= useState([1]);
  const customToast = useCustomToasty();

  const handlerForm = ()=>{
    setFormCount([...formCount, 1]);
  };
  const data=()=>{
    return [
      {
        title: '',
        inputs: [
          {
            field: (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  alignContent: 'flex-start',
                }}
              >
                <Box>
                  <Typography
                    fontWeight={700}
                    fontSize={'16px'}
                    color={COLORS.light.secondary.main}
                  >
                  QUICKLINKS
                  </Typography>
                  <Typography fontWeight={300} fontSize={'14px'}>
                  Add the icons and links that you want to show in the quicklink’s section.
                  </Typography>
                </Box>
                <img
                  src={image4}
                  style={{
                    height: '290px',
                    objectFit: 'contain',
                    marginTop: '20px',
                  }}
                  alt="image1"
                  loading="lazy"
                />
              </div>
            ),
            breakpoints: { xs: 3 },
          },
          {
            field: (
              <>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                  <div>
                    <InputTitle
                      value={
                        <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                      EXAMPLE
                        </Typography>
                      }
                    />
                    <div style={{padding:'5px',margin:'15px 0px',width:'100%',display:'flex',flexDirection:'row',gap:2}}>
                      <Chip sx={{width:'50%'}} label={'Excursion'} icon={<TiAnchorOutline/>}/>
                      <Chip sx={{width:'50%'}} label={'Merch'} icon={<FaTshirt/>}/>
                      <Chip sx={{width:'50%'}} label={'Accommodation'} icon={<GiCampingTent/>}/>
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {formCount.map((item,index)=> {
                    return(
                      <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }} key={index}>
                        <div>
                          <InputTitle
                            value={
                              <Typography
                                fontWeight={600}
                                fontSize={'16px'}
                                color={'#000'}
                              >
                              ICON
                              </Typography>
                            }
                          />
                          <InputRedesignImageAws
                            info={info}
                            sx={{ marginTop: '10px',height:'auto',backgroundColor:'#d4d4d4'}}
                            noOption={true}
                            source={`home.quicklinks.items[${index}].icon`}
                            type={'icon'}
                            label=""
                            width={'130px'}
                            widthImage={'130px'}
                            heightImage={'55px'}
                          />
                        </div>
                        <div>
                          <InputText
                            source={`home.quicklinks.items[${index}].text`}
                            label=""
                            title={
                              <Typography
                                fontWeight={600}
                                fontSize={'16px'}
                                color={'#000'}
                              >
                             NAME
                              </Typography>
                            }
                          />
                        </div>
                        <div>
                          <InputText
                            source={`home.quicklinks.items[${index}].link`}
                            label=""
                            title={
                              <Typography
                                fontWeight={600}
                                fontSize={'16px'}
                                color={'#000'}
                              >
                            LINK
                              </Typography>
                            }
                          />
                        </div>
                        <div style={{marginTop:'34px'}}>
                          <DeleteSectionHC index={index} formCount={formCount} setFormCount={setFormCount} info={info} setInfo={setInfo} source={'quicklinks'}/>
                        </div>
                      </div>
                    );
                  })}
                  <div>
                    <Button  variant="text" onClick={handlerForm}>
                      <span
                        style={{
                          fontWeight: 600,
                          color: COLORS.light.secondary.main,
                        }}
                      >
                        + Add QuickLinks
                      </span>
                    </Button>
                  </div>
                </div>
              </>
            ),
            breakpoints: { xs: 8 },
          },
        ],
      },
    ];
  }  ;
  const onSubmit = async (data) => {
    const newData ={home:{quicklinks:{items:[...data.home.quicklinks.items]}}};
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      },
      data: newData
    })
      .then(res => {
        customToast('success', 'Changes submitted succesfuly.');
        setRefetch(prevState => !prevState);
      })
      .catch(err => {
        customToast('error', 'There was an error, please try again later.');
      });
  };

  const handleOpenSection = () => {
    setOpenSection(!openSection);
  };
  const activeShow = () => {
    const newData = {...info, home: {...info?.home, quicklinks: { show: !showSection}}};
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      data: newData,
    })
      .then((res) => {
        customToast('success', 'Changes submitted successfully.');
        setShowSection(!showSection);
      })
      .catch((err) => {
        customToast('error', 'There was an error, please try again later.');
      });
      
  };
  useEffect(()=>{
    let formCount = info?.home?.quicklinks?.items?.length;
    setFormCount(Array.from({ length: formCount }, (_, index) => index));
  },[info]);
  useEffect(()=>{
    let showTrue = info?.home?.quicklinks?.show;
    setShowSection(showTrue);
  },[info]);
  return(
    <>
      <Box sx={{width:'100%',padding:'20px 16px', height:'5%', backgroundColor:COLORS.forms.header.background,display:'flex',flexDirection:'row',justifyContent:'space-between' }}>
        <Typography fontSize={'24px'} fontWeight={400} color={'#313131'}>Quicklinks</Typography>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:15}}>
          {showSection === undefined ?<Skeleton variant="circular" width={40} height={40} />:
            <Switch sx={switchStyleIos} onClick={activeShow} checked={showSection}/>}
          <IconButton onClick={handleOpenSection}>
            <IoChevronDownSharp size={24} />
          </IconButton>
        </div>
      </Box>
      <Collapse in={openSection} timeout={500}>
        <AdaptableFormRedesign
          simpleform={{ record: info, onSubmit: onSubmit }}
          data={data()}
          isEdit
          floating
          from={false}
          icon={true}
        />
      </Collapse>
    </>
  );
};