import React from 'react';
import FormActivities from './FormActivities';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';

const EditActivities = () => {
  return (
    <EditFormCustom mode="onChange" redirect='list'>
      <FormActivities from={'activities'} isEdit={true} />
    </EditFormCustom>
  );
};

export default EditActivities