import React, { useEffect, useState } from 'react';
import axios from 'axios';
//Hooks & Utils
import { COLORS } from '../../assets/constants/theme';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
// Components
import { CountdownForm } from './Sections/Countdown/CountdownForm';
import { CarouselForm } from './Sections/Carousel/CarouselForm';
import { AnnouncementForm } from './Sections/Announcement/AnnouncementForm';
import { QuickLinksForm } from './Sections/QuickLinks/QuickLinksForm';
import { BannerForm } from './Sections/Banner/BannerForm';
import { FeaturedForm } from './Sections/Featured/FeaturedForm';
import { HighlightedExternalContent } from './Sections/HighlightedExternalContent/HighlightedExternalContent';
// Materials & Icons
import {Typography,Box} from '@mui/material';

export const HomeComponents = () => {
  const [info, setInfo] = useState(null);
  const [categories,setCategories]=useState(null);
  const [refetch,setRefetch]=useState(false);
  const customToast = useCustomToasty();
  const getInfo = () => axios({
    url: `${process.env.REACT_APP_BASE_PATH}/info`,
    method: 'GET',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Expose-Headers': 'content-range',
      'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
      'x-api-key': process.env.REACT_APP_API_KEY ,
    }
  }).then(res => {
    setInfo(res.data.results[0]);
  }).catch(err => {
    throw err;
  });
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_BASE_PATH}/posts/categories`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      }
    }).then(res => {
      setCategories(res.data.results);
    }).catch(err => {
      throw err;
    });
  },[]);
  useEffect(()=>{
    getInfo();
  },[refetch]);
  return(
    <>
      <Box sx={{
        '& .css-9p3jjj-MuiCardContent-root-RaSimpleForm-root':{
          paddingBottom:0
        }
      }}>
        <Box sx={{marginBottom:10,padding:'20px 55px', height:'80px', backgroundColor:COLORS?.forms?.header.background}}>
          <Typography fontSize={'32px'} fontWeight={600}>Customize your app Home</Typography>
        </Box>
        <div style={{display:'flex',flexDirection:'column', gap:0,padding:'0px 3em'}}>
          <CarouselForm info={info && info} setInfo={setInfo}/>
          <AnnouncementForm info={info && info}/>
          <QuickLinksForm info={info && info} setInfo={setInfo} setRefetch={setRefetch}/>
          <BannerForm info={info && info} setInfo={setInfo} setRefetch={setRefetch}/>
          <CountdownForm info={info && info}/>
          <FeaturedForm info={info && info } categories={categories}/>
          <HighlightedExternalContent info={info && info} setInfo={setInfo}/>
        </div>
      </Box>
    </>
  );
};