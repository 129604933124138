// EVENTS required FIELDS
export const progressBarFieldsEvents = [
  'name',
  'description',
  'tags',
  'category',
  'what_to_expect',
  'good_to_know',
];
// PROFILE required FIELDS
export const progressBarFieldsTalents = [
  'name',
  'country',
  'tagline',
  'short_bio',
  'pillars',
  'main_category',
  'picture.original',
];
