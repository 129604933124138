import { useState } from 'react';
import axios from 'axios';
// Hooks & Utils
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
import { COLORS } from '../../../../assets/constants/theme';
// Components
import {InputPillsSelect, InputSwitchRedesign} from '../../../../Components/RedesignComponents/Inputs/index.js';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
// Material & Icons
import {Typography,Box,IconButton,Collapse,Switch} from '@mui/material';
import { image6,image7 } from '../../../../assets/images/Carousel/index.js';
import { IoChevronDownSharp } from 'react-icons/io5';
import InputDndArticles from '../../../../Components/RedesignComponents/Inputs/InputDnDArticles.jsx';


export const FeaturedForm = ({info,categories}) => {
  const [openSection,setOpenSection]= useState(true);
  const [pickedF1, setPickedF1] = useState();
  const [pickedF2, setPickedF2] = useState();
  const customToast = useCustomToasty();

  const data = [
    {
      title: '',
      inputs: [
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={COLORS.light.secondary.main}
                >
                  FEATURED CATEGORY
                </Typography>
                <Typography fontWeight={300} fontSize={'14px'}>
                Long horizontally scrollable featured article category section
                </Typography>
              </Box>
              <img
                src={image6}
                style={{
                  height: '290px',
                  objectFit: 'contain',
                  marginTop: '20px',
                }}
                alt="image1"
                loading="lazy"
              />
            </div>
          ),
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <>
              <InputPillsSelect
                info={info}
                setPickedF1={setPickedF1}
                pickedF1={pickedF1}
                setPickedF2={setPickedF2}
                pickedF2={pickedF2}
                choices={categories && categories}
                source="home.featured_categories[0]"
                featured={'1'}
                reference="posts/categories"
                label=""
                title={
                  <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                    SELECT THE CATEGORY YOU WANT TO FEATURE
                  </Typography>
                }
              />
              <div style={{marginTop:'20px'}}>
                <InputSwitchRedesign
                  info={info}
                  section={0}
                  title={
                    <Typography
                      fontWeight={600}
                      fontSize={'16px'}
                      color={'#000'}
                      marginBottom={'10px'}
                    >
                    SHOW
                    </Typography>
                  }
                />
              </div>
            </>
          ),
          breakpoints: { xs: 8.5 },
        },
      ],
    },
    // {
    //   title: '',
    //   inputs: [
    //     {
    //       field: (
    //         <div
    //           style={{
    //             width: '100%',
    //             height: '100%',
    //             display: 'flex',
    //             flexDirection: 'column',
    //             justifyContent: 'flex-start',
    //             alignItems: 'flex-start',
    //             alignContent: 'flex-start',
    //           }}
    //         >
    //           <Box>
    //             <Typography
    //               fontWeight={700}
    //               fontSize={'16px'}
    //               color={COLORS.light.secondary.main}
    //             >
    //               FEATURED ARTICLES
    //             </Typography>
    //             <Typography fontWeight={300} fontSize={'14px'}>
    //             Big Images, horizontally scrollable. Only Articles.
    //             </Typography>
    //           </Box>
    //           <img
    //             src={image7}
    //             style={{
    //               height: '290px',
    //               objectFit: 'contain',
    //               marginTop: '20px',
    //             }}
    //             alt="image1"
    //             loading="lazy"
    //           />
    //         </div>
    //       ),
    //       breakpoints: { xs: 3 },
    //     },
    //     {
    //       field: (
    //         <>
    //           <InputDndArticles
    //             text={'Texto de prueba'}
    //             info={info}
    //             choices={categories && categories}
    //             source="home.featured_categories[1]"
    //             reference="posts/categories"
    //             label=""
    //             title={
    //               <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
    //                 SELECTED ARTICLES
    //               </Typography>
    //             }
    //             subtitle={
    //               'You will see the articles you’ve previously selected. Drag them to order them as you want them to appear'
    //             }
    //           />
    //           <div style={{marginTop:'20px'}}>
    //             <InputSwitchRedesign
    //               info={info}
    //               section={1}
    //               title={
    //                 <Typography
    //                   fontWeight={600}
    //                   fontSize={'16px'}
    //                   color={'#000'}
    //                   marginBottom={'10px'}
    //                 >
    //                 SHOW
    //                 </Typography>
    //               }
    //             />
    //           </div>
    //         </>
    //       ),
    //       breakpoints: { xs: 8.5 },
    //     },
    //   ],
    // },
  ];
  const onSubmit = async (data) => {
    console.log(data)
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      },
      data: data

    })
      .then(res => {
        customToast('success', 'Changes submitted succesfuly.');
      })
      .catch(err => {
        customToast('error', 'There was an error, please try again later.');
      });
  };
  const handleOpenSection = () => {
    setOpenSection(!openSection);
  };

  return(
    <>
      <Box sx={{width:'100%',padding:'20px 16px', height:'5%', backgroundColor:COLORS.forms.header.background,display:'flex',flexDirection:'row',justifyContent:'space-between' }}>
        <Typography fontSize={'24px'} fontWeight={400} color={'#313131'}>Featured Posts</Typography>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:15}}>
          <IconButton onClick={handleOpenSection}>
            <IoChevronDownSharp size={24} />
          </IconButton>
        </div>
      </Box>
      <Collapse in={openSection} timeout={500}>
        <AdaptableFormRedesign simpleform={{record: info, onSubmit: onSubmit}} data={data} isEdit floating from={false} icon={true} saveBackground={false}/>
      </Collapse>
    </>
  );
};