export const COLORS = {
  light: {
    primary: {
      main: '#153B34'
    },
    secondary: {
      main: '#2E7477',
      light:'#2e747780',
      ultraLight:'#2e747733',
      // main: '#00d2a6'
    },
    chart: {
      main: '#F5F5F5',
      // main: '#00d2a6'
    },
  },
  dark: {
    primary: {
      main: '#f2f2f2'
    },
    secondary: {
      // main: '#2E7477',
      main: '#00d2a6'
    },
    chart: {
      main: '#3b3b3b',
      // main: '#00d2a6'
    }
  },
  button:{
    background:'#D9D9D9'
  },
  forms:{
    header:{
      background:'#F7F7F7'
    },
    pills:{
      background:'#D9D9D9'
    }
  },
  table:{
    headerSorteable:'#7C7C7C',
    indicators:{
      background:{
        approved:'#BCF8DB',
        rejected:'#F19595',
        pending:'#FEFFC7',
        not_submitted:'#D9D9D9',
        draft:'#BCD7F8',
        inProgress:'#F7C7FF'
      },
      fontColor:{
        approved:'#21B141',
        rejected:'#B12121',
        pending:'#CCB802',
        not_submitted:'#515151',
        draft:'#2149B1',
        inProgress:'#8502C2'
      }
    }
  }

};