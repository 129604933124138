import { Box } from '@mui/material';
import './styles.css';
export const Spinner = () => {
  return (
    <Box 
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(50%, -50%)'
      }}
    >
      <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </Box>
   
  );
};