import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { COLORS } from '../../assets/constants/theme';
ChartJS.register(ArcElement, Tooltip, Legend);


const options = {
  plugins: {
    responsive:true,
    legend: {
      position: 'bottom',
      labels: {
        font: {
          size: '13px',
        },
        boxWidth: 10,
        boxHeight: 10,
      }, // Esto oculta la leyenda
    },
    title: {
      display: true,
      text: 'Submissions Status', 
      font: {
        size: 15, 
        weight: '600', 
      },
      color:'black',
    }
  },
};

export function PiechartStatus({ rejected, pending, approved }) {

  const data = {
    labels: ['Rejected', 'Pending', 'Approved'],
    datasets: [
      {
        label: 'Status forms',
        data: [rejected, pending, approved],
        backgroundColor: [
          COLORS.light.secondary.ultraLight,
          COLORS.light.secondary.light,
          COLORS.light.secondary.main,

        ],
        borderColor: [
          COLORS.light.secondary.ultraLight,
          COLORS.light.secondary.light,
          COLORS.light.secondary.main,

        ],
        borderWidth: 1,
      },
    ],
  };
  return <Pie data={data} options={options} />;
}
