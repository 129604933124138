import 'react-calendar-timeline/lib/Timeline.css';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGetList, useNotify, useRedirect, useUpdate,useStore } from 'react-admin';
import moment from 'moment';
import Timeline,{
  TimelineHeaders,
  SidebarHeader,
  CursorMarker,
  CustomHeader,
} from 'react-calendar-timeline/lib';
import { useDrop } from 'react-dnd';
// make sure you include the timeline stylesheet or the timeline will not be styled
// Hooks & Utils 
import { useAbsolute } from '../../Hooks/usePosition';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { TimeStepsCalendar, defaultKeys, itemPropsDefaults, styleTitleDate } from '../../assets/constants/Calendar';
import { animateScroll, checkCanMove, formattedItemsCalendar } from '../../helpers/calendar';
// Components
import GroupItemCalendar from './GroupItemCalendar';
import CalendarItem from './CalendarItem';
import ModalItemBottom from './ModalItemBottom';
import ModalAddSecondaryItems from './ModalAddSecondaryItems';
import { CalendarHeaderBox } from './CalendarHeaderBox';
// Material & Icons
import { Box, Button, IconButton, Typography } from '@mui/material';
import { MdNavigateNext, MdNavigateBefore } from 'react-icons/md';
import './calendarStyles.css';
import { festivalDates } from '../../dataProvider';
import {  convertToUtcMinusSix } from '../../helpers/date';

const CalendarTimeLine = ({
  setDateValue,
  filterDate,
  setViewUnscheduled,
  viewUnscheduled,
  items, 
  secondaryItems,
  groups,
  keys = defaultKeys ,
  setFilterState,
  filters
}) => {
  const [eventEdited,setEventEdited]=useStore('eventEditing',null);
  const [itemList, setItemList] = useState(formattedItemsCalendar(items, itemPropsDefaults, keys));
  const [groupList, setGroupList] = useState(groups);
  const [canvasDClicked, setCanvasDClick] = useState(null);
  // Con estos estados, me guardo datos de eventos
  const [eventsFiltered, setEventsFiltered] = useState(null);

  const customToast = useCustomToasty();
  const scrollRef = useRef();

  const redirect = useRedirect();
  const [update] = useUpdate(undefined, undefined, {
    onError: (err) => {
      customToast('error', err);
    },
    onSuccess: (data) => {
      customToast('success', 'Event updated Succesfuly');
    }
  });
  const ref = useRef(null);
  // Union de Useeffects, antes separados en items y groups.
  useEffect(() => {
    setItemList(formattedItemsCalendar(items, itemPropsDefaults, keys));
  },[items]);

  useEffect(() => {
    setGroupList(groups);
  },[groups]);
  // Festival dates
  // const { data } = useGetList('info',{ pagination: {perPage:9999, page: 1}, filter: {}, sort: {field: 'start_date', order: 'DESC'}});
  
  const defaultDates = useMemo(() => {
    let start = moment(items?.[0]?.[defaultKeys?.itemTimeStartKey]).startOf('date');

    return (
      {
        start: moment(items?.[0]?.[defaultKeys?.itemTimeStartKey]).startOf('date'),
        end: moment(start).add(9, 'hours')
      }
    );
  },[]);
  const handleClickGroup = (group) => {
    if (groupList.length == 1) {
      setGroupList(groups);
    } else {
      setGroupList([group]);
    }
  };
  
  const handleItemMove = (itemId, dragTime, newGroupOrder, itsItem = false) => {
   
    const moveToVenue = (typeof newGroupOrder == 'number') ? groupList[newGroupOrder].id : newGroupOrder;
    if(itsItem){
      let newItem = {...itemId};
      newItem[keys.itemTimeStartKey] = dragTime,
      newItem[keys.itemTimeEndKey] = dragTime + (newItem[keys.itemTimeEndKey] - newItem[keys.itemTimeStartKey]),
      newItem['stage_id'] = moveToVenue;

      update('events', { id: newItem.id, data: newItem }, {
        onSuccess: (data) => {
          let arr = itemList;
          arr.push(data);
          return setItemList(arr);
        },
      });
      return true;
    } else {

      const item = itemList.find(item => item.id === itemId);
      const canMove = checkCanMove(item, itemList, dragTime, moveToVenue, keys, false);
      
      if (canMove) {
        const itemEdit = {
          [keys.itemTimeStartKey]: dragTime,
          [keys.itemTimeEndKey]: dragTime + (item[keys.itemTimeEndKey] - item[keys.itemTimeStartKey]),
        };
        const itemsListEdit = itemList.map(item => item.id === itemId ? Object.assign({}, item, { ...itemEdit, [keys.itemGroupKey]: moveToVenue }) : item);
        update('events', { id: itemId, data: { ...itemEdit, stage_id: moveToVenue } }, {
          onSuccess: (data) => setItemList(itemsListEdit),
          onError: (err) => customToast('error', 'There was an error')
        });
        
        // setItemList(itemsListEdit);
        return true;
      } else {
        customToast('error', 'This time is not available for scheduling an event.');
        return false;
      }
    }
  };

  const handleItemResize = (itemId, time, edge) => {
    const item = itemList.find(item => item.id === itemId);
    const moveToVenue = item[keys.itemGroupKey];
    const canResize = checkCanMove(item, itemList, time, moveToVenue, keys, true);

    if (canResize) {
      let start = edge === 'left' ? time : item[keys.itemTimeStartKey];
      let end = edge === 'left' ? item[keys.itemTimeEndKey] : time;
      let duration = (end - start) / 1000 / 60;

      let itemEdit = {
        [keys.itemTimeStartKey]: start,
        [keys.itemTimeDuration]:duration
      };
      update('events', { id: itemId, data: itemEdit }, {
        onSuccess: (res) => {
          const itemsListEdit = itemList.map(item => item.id === itemId ? Object.assign({}, item, itemEdit) : item);
          return setItemList(itemsListEdit);
        }
      });
      
    } else {
      customToast('error', 'The duration of this event cannot be extended as it overlaps with another event at the same stage during the same time');
    }
  };
  const handleItemDoubleClick = (itemId) => {
    redirect(`/events/${itemId}`);
  };

  const onPrevClick = () => {
    animateScroll(scrollRef, true);
  };

  const onNextClick = () => {
    animateScroll(scrollRef, false);
  };

  const handleCanvasDoubleClick = (groupId, time, e) => {
    setCanvasDClick({ groupId, time });
  };

  const isSelectedGroup = (group) => {

    return (groupList.length == 1 && groupList[0].id == group.id);

  };

  const handleAddPrevItem = (item) => {
    setItemList((prev) => [...prev, item]);
  };

  const handleDeletePrevItem = (item) => {
    // const itemListFilterItem = itemList.filter(i => i.id !== item.id);
    setItemList((prev) => prev.filter(i => i.id !== item.id));
    // setItemList(itemListFilterItem);
  };


  // Logica para drag and drop de unscheduled events
  const [visibleTimeStart, setVisibleTimeStart] = useState(moment(festivalDates?.start_date).startOf('date').valueOf());
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(moment(festivalDates?.start_date).startOf('date').add(24, 'hours').valueOf());
  const [droppedPosition, setDroppedPosition] = useState(null);
  const [venue, setVenue] = useState(null);

  useEffect(() => {
    setVisibleTimeStart(moment(festivalDates?.start_date).startOf('date').valueOf());
    setVisibleTimeEnd(moment(festivalDates?.start_date).startOf('date').add(24, 'hours').valueOf());
  },[festivalDates]);

  const defaultTimeRange = Math.floor(visibleTimeStart - visibleTimeEnd);
  
  const click = (x, y) =>  {
    const parentRef = scrollRef.current.getBoundingClientRect();
    let pointX = parentRef.left + window.pageXOffset;
    let pointY = parentRef.top + window.pageYOffset;

    let ev = new MouseEvent('click', {
      'view': window,
      'bubbles': true,
      'cancelable': true,
      'clientX': pointX,
      'screenX': pointX,
      'screenY': pointY,
      'clientY': pointY
    });
    let el = document.elementFromPoint(x, y);
    el.dispatchEvent(ev);
  };

  const [{ canDrop, isOver, dropped, draggedItem }, drop] = useDrop({
    accept: 'ITEM',
    drop: (item, monitor) => {
      
      const clientOffset = monitor.getClientOffset();
      const parentRef = scrollRef.current.getBoundingClientRect();
      const x = clientOffset.x - parentRef.left;
      const y = clientOffset.y - parentRef.top;
      setDroppedPosition({x: x, y: y});
      click(clientOffset.x, clientOffset.y);
    },
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
      dropped: monitor.getDropResult(),
      draggedItem: monitor.getItem()
    }),
  });

  useEffect(() => {
    if(filterDate){
      setVisibleTimeStart(moment(filterDate));
      setVisibleTimeEnd(moment(filterDate).add(24, 'hours'));
    }
  },[filterDate]);

  const handleTimeChange = (_start, _end) => {
    if(_start < moment(festivalDates.start_date).valueOf() || _end > moment(festivalDates.end_date).add(1, 'days').valueOf()) return;
    setDateValue(null);
    setVisibleTimeStart(_start);
    setVisibleTimeEnd(_end);
  };
  
  const handleX = (width, xPosition) => {
    const distanceRange = width;
    const timeRange = visibleTimeEnd - visibleTimeStart;
    const time = visibleTimeStart + (xPosition / distanceRange) * timeRange;
    return time;
  };

  const headerDayDiff = moment(festivalDates.end_date).add(1, 'days').diff(festivalDates.start_date, 'days');

  useEffect(() => {
    if(itemList.length > 0 && dropped){
      const width = scrollRef.current.getBoundingClientRect();
      const startingTime = Math.ceil(handleX(width.right, droppedPosition?.x));
      let newItem = {
        ...draggedItem,
        [keys.itemTimeDuration]: draggedItem[keys.itemTimeDuration] ? draggedItem[keys.itemTimeDuration] : 15,
        [keys.itemTimeStartKey] : moment(startingTime).format('YYYY-MM-DDTHH:mm'),
        [keys.itemTimeEndKey] : draggedItem?.[keys.itemTimeEndKey] ?? moment(startingTime).add(15, 'minutes').format('YYYY-MM-DDTHH:mm'),
        ['type']: draggedItem['envType'],
        stageFormat: venue
      };
      console.log(newItem);
      delete newItem.envType;
      handleItemMove(newItem, startingTime, venue, true);
    }

  },[dropped, draggedItem]);

  // Funcion que se encarga de moverme en el timeline al primer item filtrado
  const moveTimelineToFirstFilteredItem = () => {
    if (
      !filters.filterCategorys?.length &&
      !filters.filterTalents?.length &&
      !eventEdited
    ) {
      setGroupList([...groups]); 
      return; 
    }
  
    if (filters?.filterCategorys?.length >= 1) {
      const filter = filters.filterCategorys[0];
      const filteredItems = itemList.filter((item) => item?.category === filter);
    
      // Los ordeno por fecha de inicio
      const sortedItems = filteredItems.sort((a, b) => moment(a[keys.itemTimeStartKey]) - moment(b[keys.itemTimeStartKey]));
      const closestItem = sortedItems.find((item) => moment(item[keys.itemTimeStartKey]) >= moment('2023-03-04'));
      setEventsFiltered(sortedItems);
      if (closestItem) {
        const findedStage = groupList.find((group) => group?.id === closestItem?.stage_id);
        const startingTime = moment(closestItem[keys.itemTimeStartKey]).valueOf();
        const endingTime = moment(closestItem[keys.itemTimeEndKey]).valueOf();
        setVisibleTimeStart(startingTime);
        setVisibleTimeEnd(moment(endingTime).add(4, 'hours').valueOf());
        // Esto filtra los stages los cuales existan en la lista nueva filtrada por category
        const stagesEnEventos = groupList.filter(stage =>
          sortedItems.some(evento => evento.stage_id === stage.id)
        );
        setGroupList(stagesEnEventos);
      }
    }
    if(filters?.filterTalents?.length >=1 ){
      const filter = filters?.filterTalents[0];
      const filteredItem = itemList?.find((item) => item?.talents_ids[0] === filter);
      console.log(filteredItem);
      if (filteredItem) {
        const startingTime = moment(filteredItem[keys?.itemTimeStartKey]).valueOf();
        const endingTime = moment(filteredItem[keys?.itemTimeEndKey]).valueOf();
        setVisibleTimeStart(startingTime);
        setVisibleTimeEnd(moment(endingTime).add(4, 'hours').valueOf());
        if(filteredItem.stageFormat !== null){
          const findedStage = groupList?.find((group) => group?.id === filteredItem?.stageFormat);
          setGroupList([findedStage]);
        }
      }
    }
    if(eventEdited){
      const filteredItem = itemList.find((item) => item?.id === eventEdited);
      if (filteredItem) {
        const startingTime = moment(filteredItem[keys.itemTimeStartKey]).valueOf();
        const endingTime = moment(filteredItem[keys.itemTimeEndKey]).valueOf();
        setVisibleTimeStart(startingTime);
        setVisibleTimeEnd(moment(endingTime).add(4, 'hours').valueOf());
      }
    }
  };
  
  // Cada vez que filters se actualiza, se mueve el timeline al primer item filtrado
  useEffect(() => {
    moveTimelineToFirstFilteredItem();
  },[filters]);
  //Reset de store:
  useEffect(() => {
    setEventEdited('');
  },[groupList]);
  // Agrego este useEFFECT para que modifique los stages cuando se setea una category y tambien un filtro de stage en ese orden
  useEffect(() => {
    let filtrosVenues = filters?.filterVenues;
    let filtrosCategory = filters?.filterCategorys;

    if(filtrosVenues?.length > 0 && filtrosCategory?.length > 0){
      setGroupList(groups);
    }else if(filtrosCategory?.length > 0){
      const stagesEnEventos = groups?.filter(stage =>
        eventsFiltered.some(evento => evento.stage_id === stage.id)
      );
      setGroupList(stagesEnEventos);
    }
  },[groups]);

  const fixedPosition = useAbsolute(0);

  return (
    <div
      ref={drop}
      id={0}
      style={{height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', position:'relative'}}
    >
      <CalendarHeaderBox
        fixedPosition={fixedPosition}
        handleTimeChange={handleTimeChange}
        visibleTime={visibleTimeStart} 
        dates={{start: festivalDates?.start_date, end: moment(festivalDates.end_date).add(1, 'days')}}
        headerDayDiff={headerDayDiff}
      />
      
      <Timeline
        scrollRef={(e) => (scrollRef.current = e)}
        ref={ref}
        groups={groupList}
        keys={keys}
        items={itemList}
        onTimeChange={handleTimeChange}
        itemTouchSendsClick={false}
        itemHeightRatio={.93}
        defaultTimeStart={defaultDates.start}
        defaultTimeEnd={defaultDates.end}
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        itemRenderer={(props) => <CalendarItem {...props} keys={keys} />}
        timeSteps={TimeStepsCalendar}
        lineHeight={60}
        maxZoom={24 * 60 * 60 * 1000}
        minZoom={60 * 60 * 1000}
        // onItemSelect={handleItemSelect}
        // onItemDeselect={handleItemDeselect}
        onItemMove={handleItemMove}
        onCanvasClick={(e) => setVenue(e)}
        onItemDoubleClick={handleItemDoubleClick}
        onCanvasDoubleClick={handleCanvasDoubleClick}
        groupRenderer={({ group, ...rest }) =>
          <GroupItemCalendar
            {...rest}
            group={group} 
            selected={isSelectedGroup(group)} 
            handleClick={handleClickGroup} 
          />}
        onItemResize={handleItemResize}
        horizontalLineClassNamesForGroup={(group) => group.root ? ['row-root'] : []}
        style={{ 
          border: 'none', 
          boxShadow: (canvasDClicked || viewUnscheduled) ? 'rgba(0, 0, 0, 0.23), 0px 3px 3px' : null,
          width: '100%',
          position: 'absolute',
          top: 120,
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <CursorMarker>
          {({ styles }) =>
            <div style={{ ...styles, backgroundColor: 'rgba(0,0,0,.4)' }} />
          }
        </CursorMarker> 

        <TimelineHeaders className="sticky" style={{position: !fixedPosition ? 'sticky' : 'fixed', top: 120,}} >
          <SidebarHeader variant={'left'} height={100}>
            {() => {
              return <Box style={{ width: 150, backgroundColor: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box>
                  <IconButton onClick={onPrevClick} >
                    <MdNavigateBefore fontSize={36} />
                  </IconButton>
                </Box>
                <Box>
                  <IconButton onClick={onNextClick} >
                    <MdNavigateNext fontSize={36} />
                  </IconButton>
                </Box>

              </Box>;
            }}
          </SidebarHeader>

          <CustomHeader height={10} >
            
            {({
              headerContext,
              getRootProps,
              showPeriod,
              getIntervalProps,
              ...rest
            }) => {
              return (
                <Box style={{ ...getRootProps().style, backgroundColor: '#fff', display: 'flex' }}>
                  {headerContext.intervals.map(interval => {
                    return (
                      <Box
                        {...getIntervalProps({ 
                          interval, 
                          style: { ...styleTitleDate,
                            
                            paddingRight: '84px',
                            borderTopLeftRadius: 12,
                            borderTopRightRadius: 12,
                            display: 'flex', 
                            alignItems: 'center',
                            justifyContent: 'center' 
                          } })}
                        onClick={() => {
                          showPeriod(interval.startTime, interval.endTime);
                        }}
                      >
                        <Typography sx={{fontSize: '.8em', fontWeight: 500, }}>
                          {
                            headerContext.unit == 'hour' ?
                              moment(interval.startTime).format('HH:mm') :
                              moment(interval.startTime).format('HH:mm')
                          }
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              );
            }}
          </CustomHeader>
        </TimelineHeaders> 
      </Timeline>
      {
        (canvasDClicked || viewUnscheduled) && (
          <ModalAddSecondaryItems
            viewUnscheduled={viewUnscheduled}
            setViewUnscheduled={setViewUnscheduled}
            items={secondaryItems}
            scheduledItems={items}
            groupList={groupList}
            canvasClicked={canvasDClicked}
            setCanvasClicked={setCanvasDClick}
            handleAddPrevItem={handleAddPrevItem}
            handleDeletePrevItem={handleDeletePrevItem}
            saveItem={handleItemMove}
            setFilterState={setFilterState}
          />
        )
      }
    </div>
  );
};




export default CalendarTimeLine;