import React, { useEffect, useState } from 'react';
import { useInput} from 'react-admin';
import { useFormContext } from 'react-hook-form';
// Hooks & Utils
import useUploadAws from '../../../Hooks/useUploadAws';
// Material & Icons
import {
  Box,
  FormHelperText,
  Typography,
  ImageList,
  ImageListItem,
  IconButton,
} from '@mui/material';
import { GrGallery } from 'react-icons/gr';
import { IoClose } from 'react-icons/io5';

const InputGalleryAws = ({
  helperText,
  validate,
  label,
  source = 'picture',
  limit,
  sx,
}) => {
  const {getValues} = useFormContext();
  const {field, fieldState } = useInput({ source, validate });
  const [uploadedImages, setUploadedImages] = useState([]);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);

  const { uploadAWS } = useUploadAws();

  const handleClick = async (e) => {
    const fileUpload = await uploadAWS(e);
    if (fileUpload.success) {
      console.log(fileUpload.location,'IMAGEN');
      // Entra aca si ya tiene una imagen en la galeria
      if(uploadedImages !== undefined){
        if(source !== 'home.carousel.items'){
          setUploadedImages([...uploadedImages,{original:fileUpload.location}]);
          field.onChange([...uploadedImages, {original:fileUpload.location}]);
        }else{
          // Para el carousel de HOME COMPONENTS
          setUploadedImages([...uploadedImages,{picture : {original:fileUpload.location},url:fileUpload.location}]);
          field.onChange([...uploadedImages, {picture : {original:fileUpload.location},url:fileUpload.location}]);
        }
  
      }else{
        console.log('second con')
        setUploadedImages([{original:fileUpload.location}]);
        console.log(fileUpload.location,'IMAGEN');
        field.onChange([ {original:fileUpload.location}]);
      }
    }
  };
  const handleRemoveImage = (index) => {
    const updatedImages = [...uploadedImages];
    updatedImages.splice(index, 1);
    setUploadedImages(updatedImages);
    field.onChange(updatedImages);
  };
  useEffect(()=>{
    let actualImages = getValues(source);
    if(actualImages === undefined || actualImages === null){
      setUploadedImages([]);
    }else{
      setUploadedImages(actualImages);
    }
  },[]);
  console.log(uploadedImages,'imagenes actuales');
  return (
    <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, marginBottom: 3 }}>
      <Box sx={{ width: '100%', ...sx }}>
        {label && (
          <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
            {label}
          </Typography>
        )}
        <Box
          sx={{
            color: fieldState.invalid && 'red',
            borderColor: fieldState.invalid && 'red',
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid #D7D7D7',
            borderRadius: '10px',
            width: '100%',
            height: '100%',
            marginTop: '10px',
            padding: uploadedImages?.length > 0 ? '0px' : '10px 20px',
          }}
        >
          {uploadedImages && uploadedImages?.length > 0 && (
            <ImageList
              sx={{
                width: uploadedImages < 3 ? ' 70%' : '100%',
                margin: '0px',
                height: '100%',
              }}
              cols={3}
            >
              {uploadedImages?.map((image, index) => (
                <ImageListItem
                  onMouseEnter={() => setHoveredImageIndex(index)}
                  onMouseLeave={() => setHoveredImageIndex(null)}
                  key={index}
                  sx={{
                    height: '100%',
                    ':hover': { cursor: 'pointer' },
                  }}
                >
                  <img
                    src={source === 'home.carousel.items' ? image?.picture?.original : image?.original}
                    alt={`Uploaded Image ${index + 1}`}
                    loading="lazy"
                    style={{
                      background: 'rgba(0, 0, 0, 0.5)',
                      maxHeight: '170px',
                      width: '100%',
                      opacity: hoveredImageIndex === index ? '0.1' : '1',
                    }}
                  />
                  {hoveredImageIndex === index && (
                    <IconButton
                      onClick={() => handleRemoveImage(index)}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        ':hover': {
                          backgroundColor: '#474747',
                        },
                      }}
                    >
                      <IoClose size={24} color="#ff0000" />
                    </IconButton>
                  )}
                </ImageListItem>
              ))}
            </ImageList>
          )}
          {uploadedImages && uploadedImages?.length < limit && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton color="primary" component="label">
                <GrGallery size={24} />
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleClick}
                  multiple
                />
              </IconButton>
              <Typography fontWeight={700} fontSize={'15px'} color={'#515151'}>
                Add images here
              </Typography>
              <Typography fontWeight={400} fontSize={'13px'} color={'#515151'}>
                Drag images here or click to browse
              </Typography>
            </div>
          )}
          {uploadedImages == undefined || uploadedImages === null ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton color="primary" component="label">
                <GrGallery size={24} />
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  onChange={handleClick}
                  multiple
                />
              </IconButton>
              <Typography fontWeight={700} fontSize={'15px'} color={'#515151'}>
                Add images here
              </Typography>
              <Typography fontWeight={400} fontSize={'13px'} color={'#515151'}>
                Drag images here or click to browse
              </Typography>
            </div>
          ):null}
        </Box>
        {helperText && (
          <FormHelperText sx={{ marginLeft: '10px' }}>
            {helperText}
          </FormHelperText>
        )}
        {fieldState.invalid && (
          <FormHelperText sx={{ color: 'red' }}>
            The Image is required
          </FormHelperText>
        )}
      </Box>
    </Box>
  );
};

export default InputGalleryAws;
