import { TextField, useGetOne } from 'react-admin';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../../Components/InputsForm/InputText';
import { useEffect, useState } from 'react';
import axios from 'axios';


export const FestivalEditForm = () => {
  const [info, setInfo] = useState(null);
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_BASE_PATH}/info`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      }
    }).then(res => {
      setInfo(res.data);
    }).catch(err => {
      throw err;
    });
  },[]);
  
  const data = [
    {
      title: 'Festival',
      inputs: [
        {
          field: <InputText source="name" label="name" />
        },
      ],
      breakpoints: { xs: 8 }
    }
  ];

  return(
    <AdaptableForm simpleform={{record: info}} data={data} isEdit from={false}/>
  );
};