import React,{useState} from 'react';
import { ReferenceInput, required,useRecordContext,useRedirect,useGetList,ReferenceArrayInput } from 'react-admin';
// Hooks & Utils
import { COLORS } from '../../../assets/constants/theme';
// Components
import VenueMap from './Map/VenueMap';
import { InputSubtitle } from '../../../Components/StyledComponents/InputSubtitle';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import { InputTitle } from '../../../Components/StyledComponents/InputTitle';
import InputRedesignImageAws from '../../../Components/RedesignComponents/Inputs/InputRedesignImageAws';
import InputText from '../../../Components/InputsForm/InputText';
import InputSelect from '../../../Components/InputsForm/InputSelect';
import { validateVenues } from './Validation/validate';
import { InputSwitch } from './Map/SwitchInput';
import {InputRichTextCountdown} from '../../../Components/InputsForm/InputRIchTextCountdown';
// Material & Icons
import { Typography,Box } from '@mui/material';
import { MdArrowBack } from 'react-icons/md';
import { LOCATIONS_TOOLTIPS } from '../../TalentForm/Tooltips';
import { InputPillsSelect } from '../../../Components/RedesignComponents/Inputs';
import InputAutocomplete from '../../../Components/InputsForm/InputAutocomplete';

const FormVenues = ({ isEdit,from }) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const [mapOn, setMapOn] = useState(true);
  const {data: typesData} = useGetList('locations/types', {
    pagination: { page: 1, perPage: 100 },
  });
  console.log(typesData)
  const data = [
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>LOCATION DETAILS</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 },
        },
        {
          field: <InputText required={true} fullWidth key={Math.random()} source="name" validate={[required()]} title={'Name'} label={''}/>,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        },
        {
          field:
          <>
            <InputTitle fontWeight={600} fontSize={'16px'} color={'#000'} required={true}>
              DESCRIPTION
            </InputTitle>
            <InputSubtitle sx={{minHeight:'0px'}}>
              Describe thoroughly your location
            </InputSubtitle>
            <InputRichTextCountdown label=' ' fullWidth key={Math.random()} source="description" rows={10} multiline />
          </>,
         
          breakpoints: { xs: 8.5 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        }, 
        {
          field: <> 
            <InputPillsSelect
              required={true}
              choices={typesData && typesData}
              withText={true}
              source="type"
              reference="posts/categories"
              label=""
              validate={[required()]}
              title={'Type'}
              subtitle={'Selec yout location type'}
            /></>,
          breakpoints: { xs: 8.5 }
        }, 
        {
          field: <></>,
          breakpoints: { xs: 3 }
        }, 
        {
          field:<Box style={{marginTop:'30px'}}>
            <ReferenceArrayInput reference="locations/tags" source="tags">
              <InputAutocomplete
                title="Tags"
                required={true}
                subtitle={'Select the tags that best represent the event'}
                freeSolo={false}
                optionText='text'
                optionValue='text'
                isArray
                label=""
                fullWidth
                validate={[required()]}
              />
            </ReferenceArrayInput>
          </Box>,
          breakpoints: { xs: 4}
        },
        {
          field: <></>,
          breakpoints: { xs: 4 }
        }, 
        {
          field: <></>,
          breakpoints: { xs: 3 }
        }, 
        { 
          field: <InputRedesignImageAws from='location' title={'Image'} tooltip={LOCATIONS_TOOLTIPS['image']} source={'picture.original'} actionText="Upload Image"/>,
          breakpoints: { xs: 4 }
        },
        {
          field: 
          <>
            <InputTitle  value={'Map'} />      
            <InputSubtitle>Mark this if your location will be visible in the interactive app map</InputSubtitle>
            <InputSwitch mapOn={mapOn} setMapOn={setMapOn} source='location.geometry'/>
          </>,
          breakpoints: { xs: 4 }
        },
      ]
    },
    {
      title: '',
      noShow:mapOn? false : true,
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>MAP</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 },
        },
        { 
          field: <InputRedesignImageAws title={'Icon'} tooltip={LOCATIONS_TOOLTIPS['icon']} source={'icon.url'} actionText="Upload Pin"/>,
          breakpoints: { xs: 8.5 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        },
        {
          field: <InputText source={'brief_description'} fullWidth counter={150} title={'Brief description'} subtitle={'This is the description that will appear in the map pin'}/>,
          breakpoints: { xs: 8.5 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        },
        {
          field: 
          <>
            <InputTitle  value={'Map Location'} />      
            <InputSubtitle>Select from the map where your location will be, by marking the pin you will be able to see how it looks against other locations</InputSubtitle>
            <VenueMap source={'location.geometry.coordinates'}/>
          </>,
          breakpoints: { xs: 8.5 }
        },
      ]
    },
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>OPEN TIMES</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 },
        },
        // {
        //   field: <InputSelectTimes source={'open_times'} />,
        //   breakpoints: { xs: 8.5 }
        // },
      ]
    }
  ];
  const goBack = () => {
    redirect('/locations');
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          padding: '20px 16px',
          gap: 1,
        }}
      >
        <button
          onClick={() => goBack()}
          style={{
            display: 'flex',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <MdArrowBack size={30} />
        </button>
        <Typography fontSize={'32px'} fontWeight={600}>
          {isEdit  ?'Edit Location - '+ record?.name :'New Location'}
        </Typography>
      </Box>
      <AdaptableFormRedesign
        simpleform={{
          validate: validateVenues
        }}
        isEdit={isEdit}
        from={from}
        data={data}
        mapOn={mapOn}
        saveBackground={true}
        showDelete
      />
    </>
  );
};

export default FormVenues;