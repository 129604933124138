import React from 'react';
import { useRedirect,useRecordContext } from 'react-admin';
// Components
import InputText from '../../../Components/InputsForm/InputText';
import InputRichText from '../../../Components/InputsForm/InputRichText';
import InputBoolean from '../../../Components/InputsForm/InputBoolean';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputCategoryPills from '../../../Components/RedesignComponents/Inputs/InputCategoryPills';
// Hooks & Utils
import { COLORS } from '../../../assets/constants/theme';
// Material & Icons
import { Box,Typography } from '@mui/material';
import { MdArrowBack } from 'react-icons/md';
import { InputTitle } from '../../../Components/StyledComponents/InputTitle';

const FormFaqs = ({ isEdit,from }) => {
  const redirect = useRedirect();
  const record = useRecordContext();
  const data = [
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>INFORMATION</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 }
        },
        {
          field: <InputText required={true} fullWidth source="question" title={'Question'} label=''/>,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputBoolean label="" fullWidth source="_archived" title={'Archived'} isColumn={true}/>,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        },
        {
          field: <>
            <InputCategoryPills
              required={true}
              source="category"
              reference="faqs/categories"
              label=""
              optionText='text'
              optionValue={'text'}
              title='Category'
              sx={{marginTop:'10px'}}
            />
          </>,
          breakpoints: { xs: 8.5 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        },
        {
          field: (
            <>
              <InputTitle required={true}>ANSWER</InputTitle>
              <InputRichText fullWidth source="answer" label=' '
              />
            </>
          )
          ,
          breakpoints: { xs: 8.5 }
        },
      ]
    }
  ];
  const goBack = () => {
    redirect('/faqs');
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          padding: '20px 16px',
          // height: '6.5%',
          gap: 1,
        }}
      >
        <button
          onClick={() => goBack()}
          style={{
            display: 'flex',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <MdArrowBack size={30} />
        </button>
        <Typography fontSize={'32px'} fontWeight={600}>
          {isEdit ?'Edit FAQ - '+ record?.question : 'New FAQ'}
        </Typography>
      </Box>
      <AdaptableFormRedesign
        isEdit={isEdit}
        data={data}
        from={from}
        icon={false}
        saveBackground={true}
        showDelete
      />
    </>
  );
};

export default FormFaqs;