import React, { useState } from 'react';
import { regex, required, ReferenceInput, SelectInput, ReferenceArrayInput } from 'react-admin';
// Hooks & Utils
import { EventsNameComponent } from '../../Resources/TalentForm/CustomComponents/EventsNameComponent';
import { EVENTS_TOOLTIPS } from '../../Resources/TalentForm/Tooltips';
import { ShowEventsSubmissionModal } from '../../Resources/Events/Form/ShowEventsSubmissionModal';
import { COLORS } from './theme';
// Components
import {InputCategoryPills,InputTime,InputRedesignImageAws} from '../../Components/RedesignComponents/Inputs/index';
import { InputDurationMinutes } from '../../Components/InputsForm/InputDuration';
import InputDate from '../../Components/InputsForm/InputDate';
import InputButtonAws from '../../Components/InputsForm/InputButtonAws';
import { InputTitle } from '../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../Components/StyledComponents/InputSubtitle';
import InputText from '../../Components/InputsForm/InputText';
import { InputPhoneNumber } from '../../Components/InputsForm/InputPhoneNumber';
import InputAutocomplete from '../../Components/InputsForm/InputAutocomplete';
import SchedulingEvent from '../../Components/RedesignComponents/SchedulingEvent';
// Material & Icons
import { FormControl,Typography,Box,Alert } from '@mui/material';
import { MessageAutocomplete, MessageInput } from '../../Components/InputsForm/MessageAutocomplete';


// Data para CONTACT INFORMATION modal
export const getDataForCreate = (manager, emailRegex) => [
  {
    inputs: [
      {
        field:
        <div style={{width:'100%'}}>
          <InputTitle sx={{fontSize:'2em',textTransform:'none'}}>Invite new talent manager</InputTitle>
        </div>,
      },
      {
        field: (
          <>
            <InputTitle>Name</InputTitle>
            <InputSubtitle>Enter the full name for a new talent managment collaborator</InputSubtitle>
            <InputText
              variant="outlined"
              defaultValue={manager?.name || ''}
              validate={[required()]}
              source="name"
              label=""
              fullWidth
            />
          </>
        ),
        breakpoints: { xs: 8 },
      },
      {
        field: (
          <FormControl fullWidth>
            <InputTitle>Role</InputTitle>
            <InputSubtitle>Select the role of this talent manager</InputSubtitle>
            <InputCategoryPills    
              title=''
              source='manager_role'
              reference='admins/manager_roles'
              label=""
              filter={{ type: 'role' }}
              validate={[required()]}
              optionText='text'
              optionValue='text' />
          </FormControl>
        ),
      },
      {
        field: (
          <>
            <InputTitle>Email</InputTitle>
            <InputText
              variant="outlined"
              defaultValue={manager?.email || ''}
              validate={[required(), regex(emailRegex, 'Not a valid email')]}
              source="email"
              label=""
              fullWidth
            />
          </>
        ),
        breakpoints: { xs: 8 },
      },
   
    ],
  },
];
export const getDataForEditDelete = (thisManagerName, manager, emailRegex) => [
  {
    inputs: [
      {
        field:
        <div style={{width:'100%'}}>
          <InputTitle sx={{fontSize:'2em',textTransform:'none'}}>Edit {manager && manager.name}</InputTitle>
        </div>,
      },
      {
        field: (
          <>
            <InputTitle>
              {thisManagerName ? 'Your Name' : 'Talent Manager\'s Name'}
            </InputTitle>
            <InputText
              variant="outlined"
              defaultValue={manager?.name || ''}
              validate={[required()]}
              source="name"
              label=""
              fullWidth
            />
          </>
        ),
        breakpoints: { xs: 8 },
      },
      {
        field: (
          <>
            <FormControl fullWidth>
              <InputPhoneNumber
                thisManagerName={thisManagerName}
                variant="outlined"
                defaultValue={manager?.phone || ''}
                source="phone"
                label=""
              />
            </FormControl>
          </>
        ),
      },
      {
        field: (
          <>
            <InputTitle sx={{ marginTop: '25px' }}>
              {thisManagerName ? 'Your Email' : 'Talent Manager\'s Email'}
            </InputTitle>

            <InputText
              variant="outlined"
              defaultValue={manager?.email || ''}
              validate={[required(), regex(emailRegex, 'Not a valid email')]}
              source="email"
              label=""
              fullWidth
            />
          </>
        ),
        breakpoints: { xs: 8 },
      },
      {
        field: (
          <FormControl fullWidth>
            <InputTitle>
              {thisManagerName ? 'Your Role' : 'Talent Manager\'s Role'}
            </InputTitle>
            <InputCategoryPills
              isEdit={true}    
              title=''
              source='manager_role'
              reference='admins/manager_roles'
              label=""
              filter={{ type: 'role' }}
              validate={[required()]}
              optionText='text'
              optionValue='text' />
          </FormControl>
        ),
      },
    ],
  },
];
// Data del antiguo TalentsEventForm >
export const getTalentsEvenData = (
  permis,
  talentName,
  talentNameTM,
  isFetching
) => {
  const [talentMessage, setTalentMessage] = useState(null);

  return [
    {
      title: `${
        permis !== 'talent_manager' ? talentName : talentNameTM
      } Events details`,
      inputs: [
        {
          field: (
            <>
              <InputTitle>EVENTS CATEGORY</InputTitle>
              <ReferenceInput source="category" reference="events/categories">
                <SelectInput
                  validate={[required()]}
                  fullWidth
                  InputProps={{ sx: { borderRadius: '10px' } }}
                  source="category"
                  optionValue="text"
                  label=""
                  optionText={(option) => {
                    return option.text;
                  }}
                  variant="outlined"
                />
              </ReferenceInput>
            </>
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: <EventsNameComponent isLoading={isFetching} />,
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputText
              subtitle={EVENTS_TOOLTIPS['description']}
              title="Events / workshop description"
              label=""
              multiline
              minRows={6}
              fullWidth
              source="description"
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <>
              <InputTitle>Tags</InputTitle>
              <ReferenceArrayInput source="tags" reference="events/tags">
                <InputAutocomplete
                  InputProps={{ sx: { borderRadius: '10px' } }}
                  source="tags"
                  label=""
                  optionValue="text"
                  isArray
                  optionText="text"
                  fullWidth
                  validate={[required()]}
                />
              </ReferenceArrayInput>
            </>
          ),
          breakpoints: { xs: 5 },
        },
        {
          field: (
            <>
              <InputTitle>Events Image</InputTitle>
              <InputSubtitle>
                You can upload a custom image for your event. If you don&apos;t
                upload an image, your profile picture will be shown.
              </InputSubtitle>
              <InputButtonAws
                sx={{ marginTop: '20px' }}
                source={'picture.original'}
              />
            </>
          ),
          // tooltip: EVENTS_TOOLTIPS['picture'],
          breakpoints: { xs: 12 },
        },
        {
          field:  <MessageAutocomplete setTalentMessage={setTalentMessage} permis={permis} />,
          breakpoints: { xs: 3 },
        },
        talentMessage && {
          field: <></>,
          breakpoints: {xs: 3.5}
        },
        talentMessage && {
          field: <MessageInput />,
          breakpoints: {xs: 8.5}
        },
        {
          field: (
            <ReferenceArrayInput reference="talents" source="collaborators_ids">
              <InputAutocomplete
                placeholder="Collaborators-TBD"
                freeSolo={false}
                popupIcon={''}
                optionText="name"
                disabled
                multiple
                subtitle={
                  'In case collaborators have been assigned to your event, this is where you can find them.'
                }
                title=" COLLABORATORS"
                label=""
                fullWidth
              />
            </ReferenceArrayInput>
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
    {
      title: 'Workshops Information for Guests',
      inputs: [
        {
          field: (
            <InputText
              validate={[required()]}
              subtitle={EVENTS_TOOLTIPS['what_to_expect']}
              label=""
              title="What to expect"
              fullWidth
              multiline
              minRows={2}
              source="what_to_expect"
            />
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <InputText
              validate={[required()]}
              subtitle={EVENTS_TOOLTIPS['good_to_know']}
              label=""
              title="Good to know"
              multiline
              minRows={2}
              fullWidth
              source="good_to_know"
            />
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
    {
      title: 'Events Schedule',
      inputs: [
        {
          field: (
            <>
              <InputTitle>Stage / Area</InputTitle>
              <InputSubtitle>{EVENTS_TOOLTIPS['stage']}</InputSubtitle>
              <ReferenceInput
                source="stage_id"
                reference="locations"
                filter={{ event_ready: true }}
              >
                <InputAutocomplete
                  disabled={permis === 'talent_manager'}
                  optionText="name"
                  label=""
                  fullWidth
                />
              </ReferenceInput>
            </>
          ),
          breakpoints: { xs: 12 },
        },
        {
          field: (
            <>
              <InputTitle>Start date</InputTitle>
              <InputDate
                InputLabelProps={{ sx: { display: 'none' } }}
                label=""
                fullWidth
                inputTime
                disabled
                source="start_date"
              />
            </>
          ),
          breakpoints: { xs: 5 },
        },
        {
          field: (
            <>
              <InputTitle>End date</InputTitle>
              <InputDate
                InputLabelProps={{ sx: { display: 'none' } }}
                variant="outlined"
                label={''}
                inputTime
                fullWidth
                disabled
                source="end_date"
              />
            </>
          ),
          breakpoints: { xs: 5 },
        },
        {
          field: (
            <InputDurationMinutes
              disabled={permis === 'talent_manager'}
              label="Duration"
              source="duration"
              InputProps={{ sx: { borderRadius: '10px' } }}
            />
          ),
          breakpoints: { xs: 12 },
        },
      ],
    },
  ];
};
// From es el diferenciador entre Eventos desde Events > Active y/ Talents > Active > Events
export const getFullData = (args) => {
  const {
    from,
    permis,
    talentName,
    talentNameTM,
    isFetching,
    noValidate,
    isEdit,
    editedFields,
    events
  } = args;
  const [talentMessage, setTalentMessage] = useState(null);

  const pendingStyledField = (source) => {
    if(editedFields){
      if (editedFields?.includes(source)) return { color: 'blue', '-webkit-text-fill-color': 'blue' };
    }
    return;
  };
  
  return [
    {
      noShow: permis !== 'talent_manager',
      title:'submission',
      inputs:[
        {
          field: (
            <ShowEventsSubmissionModal />
          ),
          breakpoints: { xs: 2 },
        },
      ]
    },
    {
      title: `${
        permis !== 'talent_manager' ? talentName : talentNameTM
      } Event details`,
      inputs: [
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={COLORS.light.secondary.main}
                >
                    EVENT DETAILS
                </Typography>
              </Box>
            </div>
          ),
          breakpoints: { xs: 3},
        },
        {
          field:  permis !== 'talent_manager' ?
            <InputText
              required={true}
              subtitle={EVENTS_TOOLTIPS['eventName']}
              title="Event name"
              fullWidth
              label=""
              source="name"
              validate={[required('This field is required')]}
            />  
            : <EventsNameComponent isLoading={isFetching} />,
          breakpoints: { xs:3.7 },
        },
        {
          field:  <MessageAutocomplete setTalentMessage={setTalentMessage} permis={permis} />,
          breakpoints: { xs: 3 },
        },
        talentMessage && {
          field: <></>,
          breakpoints: {xs: 3}
        },
        talentMessage && {
          field: <MessageInput />,
          breakpoints: {xs: 8.5}
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: (
            <>
              <InputCategoryPills
                source={'category'}
                reference='events/categories'
                label=""
                validate={[required()]}
                title='Category'
                subtitle={EVENTS_TOOLTIPS['mainCategory']}
                inputSx={pendingStyledField('description')}
              />
            </>
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: (
            <InputText
              subtitle={EVENTS_TOOLTIPS['description']}
              title="Event / workshop description"
              label=""
              multiline
              minRows={4}
              counter={200}
              fullWidth
              source="description"
              inputSx={pendingStyledField('description')}
            />
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <>
              <InputRedesignImageAws
                title={'Event Image'}
                subtitle={EVENTS_TOOLTIPS['eventImage']}
                source={'picture.original'}
                width= '100%'
              />
            </>
          ),
          breakpoints: { xs: 5 },
        },
        {
          field: (
            <>
              <ReferenceArrayInput source="tags" reference="events/tags">
                <InputAutocomplete
                  required={true}
                  title='Tags'
                  optionValue="text"
                  freeSolo={false}
                  optionText="text"
                  isArray
                  label=""
                  fullWidth
                  // source="tags"
                  validate={[required()]}
                />
              </ReferenceArrayInput>
            </>
          ),
          breakpoints: { xs: 3 },
        },
      ],
    },
    {
      title: '',
      inputs: [
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={COLORS.light.secondary.main}
                >
                   WORKSHOPS INFORMATION FOR GUESTS
                </Typography>
              </Box>
            </div>
          ),
          breakpoints: { xs: 2 },
        },
        {
          field: (
            <InputText
              // validate={[required()]}
              subtitle={EVENTS_TOOLTIPS['what_to_expect']}
              label=""
              title="What to expect"
              fullWidth
              multiline
              minRows={2}
              source="what_to_expect"
              inputSx={pendingStyledField('what_to_expect')}
            />
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: (
            <InputText
              // validate={[required()]}
              subtitle={EVENTS_TOOLTIPS['good_to_know']}
              label=""
              title="Good to know"
              multiline
              minRows={2}
              fullWidth
              source="good_to_know"
              inputSx={pendingStyledField('good_to_know')}
            />
          ),
          breakpoints: { xs: 8.5 },
        },
      ],
    },
    {
      noShow: permis !== 'talent_manager' ?false : true, 
      title: '',
      inputs: [
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={COLORS.light.secondary.main}
                >
                   EVENT SCHEDULE
                </Typography>
              </Box>
            </div>
          ),
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <InputDurationMinutes
              title="Duration (IN MINUTES)"
              source="duration"
              InputProps={{ sx: { borderRadius: '10px' } }}
            />
          ),
          breakpoints: { xs: 3},
        },
        {
          field: <></>,
          breakpoints: { xs: 5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: (
            <>
              <InputCategoryPills
                title='Select Stage'
                subtitle={EVENTS_TOOLTIPS['selectStage']}
                source='stage_id'
                reference='locations'
                label=""
                filter={{ event_ready: true }}
                // validate={[required()]}
                optionText='name'
                optionValue='name'
              />
            </>
          ),
          breakpoints: { xs: 8.5 },
        },
        {
          field: <></>,
          breakpoints: { xs: 2 },
        },
        {
          field: 
            <InputTime 
              source={['start_date','end_date']} 
              subtitle={EVENTS_TOOLTIPS['selectDay']}
              title="Select Day"
              checkStageTime
            />
          ,
          breakpoints: { xs: 8.5 }
        },
      ],
    },
    {
      noShow: permis !== 'talent_manager' ? true : false, 
      title:'',
      inputs:[
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={COLORS.light.secondary.main}
                >
                   SCHEDULING
                </Typography>
              </Box>
            </div>
          ),
          breakpoints: { xs: 3 },
        },
        {
          field:(
            <SchedulingEvent source={'stage'}/>
          ),
          breakpoints: { xs: 8.5 },
        }

      ]
    }
  ];
  
};