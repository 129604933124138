import React from 'react';
import { useDataProvider } from 'react-admin';
// Hooks & Utils
import { useFormContext } from 'react-hook-form';
import { COLORS } from '../../../assets/constants/theme';
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
// Material & Icons
import { BiTrashAlt } from 'react-icons/bi';
import {IconButton } from '@mui/material';

// Funcion que genera un IconButton de funcion delete, usado principalmente en Home Components, para aquellos elementos que se mapean , como son : Banner
// Quicklinks, etc.
const DeleteSectionHC = ({index,formCount,info,setInfo,source,setFormCount }) => {
  const dataProvider = useDataProvider();
  const customToast = useCustomToasty();
  const {getValues} = useFormContext();
  const valores = getValues();
  const deleteForm = async(index)=>{
    if(formCount.length > 1){
      let updatedInfo = { ...valores };
      updatedInfo.home[source].items = [...valores.home[source].items];
      updatedInfo.home[source].items.splice(index, 1);
      const newData = {home: {[source]: {items: [...updatedInfo.home[source].items]}}};
      return dataProvider.update('info', {id: info?.id, data: {...newData}})
        .then(res => {
          customToast('success', 'Deleted succesful.');
          setInfo(prevInfo => ({
            ...prevInfo,
            home: {
              ...prevInfo.home,
              [source]: res.data.home[source]
            }
          }));
        })
        .catch(err => {
          customToast('error', 'There was an error, please try again later.');
        });
    }
    else{
      customToast('info', 'You need to have at least 1 section');
    }
  };
  return (
    <IconButton size='small'sx={{height:'30px',backgroundColor:COLORS.table.indicators.fontColor.rejected,color:'white', '&:hover':{
      backgroundColor:COLORS.table.indicators.fontColor.rejected
    }}} onClick={()=>deleteForm(index)}><BiTrashAlt/></IconButton>
  );
};

export default DeleteSectionHC;