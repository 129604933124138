import { DeleteButton, SaveButton, Toolbar, usePermissions, useRecordContext, useRefresh, useResourceContext, useSidebarState } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { apiUrl } from '../../dataProvider';
import axios from 'axios';
import { Box, Button, Tooltip } from '@mui/material';
import { COLORS } from '../../assets/constants/theme';
import { FaSave } from 'react-icons/fa';
import ActionButtons from '../StyledComponents/ActionButtons';
export const AdaptableToolbarRedesign = (props) => {

  const {
    from,
    recordFromModal,
    draft,
    closeModalEdit,
    resetFilterCategory,
    floating,
    index,
    noTooltip,
    showDelete,
    isEdit,
    saveBackground,
    icon,
    deleteResource,
    isHome,
    sx
  } = props;

  const methods = useFormContext();
  const { permissions: permis } = usePermissions();
  const resource = useResourceContext();
  const record = useRecordContext();
  const refresh = useRefresh();
  const customToast = useCustomToasty();

  const handleData = async (props) => {
    let draftData = methods.getValues();
    if (draftData.name === '' || !draftData.name) {
      customToast('error', 'The name is required.');
      return;
    }
    try {
      const response = await axios({
        url: `${apiUrl}/${recordFromModal !== undefined ? 'events' : draft}/${
          draftData.id
        }`,
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          Authorization: 'Bearer ' + localStorage.getItem('auth'),
          'x-api-key': process.env.REACT_APP_API_KEY,
        },
        data: { ...draftData, sketch: true },
      });
      if (response.data) {
        customToast('success', 'Changes were saved.');
        closeModalEdit(false);
        refresh();
        resetFilterCategory();
      }
    } catch (error) {
      if(props === 'modalEdit'){
        throw new Error(error);
      }
      customToast('error', 'There was an error, please try again later.');
      throw new Error(error);
    }
  };

  const onError = ({ ...rest }) => {
    customToast('error', rest?.body?.detail);
  };
  const [openSideBar, setOpenSideBar] = useSidebarState();
  const styledFloating = floating
    ? {
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: `calc(100% - ${openSideBar ? '0px' : '0px'})`,
      transition: 'width 500ms',
      animation: '1s ease-in',
      backgroundColor: saveBackground ? COLORS.forms.header.background : 'transparent',
    }
    : {};
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        padding:0,
        margin:0,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          flexDirection: showDelete ? 'row-reverse' : 'row',
          height:'70px',
          justifyContent: showDelete ? 'space-between' : 'flex-end',
          gap: '20px',
          backgroundColor: saveBackground ? COLORS.forms.header.background : 'transparent',
          ...(recordFromModal ? {} : styledFloating)
        }}
      >
        <Tooltip
          slotProps={{ tooltip: { sx: { fontSize: '.9em' } } }}
          placement="top"
          title={
            noTooltip
              ? ''
              : permis === 'talent_manager' && 'This information will be reviewed by the staff. You will be notified when this process is done or if any change is needed.'
          }
        >
          <div>
            {recordFromModal === undefined ? (
              permis !== 'talent_manager' ? (
                ActionButtons({action:'save', label:isEdit?'Save':'Create',icon:icon,isHome:isHome,...sx})
              ) : (
                ActionButtons({action:'save', label:'submit',icon:icon})
              )
            ) : (
              ActionButtons({action:'save', onClick:()=>handleData('modalEdit')})
            )}
          </div>
        </Tooltip>
        {
          draft && permis === 'talent_manager' && ['created', 'draft', null].includes(record?.submission?.status) && (
            <Tooltip slotProps={{tooltip: { sx: {fontSize: '.9em'}}}} placement="top" title={'Save the current changes in case you are you lack required information to submit the document.'}>
              <div>
                <Button
                  sx={{
                    backgroundColor: COLORS.light.primary.main,
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: COLORS.light.primary.main,
                      color: '#fff',
                    },
                  }}
                  variant="filled"
                  startIcon={<FaSave size="15px" />}
                  onClick={handleData}
                >
                    Save as Draft
                </Button>
              </div>
            </Tooltip>
          )}
        <Box>
          {permis !== 'talent_manager' && permis !== 'admin' && showDelete && isEdit && (
            <DeleteButton
              confirmContent={deleteResource ? 'Are you sure you want to delete this event':`Are you sure you want to delete this ${resource === 'events' ? 'event' : resource}?`}
              confirmTitle="Delete"
              mutationOptions={{ onError }}
              mutationMode='pessimistic'
              // variant="contained"
              // Si se le pasa la prop deleteResource, que en el unico lugar es talents events
              // se toma events para eliminar, pero si no es el caso, usa el default
              resource={deleteResource ? deleteResource : resource}
              redirect={deleteResource && '/talents'}
              sx={{paddingX: '15px',paddingY: '10px', borderRadius: '10px'}}
            />
          )}
        </Box>
      </Toolbar>
    </Box>
  );
};