import { Box, FormControl, FormLabel, MenuItem, Typography, Alert } from '@mui/material';
import { RadioButtonGroupInput, SelectInput, useGetOne, usePermissions } from 'react-admin';
import { useParams } from 'react-router-dom';
import { TabbedSimpleForm } from './TabbedSimpleForm';
import InputCheckbox from '../../Components/InputsForm/InputCheckbox';
import InputText from '../../Components/InputsForm/InputText';
import { PROFILE_TOOLTIPS } from './Tooltips';
import InputSelect from '../../Components/InputsForm/InputSelect';
import { InputTitle } from '../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../Components/StyledComponents/InputSubtitle';
import { LinkedForm } from './LinkedForm';


export const MarketingForm = () => {
  const permisos = usePermissions();
  const params = useParams();
  const talent_id = params['*']?.split('/')[1] ?? params?.id;
  const { data: talentData, loading, error } = useGetOne('talents', { id: talent_id });

  const data = [
    {
      title: '',
      inputs: [
        {
          field: <Box sx={{ margin: '0 20px 50px 20px' }}>
            <InputSubtitle>These are all marketing opportunities that you can be part of.<br /> Please note that whatever you choose to do (or not to do) does not modify or exempt you from the obligations outlined in your contract. </InputSubtitle>
          </Box>,
          breakpoints: { xs: 12 }
        },
      ]
    },
    {
      title: 'PRE-EVENT MKT & PROMO OPPS',
      inputs: [

        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.written_interviews'}
            label={'Written Interviews'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.video_or_audio_interviews'}
            label={'Video or Audio Interviews'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.voice_over_recording'}
            label={<span>Voice over recording <span style={{ color: '#212121', fontStyle: 'italic' }}>(used to create a reel with festival&apos;s footge)</span></span>}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.instagram_live'}
            label={'Instagram live'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.free_live_zoom_classes'}
            label={'Free live zoom classes or workshops for Envision Festival\'s audience prior to the festival'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.instagram_collaborative_post'}
            label={'Instagram collaborative post'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.curate_spotify_playlist'}
            label={'Curate a Spotify playlist for Envision Festival'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.record_hype_video'}
            label={'Record a hype video for social media speaking to your audience'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.somos_el_cambio_campaign'}
            label={'Join Somos el Cambio\'s donation campaign'}
          />,
          breakpoints: { xs: 12 }
        },

      ]
    },
    {
      title: 'DURING EVENT MKT & PROMO OPPS',
      inputs: [

        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.instagram_takeover'}
            label={'Instagram takeover'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.tree_planting_activity'}
            label={'Participate in Tree Planting activity'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.pride_parade_celebration'}
            label={'Participate in Pride Parade Celebration'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.beach_activation'}
            label={'Beach activation'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.video_interview'}
            label={'Video interview at Envision Festival'}
          />,
          breakpoints: { xs: 12 }
        },
      ]
    },
    {
      title: 'POST-EVENT MKT & PROMO OPPS',
      inputs: [
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.live_set_recording_soundcloud'}
            label={'Envision\'s live set recording (audio) for Soundcloud'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.live_performance_recording_youtube'}
            label={'Envision\'s live performance recording (video) for Youtube'}
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputCheckbox sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
            labelPlacement={'start'}
            source={'marketing_opportunities.collaborative_post'}
            label={'Collaborative post (with event photos)'}
          />,
          breakpoints: { xs: 12 }
        },
      ]
    },
    {
      title: 'Talent Media Team',
      inputs: [
        {
          field:
            <>
              <InputTitle textTransform={null}>Do you come to Envision with your own Media Team?</InputTitle>
              <RadioButtonGroupInput label=""
                sx={{
                  width: '20%'
                }}
                source="has_media_team" choices={[
                  { id: true, name: 'Yes' },
                  { id: false, name: 'No' }

                ]} />
            </>,
          breakpoints: { xs: 12 }
        },
        {
          field:
            <InputText
              source="media_team_details"
              multiline
              watchField="has_media_team"
              minRows={4}
              variant="outlined"
              title="Media Team"
              subtitle={'Please provide details of your Media Team\'s members (name, email address, role)'}
              label=""
              fullWidth
            />,
          // tooltip: 'Please provide details of your Media Team\'s members (name, email address, role)',
          breakpoints: { xs: 12 }
        },
        {
          field:
            <InputSelect
              labelId="label"
              textTransform={null}
              title="Please take into consideration that all media passes are subject to approval by our internal media team
              *A copy of all footage must be delivered onsite to Envision's media team"
              optionValue="value"
              variant="outlined"
              watchField={'has_media_team'}
              choices={[{ key: '1', value: '1' }, { key: '2', value: '2' }, { key: '3', value: '3' }, { key: '4', value: '4' }, { key: '5', value: '5' }]}
              optionText={'value'}
              source="media_passes"
              label=""
              fullWidth
            />,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <InputTitle textTransform={null}>If any, what are some current projects/releases/new content you're working on that we can promote?</InputTitle>
            <InputText
              multiline
              minRows={2}
              source='media.current_projects'
              label=''
              fullWidth
            />
          </>,
          tooltip: PROFILE_TOOLTIPS['media']['current_projects'],
          breakpoints: { xs: 12 }
        },

        {
          field: <>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <InputTitle textTransform={null}>What tracks and/or songs would you prefer Envision's Team use in our Marketing & Promotional videos and clips? Please specify track name, album and if it's a remix</InputTitle>
            <InputText
              multiline
              minRows={2}
              label=""
              source='approved_tracks'
              fullWidth
            />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <InputTitle textTransform={null}>What tracks and/or songs would you prefer Envision's Team NOT to use in our Marketing & Promotional videos and clips? Please specify track name, album and if it's a remix</InputTitle>
            <InputText
              multiline
              minRows={2}
              source='not_approved_tracks'
              label=""
              fullWidth
            />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle textTransform={null} value="Have you performed at Envision Festival before?" />
            <RadioButtonGroupInput
              sx={{
                width: '20%'
              }}
              label="" source="previous_editions" choices={[
                { id: true, name: 'Yes' },
                { id: false, name: 'No' }

              ]} />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputText
            source="previous_editions_reason"
            label=""
            // placeholder="Previous editions reason"
            titleTransform={null}
            switchTitle="If no, what excites you the most about the prospect of performing at Envision Festival for the first time?"
            title="If yes, what is it that you would highlight about your previous experience at Envision Festival, and what makes it a special event for you?"
            watchField="previous_editions"
            fullWidth
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputText
            source="performance_contribution"
            titleTransform={null}
            // placeholder="Performance contribution"
            label=""
            title="Envision Festival is known for its unique blend of music, art, and transformative experiences. How do you envision your performance contributing to this diverse and vibrant tapestry?"
            fullWidth
          />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputText
            source="artistic_vision"
            titleTransform={null}
            // placeholder="Artistic vision"
            label=""
            title="Envision Festival emphasizes a strong connection to nature and community. How does this align with your artistic vision, and what message or experience do you hope to convey to our guests through your performance?"
            fullWidth
          />,
          breakpoints: { xs: 12 }
        }
      ]
    }
  ];

  // return (
  //   <TabbedSimpleForm floating form="marketing" lastSubmitter record={talentData} noTooltip isEdit data={data} />
  // );

  return (
    <>
      {permisos.permissions === 'talent_manager' && (
        <div style={{ marginTop: '20px' }}>
          <Typography
            variant="h5"
            gutterBottom
            fontWeight={400}
            alignSelf={'flex-start'}
          >
            📢 Marketing & Promo Opportunities
          </Typography>
        </div>
      )}
      <LinkedForm link="https://noteforms.com/forms/talent-marketing-database-2024-opportunities-4nz6kq" />
    </>
  );

};