import React from 'react';
import { Edit } from 'react-admin';
import FormNotifications from './FormNotifications';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';

const EditNotifications = () => {
  return (
    <EditFormCustom>
      <FormNotifications isEdit />
    </EditFormCustom>
  );
};

export default EditNotifications;