import * as React from 'react';
import { useListContext, useGetList,useResourceContext} from 'react-admin';
// Hooks & Utils
import { COLORS } from '../../assets/constants/theme';
import { responsiveFilter } from '../../Hooks/useMediaQuery';
// Components
import { AddTalentModal } from '../../Resources/Talents/Form/TalentsListComponents/AddTalentModal';
import { CsvDownloader } from '../ReportButtons/CsvDownloader';
import AllAutocomplete from '../ReportButtons/AllAutocomplete';
import { renderPopper } from '../../Resources/Events/CalendarToolbar';
// Material & Icons
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, Skeleton, TextField} from '@mui/material';
import { IoMdSearch } from 'react-icons/io';

export const ListActions = ({permission,from,placeholder}) => {
  const { filterValues, setFilters } = useListContext();
  const responsive = responsiveFilter();
  return (
    <>
      <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between',marginBottom: '20px'}}>
        {permission !== 'talent_manager' &&<>
          <Box sx={{display: 'flex', flexDirection :'row', alignItems: 'center', width: '100%',paddingX: '20px',marginBottom:2}}>
            <div style={{width:'100%',display:'flex',flexDirection:'row', alignItems: 'center',marginTop:20,justifyContent: 'space-between'}}>
              <PostFilterButton  sx={{ width: '350px',borderRadius: '10px',}} placeholder={placeholder}/>
              <div style={{width:'auto',display:'flex',alignItems: 'center',height:'50px',gap:20,marginLeft:50}}>
                <CsvDownloader minWidth={'(min-width:1060px)'}  from={from} filterValues={filterValues}/>
                <AddTalentModal />
              </div>
            </div>
          </Box>
        </>}
        <Box sx={{width: '100%', display: 'flex',flexDirection: 'row', alignItems: 'center',gap:2, justifyContent: 'flex-start', padding: '20px', height: '50px'}}>
          {/* <Box sx={{gap:'20px', display: 'flex', flexDirection: 'row', width: '85%',height:'80px'}}> */}
          <MainCategoryFilter responsive={responsive}/>
          {permission !== 'talent_manager' && <StatusFilter responsive={responsive}/>}
          <CompletedFilter responsive={responsive}/> 
          {permission !== 'talent_manager' && <VisiblityFilter responsive={responsive}/>}
          {/* </Box> */}
        </Box>
      </Box>
    </>
  );
};

export const PostFilterButton = ({ sx, filterState, setFilterState,placeholder}) => {
  // En caso de hacer una busqueda en EVENTOS desde el CALENDARIO se hace 'manual', modificamos el array
  // de eventos con lo actual
  const [value, setValue] = React.useState('');
  const { filterValues, setFilters } = useListContext();
  const handleFilter = (e) => {
    setFilterState && setFilterState({...filterState, filterName: e.target.value});
    setFilters && setFilters({...filterValues, 'q': e.target.value});
    setValue(e.target.value);
  };
  return  <TextField  
    sx={{ width: '100%', backgroundColor: 'white', ...sx }}
    value={value} 
    variant="outlined" 
    hiddenLabel={true} 
    onChange={handleFilter}
    placeholder={placeholder}
    InputProps={{
      style: {
        borderRadius: '10px',
        fontSize: '14px',
      },
      startAdornment: <IoMdSearch size={24} />,
    }} />;

};

export const MainCategoryFilter = ({_callback,sx,filter,from,responsive,...rest}) => {
  const [value, setValue] = React.useState([]);
  const resource = useResourceContext();
  const { setFilters, filterValues } = useListContext();
  const { data, isFetching } = useGetList(`${resource}/categories`, { pagination: {perPage:9999, page: 1}, sort: { field: 'rank', order: 'DESC'}});

  const handleFilter = (e,v) => {
    console.log(v);
    if(_callback) {
      _callback(e,v);
    } else {
      setFilters({...filterValues, 'main_category': v?.map(item => encodeURIComponent(item.text))});
    }
    if(_callback) {
      _callback(e,v);
    } else {
      setFilters({...filterValues, 'main_category': v?.map(item => encodeURIComponent(item.text))});
      setValue(v);
    }
    setValue(v);
  };
console.log(responsive,'filter cartegory')
  return ( 
    isFetching ? (
      <Skeleton height="50px" width="100%" sx={sx}/>
    ) : (
      // <CategoryFilter from={from} data={data} changeFilter={handleFilter} filterValues={filter === undefined ? filterValues : filter} sx={sx} />
      <AllAutocomplete
        width={responsive}
        value={value}
        limitTags={2}
        allTitle={null}
        sx={{
          marginBottom: '0px',
          // height: '50%',
          '& .MuiInputBase-sizeSmall':{
            borderRadius:'50px',
            color:'black',
          },
          '& .MuiInputLabel-root':{
            color:'black',
            fontWeight:400
          },
        }}
        onChange={handleFilter} 
        getOptionLabel={(option) => option.text} 
        arr={data} 
        PopperComponent={renderPopper}
        renderInput={(params) => 
          <TextField label={'Category'} variant="outlined" {...params} />
        }
      />
    )
  
  );
};
export const VisiblityFilter =({responsive})=>{
  const { filterValues, setFilters } = useListContext();
  const visiblityValues =['Visible', 'Hidden'];
  const handleFilter = (e,v) => {
    const target = v;
    if(target === 'Visible'){
      return setFilters({...filterValues, 'draft': false});
    }else if(target === 'Hidden'){
      return setFilters({...filterValues, 'draft': true});
    }else{
      return setFilters({...filterValues, 'draft': null});
    }
  };
  return(
    <Autocomplete 
      onChange={handleFilter} 
      sx={{
        marginBottom: '0px',
        // height: '50%',
        '& .MuiInputLabel-root':{
          color:'black',
          fontWeight:400
        },
        '& .MuiInputBase-sizeSmall':{
          borderRadius:'50px',
          maxHeight:'45px'
        }
      }}
      getOptionLabel={(option) => option} 
      options={visiblityValues} 
      renderInput={(params) => 
        <TextField 
          label="Visibility" 
          variant="outlined" 
          sx={{
            width:responsive,
            height: '100%',
            zIndex: 9999,
            color:'black',
          }}
          InputProps={{
            ...params.InputProps,
            sx: {
              overflow: 'auto',
              margin: '0px',
            },
            startAdornment: (
              <div style={{
                overflow: 'auto',
                margin: '0px',

              }}
              >{params.InputProps.startAdornment}</div>
            ),
          }}
          {...params} 

        />}
    />
  );
};
export const AdditionalFilters = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  
  return (
    <>
      <Button sx={{width: '100%', height: '100%', marginTop:.2, fontWeight: '600', fontSize: '.8em'}} variant="outlined" onClick={handleClick}>
        Status
      </Button>
      {/* <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: -5 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            sx: {
              overflow: 'visible',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 8,
                width: 10,
                height: 10,
                backgroundColor: 'inherit',
                transform: 'translateY(-50%) rotate(45deg)',
                boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
                borderTop: `1px solid grey`,
                borderLeft: `1px solid grey`
              },
            },
          },
        }}
      > */}
      <CustomFilterCheckbox />
      {/* </Popover> */}
    </>
  );
};

export const StatusFilter = ({responsive})=>{
  const { filterValues, setFilters } = useListContext();
  const [value, setValue] = React.useState([]);
  const resource = useResourceContext();
  const statusValues =['Approved', 'Pending', 'Rejected', 'Not Submitted'];
  const handleFilter = (e,v) => {
    setFilters({...filterValues, 'status': v?.map(item => item === 'Not Submitted' ? encodeURIComponent('not_submitted') : encodeURIComponent(item.toLowerCase()))});
    setValue(v);
  };
  return(
    <AllAutocomplete
      width={responsive}
      value={value}
      limitTags={2}
      allTitle={null}
      sx={{
        marginBottom: '0px',
        '& .MuiInputBase-sizeSmall':{
          borderRadius:'50px',
          color:'black',
        },
        '& .MuiInputLabel-root':{
          color:'black',
          fontWeight:400
        },
      }}
      onChange={handleFilter} 
      getOptionLabel={(option) => option} 
      arr={statusValues} 
      PopperComponent={renderPopper}
      renderInput={(params) => 
        <TextField label={'Last submission status'} variant="outlined" {...params} />
      }
    />
  );
};
export const CompletedFilter = ({width,responsive})=>{
  const { filterValues, setFilters } = useListContext();
  const completeValues =['Complete', 'Incomplete'];
  const handleFilter = (e,v) => {
    const target = v;
    if(target === 'Complete'){
      return setFilters({...filterValues, 'completed': true});
    }else if(target === 'Incomplete'){
      return setFilters({...filterValues, 'completed': false});
    }else{
      return setFilters({...filterValues, 'completed': null});
    }
  };
  return(
    <Autocomplete 
      onChange={handleFilter} 
      sx={{
        marginBottom: '0px',
        // height:'50%',
        '& .MuiInputBase-sizeSmall':{
          borderRadius:'50px',
          color:'black',
          maxHeight:'45px'
        },
        '& .MuiInputLabel-root':{
          color:'black',
          fontWeight:400
        },
      // outline: '1px red solid'
      }}
      getOptionLabel={(option) => option} 
      options={completeValues} 
      renderInput={(params) => 
        <TextField 
          label="Completed?" 
          variant="outlined" 
          sx={{
            width:responsive,
            height: '100%',
            zIndex: 9999,
            color:'black',
          // margin: '0px',
          }}
          InputProps={{
            ...params.InputProps,
            sx: {
              overflow: 'auto',
              margin: '0px',
            },
            startAdornment: (
              <div style={{
                overflow: 'auto',
                margin: '0px',

              }}
              >{params.InputProps.startAdornment}</div>
            ),
          }}
          {...params} 

        />}
    />
  );
};

export const CustomFilterCheckbox = () => {
  const { filterValues, setFilters } = useListContext();
  const [publishValue, setPublishValue] = React.useState(null);
  const [completeValue, setCompleteValue] = React.useState(null);
  const handleStatus = (e) => {
    let value = e.target.value;
    if(e.target.checked){
      let statusFilter = Array.isArray(filterValues.status) ? [...filterValues.status, value] : [value];
      return setFilters({...filterValues, status: statusFilter});
    } else {
      let statusFilter = Array.isArray(filterValues.status) ? filterValues?.status?.filter(item => item !== value) : [];
      return setFilters({...filterValues, status: statusFilter});
    }
  };

  const handlePublish = (e) => {
    let value = e.target.value;
    let bool = e.target.checked;
    let newFilter = {...filterValues};
    switch(true){
    case (value === publishValue):
      setPublishValue('');
      delete newFilter?.['_draft'];
      return setFilters({...newFilter});
    case (value === completeValue):
      setCompleteValue('');
      delete newFilter?.['complete'];
      return setFilters({...newFilter});

    case (value === 'published') :
      setPublishValue(value);
      return setFilters({...newFilter, ['draft']: !bool});
    case (value === 'draft') :
      setPublishValue(value);
      return setFilters({...newFilter, ['draft']: bool});

    case (value === 'incomplete') :
      setCompleteValue(value);
      return setFilters({...newFilter, ['completed']: !bool});
    case (value === 'complete') :
      setCompleteValue(value);
      return setFilters({...newFilter, ['completed']: bool});

    }
  };


  return(
    <Box sx={{
      // maxWidth: '320px',
      width: '700px',
      backgroundColor: '#fff',
      padding: '15px 18px',
      display: 'flex',
      flexDirection:'row'
    }}>
      <FormGroup sx={{
        display: 'flex',
        flexDirection:'row',
        width: '100%'
      }} >
        <FormControl>
          <FormLabel>Submission Status</FormLabel>
          <FormControlLabel label="Approved" value="approved" control={<Checkbox onChange={handleStatus} />}/>
          <FormControlLabel label="Pending" value="pending" control={<Checkbox onChange={handleStatus} />}/>
          <FormControlLabel label="Rejected" value="rejected" control={<Checkbox onChange={handleStatus} />}/>
          <FormControlLabel label="Draft" value="draft" control={<Checkbox onChange={handleStatus} />}/>
        </FormControl>
      </FormGroup>
      <FormGroup sx={{
        display: 'flex',
        flexDirection:'row',
        width: '100%'
      }} >
        <FormControl>
          <FormLabel>Completeness</FormLabel>
          <RadioGroup value={completeValue}>
            <FormControlLabel label="Complete" value={'complete'} control={<Radio onClick={handlePublish} />}/>
            <FormControlLabel label="Incomplete" value={'incomplete'} control={<Radio onClick={handlePublish} />}/>
          </RadioGroup>
        </FormControl>
      </FormGroup>
      <FormGroup sx={{
        display: 'flex',
        flexDirection:'row',
        width: '100%'
      }} >
        <FormControl>
          <FormLabel>Published</FormLabel>
          <RadioGroup value={publishValue}>
            <FormControlLabel label="Visible" value={'published'} control={<Radio onClick={handlePublish} />}/>
            <FormControlLabel label="Hidden" value={'draft'} control={<Radio onClick={handlePublish} />}/>
          </RadioGroup>
        </FormControl>
      </FormGroup>
    </Box>
  );
};