import React, { useEffect, useMemo, useState } from 'react';
import { Switch,Tooltip  } from '@mui/material';
import { switchStyleIos } from '../../StyledComponents/Inputs';
import { useFormContext } from 'react-hook-form';
import { InputTitle } from '../../StyledComponents/InputTitle';
const InputSwitchRedesign = ({ titleTransform, section, switchTitle, watchField, title, sx, counter,info, ...rest }) => {
  const { watch, formState, getValues,setValue } = useFormContext();
  const valores = getValues();

  const [checked,setChecked]= useState(undefined);
  const hasCategoryPicked = info?.home?.featured_categories[section]?.category;

  const watchLogic = useMemo(() => {
    if(switchTitle) return 'block';
    if(watch(watchField) === 'true') return 'block';
    if(watch(watchField) === 'false') return 'none';
    return 'block';
  },[formState]);

  const handlerShowSection =  (event) => {
    setChecked(event.target.checked ?? false);
  };
  useEffect(() => {
    if(checked !== null || checked !== undefined){
      setValue(`home.featured_categories[${section}]`, { ...valores?.home?.featured_categories[section], show: checked});
    }
  },[checked]);

  useEffect(() => {
    if(valores){
      setChecked(info?.home?.featured_categories[section]?.show);
    } 
  },[]);
  return (
    <>
      {title &&  
      <InputTitle textTransform={titleTransform} sx={{display: watchLogic}} value={title} />
      }
      <Tooltip title={hasCategoryPicked?.length === undefined ? 'You must select at least one category' : ''} placement='right'>
        <div style={{width:70}}>
          <Switch sx={switchStyleIos} disabled={hasCategoryPicked?.length === undefined ? true : false}  onChange={handlerShowSection} checked={checked} inputProps={{ 'aria-label': 'controlled' }}   />
        </div>
      </Tooltip>
      
   
    </>
  );
};

export default InputSwitchRedesign;