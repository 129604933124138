import { useState,useEffect } from 'react';
import axios from 'axios';
import { useDataProvider } from 'react-admin';
// Hooks & Utils
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
import { COLORS } from '../../../../assets/constants/theme';
// Components
import { switchStyleIos } from '../../../../Components/StyledComponents/Inputs';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle.jsx';
import InputText from '../../../../Components/InputsForm/InputText.jsx';
import {InputRedesignImageAws, InputDropdownSelector} from '../../../../Components/RedesignComponents/Inputs/index.js';
// Material & Icons
import {Typography,Box,Switch,IconButton,Collapse,Skeleton,Button} from '@mui/material';
import bannerSample from '../../../../assets/images/fivereasonswhypng.png';
import {image5} from '../../../../assets/images/Carousel/index.js';
import { IoChevronDownSharp } from 'react-icons/io5';
import DeleteSectionHC from '../../../../Components/RedesignComponents/Inputs/DeleteSectionHC.jsx';


export const BannerForm = ({info,setInfo,setRefetch}) => {
  const [openSection,setOpenSection]= useState(true);
  const [showSection, setShowSection] = useState(undefined);
  const [formCount, setFormCount]= useState([1]);
  const customToast = useCustomToasty();
  const handlerForm = ()=>{
    setFormCount([...formCount, 1]);
  };
  const data =()=>{
    return   [
      {
        title: '',
        inputs: [
          {
            field: (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  alignContent: 'flex-start',
                }}
              >
                <Box>
                  <Typography
                    fontWeight={700}
                    fontSize={'16px'}
                    color={COLORS.light.secondary.main}
                  >
                    BANNER
                  </Typography>
                </Box>
                <img
                  src={image5}
                  style={{
                    height: '290px',
                    objectFit: 'contain',
                    marginTop: '20px',
                  }}
                  alt="image1"
                  loading="lazy"
                />
              </div>
            ),
            breakpoints: { xs: 3 },
          },
          {
            field: (
              <>
                <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                  <div >
                    <InputTitle
                      value={
                        <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                      EXAMPLE
                        </Typography>
                      }
                    />
                    <div
                      style={{
                        padding: '5px',
                        margin: '15px 0px',
                        width: '40%',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2,
                        height:'90px'
                      }}
                    >
                      <img src={bannerSample} alt="banner" />
                    </div>
                  </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                  {formCount.map((item,index)=> {
                    return(
                      <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }} key={index}>
                        <div>
                          <InputTitle
                            value={
                              <Typography
                                fontWeight={600}
                                fontSize={'16px'}
                                color={'#000'}
                              >
                          IMAGE
                              </Typography>
                            }
                          />
                          <InputRedesignImageAws
                            info={info}
                            sx={{ marginTop: '10px',height:'auto' }}
                            source={`home.banner.items[${index}].image.original`}
                            label=""
                          />
                        </div>
                        <div>
                          <InputText
                            source={`home.banner.items[${index}].title`}
                            label=""
                            title={
                              <Typography
                                fontWeight={600}
                                fontSize={'16px'}
                                color={'#000'}
                              >
                          TITLE
                              </Typography>
                            }
                          />
                        </div>
                        <div>
                          <InputText
                            source={`home.banner.items[${index}].link`}
                            label=""
                            title={
                              <Typography
                                fontWeight={600}
                                fontSize={'16px'}
                                color={'#000'}
                              >
                          LINK
                              </Typography>
                            }
                          />
                        </div>
                        <div style={{ width: '20%' }}>
                          <InputDropdownSelector source={`home.banner.items[${index}].size`} width={'100%'}  withChoices={true} label='' title={
                            <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                        SIZE
                            </Typography>
                          }/>
                        </div>
                        <div style={{marginTop:'34px'}}>
                          <DeleteSectionHC index={index} formCount={formCount} setFormCount={setFormCount} info={info} setInfo={setInfo} source={'banner'}/>
                        </div>
                      </div>
                    );
                  })}
                  <div>
                    <Button  variant="text" onClick={handlerForm}>
                      <span
                        style={{
                          fontWeight: 600,
                          color: COLORS.light.secondary.main,
                        }}
                      >
                        + Add Banner
                      </span>
                    </Button>
                  </div>               
                </div>
              </>
            ),
            breakpoints: { xs: 8 },
          },
        ],
      },
    ];
  };
  const onSubmit = async (data) => {
    const newData ={home:{banner:{items:[...data.home.banner.items]}}};
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      },
      data: data
    })
      .then(res => {
        customToast('success', 'Changes submitted succesfuly.');
        setRefetch(prevState => !prevState);
      })
      .catch(err => {
        customToast('error', 'There was an error, please try again later.');
      });
  };

  const handleOpenSection = () => {
    setOpenSection(!openSection);
  };
  const activeShow = () => {
    const newData = {...info, home: {...info?.home, banner: { show: !showSection}}};
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      data: newData,
    })
      .then((res) => {
        customToast('success', 'Changes submitted successfully.');
        setShowSection(!showSection);
      })
      .catch((err) => {
        customToast('error', 'There was an error, please try again later.');
      });
      
  };
  useEffect(()=>{
    let showTrue = info?.home?.banner?.show;
    setShowSection(showTrue);
  },[info]);
  useEffect(()=>{
    let formCount = info?.home?.banner?.items?.length;
    setFormCount(Array.from({ length: formCount }, (_, index) => index));
  },[info]);

  return(
    <>
      <Box sx={{width:'100%',padding:'20px 16px', height:'5%', backgroundColor:COLORS.forms.header.background,display:'flex',flexDirection:'row',justifyContent:'space-between' }}>
        <Typography fontSize={'24px'} fontWeight={400} color={'#313131'}>Banner</Typography>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:15}}>
          {showSection === undefined ?<Skeleton variant="circular" width={40} height={40} />:
            <Switch sx={switchStyleIos} onClick={activeShow} checked={showSection}/>}
          <IconButton onClick={handleOpenSection}>
            <IoChevronDownSharp size={24} />
          </IconButton>
        </div>
      </Box>
      <Collapse in={openSection} timeout={500}>
        <AdaptableFormRedesign
          simpleform={{ record: info, onSubmit: onSubmit }}
          data={data()}
          isEdit
          floating
          from={false}
          icon={true}
        />
      </Collapse>
    </>
  );
};