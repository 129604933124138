import React, { useEffect, useState } from 'react';
import { useInput, useGetList, useStore } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { COLORS } from '../../../assets/constants/theme';
import { Chip } from '@mui/material';

const InputCategoryPills = ({
  title,
  source,
  reference,
  optionText = 'text',
  optionValue = 'text', 
  subtitle,
  filter,
  isEdit,
  required,
  isArray,
  ...rest
}) => {
  const [_, setStoredLocations] = useStore('locations');
  const { watch, setValue, getValues } = useFormContext();
  const { id, field: openField, fieldState } = useInput({ source });
  const [categoryPicked, setCategoryPicked] = useState();
  const { data: choices, error, loading } = useGetList(`${reference}`, { 
    pagination: {
      perPage:100, 
      page: 1
    },
    filter: {
      ...filter
    },
    limit:100
  },
  {
    onSuccess: ({data}) => setStoredLocations(data)
  });
  const valores = getValues();
  const handlerSetValue = (value) => {
    let valor;
    
    if (isArray) {
      if (!categoryPicked?.includes(value?.text)) {
        // Si no existe, agregarlo al array
        valor = value;
        setCategoryPicked([...categoryPicked, valor?.text]);
        openField.onChange([...categoryPicked, valor?.text]);
      }else{
        // Si ya se tiene agregado, eliminarlo
        valor = value;
        setCategoryPicked(categoryPicked.filter((item) => item !== value?.text));
        openField.onChange(categoryPicked.filter((item) => item !== value?.text));
      }
    } else {
      if (reference === 'events' || reference === 'talents') {
        valor = value?.value ?? '';
      } else if (reference === 'admins/manager_roles' || reference === 'talents/categories' || reference === 'events/categories' || reference === 'faqs/categories') {
        valor = value?.text ?? '';
      }else if(reference === 'locations') {
        valor = value?.name ?? '';
        setCategoryPicked(valor);
        openField.onChange(value.id);
        return;
      }
      else {
        valor = value?.id ?? '';
      }
      setCategoryPicked(valor);
      openField.onChange(valor);
    }
  };
  useEffect(() => {
    // Rellenamos el valor por defecto
    if(reference === 'events' || reference === 'events/categories' || reference === 'faqs/categories'){
      setCategoryPicked(valores?.category);
    }else if(reference === 'talents' || reference === 'talents/categories'){
      setCategoryPicked(valores?.main_category);
    }else if (reference === 'admins/manager_roles' && isEdit) {
      setCategoryPicked(valores?.manager_role && valores?.manager_role[0]);
    }else if (reference === 'talents/pillars') {
      if(valores?.pillars !== undefined){
        setCategoryPicked(valores?.pillars);
      }else{
        setCategoryPicked([]);
      }
    }
    else {
      setCategoryPicked(valores?.stage?.name);
    }
  },[]);
   
  return (
    <>
      {title && (
        <InputTitle required={required}>
          {title}
        </InputTitle>
      )}
      {subtitle &&  
      <InputSubtitle >{subtitle}</InputSubtitle>
      }
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10,marginBottom:'1.8em',...rest.sx}}>
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error</div>
        ) : isArray? (
          choices &&
          choices.map((choice, index) => (
            <Chip
              sx={{
                width: 'auto',
                padding: '10px',
                color: categoryPicked?.includes(choice?.[optionValue]) ? 'white' : '#000000',
                fontSize: '13px',
                fontWeight: '400',
                backgroundColor: categoryPicked?.includes(choice?.[optionValue])
                  ? COLORS.light.secondary.main
                  : COLORS.forms.pills.background,
                ':hover': {
                  backgroundColor: COLORS.light.secondary.main,
                  color: 'white',
                },
              }}
              key={index}
              onClick={() => handlerSetValue(choice)}
              label={choice[optionText]}
            />
          ))
        ):(
          choices &&
          choices.map((choice, index) => (
            <Chip
              sx={{
                width: 'auto',
                padding: '10px',
                color: categoryPicked === choice?.[optionValue] ? 'white' : '#000000',
                fontSize: '13px',
                fontWeight: '400',
                backgroundColor:
                  categoryPicked === choice?.[optionValue]
                    ? COLORS.light.secondary.main
                    : COLORS.forms.pills.background,
                ':hover': { backgroundColor: COLORS.light.secondary.main, color: 'white' },
              }}
              key={index}
              onClick={() => handlerSetValue(choice)}
              label={choice[optionText]}
            />
          ))
        )
        
        }
      </div>
    </>
  );
};

export default InputCategoryPills;
