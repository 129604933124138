import { Autocomplete, CircularProgress, TextField,Chip,Button } from '@mui/material';
import { Controller, useController, useForm, useFormContext, useWatch } from 'react-hook-form';
import { required, useGetList } from 'react-admin';
import { useState } from 'react';
import { COLORS } from '../../../../assets/constants/theme';
  
export const SelectCategory = ({choices,inputChange, control, resource, fieldName, name, label, pattern, ...rest}) => {
  const [inputValue, setInputValue] = useState(null);
  const [value, setValue] = useState(null);
  const { data, isFetching } = useGetList('talents/categories', {filter: {}, pagination: {perPage:9999, page: 1}});
  const [categoryPicked, setCategoryPicked] = useState(null);

  const handleChange = (e,v) => {
    setCategoryPicked(v?.text);
    inputChange && inputChange(v?.text);
    setInputValue(v?.text);
    setValue(v);
    if(v?.text === 'Music'){
      rest.setEvents((f) => {
        let newEventsNames = f?.reduce((a, b, index) => {
          let {value : { duration, name } = {}} = b;
          return [...a, {...b, value: {duration: duration, name: control._formValues['name']}}];
        },[]);
        console.log(newEventsNames,'eventosnAME')
        return newEventsNames;
      });
    } else {
      rest.setEvents((f) => {
        let newEventsNames = f?.reduce((a, b, index) => {
          let {value : { duration } = {}} = b;
          return [...a, {...b, value: {duration: duration, name: `Event ${index}`}}];
        },[]);
        return newEventsNames;
      });
    }
  };

//   return (
//   <Controller
//     name={name}
//     value={inputValue}
//     control={control}
//     render={({field,
//       fieldState,
//       formState}) => {
//       return (
//         <Autocomplete
//           {...field}
//           {...formState}
//           {...fieldState}
//           fullWidth 
//           value={value}
//           getOptionLabel={(option) => option?.text}
//           options={data}
//           onChange={(e,v) => {field.onChange(v?.text); handleChange(e,v);}}
//           renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
//         />
//       );
//     }}
//   />
// );
  return (
    <Controller
      name={name}
      value={inputValue}
      control={control}
      render={({ field, fieldState, formState }) => (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10,marginTop:'20px' }}>
          {data?.map((option) => (
            <Chip
              sx={{
                width: 'auto',
                paddingx: '10px',
                paddingy:'0px',
                color: categoryPicked === option.text ? 'white' : '#000000',
                fontSize:'13px',
                fontWeight:'400',
                backgroundColor:
                    categoryPicked === option.text
                      ? COLORS.light.secondary.main
                      : COLORS.forms.pills.background,
                  ':hover': { backgroundColor: COLORS.light.secondary.main, color: 'white' },
              }}
              key={option.text}
              onClick={() => {
                const selectedValue = option?.text || null; 
                field.onChange(selectedValue);
                handleChange(null, option);
              }}
              label={option.text}
            />
          ))}
        </div>
      )}
    />
  );
};