import { AiOutlineDownload } from 'react-icons/ai';
import { Box, Button, Tooltip } from '@mui/material';
import {  usePermissions,useSidebarState } from 'react-admin';
import axios from 'axios';
import { apiUrl } from '../../dataProvider';
import { mainColor } from '../../Theme';
import { useParams } from 'react-router-dom';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { ModalReporte } from './ModalReporte';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

export const CsvDownloader = ({
  list,
  talent,
  resource = 'talents',
  sx,
  minWidth,
  filterValues
}) => {
  const matches = useMediaQuery(minWidth ? minWidth : '(min-width:1600px)');
  const [open, setOpen] = useState(false);
  const params = useParams();
  const customToast = useCustomToasty();
  const permis = usePermissions()?.permissions !== 'talent_manager';
  const handleDownloadCsv = async (listFilter) => {
    const talent_id = talent ? [talent] : [params['*']?.split('/')[1]];
    // let filter = `?filter={"form":"${form}", "ids":${JSON.stringify(
    //   talent_id
    // )}}`;
    let url;
    if(resource === 'talents'){
      if(Object.keys(filterValues).length > 0){
        const filterParam = encodeURIComponent(JSON.stringify(filterValues));
        const decodedFilterParam = decodeURIComponent(filterParam);
        url = `${apiUrl}/${resource}/export_to_csv?filter=${decodedFilterParam}`;
      }else{
        url = `${apiUrl}/${resource}/export_to_csv`;
      }
    }
    if(resource === 'events'){
      if(Object.keys(filterValues).length > 0){
        console.log(filterValues);
        const filterParam = encodeURIComponent(JSON.stringify(filterValues));
        const decodedFilterParam = decodeURIComponent(filterParam);
        url = `${apiUrl}/${resource}/export_to_csv?filter=${decodedFilterParam}`;
      }else{
        url = `${apiUrl}/${resource}/export_to_csv`;
      }
    }
    if(resource === 'admins'){
      if(filterValues !== undefined && Object.keys(filterValues).length > 0){
        let newFilterValues ={
          ...filterValues,
          talent_id: talent_id
        };
        const filterParam = encodeURIComponent(JSON.stringify(newFilterValues));
        const decodedFilterParam = decodeURIComponent(filterParam);
        url = `${apiUrl}/${resource}/export_to_csv?filter=${decodedFilterParam}`;
      }else{
        let newFilterValues ={
          talent_id: talent_id
        };
        const filterParam = encodeURIComponent(JSON.stringify(newFilterValues));
        const decodedFilterParam = decodeURIComponent(filterParam);
        url = `${apiUrl}/${resource}/export_to_csv?filter=${decodedFilterParam}`;
      }
    }
    try {
      let response = await axios({
        method: 'GET',
        url: url,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          Authorization: 'Bearer ' + localStorage.getItem('auth'),
          'x-api-key': process.env.REACT_APP_API_KEY,
          'Content-Type': 'text/csv',
        },
        responseType: 'arrayBuffer',
      });
      if (response) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'downloaded_file.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        customToast('success', 'CSV downloaded succesfuly!');
      }
    } catch (error) {
      customToast('error', 'There was an error, please try again later.');

      throw new Error(error);
    }
  };
  // VERIFICAMOS SI EL OBJETO TIENE ALGO DENTRO O ESTA VAICO
  const styleButton =
    list && matches
      ? {
        fontSize: '.8em',
        fontWeight: '600',
        height: 'auto',
        minWidth: '260px',
      }
      : {
        width: '150px',
        borderRadius:'10px',
        fontWeight: '700',
        display: 'flex',
        border: '2px solid black',
        height: '44px',
        alignItems: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        justifyContent: 'center',
        textTransform:'none',
        gap:'10px',
        '&: hover': {
          transition: '300ms',
          backgroundColor: '#6b858680',
        },
      };
  return (
    <>
      {permis && (
        <Box
          sx={{
            display: 'flex',
            ...sx,
          }}
        >
          <Tooltip title="Export CSV" placement="top">
            {resource === 'talents' ? (
              <Button
                variant="outlined"
                onClick={!list ? handleDownloadCsv : () => setOpen(true)}
                sx={styleButton}
              >
                {matches  ? (<><AiOutlineDownload size="20px" />Export CSV</>):null}
                {!matches &&(
                  <AiOutlineDownload style={{ color: mainColor }} size="15px" />
                )}
              </Button>
            ) : resource === 'events' ? (
              <Button
                variant="outlined"
                onClick={handleDownloadCsv}
                sx={styleButton}
              >
                {matches  ? (<><AiOutlineDownload size="20px" />Export CSV</>):null}
                {!matches && (
                  <AiOutlineDownload style={{ color: mainColor }} size="15px" />
                )}
              </Button>
            ) : (
              <Button
                variant="outlined"
                onClick={handleDownloadCsv}
                sx={styleButton}
              >
                {matches  ? (<><AiOutlineDownload size="20px" />Export CSV</>):null}
                {!matches && (
                  <AiOutlineDownload style={{ color: mainColor }} size="15px" />
                )}
              </Button>
            )}
          </Tooltip>
          {list && open && (
            <ModalReporte
              defaultFilter={{ form: null, ids: null }}
              handleDownload={handleDownloadCsv}
              setOpen={setOpen}
              open={open}
            />
          )}
        </Box>
      )}
    </>
  );
};
