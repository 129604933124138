import React, { useState, useEffect } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Button, Typography, Box, Card } from '@mui/material';
import { COLORS } from '../../assets/constants/theme';
import { getToken } from '../../helpers/getToken';
import { useStore } from 'react-admin';
const logo = '/icon.png';

const JoyForTM = () => {
  const [selectedTalent, setSelectedTalent] = useStore('selectedTalent', {selected:false, talent:''});

  const tokens = getToken()?.talent_id;
  const [state, setState] = useState({
    run: false,
    steps: [
      {
        content: (
          <Typography variant="body1" fontWeight={400} gutterBottom>
            This tour will help you understand the different sections of this talent management tool.
          </Typography>
        ),
        title: (
          <Typography variant="h6" fontWeight={500} sx={{ pt: 2 }}>
            Welcome to Envision Festival 2024 Talent Manager Dashboard! 🌴
          </Typography>
        ),
        placement: 'center',
        target: 'body',
      },
      {
        content: (
          <Typography
            variant="body1"
            fontWeight={400}
            alignSelf={'flex-start'}
            gutterBottom
          >
            Here you choose which talent to manage, be careful over which talent you are editing. Every form needs to be filled out for each one of them.
          </Typography>
        ),
        title: (
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            alignSelf={'flex-start'}
          >
            Talent Dropdown
          </Typography>
        ),
        local: { skip: <strong>SKIP</strong> },
        placement: 'bottom',
        target: '#dropdowns',
      },
      {
        content: (
          <Typography
            variant="body1"
            fontWeight={400}
            alignSelf={'flex-start'}
            gutterBottom
          >
            {tokens.length > 1 ? 'This is the home page, where all the talents will be shown, here you can check their status and completeness.' :'This is the home page, you can check the talent’s status and completeness.'}
          </Typography>
        ),
        placement: 'bottom',
        target: '#talents-page',
        title: (
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            alignSelf={'flex-start'}
          >
            Talents
          </Typography>
        ),
      },
      {
        content: (
          <Typography
            variant="body1"
            fontWeight={400}
            alignSelf={'flex-start'}
            gutterBottom
          >
            This is the talent status tied to Envision’s approval flow. Each time you submit a Profile or Event form will be reviewed by Envision’s staff and approved, edited or rejected.
          </Typography>
        ),
        placement: 'bottom',
        target: '#statusSection',
        title: (
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            alignSelf={'flex-start'}
          >
            Status
          </Typography>
        ),
      },
      {
        target: '#tab-0',
        content: (
          <>
            <Typography
              variant="body1"
              fontWeight={400}
              alignSelf={'flex-start'}
              gutterBottom
            >
              In this tab you can update your contact details and expand your team by adding new members
            </Typography>
          </>
        ),
        placement: 'bottom',
        title: (
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            alignSelf={'flex-start'}
          >
            Contact Information:
          </Typography>
        ),
      },
      {
        target: '#tab-1',
        content: (
          <>
            <Typography
              variant="body1"
              fontWeight={400}
              alignSelf={'flex-start'}
              gutterBottom
            >
              {tokens?.length > 1 ? 'Here you will fill out information to shape the talent\'s profile landing page in our website and festival app. Content from here will be used to create meme cards and other promotional materials.'  : 'Here you will fill out information to shape your unique profile landing page in our website and festival app. Content from here will be used to create meme cards and other promotional materials.'}
            </Typography>
          </>
        ),
        placement: 'bottom',
        title: (
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            alignSelf={'flex-start'}
          >
            Profile Information:
          </Typography>
        ),
      },
      {
        target: '#tab-2',
        content: (
          <Typography
            variant="body1"
            fontWeight={400}
            alignSelf={'flex-start'}
            gutterBottom
          >
            {tokens?.length > 1 ? 'Here you will fill out information to shape the talent’s events in our website and festival app': 'Here you will fill out information to shape your events in our website and festival app'}
          </Typography>
        ),
        placement: 'bottom',
        title: (
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            alignSelf={'flex-start'}
          >
            Events
          </Typography>
        ),
      },
      {
        target: '#tab-3',
        content: (
          <Typography
            variant="body1"
            fontWeight={400}
            alignSelf={'flex-start'}
            gutterBottom
          >
            {tokens?.length > 1 ?'Here you will find the Marketing and Opportunities form to indicate promotional and collaboration opportunities that may be of interest to the talent.' : 'Here you will find the Marketing and Opportunities form to indicate promotional and collaboration opportunities that may be of interest to you.'}
          </Typography>
        ),
        placement: 'bottom',
        title: (
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            alignSelf={'flex-start'}
          >
            Marketing and Promotions
          </Typography>
        ),
      },
      {
        target: '#tab-4',
        content: (
          <Typography
            variant="body1"
            gutterBottom
            fontWeight={400}
            alignSelf={'flex-start'}
          >
            {tokens?.length > 1 ? 'In this tab you wil find the Talent Advance form, that will enable the talent to have a smooth arrival and experience on site.' : 'In this tab you wil find the Talent Advance form, that will enable you to have a smooth arrival and experience on site.' }
          </Typography>
        ),
        placement: 'bottom',
        title: (
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            alignSelf={'flex-start'}
          >
            AR & Hospitality
          </Typography>
        ),
      },
      {
        target: '#tab-5',
        content: (
          <Typography
            variant="body1"
            fontWeight={400}
            alignSelf={'flex-start'}
            gutterBottom
          >
            {tokens?.length > 1 ? 'Find the assets to help you promote the talent’s participation in the festival.' : 'Find the assets to help you promote your participation in the festival.'}
          </Typography>
        ),
        placement: 'bottom',
        title: (
          <Typography
            variant="subtitle1"
            gutterBottom
            fontWeight={500}
            alignSelf={'flex-start'}
          >
            Marketing Assets Library:
          </Typography>
        ),
      },
    ],
  });

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setState((prevState) => ({
        ...prevState,
        run: false,
      }));
    }

    // console.groupCollapsed(type);
    // console.log(data); //eslint-disable-line no-console
    // console.groupEnd();
  };
  const handleClick = (e) => {
    e.preventDefault();
    setState((prevState) => ({
      ...prevState,
      run: true,
    }));
    // Cuando inicia el TOUR tambien selecciona el primer talent , para asi desplegar el dropdown y mostrar todas las tabs.
    setSelectedTalent({
      selected: true,
      talent: tokens && tokens[0]
    });
  };
  return (
    <Box
      sx={{
        width: '100%',
        height:'15em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: '3em',
      }}
    >
      <Card variant="outlined" raised={true} sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', bgcolor: 'rgba(0,0,0,0.03)',width:'50em',borderRadius:'10px' }}>
        <img src={logo} alt="logo" style={{ width: '20%',height: '100%', objectFit: 'contain' }} />
        <div style={{width:'60%'}}>
          <Typography
            variant="h6"
            fontWeight={700}
            gutterBottom
            alignSelf={'flex-start'}
          >
          Welcome to Envision Festival !
          </Typography>
          <Typography>
        In this dashboard you will manage your talent and see their approval status
          </Typography>
        </div>
        <Button
          onClick={handleClick}
          size="medium"
          color="secondary"
          variant="contained"
          sx={{ marginLeft: '5px',width:'15%',borderRadius:'10px',paddingY:'10px' }}
        >
          Start Tour
        </Button>
      </Card>
      <Joyride
        continuous
        styles={{
          options: {
            primaryColor: COLORS.light.primary.main
          }
        }}
        callback={handleJoyrideCallback}
        run={state?.run}
        steps={state?.steps}
        scrollToFirstStep
        showSkipButton
        showProgress
      />
    </Box>
  );
};

export default JoyForTM;
