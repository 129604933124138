import { Box, Typography } from '@mui/material';
import { FaRegHandPointRight } from 'react-icons/fa';
import { useSidebarState } from 'react-admin';



export const LinkedForm = ({link,from}) => {
  const openSideBar = useSidebarState();
  return(
    <Box
      sx={{
        position: 'absolute',
        top: '20%',
        left: '50%',
        transform: `translate(${!openSideBar[0] ? 'calc(58px + -70%)' : 'calc(248px + -70%)'}, 50%)`,
        padding: '50px',
        border: `1px solid ${'#757575'}`,
        borderRadius: '12px'
      }}
    >
  
      {from !== 'hospitality'? <Typography
        sx={{
          marginBottom: '20px',
          color: '#757575'
        }}
      >
        Marketing & Promotional Opportunities:
        The information collected in this form will be used for a variety of marketing and promotional activities and other online opportunities, both leading up to the festival, during and after it.<br /> 
        We kindly ask you to fully complete this form. We appreciate your collaboration and look forward to seeing you in the jungle soon!
        <br /><br />
        <div style={{'display': 'flex', 'flex-direction': 'row', 'align-items': 'center', 'gap': '20px'}}>
          <FaRegHandPointRight size="20px"/> <a target="_blank" href={link} rel="noreferrer">FORM</a>
        </div>
        {/* We&apos;re thrilled to embark on another journey into the jungle with you! As we prepare to make this festival one to remember, we invite you to complete our Marketing & Promo Form on <a target="_blank" href={link} rel="noreferrer">THIS LINK</a>. Your input will help us create incredible collaboration opportunities and activities, both leading up to the festival and during and after it. Please ensure your information is submitted by January 20th, 2024. */}
      </Typography>: <Typography
        sx={{
          marginBottom: '20px',
          color: '#757575'
        }}
      >
        🛎AR - Hospitality:
Please take the time to fill out our Talent Advance Form with pertinent information so we can ensure your smooth arrival and experience on-site.
Envision is a large festival in a remote location, so please take the time to plan your experience in advance as much as possible.
Due by no later than January 20th, 2024.
        <br /><br />
        <div style={{'display': 'flex', 'flex-direction': 'row', 'align-items': 'center', 'gap': '20px'}}>
          <FaRegHandPointRight size="20px"/> <a target="_blank" href={link} rel="noreferrer">FORM</a>
        </div>
        {/* We&apos;re thrilled to embark on another journey into the jungle with you! As we prepare to make this festival one to remember, we invite you to complete our Marketing & Promo Form on <a target="_blank" href={link} rel="noreferrer">THIS LINK</a>. Your input will help us create incredible collaboration opportunities and activities, both leading up to the festival and during and after it. Please ensure your information is submitted by January 20th, 2024. */}
      </Typography>}
    </Box>
  );
};

//<a target="_blank" href={link} rel="noreferrer">THIS LINK</a>