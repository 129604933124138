import React, { useEffect, useState } from 'react';
import AdaptableForm from '../../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../../Components/InputsForm/InputText';
import { Box, Button, Link } from '@mui/material';

import axios from 'axios';
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
import { useGetList } from 'react-admin';

const FormResources = () => {


  const resorce = useGetList('resources', { filter: {}, pagination: { perPage: 9999, page: 1 } });
  const [info, setInfo] = useState(null);
  const customToast = useCustomToasty();
  console.log(resorce)
  if (!resorce) return null;
  if (!info) {
    axios({
      url: `${process.env.REACT_APP_BASE_PATH}/resources/${resorce?.data?.[0]?.id}`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      }
    }).then(res => {
      setInfo(res);
    }).catch(err => {
      throw err;
    });
  }
  if (!info) return null;

  const data = [
    {
      title: 'Promo Assets link ',
      inputs: [
        {
          field:
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <InputText source="link" fullWidth defaultValue={info?.data?.link} />
                          <Link target="_blank" href={info?.data?.link} sx={{ marginBottom: '20px', marginLeft: '20px', width: '140px' }}>
                            <Button color='secondary' variant='contained'>Go to link</Button>
                          </Link>
                        </Box>,
          breakpoints: { xs: 8 }
        },
      ]
    }
  ];

  const onSubmit = async (data) => {
    axios({
      url: process.env.REACT_APP_BASE_PATH + `/resources/${resorce?.data?.[0]?.id}`,
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      data: data

    })
      .then(res => {
        customToast('success', 'Changes submitted succesfuly.');
      })
      .catch(err => {
        customToast('error', 'There was an error, please try again later.');
      });
  };

  return (

    <AdaptableForm
      data={data}
      isEdit
      simpleform={{ record: info.data, onSubmit: onSubmit }}
      from={false}
    />

  );
};

export default FormResources;