import React from 'react';
import { useRedirect,useRecordContext,useGetList } from 'react-admin';
// Components
import InputText from '../../../Components/InputsForm/InputText';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputSelect from '../../../Components/InputsForm/InputSelect';
import { InputRedesignImageAws, InputTime } from '../../../Components/RedesignComponents/Inputs';
// Hooks & Utils
import { COLORS } from '../../../assets/constants/theme';
// Material & Icons
import { Box,Typography } from '@mui/material';
import { MdArrowBack } from 'react-icons/md';
import { EXCURSIONS_TOOLTIPS } from '../../TalentForm/Tooltips';
import { InputDurationMinutes } from '../../../Components/InputsForm/InputDuration';

const FormActivities = ({isEdit,from}) => {
  const record = useRecordContext();
  const redirect = useRedirect();
  const { data: locations,isFetching } = useGetList('locations', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
    // filter: {
    //   event_ready: true
    // }
  });
  const { data: experiencesTypes, isFetching: isFetchingExperiences } = useGetList('experiences/types', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'name', order: 'ASC' },
  });
  const data= [
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>LOCATION DETAILS</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 }
        },
        {
          field: <InputText required={true} fullWidth source="name" title={'Name'} label=''/>,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputSelect
            required={true}
            choices={isFetchingExperiences ? [] : experiencesTypes}
            optionValue={'text'}
            optionText={'text'}
            fullWidth
            source="type"
            title={'Type'} label={''}
          />,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 3 }
        },
        {
          field:  <InputText  required={true}  fullWidth key={Math.random()} source="description"  title={'DESCRIPTION'} subtitle={'Describe thoroughly your location'} label={''}/>,
          breakpoints: { xs:8.5 }
        },
        {
          field: <></>,
          breakpoints: { xs: 3 }
        },
        { 
          field: <InputRedesignImageAws title={'Image'} tooltip={EXCURSIONS_TOOLTIPS['image']} source={'picture.original'}  actionText="Upload Image"/>,
          breakpoints: { xs: 4 }
        },
        {
          field: 
          <InputSelect
            choices={isFetching ? [] : locations}
            optionValue={'id'}
            optionText={'name'}
            fullWidth
            source="location_id"
            title={'Meeting Point'} 
            subtitle={'If your location has a meeting point or a location where it takes place, select from festival’s available locations'}
            label={''}
          />,
          breakpoints: { xs: 4 }
        },   
      ]
    }, 
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>BOOKING DETAILS</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 },
        },
        {
          field: 
          <InputText source="book_url" title={'URL'} subtitle={EXCURSIONS_TOOLTIPS['book_url']} label={''}/>,
          breakpoints: { xs: 8.5 }
        },
      ]
    },
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>TIMES</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <InputDurationMinutes
              title="Duration (IN MINUTES)"
              subtitle={'The end date and time will be set automatically'}
              source="duration"
              InputProps={{ sx: { borderRadius: '10px' } }}
            />
          ),
          breakpoints: { xs:4},
        },
        {
          field: <></>,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 3 }
        },
        {
          field: 
          <InputTime 
            source={['start_date','end_date']} 
            checkStageTime
          />,
          breakpoints: { xs: 8.5 }
        },
      ]
    }
  ];
  const goBack = () => {
    redirect('/experiences');
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          padding: '20px 16px',
          // height: '6.5%',
          gap: 1,
        }}
      >
        <button
          onClick={() => goBack()}
          style={{
            display: 'flex',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <MdArrowBack size={30} />
        </button>
        <Typography fontSize={'32px'} fontWeight={600}>
          {isEdit ?'Edit Excursion - '+ record?.name : 'New Excursion'}
        </Typography>
      </Box>
      <AdaptableFormRedesign
        isEdit={isEdit}
        data={data}
        from={from}
        icon={false}
        saveBackground={true}
        showDelete
      />
    </>
  );
};

export default FormActivities;