import React, { useCallback, useState } from 'react';
import { AutocompleteArrayInput, AutocompleteInput } from 'react-admin';
import { INPUTS_VARIANT } from '../../assets/constants/Form';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
import { TextField } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { COLORS } from '../../assets/constants/theme';
const InputAutocomplete = ({placeholder, required,title, titleTransform, subtitle, limit, isArray, width,...rest }) => {
  const { watch, formState, getValues,setValue } = useFormContext();

  const [limitReached, setLimitReached] = useState(false);
  const [values, setValues] = useState([]);
  const onSelect = useCallback((newValues) => {
    setValues(newValues);
    setLimitReached(newValues.length >= limit);
  }, [limit]);

  const checkDisable = useCallback(option => limitReached && !values.includes(option), [limitReached, values]);

  if (limit) {
    return (
      <AutocompleteArrayInput 
        onChange={onSelect} 
        getOptionDisabled={checkDisable} 
        variant={INPUTS_VARIANT}
        sx={{'& .MuiAutocomplete-inputRoot': {
          borderRadius: '10px'
        }}} 
        {...rest} />
    );
  }

  if (isArray) {
    return (
      <div>
        {title &&  
      <InputTitle required={required} textTransform={titleTransform} value={title} />
        }
        {subtitle &&  
        <InputSubtitle>{subtitle}</InputSubtitle>
        }
        <AutocompleteArrayInput
          variant={INPUTS_VARIANT} 
          {...rest} 
          sx={{'& .MuiAutocomplete-inputRoot': {
            borderRadius: '10px',
            width:width ? width : null,
            '& .MuiChip-root':{
              backgroundColor:COLORS.light.secondary.main,
              color:'white',
              padding:'5px'
            },
            '& .MuiChip-deleteIcon':{
              color:'white',
            }
          }}}
        />
      </div>
    );
  }

  if(placeholder) {
    return (
      <>
        {title &&  
        <InputTitle textTransform={titleTransform} value={title} />
        }
        {subtitle &&  
        <InputSubtitle >{subtitle}</InputSubtitle>
        }
        <AutocompleteInput variant={INPUTS_VARIANT} {...rest} renderInput={(params) => <TextField {...params} {...rest} variant="outlined" placeholder={placeholder} />} sx={{'& .MuiAutocomplete-inputRoot': {
          borderRadius: '10px'
        }}}/>
      </>
    );
  }

  return (
    <>
      {title &&  
      <InputTitle required={required} textTransform={titleTransform} value={title} />
      }
      {subtitle &&  
      <InputSubtitle>{subtitle}</InputSubtitle>
      }
      <AutocompleteInput variant={INPUTS_VARIANT} {...rest} sx={{'& .MuiAutocomplete-inputRoot': {
        borderRadius: '10px'
      }}}/>
    </>
  );
};

export default InputAutocomplete;