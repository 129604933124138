import { Box, FormLabel, TextField, Typography } from '@mui/material';
import { ArrayInput, BooleanInput, NumberInput, RadioButtonGroupInput, SelectArrayInput, SelectInput, SimpleFormIterator, TextInput, required, useGetOne, useInput } from 'react-admin';
import { useParams } from 'react-router-dom';
import { TabbedSimpleForm } from './TabbedSimpleForm';
import { EDUCATION_TOOLTIPS } from './Tooltips';
import { InputAddTextInputs } from '../Talents/Form/TalentsListComponents/InputAddTextInputs';
import { useEffect, useState } from 'react';
import { InputTitle } from '../../Components/StyledComponents/InputTitle';
import { OnlineRetreats, PersonRetreats, SelectArrayEducationInput } from '../../Components/InputsForm/SelectArrayEducationInput';
import { InputSubtitle } from '../../Components/StyledComponents/InputSubtitle';
import InputSelect from '../../Components/InputsForm/InputSelect';
import { InputRadioGroup } from '../../Components/InputsForm/InputRadioGroup';
import { LinkedForm } from './LinkedForm';
import InputText from '../../Components/InputsForm/InputText';
import AdaptableFormRedesign from '../../Components/AdaptableForm/AdaptableFormRedesign';
import { COLORS } from '../../assets/constants/theme';
import InputBoolean from '../../Components/InputsForm/InputBoolean';


export const EducationForm = () => {

  const params = useParams();
  const talent_id = params['*']?.split('/')[1] ?? params?.id;
  const { data: talentData, loading, error } = useGetOne('talents', {id: talent_id});
  
  const data = [
    {
      title: 'Online Education',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>ONLINE EDUCATION</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 },
        },
        {
          field: <>
            <InputTitle  value={'Has online content'} />
            <InputSubtitle>Mark this if your artist offers additional bookable content through other platforms</InputSubtitle>
            <BooleanInput 
              InputProps={{
                sx: {
                  borderRadius: '10px'
                }
              }}
              variant="outlined"
              source={'online_education.active'}
              
              label=''
              placeholder={'Online Content'}
              fullWidth
            />
          </>,
          breakpoints: {xs: 4}
        },
        {
          field: <></>,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 3 }
        },
        {
          field: 
            <>
              <InputText 
                InputProps={{
                  sx: {
                    borderRadius: '10px'
                  }
                }}
                title="Title"
                subtitle="Enter a brief title that describes your talent's package (Max 8 char)"
                variant="outlined"
                placeholder="Care your hair with Hanna Montana"
                source={'online_education.text'}
                
                label=''
                fullWidth
              />
            </>,
          // tooltip: EDUCATION_TOOLTIPS['online_education.online_content'],
          breakpoints: { xs: 4}
        },
       
        {
          field: 
            <>
              <InputText 
                InputProps={{
                  sx: {
                    borderRadius: '10px'
                  }
                }}
                title="Button Text"
                subtitle="Enter a brief title that describes your talent's package (Max 8 char)"
                variant="outlined"
                placeholder="Book Now"
                source={'online_education.cta'}
                
                label=''
                fullWidth
              />
            </>,
          // tooltip: EDUCATION_TOOLTIPS['online_education.online_content'],
          breakpoints: { xs: 4}
        },
        {
          field: <></>,
          breakpoints: { xs: 3 }
        },
        {
          field: 
            <>
              <InputText 
                InputProps={{
                  sx: {
                    borderRadius: '10px'
                  }
                }}
                title="Url"
                subtitle="The url to your package"
                variant="outlined"
                placeholder="https://my.course"
                source={'online_education.url'}
                
                label=''
                fullWidth
              />
            </>,
          // tooltip: EDUCATION_TOOLTIPS['online_education.online_content'],
          breakpoints: { xs: 4}
        }, {
          field: <></>,
          breakpoints: { xs: 4 }
        },
        // {
        //   field: 
        //   <>
        //     <InputTitle  value={'Extended online education'} />
        //     <BooleanInput 
        //       InputProps={{
        //         sx: {
        //           borderRadius: '10px'
        //         }
        //       }}
        //       variant="outlined"
        //       source={'online_education.active'}
        //       validate={[required()]}
        //       label=''
        //       placeholder={'Online Content'}
        //       fullWidth
        //     />
        //   </>,
        //   // tooltip: EDUCATION_TOOLTIPS['online_education.online_content'],
        //   breakpoints: { xs: 4}
        // },
        // {
        //   field: 
        //   <>
        //     <InputText 
        //       InputProps={{
        //         sx: {
        //           borderRadius: '10px'
        //         }
        //       }}
        //       title="Call to action"
        //       watchField={'online_education.active'}
        //       variant="outlined"
        //       source={'online_education.call_to_action'}
        //       validate={[required()]}
        //       label=''
        //       fullWidth
        //     />
        //   </>,
        //   // tooltip: EDUCATION_TOOLTIPS['online_education.online_content'],
        //   breakpoints: { xs: 4}
        // },
       
        // {
        //   field: 
        //   <>
        //     <InputText 
        //       InputProps={{
        //         sx: {
        //           borderRadius: '10px'
        //         }
        //       }}
        //       title="External URL"
        //       watchField={'online_education.active'}
        //       variant="outlined"
        //       source={'online_education.url'}
        //       validate={[required()]}
        //       label=''
        //       placeholder={'https://url.com/...'}
        //       fullWidth
        //     />
        //   </>,
        //   // tooltip: EDUCATION_TOOLTIPS['online_education.online_content'],
        //   breakpoints: { xs: 4}
        // },
        // {
        //   field: <InputSelect 
        //     fullWidth
        //     textTransform={null}
        //     watchField={'online_education.online_content'}
        //     approvedFields={['yes', 'maybe']}
        //     optionText='name'
        //     optionValue='id'
        //     title={<><span>Type of online content you would like to offer.<br/></span> <br/></>}
        //     choices={[
        //       {id: 'courses', name: 'Online Courses'},
        //       {id: 'ebooks', name: 'eBooks'}, 
        //       {id: 'in_person_retreats', name: 'In person retreats'}, 
        //       {id: 'workshops', name: 'Live Online Workshops/Classes'},
        //       {id: 'online_events', name: 'Online Events'},
        //       {id: 'others', name: 'Others'},
        //     ]}
        //     label=""
        //     source="online_education.online_content_type" />,
        //   tooltip: 'Select the type of content you offer',
        //   breakpoints: { xs: 5}
        // },
        // {
        //   field: <InputSelect 
        //     fullWidth
        //     textTransform={null}
        //     watchField={'online_education.online_content'}
        //     approvedFields={['yes', 'maybe']}
        //     optionText='name'
        //     optionValue='id'
        //     title={<><span>How would you like to offer your content?<br/></span> <br/></>}
        //     choices={[
        //       {id: 'paid', name: 'Paid'},
        //       {id: 'free', name: 'Free'},
        //       {id: 'both', name: 'Both'}
        //     ]}
        //     label=""
        //     source="online_education.pricing_type" />,
        //   breakpoints: { xs: 5}
        // },
        
        // {
        //   field: <SelectArrayEducationInput 
        //     title={'Please provide us with links and some brief information regarding your content.'}
        //     addText="Add your Content" 
        //     approvedFields={['yes']}
        //     element={<OnlineRetreats />}
        //     source="online_education.contents"
        //     watchField="online_education.online_content"
        //   />,
        //   breakpoints: {xs: 12}
        // },
      ]
    }
  ];

  return (
    <AdaptableFormRedesign 
      floating 
      lastSubmitter 
      noTooltip 
      record={talentData}
      isEdit 
      data={data} 
      draft="talents"
      form="education"
    />
  );
  // return <LinkedForm link="" />;

};

export const LinksArrayTextField = ({source, label}) => {
  const {field} = useInput({source, defaultValue: []});
  const [contents, setContents] = useState([]);

  const onDelete = (index) => {
    setContents((arr) => {
      let updatedArr = arr.filter((item, i) => i !== index);
      let reindexedArr = updatedArr.map((item, i) => ({
        ...item,
        key: i + 1,
      }));
      console.log('mapped', reindexedArr);
      return reindexedArr;
    });
  };

  useEffect(() => {
    field.onChange(contents);
  },[contents]);

  return(
    <>
      {
        contents && (
          <InputAddTextInputs
            // disable 
            limit={8}
            direction="row"
            values={contents}
            // setValues={setContents}
            onDelete={onDelete}
            sx={{gap: '20px'}}
            element={<ContentComponent contents={contents} setContents={setContents} />}/>
        )
      
      }
    </>
    
  );
};

export const ContentComponent = ({contents, setContents, componentKey}) => {

  const [content, setContent] = useState({key: Math.floor(parseFloat(componentKey))});

  const handleAddLink = (e, name, id) => {
    let value = e.target.value;
    let newObj = {...content, [name]:value};
    setContent(newObj);
  };

  useEffect(() => {
    setContents((arr) => {
      const index = arr.findIndex((item) => item?.key === content.key);
  
      if (index !== -1) {
        const newArr = [...arr];
        newArr[index] = content;
        return newArr;
      } else {
        return [...arr, content];
      }
    });
  },[content]);

  return (
    <Box
      sx={{
        display: 'grid',
        width: '80%',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridGap: '20px',
        // width: '80%'
      }}
    >
      <TextField sx={{gridColumn: 'span 4'}} onChange={(e,v) => handleAddLink(e, 'link')} label={'Url'} variant="outlined"  />
      <TextField sx={{gridColumn: 'span 4'}} multiline minRows={2} onChange={(e) => handleAddLink(e, 'description')} label={'Description'} variant="outlined"  />
      <TextField sx={{gridColumn: 'span 2'}} type="number" onChange={(e) => handleAddLink(e, 'min')} InputLabelProps={{shrink: true}} label={'Min Value * USD'} variant="outlined"  />
      <TextField sx={{gridColumn: 'span 2'}} type="number" onChange={(e) => handleAddLink(e, 'max')} InputLabelProps={{shrink: true}} label={'Max Value * USD'} variant="outlined"  />
    </Box>
  );
};