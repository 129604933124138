import React from 'react';
import FormVenues from './FormVenues';
import { Create } from 'react-admin';
import { transform } from '../transform/transform';

const CreateVenues = () => {
  return (
    <Create mode="onChange" transform={transform} redirect='list' >
      <FormVenues from={'venues'} isEdit={false}/>
    </Create>
  );
};

export default CreateVenues;