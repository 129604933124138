import dataProvider from './dataProvider';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import Events from './Resources/Events';
import Talents from './Resources/Talents';
import { QueryClient } from 'react-query';
import { lightTheme } from './Theme';
import LayoutCustom from './Components/LayoutCustom/LayoutCustom';
import LoginPageCustom from './Resources/Login/LoginPageCustom';
import authProvider from './authProvider';
import Venues from './Resources/Venues';
import Activities from './Resources/Activities';
import Notifications from './Resources/Notifications';
import HomeComponents from './Resources/HomeComponents/index.js';
import Faqs from './Resources/Faqs';
import Blogs from './Resources/Articles/Blogs';
import Admins from './Resources/Admins';
import Popups from './Resources/Popups/index.js';
import Resources from './Resources/Resources/index.js'
import {MdOutlineAppSettingsAlt } from 'react-icons/md';
import { FaBullhorn } from 'react-icons/fa';
import { TbMicrophone2 } from 'react-icons/tb';
import { MdInterpreterMode } from 'react-icons/md';
import { userAccessControl } from './helpers/permissions';
import { useEffect } from 'react';
import moment from 'moment-timezone';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Route } from 'react-router-dom';
import { ProfileForm } from './Resources/TalentForm/ProfileForm';
import { ContactInformation } from './Resources/TalentForm/ContactInformation';
import { FestivalEditForm } from './Resources/Festivals/Form/FestivalEditForm';
import FormEvents from './Resources/Events/Form/FormEvents.jsx';
import { MarketingForm } from './Resources/TalentForm/MarketingForm';
import { EducationForm } from './Resources/TalentForm/EducationForm';
import { HospitalityForm } from './Resources/TalentForm/HospitalityForm';
import { CountdownForm } from './Resources/HomeComponents/Sections/Countdown/CountdownForm.jsx';
import { PopupsForm } from './Resources/Popups/PopupsForm.jsx';
import TalentsList from './Resources/Talents/TalentsList';
import Dashboard from './Resources/Dashboard/index';
import FormResources from './Resources/Resources/Form/FormResource.jsx';
import EventSubmissions from './Resources/Submissions/EventSubmissions/index.js';
import TalentSubmissions from './Resources/Submissions/TalentSubmissions/index.js';
import DashboardTM from './Resources/Dashboard/DashboardTM/index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function App() {

  useEffect(() => {
    moment.tz.setDefault('America/Costa_Rica');
  }, []);
  return (
    <>
      <Admin
        sx
        queryClient={queryClient}
        dataProvider={dataProvider}
        theme={lightTheme}
        layout={LayoutCustom}
        authProvider={authProvider}
        loginPage={LoginPageCustom}
        requireAuth
        disableTelemetry>
        {(permissions) => [
          userAccessControl(permissions, 'read', ['talentsForm']) ? (
            <CustomRoutes>
              <Route
                path={'/talent-information'}
                // path={`/talent-information/${getToken()?.talent_id}`}
                element={<TalentsList />}
              /> 
               ,
              <Route
                path={'/profile'}
                // path={`/profile/${getToken()?.talent_id}`}
                element={<ProfileForm isEdit />}
              />
              ,
              {/*(NEW VIEW TM) ERROR DE pathname si existe el antiguo path= */}
              <Route
                path={'/info'}
                // path={`/info/${getToken()?.talent_id}`}
                element={<ContactInformation />}
              />  
              ,
              <Route
                path={'/event'}
                // path={`/event/${getToken()?.talent_id}`}
                element={<FormEvents isEdit from={'talents'}/>}
              /> 
              ,
              <Route
                path={'/marketing'}
                // path={`/marketing/${getToken()?.talent_id}`}
                element={<MarketingForm />}
              />
              ,
              <Route
                path={'/education'}
                // path={`/education/${getToken()?.talent_id}`}
                element={<EducationForm />}
              />
              ,
              {/* <Route
                path={'/resources'}
                // path={`/resources/${getToken()?.talent_id}`}
                element={<TalentResource />}
              /> */}
              ,
              <Route 
                path={'/hospitality'} 
                // path={`/hospitality/${getToken()?.talent_id}`}
                element={<HospitalityForm/>} />,
            </CustomRoutes>
          ) : null,
          // userAccessControl(permissions, 'read', ['dashboard']) ?
          //   <Resource name="dashboard" options={{ label: 'Dashboard'}} {...Dashboard} /> : null,
          userAccessControl(permissions, 'read', ['dashboardTM']) ?
            <Resource name="dashboardtm" options={{ label: 'Dashboard'}} {...DashboardTM} /> : null,
          //******************* FESTIVAL MANAGEMENT SECTION *******************
          userAccessControl(permissions, 'read', ['submissions']) ? (
            <Resource
              name='FESTIVAL MANAGEMENT'
              options={{ isTitle: true}}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['submissions']) ? (
            <Resource
              name='Talent'
              options={{ isMenuParent: true }}
              icon={MdInterpreterMode}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['talents']) ? (
            <Resource
              name='talents'
              options={{ label: 'Active', menuParent: 'Talent' }}
              {...Talents}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['submissions']) ? (
            <Resource
              name='talent_submissions'
              options={{ label: 'Submissions', menuParent: 'Talent', notifications: true }}
              {...TalentSubmissions}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['submissions']) ? (
            <Resource
              name=' Events'
              options={{ isMenuParent: true }}
              icon={TbMicrophone2}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['events']) ? (
            <Resource name='events' options={{ label: 'Active', menuParent: ' Events' }} {...Events} />
          ) : null,
          userAccessControl(permissions, 'read', ['events']) ? (
            <Resource name='event_submissions' options={{ label: 'Submissions', menuParent: ' Events', notifications: true }} {...EventSubmissions} />
          ) : null,
          userAccessControl(permissions, 'read', ['locations']) ? (
            <Resource name='locations' options={{ label: 'Locations' }} {...Venues} />
          ) : null,
          userAccessControl(permissions, 'read', ['experiences']) ? (
            <Resource name='experiences' options={{ label: 'Activities' }} {...Activities} />
          ) : null,
          //******************* APP MANAGEMENT SECTION *******************
          userAccessControl(permissions, 'read', [
            'popups',
            'notifications',
            'articles',
            'faqs',
            'homeComponents',
            'posts'
          ]) ? (
              <Resource
                name='APP MANAGEMENT'
                options={{ isTitle: true }}
                icon={MdOutlineAppSettingsAlt}
              />
            ) : null,
          userAccessControl(permissions, 'read', ['homeComponents']) ? (
            <Resource
              name='homeComponents'
              options={{ label: 'Home Components' }}
              {...HomeComponents}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['notifications']) ? (
            <Resource
              name='notifications'
              options={{ label: 'Notifications' }}
              {...Notifications}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['articles']) ? (
            <Resource
              name='posts'
              options={{ label: 'Articles' }}
              {...Blogs}
            />
          ) : null,
          // userAccessControl(permissions, 'read', ['categories']) ? (
          //   <Resource
          //     name='categories'
          //     options={{ label: 'Categories',menuParent:'articles' }}
          //     {...Blogs}
          //   />
          // ) : null,
          userAccessControl(permissions, 'read', ['popups']) ? (
            <Resource
              name='popups'
              options={{ label: 'Pop Up' }}
              {...Popups}
            />
          ) : null,

          //******************* UTILITIES SECTION *******************
          userAccessControl(permissions, 'read', [
            // 'heros',
            'faqs',
            'resources',
          ]) ? (
              <Resource
                name='UTILITIES'
                options={{ isTitle: true }}
                icon={FaBullhorn}
              />
            ) : null,
          userAccessControl(permissions, 'read', ['faqs']) ? (
            <Resource
              name='faqs'
              options={{ label: 'FAQs'}}
              {...Faqs}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['resources']) ? (
            <Resource
              name='asd'
              options={{ label: 'Digital Assets' }}
              {...Resources}
            />
          ) : null,
          //******************* SETTINGS SECTION *******************
          userAccessControl(permissions, 'read', ['admins']) ? (
            <Resource
              name='SETTINGS'
              options={{ isTitle: true }}
            />
          ) : null,
          userAccessControl(permissions, 'read', ['admins']) ? (
            <Resource
              name='admins'
              options={{ label: 'Users'}}
              {...Admins}
            />
          ) : null,
          // userAccessControl(permissions, 'read', ['users']) ? (
          //   <Resource name='users' options={{ label: 'Users' }} {...Users} />
          // ) : null,
          // userAccessControl(permissions, 'read', ['users']) ? (
          //   <Resource name='usersData' options={{ label: 'Users Data' }} {...UsersData} />
          // ) : null,
          // userAccessControl(permissions, 'read', ['submissions']) ? (
          //   <Resource
          //     name='Event'
          //     options={{ isMenuParent: true }}
          //     icon={TbMicrophone2}
          //   />
          // ) : null,

          userAccessControl(permissions, 'read', ['festivals']) ? (
            <CustomRoutes>
              <Route
                path={'/festivals/64de33473c70cacd8a1fdbde/edit'}
                element={<FestivalEditForm />}
              />
              <Route path={'/countdown'} element={<CountdownForm />} />
              <Route path={'/resources'} element={<FormResources />} />
              <Route path='/popups' element={<PopupsForm />} />
            </CustomRoutes>
          ) : null,
        ]}
      </Admin>
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </>
  );
}

export default App;