import { Box, Button, IconButton, TextField, ToggleButton, Typography } from '@mui/material';
import { BannerImage, appCard, appCard2, styledBanner, styledButton, styledImage, styledModal } from './BlogStyles/styledBlogs';
import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';
import { useFormContext } from 'react-hook-form';
import { appColor } from '../../Theme';
import { strCapitalize } from '../../helpers/strCapitilize';
import { BsArrowLeft } from 'react-icons/bs';
import { recursiveCard } from './helpers';
import VisibilityIcon from '@mui/icons-material/Visibility';


const BlogRenderer = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef(false);
  useOnClickOutside(ref, () => setOpen(false));
  return (
    <>
      <ToggleButton
        value="left"
        title="Preview of the blog"
        onClick={() => setOpen(!open)}><VisibilityIcon fontSize="20px" /></ToggleButton>
      {
        open && (
          <Box style={{position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '999'}}>
            <Box ref={ref} style={styledModal}>
              <PhoneDiv />
            </Box>
          </Box>
        )
      }
    </>
  );
};

export default BlogRenderer;

export const PhoneDiv = () => {
  const {getValues} = useFormContext();
  
  return(
    <Box style={{backgroundColor: appColor, width: '100%', height: '800px', display: 'flex', flexDirection: 'column', overflow: 'hidden', overflowY: 'scroll', padding: '10px'}}>
      <Box style={{ height: '50px', width: '100%', textTransform: 'uppercase', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}}>
        <IconButton style={{flexGrow: 4, marginLeft: '20px'}}><BsArrowLeft /></IconButton>
        <Typography style={{flexGrow: 2, textTransform: 'uppercase', width: '100%'}}>{getValues('title') ?? ''}</Typography>
        <Box style={{display:'none', flexGrow: 1, width: '100%'}} />
      </Box>
      <img style={styledImage} src={getValues('picture.original')} />
      <Typography style={{fontSize: '30px', textTransform: 'uppercase', fontWeight: '600', margin: '10px 0 0 10px'}}>{getValues('title')}</Typography>
      <Typography style={{fontSize: '20px', fontWeight: '100', margin: '10px 0 0 20px'}}>{strCapitalize(getValues('subtitle') ?? '') ?? ''}</Typography>
      <TextBioArea>{getValues('body')}</TextBioArea>
    </Box>
  );
};


export const TextBioArea = ({children}) => {
  const [render, setRender] = useState(recursiveCard(children));

  useEffect(() => {
    let str = children;
    setRender(recursiveCard(str));
    return() => setRender(children);
  },[children]);

  return(
    <Box style={{ marginLeft: '20px', whiteSpace: 'pre-line'}}>
      {React.createElement('div', { dangerouslySetInnerHTML: { __html: render } })}
    </Box>
  );
};