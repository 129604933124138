import React, { useEffect, useMemo, useState } from 'react';
import { DateField, FunctionField, ReferenceField, TextField, useGetList, useListContext, usePermissions, useRefresh, useSidebarState, useStore } from 'react-admin';
import { Box,Typography,Chip } from '@mui/material';
import CalendarTimeLine from '../../Components/CalendarTimeLine/CalendarTimeLine';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CalendarToolbar } from './CalendarToolbar';
import { RedirectModal } from '../Talents/TalentsList';
import { COLORS } from '../../assets/constants/theme';
import { MdCheckCircle } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { Indicator } from '../../Components/RedesignComponents/Indicator';
import { SideActions } from '../Talents/TalentsList';
import { convertToCostaRicaTime } from '../../helpers/date';
import moment from 'moment';
const EventsList = ({ ...props }) => {
  const [viewCalendar, setViewCalendar] = useStore('viewCalendar', false);
  const [dateValue, setDateValue] = useState(null);

  if (viewCalendar)
    return (
      <CalendarBase
        dateValue={dateValue}
        setDateValue={setDateValue}
        viewCalendar={viewCalendar}
        setViewCalendar={setViewCalendar}
      />
    );
  if (!viewCalendar)
    return (
      <>
        <ListView
          dateValue={dateValue}
          setViewCalendar={setViewCalendar}
          setDateValue={setDateValue}
          viewCalendar={viewCalendar}
        />
      </>
    );
};

const CalendarBase = ({
  dateValue,
  setDateValue,
  viewCalendar,
  setViewCalendar,
}) => {
  const { data, isLoading } = useGetList('events', {
    pagination: { perPage: 9999, page: 1 },
    filter: { type: 'show' },
    sort: { field: 'date_created' },
  });
  const [filterState, setFilterState] = useState({
    filterDate: null,
    filterVenues: null,
    filterTalents: null,
    filterCategorys: null,
    filterName: null
  });
  const [open, setOpen] = useSidebarState();
  const { data: venues, isLoading: loadingVenues } = useGetList('locations', {
    filter: { event_ready: true},
    pagination: { perPage: 9999, page: 1 },
    sort: { field: 'rank', order: 'DESC' },
  });
  const [viewUnscheduled, setViewUnscheduled] = useState(false);

  const updatedVenues = useMemo(() => {
    let arr = [];
    if (venues) {
      if (filterState?.filterVenues?.length > 0) {
        for (let i = 0; i < venues.length; i++) {
          for (let j = 0; j < filterState?.filterVenues?.length; j++) {
            if (venues[i].id === filterState?.filterVenues[j]) {
              arr.push(venues[i]);
              continue;
            }
          }
        }
        if (filterState?.filterVenues?.length > 0) return arr;
      }
      return venues;
    }
  }, [venues, filterState.filterVenues]);

  const updatedEvents = useMemo(() => {
    let arr = [];
    if (data) {
      if (filterState?.filterTalents?.length > 0) {
        console.log('entre a filtrar');
        for (let i = 0; i < data.length; i++) {
          for (let z = 0; z < filterState?.filterTalents?.length; z++) {
            if (
              data[i]?.['talents_ids']?.find(
                (item) => item === filterState?.filterTalents[z]
              )
            ) {
              arr.push(data[i]);
              continue;
            }
          }
        }
        if (filterState?.filterTalents?.length > 0) return arr;
      }
      if(filterState?.filterCategorys?.length > 0){
        for (let i = 0; i < data.length; i++) {
          for (let z = 0; z < filterState?.filterCategorys?.length; z++) {
            if (
              data[i]?.['category'] === filterState?.filterCategorys[z]
            ) {
              arr.push(data[i]);
              continue;
            }
          }
        }
        if (filterState?.filterCategorys?.length > 0) return arr;
      }
      // if (filterState?.filterEvents?.length > 0) {
      //   const filterTerm = filterState.filterEvents.toLowerCase(); 
      //   return data.filter(event =>
      //     event?.name.toLowerCase().includes(filterTerm)
      //   );
      // }else{
      //   return data;
      // }
      if (filterState?.filterName?.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let value = data?.[i]?.['name'];

          if (
            value.toLowerCase().includes(filterState?.filterName) || value.toUpperCase().includes(filterState?.filterName)
          ) {
            arr.push(data[i]);
            continue;
          }
          
        }
        if (filterState?.filterName?.length > 0) return arr;
      }
      if(filterState?.filterCategorys?.length > 0){
        for (let i = 0; i < data.length; i++) {
          for (let z = 0; z < filterState?.filterCategorys?.length; z++) {
            if (
              data[i]?.['category'] === filterState?.filterCategorys[z]
            ) {
              arr.push(data[i]);
              continue;
            }
          }
        }
        if (filterState?.filterCategorys?.length > 0) return arr;
      }
      return data;
    }
  }, [data, filterState]);

  const keys = {
    groupIdKey: 'id',
    groupTitleKey: 'name',
    itemIdKey: 'id',
    itemTitleKey: 'name', // key for item div content
    itemDivTitleKey: 'name', // key for item div title (<div title="text"/>)
    itemGroupKey: 'stageFormat',
    itemTimeStartKey: 'start_date',
    itemTimeDuration: 'duration',
    itemTimeEndKey: 'end_date',
  };

  if (!loadingVenues && !isLoading) {
    return (
      <Box sx={{ width: open ? 'calc(100vw - 274px)' : 'calc(100vw - 83px)' }}>
        {data && (
          <>
            <DndProvider backend={HTML5Backend} key={1}>
              <CalendarToolbar
                venues={venues}
                setFilterState={setFilterState}
                filterState={filterState}
                viewCalendar={viewCalendar}
                setViewCalendar={setViewCalendar}
                dateValue={dateValue}
                setDateValue={setDateValue}
                setViewUnscheduled={setViewUnscheduled}
                hasCreate
              />
              <CalendarTimeLine
                setDateValue={setDateValue}
                filterDate={filterState.filterDate}
                filters={filterState}
                setViewUnscheduled={setViewUnscheduled}
                viewUnscheduled={viewUnscheduled}
                items={updatedEvents.filter(
                  (item) => item.start_date !== null && item.end_date !== null
                )}
                secondaryItems={data.filter((i) => i.start_date == null)}
                groups={updatedVenues}
                keys={keys}
                setFilterState={setFilterState}
              />
            </DndProvider>
          </>
        )}
      </Box>
    );
  }
};

const ListView = ({
  dateValue,
  setDateValue,
  viewCalendar,
  setViewCalendar,
}) => {
  const { setFilters, displayedFilters, filterValues } = useListContext();
  const {permissions:permis}= usePermissions();
  const [redirectModal, setRedirectModal] = useState({bool: false, id: null});
  const handlePopup = (status, id) => {
    console.log(id);
    if(status === 'pending') return setRedirectModal({bool: true, id: id});
    return 'edit';
  };
  const [filter, setFilter] = useState({});
  const refresh = useRefresh();
  const tab = filter.status?.length > 1 ? 'main' : 'pending';

  React.useEffect(() => {
    refresh();
  }, [filter]);

  useEffect(() => {
    if (window.location.href.includes('pending')) {
      let baseUrl = window.location.href.split('?')[0];
      window.history.pushState({}, document.title, baseUrl);
    }
  }, []);

  useEffect(() => {
    if (!displayedFilters?.date && displayedFilters?.length > 0) {
      let newValues = { ...filterValues };
      delete newValues.start_date_from;
      delete newValues.end_date_to;
      return setFilters({ ...newValues });
    }
  }, [displayedFilters]);

  const paramsList = {
    listParams: {
      hasCreate: true,
      // filters: eventsFilters,
      actions: (
        <>
          <CalendarToolbar
            viewCalendar={viewCalendar}
            setViewCalendar={setViewCalendar}
            dateValue={dateValue}
            setDateValue={setDateValue}
            placeholder={'Search by name, description, stage and talents...'}
          />
        </>
      ),
      sort: {
        field: 'last_modified',
        order: 'DESC'
      }
    },
    datagridParams: {
      bulkActionButtons: false,
      sx: {
        padding:'0px 20px',
        '& .column-picture.original': {
          width: '15%'
        },
        '& .MuiTypography-root':{
          color:'#000000',
          fontWeight:400
        },
        '& .MuiTableSortLabel-root':{
          color:COLORS.table.headerSorteable,
          fontWeight:400
        },
        '& .column-name': {
          width: '15%'
        },
        '& .column-category': {
          width: '15%',
          textAlign: 'center',
        },
        '& .column-id': {
          width: '10%'
        },
        '& .column-completed': {
          width: '10%',
          textAlign: 'center',
        },
        '& .column-status': {
          width: '20%',
          textAlign: 'center',
        },
        '& .column-published': {
          width: '15%'
        } ,
        '& .MuiTableCell-body': {
          borderBottom:'0px'
        },
      },
      rowClick: (id, resource, record) => handlePopup(record?.submission?.status, id)
      
    },
  };
  return (
    <div>
      <ListCustom
        filter={filter}
        defaultTab={
          window.location.href.includes('pending') ? 'main' : 'pending'
        }
        empty={<Typography>No submissions</Typography>}
        setFilter={setFilter}
        {...paramsList}
      >
        <TextField source="name" />
        <FunctionField label="Category"
          source='category'  
          render={(record) => {
            if(record?.category === null) return null;
            return (
              <Chip
                sx={{width:'auto'}} label= {record?.category}
              />
            );
          }}/>
        <TextField label="Talent" source="talents[0].name" showTime />
        <FunctionField label="Complete?"  source="completed" render={(record) => 
          <Typography
            sx={{
              fontSize: '.9em',
              fontWeight: '600',
              color: record?.completed ? '#009b00' : '#ffc990',
              textTransform: 'uppercase',
            }}
          >
            {record?.completed ? <MdCheckCircle color='#21B141' size={26}/> : <IoMdCloseCircle color='grey' size={26}/>}
          </Typography> 
        } />
        <FunctionField label="Last submission status" source="status" 
          render={(record) => 
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              {record?.submission?.status === 'draft'  || record?.submission === null || record?.submission.status === 'not_submitted' ? <Indicator /> : (<Indicator data={record?.submission} />)}
            </div>
          }/>
        <ReferenceField source="stage.id" label="Stage" reference="venues">
          <TextField source="name" />
        </ReferenceField>
        {/* <DateField source="start_date" showTime />
        <DateField source="end_date" showTime /> */}
        <FunctionField source="start_date" render={(record) => {
          if(record?.start_date) return moment(convertToCostaRicaTime(record.start_date)).format('DD/MM/YYYY, HH:mm:ss');
          return 'Not scheduled';

        }} />
        <FunctionField source="end_date" render={(record) => {
          console.log(moment(convertToCostaRicaTime(record.end_date)).format('DD/MM/YYYY, HH:mm:ss'));
          if(record?.start_date) return moment(convertToCostaRicaTime(record.end_date)).format('DD/MM/YYYY, HH:mm:ss');
          return '';
        }} />
      </ListCustom>
      <RedirectModal resource="events" open={redirectModal.bool} id={redirectModal.id} setOpen={setRedirectModal} />
    </div>
  );
};

export default EventsList;
