import { Box, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useInput } from 'react-admin';
import { RgbaColorPicker } from 'react-colorful';


export const InputColorPicker = ({source, label='Color picker', showColor = true}) => {
  const {field} = useInput({source});
  const [color ,setColor] = useState({});

  const handleChange = (e, v) => {
    if(v) {
      let newColor = {...color, [v]: parseFloat(e)};
      setColor(newColor);
      field.onChange(`rgba(${newColor.r}, ${newColor.g}, ${newColor.b}, ${newColor.a})`);
      return;
    }
    setColor(e);
    field.onChange(`rgba(${e.r}, ${e.g}, ${e.b}, ${e.a})`);
  };

  useEffect(() => {
    const numbers = field?.value?.match(/\d+\.\d+|\d+/g) ?? null;
    setColor({
      r: parseFloat(numbers?.[0]), 
      g:parseFloat(numbers?.[1]), 
      b: parseFloat(numbers?.[2]), 
      a:parseFloat(numbers?.[3])
    });
  },[]);
  return (
    <>
      <Typography sx={{
        fontSize: '16px',
        fontWeight: '600',
        marginBottom: '10px',
      }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '20px'
        }}
      >
        <RgbaColorPicker
          sx={{height: '200px'}}
          color={color}
          onChange={handleChange}
        />
        {
          showColor && (
            <Box sx={{display: 'flex', flexDirection: 'column', width: '200px'}}>
              <TextField 
               
                value={color.r}
                onChange={(e) => handleChange(e.target.value, 'r')} 
                InputLabelProps={{shrink: true}} 
                variant="outlined" 
                label="Red"
              />
              <TextField 
               
                value={color.g}
                onChange={(e) => handleChange(e.target.value, 'g')} 
                InputLabelProps={{shrink: true}} 
                variant="outlined" 
                label="Green"
              />
              <TextField 
               
                value={color.b}
                onChange={(e) => handleChange(e.target.value, 'b')} 
                InputLabelProps={{shrink: true}} 
                variant="outlined" 
                label="Blue"
              />
              <TextField 

                value={isNaN(color.a) ? '' : color.a}
                onChange={(e) => handleChange(e.target.value, 'a')} 
                InputLabelProps={{shrink: true}} 
                variant="outlined" 
                label="Opacity"
              />
            </Box>
          )
        }
      </Box>
    </>
  );
};