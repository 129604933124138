import { Box } from '@mui/material';
import React from 'react';
import ErrorGif from '../../assets/images/73696-error.gif';

const ErrorCustom = () => {
  return (
    <Box>
      <img src={ErrorGif} style={{width: '400px', height: '400px', objectFit: 'contain'}}/>
    </Box>
  );
};

export default ErrorCustom;