import { Box, Button, Divider, IconButton, Popover, Popper, Typography } from '@mui/material';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { DateHeader } from 'react-calendar-timeline';
import { usePosition } from '../../Hooks/usePosition';
import { useSidebarState } from 'react-admin';
import { BsCalendar3 } from 'react-icons/bs';

export const CalendarHeaderBox = ({fixedPosition, handleTimeChange, headerDayDiff, visibleTime, dates, sx}) => {

  const [anchorEl, setAnchorEl] = useState(false);
  const festivalDates = useMemo(() => {
    let arr = [];
    for(let i = 0; i < headerDayDiff;i++){
      let obj = {
        day: moment(dates.start).add(i, 'days'),
        timeStart: moment(dates.start).add(i, 'days').valueOf(),
        timeEnd: moment(dates.start).add(i + 1, 'days').valueOf()
      };
      arr.push(obj);
      continue;
    }
    return arr;
  },[headerDayDiff]);

  const handleClick = (item) => {
    handleTimeChange(item.timeStart, moment(item.timeStart).add(24, 'hours').valueOf());
  };

  const visibleStyle = (time) => {
    if(moment(visibleTime).isBetween(time.timeStart, time.timeEnd, 'days', '[)')){
      return {
        backgroundColor: '#2E7477',
        color: '#fff',
        '&:hover': {
          // color: '#95453e',
          // Lo dejo con el cursor asi, ya que no tiene una funcionalidad al cliquearlo nuevamente
          color: '#fff',
          backgroundColor: '#2E7477',
          cursor: 'default',
        }
      };
    }
    return {
      backgroundColor: '#d9d9d9',
      color: '#2E7477',
      '&:hover': {
        color: '#fff',
        backgroundColor: '#2E7477',
      }
    };
  };

  const handleClickMenu = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const openMenu = Boolean(anchorEl);

  const sidebarOpen = useSidebarState();
  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        backgroundColor: '#fff',
        height:260,
        zindex: 98
        //   transform: 'translateX(1500px)',
      }}>
      <Box
        sx={{
          backgroundColor: '#fff',
          position: !fixedPosition ? 'flex' : 'fixed',
          top: 0,
          width: !fixedPosition? 'inherit' : sidebarOpen[0] ? 'calc(100vw - 275px)' : 'calc(100vw - 5vw)',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          zIndex: 99,
          justifyContent: 'space-between',
          paddingTop: '20px',
          margin: '0px 30px 0 0',
          paddingBottom: '20px',
          height: !fixedPosition ? 'auto' : '150px',
        }}
      >
        { 
          festivalDates?.map((item, index) => {
            return (
              <Button
                //   variant="contained"
                onClick={() => handleClick(item)}
                sx={{
                  width: '7vw',
                  // maxWidth: '100px',
                  // height: '50px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '.5vw',
                  margin: '0px 30px',
                  ...visibleStyle(item)
                }}
              >
                <Typography sx={{fontSize: '1.1em', fontWeight: '600'}}>{moment(item.day).format('ddd')}</Typography>
                <Typography sx={{fontSize: '1.1em', fontWeight: '600'}}>{moment(item.day).format('DD-MM')}</Typography>
              </Button>
            );
          })
        }
      </Box>
      <Divider 
        sx={{
          height: '5px',
          width: '100%',
          color: '#2E7477',
          padding: '0 50px',
          marginBottom: '20px'
        }} />
    </Box>
  );
};