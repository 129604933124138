import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Chip } from '@mui/material';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { COLORS } from '../../../assets/constants/theme';
import { useInput } from 'react-admin';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
const InputPillsSelect = ({
  choices,
  withText,
  required,
  approvedFields,
  textTransform,
  title,
  subtitle,
  watchField,
  optionText = 'text',
  optionValue = 'text',
  source,
  featured,
  setPickedF1,
  setPickedF2,
  pickedF1,
  pickedF2,
  info,
  ...rest
}) => {
  const { watch, setValue, getValues } = useFormContext();
  const valores = getValues();
  const { id, field:openField, fieldState } = useInput({ source });
  const [categoryPicked, setCategoryPicked] = useState(undefined);
  const handlerSetValue = (value) => {
    setCategoryPicked(withText ? value.text :value);
    if(!info && !withText){
      openField.onChange(value);
    }else if(withText){
      openField.onChange(value.text);
    }else{
      if (featured === '1') {
        openField.onChange({...valores?.home?.featured_categories[0], type: 1, category: value });
        setPickedF1(valores?.home?.featured_categories[0]?.category);
      } else if (featured === '2') {
        openField.onChange({...valores?.home?.featured_categories[1], type: 2, category: value });
        setPickedF2(valores?.home?.featured_categories[1]?.category)
      } else {
        openField.onChange(source, value);
      }
    }
  };
  useEffect(() => {
    if(info){
      setCategoryPicked(info?.home?.featured_categories[featured == 1 ? 0 :1]?.category);
      if(setPickedF1){
        setPickedF1(info?.home?.featured_categories[0]?.category);
      }
      if(setPickedF2){
        setPickedF2(info?.home?.featured_categories[1]?.category);
      }
    }else{
      setCategoryPicked(openField?.value)
    }
  }, []);
  if (featured == 1) {
    return (
      <>
        {title && (
          <InputTitle
            textTransform={textTransform}
            sx={{
              marginTop: '25px',
              display: !watchField
                ? 'block'
                : watch(watchField) === 'true' ||
                  approvedFields?.includes(watch(watchField))
                  ? 'block'
                  : 'none',
            }}
          >
            {title}
          </InputTitle>
        )}
        {subtitle && (
          <InputSubtitle>{subtitle}</InputSubtitle>
        )}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
            marginTop: '20px',
          }}
        >
          {choices &&
            choices.map((choice, index) => (
              <Chip
                sx={{
                  width: 'auto',
                  padding: '10px',
                  color: categoryPicked === choice ? 'white' : '#000000',
                  fontSize: '13px',
                  fontWeight: '400',
                  backgroundColor:
                  categoryPicked === choice
                    ? COLORS.light.secondary.main
                    : COLORS.forms.pills.background,
                  ':hover': {
                    backgroundColor: COLORS.light.secondary.main,
                    color: 'white',
                  },
                }}
                key={index}
                onClick={() => handlerSetValue(choice)}
                label={choice}
                disabled={pickedF2 === choice}
              />
            ))}
        </div>
      </>
    );
  }
  if (featured == 2) {
    return (
      <>
        {title && (
          <InputTitle
            textTransform={textTransform}
            sx={{
              marginTop: '25px',
              display: !watchField
                ? 'block'
                : watch(watchField) === 'true' ||
                  approvedFields?.includes(watch(watchField))
                  ? 'block'
                  : 'none',
            }}
          >
            {title}
          </InputTitle>
        )}
        {subtitle && (
          <InputSubtitle>{subtitle}</InputSubtitle>
        )}
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 10,
            marginTop: '20px',
          }}
        >
          {choices &&
            choices.map((choice, index) => (
              <Chip
                sx={{
                  width: 'auto',
                  padding: '10px',
                  color: categoryPicked === choice ? 'white' : '#000000',
                  fontSize: '13px',
                  fontWeight: '400',
                  backgroundColor:
                  categoryPicked === choice
                    ? COLORS.light.secondary.main
                    : COLORS.forms.pills.background,
                  ':hover': {
                    backgroundColor: COLORS.light.secondary.main,
                    color: 'white',
                  },
                }}
                key={index}
                onClick={() => handlerSetValue(choice)}
                disabled={pickedF1 === choice}
                label={choice}
              />
            ))}
        </div>
      </>
    );
  }else{
    return (
      <>
        {title && (
          <InputTitle
            required={required}
            textTransform={textTransform}
            sx={{
              marginTop: '25px',
              display: !watchField
                ? 'block'
                : watch(watchField) === 'true' ||
                  approvedFields?.includes(watch(watchField))
                  ? 'block'
                  : 'none',
            }}
          >
            {title}
          </InputTitle>
        )}
        {subtitle && (
          <InputSubtitle>{subtitle}</InputSubtitle>
        )}
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10,marginTop:withText ? '0px' :'20px' }}>
          {choices &&
            choices.map((choice, index) => {
              console.log(choice)
              return(
                <Chip
                  sx={{
                    width: 'auto',
                    padding: '10px',
                    color: withText ? (categoryPicked === choice.text ? 'white' : '#000000') : (categoryPicked === choice ? 'white' : '#000000'),
                    fontSize:'13px',
                    fontWeight:'400',
                    backgroundColor:withText ? categoryPicked === choice.text
                      ? COLORS.light.secondary.main
                      : COLORS.forms.pills.background :
                      categoryPicked === choice
                        ? COLORS.light.secondary.main
                        : COLORS.forms.pills.background,
                    ':hover': { backgroundColor: COLORS.light.secondary.main, color: 'white' },
                  }}
                  key={index}
                  onClick={() => handlerSetValue(choice)}
                  label={withText ? choice.text :choice}
                //   disabled={valores[source] === choice.id}
                />
              )})}
        </div>
      </>
    );
  }
};

export default InputPillsSelect;
