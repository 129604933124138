import moment from 'moment';
import { convertToCostaRicaTime, convertToUtcMinusSix } from '../../../../helpers/date';



const handleValidateClose = (e, row, nextDay) => {
  let lastHour = moment(row).add(1, 'days').add(7, 'hours');
  if(nextDay && moment(convertToCostaRicaTime(e)).isAfter(lastHour)){
    return 'error';
  } 
  return undefined;
};

// Validation para open times
export const validateVenues = (e) => {
  // const isNextDay = moment(convertToUtcMinusSix(e?.open_times?.[row]?.close), 'YYYY-MM-DD').isAfter(moment(row));

  let times = Object.keys(e);
  let errors = {};
  let nextDayCloseTime = null;
  console.log(e,'E LO QUE ME LLEGO')
  for (let i = 0; i < Object.keys(times).length; i++) {
    console.log(nextDayCloseTime,'El null')
    // Key es la fecha
    let key = Object.keys(times)[i];
    let open = Object.values(times)[i].open;
    let close = Object.values(times)[i].close;
    console.log(open,'opens!');
    console.log(close,'close del bucle');
    // En el momento que tiene fecha el nextDayCloseTime, o sea existe una fecha distinta entre open y close, ingresa >
    if(nextDayCloseTime && moment(open).isBefore(nextDayCloseTime)){
      errors[key] = `${moment(nextDayCloseTime).subtract(1, 'days').format('YYYY-MM-DD')} close time is after ${key} open time. `;
    }
    // } else {
    //   delete errors[key];
    // }

    // Entraria aqui cuando la fecha tomada por el KEY sea distinta a la fecha que toma de su valor de .close, osea se le asigno un dia siguiente
    //  al dia seleccionado.
    if(key !== moment(close).format('YYYY-MM-DD')) {
      nextDayCloseTime = Object.values(times)[i].close;
    } else {
      nextDayCloseTime = null;
    }
  }

  // Validation para open times
  console.log(errors);
  return errors;
};