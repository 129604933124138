import { useEffect, useState } from 'react';


export const usePosition = (index, limit = 500) => {
  const [fixedPosition, setFixedPosition] = useState(index === 0 ? true : false);
  useEffect(() => {
    const handleScroll = () => {
      const parent = document.getElementById(index);
      const parentRect = parent?.getBoundingClientRect();
      // console.log(parentRect);
      if(window.innerHeight > parentRect.bottom || window.innerHeight -100 < parentRect.top) {
        
        return setFixedPosition(false);
      } else {
        if((parentRect?.top + limit) <= window.innerHeight){
          setFixedPosition(true);
        }
      }
    };
    window.addEventListener('scroll',handleScroll);
    return () => {
      window.removeEventListener('scroll',handleScroll);
    };
  }, [index]);

  return fixedPosition;
};

export const useAbsolute = (index) => {
  const [fixedPosition, setFixedPosition] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const parent = document.getElementById(index);
      const parentRect = parent?.getBoundingClientRect();
      if(parentRect.top <= 0) {
        setFixedPosition(true);
      } else {
        setFixedPosition(false);
      }
    };
    window.addEventListener('scroll',handleScroll);
    return () => {
      window.removeEventListener('scroll',handleScroll);
    };
  }, []);
  return fixedPosition;
};