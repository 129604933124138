import { Box, Modal, IconButton } from '@mui/material';
import { SimpleManagerForm } from './ContactInformation';
import { styleFlex } from '../../Theme';

const ContactInformationModal = ({
  open,
  setOpen,
  email,
  index,
  manager,
  create,
}) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box sx={styleFlex}>
        <IconButton
          size="small"
          sx={{
            alignSelf: 'flex-end',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}
          onClick={() => setOpen(false)}
        >
          X
        </IconButton>
        {create === true ? (
          <SimpleManagerForm modal={true} setOpen={setOpen} create={create} />
        ) : (
          <SimpleManagerForm
            email={email}
            index={index}
            manager={manager}
            modal={true}
            setOpen={setOpen}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ContactInformationModal;
