import { Box, Button, Modal, Typography } from '@mui/material';
import { styledCard, styledTitle } from './CssProperties';
import { ApprovedButton } from './AsideActions/ApprovedButton';
import { RejectButton } from './AsideActions/RejectButton';
import { usePermissions, useRedirect, useRefresh } from 'react-admin';
import { apiUrl } from '../../dataProvider';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import axios from 'axios';
import { useState } from 'react';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { COLORS } from '../../assets/constants/theme';

const CardComponent = ({actions, id, route, title, children,sx}) => {
  const { permissions } = usePermissions();
  return(
    <>
      <Box style={{width: '100%'}}>
        {title &&  <Typography style={styledTitle}>{title}</Typography>}
        {children}
        {
          actions && (permissions !== 'talent_manager') &&  (
            <Box
              sx={{
                marginTop:'2em',
                width: '95%',
                height:'4.5em',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                backgroundColor:'#EEEEEE',
                paddingX:'5em'
              }}
            >
              <ApprovePublish id={id} route={route}  />
              <Box sx={{display:'flex',flexDirection:'row',gap:3}}>
                <ApprovedButton id={id} route={route} color="red">Approve</ApprovedButton>
                <RejectButton id={id} route={route} >Reject</RejectButton>
              </Box>

            </Box>
          )
        }       
      </Box>

    </>
  );
};

export const ApprovePublish = ({route, id}) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const [open, setOpen] = useState(false);
  const customToast = useCustomToasty();

  const handleApprovePublish = async () => {
    try {
      const request = axios({
        method: 'PUT',
        url: `${apiUrl}/${route}/${id}/approve?publish=true`,
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('auth')
        }
      }
      );
      console.log(request);
      const response = await request;
      if (response) {
        refresh();
        setOpen(false);
        customToast('success', 'Talent changes have been approved');
        redirect(`/${route}`);        

      }
    } catch (error) {
      customToast('error', 'There was an error, please try again later.');
    }
  };

  return (
    <>
      <Button
        sx={{
          fontSize: '1em',
          fontWeight:'600',
          'text-transform': 'uppercase',
          'place-self': 'center',
          'align-items': 'center',
          backgroundColor:'#228d39',
          height:'70%',
          borderRadius:'10px'
        }}
        variant="contained"
        onClick={() => setOpen(true)}
        startIcon={<TaskAltIcon style={{width: '20px', height: '20px', color: '#fff'}}  />}
      >
       Approve & Publish
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            width: '400px',
            height: '200px',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#fff',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '12px',
            padding: '20px'
          }}>
          <Typography sx={{fontSize: '20px', alignSelf: 'start', marginLeft: '20px', fontWeight: '600', textTransform: 'uppercase'}}>Approve Changes</Typography   >
          <Typography>Are you sure to approve and publish the pending changes?</Typography>
          <Button variant="outlined" onClick={handleApprovePublish}>SAVE</Button>
        </Box>
      </Modal>
    </>
  );
};

export default CardComponent;