import React, { useContext, useEffect, useState } from 'react';
import { Menu, MenuItemLink, ResourceDefinitionContext, useDataProvider, useGetList, useGetPermissions, usePermissions, useResourceContext, useResourceDefinitions, useSidebarState, useStore,useLogout } from 'react-admin';
import { Collapse, Grow, IconButton, Typography, css, keyframes, useTheme } from '@mui/material';
import { Box,Button } from '@mui/material';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { COLORS } from '../../assets/constants/theme';
import { getToken } from '../../helpers/getToken';
import { TbUsers } from 'react-icons/tb';
import { CgProfile } from 'react-icons/cg';
import { BsCalendarEvent, BsInfoCircle } from 'react-icons/bs';
import { IoMdMegaphone, IoIosSchool } from 'react-icons/io';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { userAccessControl } from '../../helpers/permissions';
import { CiCircleList } from 'react-icons/ci';
import { calendarContext, pendingSubmissions } from '../../dataProvider';
import { MdFolderOpen,MdKeyboardArrowLeft,MdKeyboardArrowRight  } from 'react-icons/md';
import { ModalSelectTalent } from '../modalSelectTalent/ModalSelectTalent';
import { TbLogout } from 'react-icons/tb';
import { LuHelpingHand } from 'react-icons/lu';
import { BsLayoutTextSidebarReverse } from 'react-icons/bs';
import { MdOutlinePendingActions } from 'react-icons/md';
import { TbMicrophone2 } from 'react-icons/tb';
import { MdInterpreterMode } from 'react-icons/md';
import axios from 'axios';

const logo = '/Envision.green_1.png';
export const fetchData = async (_callback) => {
      
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_BASE_PATH}/statistics`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
    });
    _callback(
      response.data.talents_status.pending,
      response.data.events_status.pending
    );
  } catch (error) {
    console.error('Error fetching data:', error);
    // Manejar el error según tus necesidades
  }
};

const MenuCustom = (props) => {
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name]
  );
  const activeResource = useResourceContext();
  const sidebarOpen = useSidebarState();
  const token = getToken();
  const handleChange = () => sidebarOpen[1](window.innerWidth > 1540);
  useEffect(() => {
    handleChange();
    window.addEventListener('resize', handleChange);
    return () => window.removeEventListener('resize', handleChange);
  }, []);

  // FEstival start_enddates
  const { setFestivalDates, setPendingSubmissions } =
    useContext(calendarContext);
  const dataProvider = useDataProvider();
  const params = {
    limit: 9999,
    offset: 0,
    ascending: true,
    filter: {},
  };

  useEffect(() => {
    const festivalId = '64f89716b40ed47355cb66e4';
    dataProvider
      .getOne('info', { id: festivalId })
      .then(({ data }) => setFestivalDates(data.start_date, data.end_date));
  }, []);

  useEffect(() => {
    fetchData(setPendingSubmissions);
  }, []);
  const handleResourcePermisisons = (e) => {
    let resourceExceptions = ['resources'];
    let resourcePermissions = getToken()?.role !== 'talent_manager';
    if (resourceExceptions.includes(e.name)) {
      return true;
    } else {
      return resourcePermissions;
    }
  };
  const openAndClose = () => {
    if (sidebarOpen[0]) {
      sidebarOpen[1](false);
    } else {
      sidebarOpen[1](true);
    }
  };

  return (
   
    <>
    
      <Menu {...props} sx={{margin:0, padding:0}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderBottom: '1px solid',
            borderColor: '#808080',
            paddingY:'15px',
            gap: sidebarOpen[0] ? 2 : 0
          }}
        >
          {sidebarOpen[0] ? (
            <img src={logo} alt="logo" style={{ width: '60%' }} />
          ) : null}
          <IconButton
            size='small'
            // sx={{ width:sidebarOpen[0] ? '100%': 'auto'}}
            onClick={() => openAndClose()}
          >
            {' '}
            {sidebarOpen[0] ? (
              <MdKeyboardArrowLeft size={30} />
            ) : (
              <MdKeyboardArrowRight size={24} />
            )}
          </IconButton>
        </Box>
        <Box
          sx={{
            height: '100%',
          }}
        >
          {userAccessControl(getToken()?.role, 'read', ['talentsForm']) && (
            <TalentManagerForm talent_id={token?.talent_id} />
          )}
          {resources.map((resource, index) => {
          // console.log(resource.name,index)
            if (
              handleResourcePermisisons(resource) &&
            resource.options?.isTitle
            ) {
            console.log(resource,'TITULO');
              return (
                <TitleSection
                  key={resource.name}
                  resource={resource}
                  resources={resources}
                />
              );
            }else if (handleResourcePermisisons(resource) &&
          resource.options?.isMenuParent){
            // console.log(resource.name,'SEMI TITULO');
              return(  
                <SectionParent
                  key={resource.name}
                  resource={resource}
                  resources={resources}
                />);
            }else {
              if (
                handleResourcePermisisons(resource) &&
              resource.hasList &&
              !resource.options?.menuParent
              ) {
                const firstIndex = index === 0 ? true : false;
                return (
                  <>
                    {!firstIndex ? (
                      <MenuItemLink
                        key={resource.name}
                        to={
                          resource.options.customPath
                            ? `/${resource.options.customPath}`
                            : `/${resource.name}`
                        }
                        primaryText={
                          (resource.options && resource.options.label) ||
                        resource.name
                        }
                        leftIcon={resource.icon ? <resource.icon /> : null}
                        sidebarIsOpen={sidebarOpen[0]}
                        sx={{
                          color: '#000000',
                          padding: '6px 16px',
                          margin: sidebarOpen[0] ? '0 12px' : '3px',
                          fontWeight: '600',
                          borderRadius: '12px',
                          lineHeight: '1.75',
                          letterSpacing: '0.01em',
                          '&.RaMenuItemLink-active': {
                            backgroundColor: '#eeeeee',
                            color: COLORS.light.primary.main,
                            '& .MuiListItemIcon-root': {
                              color: COLORS.light.primary.main,
                            },
                          },
                        }}
                      />
                    ) : null}
                    {/* PARA EL DASHBOARD */}
                    {firstIndex && (
                      <Box
                        sx={{
                          height:sidebarOpen[0]? '80px':'auto',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          borderBottom: '1px solid',
                          borderColor: '#808080',
                        }}
                      >
                        <MenuItemLink
                          key={resource.name}
                          to={
                            resource.options.customPath
                              ? `/${resource.options.customPath}`
                              : `/${resource.name}`
                          }
                          primaryText={
                            (resource.options && resource.options.label) ||
                          resource.name
                          }
                          leftIcon={resource.icon ? <resource.icon /> : null}
                          sidebarIsOpen={sidebarOpen[0]}
                          sx={{
                            padding: sidebarOpen[0]?'6px 29px':null,
                            color: '#000000',
                            fontWeight:'600',
                            width: '100%',
                            height:'100%',
                            margin: sidebarOpen[0] ? '0 0px' : '0px',       
                            '&.RaMenuItemLink-active': {
                              backgroundColor: '#eeeeee',
                              color: COLORS.light.primary.main,
                              '& .MuiListItemIcon-root': {
                                color: COLORS.light.primary.main,
                              },
                            },
                          }}
                        />
                      </Box>
                    )}
                    {/* {resource.name === 'admins' && <GuideButton/>} */}
                  </>
                );
              }
            }
          })}
          {/* {userAccessControl(token?.role, 'read', ['festivals']) && <FestivalForm />} */}
        </Box>
      </Menu>
      <LogOutButton/>

    </>
  );
};

const TitleSection = ({ resource, resources }) => {
  const sidebarOpen = useSidebarState()[0];
  return (
    <Box style={{
    }}
    >
      <Box
        sx={{
          cursor:'default',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: '5px 12px',
          padding: '6px 9px 6px 15px',
          borderRadius: '12px',
        }}
      >
        {
          sidebarOpen && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography
                  variant='subtitle2'
                  style={{
                    color: '#515151',
                    fontWeight: '600',
                    borderRadius: '12px',
                    fontSize: '14px',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                  }}
                >
                  {resource.options.label ?? resource.name}
                </Typography>
              </Box>
            </>
          )
        }
      </Box>
      <Collapse in={!sidebarOpen || open} unmountOnExit>
        {
          resources.map((item) => {
            if (item.options?.menuParent && item.options?.menuParent === resource.name) {
              
              return (
                <Box
                  sx={{
                    position: 'relative'
                  }}>
                  <MenuItemLink
                    key={item.name}
                    to={item.options.customPath ? `/${item.options.customPath}` : `/${item.name}`}
                    primaryText={(item.options && item.options.label) || item.name}
                    leftIcon={item.icon ? <item.icon /> : null}
                    sidebarIsOpen={open}
                    sx={{
                      margin: sidebarOpen[0] ? '0px 12px 0 24px' : '0px 3px',
                      color: '#757575',
                      padding: '6px 16px',
                      fontWeight: '600',
                      borderRadius: '12px',
                      fontSize: '0.85rem',
                      lineHeight: '1.75',
                      letterSpacing: '0.01em',
                      '&.RaMenuItemLink-active': {
                        backgroundColor: '#eeeeee',
                        color: COLORS.light.primary.main,
                        '& .MuiListItemIcon-root': {
                          color: COLORS.light.primary.main
                        }
                      }
                    }}
                  />
                  {
                    item.options.notifications && pendingSubmissions[item.name] && pendingSubmissions[item.name] > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          position: 'absolute',
                          top: 5,
                          right: 10,
                          backgroundColor: 'red',
                          color: 'white',
                          fontSize: '.7em',
                          width: '15px',
                          height: '15px',
                          fontWeight: '600',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {pendingSubmissions[item.name]}
                      </Box>
                    ) : null
                  }
                </Box>
              );
            }
          })
        }
      </Collapse>
    </Box>
  );
};

const SectionParent = ({ resource, resources }) => {
  const [open, setOpen] = useState(false);
  const sidebarOpen = useSidebarState();

  
  const pickIconForResource = (resourceName)=>{
    if(resourceName === 'posts'){
      return <BsLayoutTextSidebarReverse/>;
    }
    if(resourceName === 'talents'){
      return <TbUsers/>;
    }
    if(resourceName === 'talent_submissions'){
      return <MdOutlinePendingActions/>;
    }
    if(resourceName === 'events'){
      return <TbMicrophone2/>;
    }
    if(resourceName === 'event_submissions'){
      return <MdOutlinePendingActions/>;
    }
  };
  const itemsNoShow = (name)=>{
    if(sidebarOpen[0] === false){
      if(name === 'talent_submissions' || name === 'event_submissions' || name === 'categories'){
        return false;
      }
      else{ 
        return true;
      }
    }
    else{ 
      return true;
    }
  };
  const openStyle={
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0px 12px',
    padding: '6px 9px 6px 15px',
    cursor: 'pointer',
    borderRadius: '12px',
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  };
  const closeStyle={
    cursor: 'pointer',
  };
  return (
    <Box style={{
      marginBottom: open && '6px',
    }}
    >
      <Box
        sx={
          sidebarOpen[0] ? openStyle : closeStyle
        }
        onClick={ sidebarOpen[0] ? () => setOpen(!open) : null}
      >
        {
          sidebarOpen[0] && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <resource.icon style={{
                  color: '#757575',
                  marginLeft: '3px',
                  marginRight: '25px',
                  letterSpacing: '0.05em',
                  fontWeight: '600',
                  fontSize: '0.8rem'
                }} />
                <Typography
                  variant='subtitle2'
                  style={{
                    color: '#000000',
                    fontWeight: '600',
                    borderRadius: '12px',
                    fontSize: '16px',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                  }}
                >
                  {resource.options.label ?? resource.name}
                </Typography>
              </Box>
              {
                open ? <MdExpandLess style={{ color: '#757575' }} /> : <MdExpandMore style={{ color: '#757575' }} />
              }
            </>
          )
        }
      </Box>
      <Collapse in={!sidebarOpen[0] || open} unmountOnExit>
        {
          resources.map((item,index) => {
            if (item.options?.menuParent && item.options?.menuParent === resource.name && itemsNoShow(item.name) ) {
              return (
                <Box
                  sx={{
                    position: 'relative'
                  }}>
                  <MenuItemLink
                    key={index+12}
                    to={item.options.customPath ? `/${item.options.customPath}` : `/${item.name}`}
                    primaryText={sidebarOpen[0] ?(item.options && item.options.label) || item.name : item.name === 'events' ? 'Events' : item.name === 'talents' ? 'Talents' :'Content'}
                    leftIcon={sidebarOpen[0] ? null : pickIconForResource(item.name)}
                    sidebarIsOpen={open}
                    sx={{
                      margin: sidebarOpen[0] ? '0px 12px 0 24px' : '0px 3px',
                      color: '#757575',
                      padding: '6px 16px',
                      fontWeight: '600',
                      borderRadius: '12px',
                      fontSize: '0.85rem',
                      lineHeight: '1.75',
                      letterSpacing: '0.01em',
                      '&.RaMenuItemLink-active': {
                        backgroundColor: '#eeeeee',
                        color: COLORS.light.primary.main,
                        '& .MuiListItemIcon-root': {
                          color: COLORS.light.primary.main
                        }
                      }
                    }}
                  />
                  {
                    item.options.notifications && pendingSubmissions[item.name] && pendingSubmissions[item.name] > 0 ? (
                      <Box
                        sx={{
                          display: 'flex',
                          position: 'absolute',
                          top: 5,
                          right: 10,
                          backgroundColor: 'red',
                          color: 'white',
                          fontSize: '.7em',
                          width: '15px',
                          height: '15px',
                          fontWeight: '600',
                          borderRadius: '50%',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        {pendingSubmissions[item.name]}
                      </Box>
                    ) : null
                  }
                </Box>
              );
            }
          })
        }
      </Collapse>
    </Box>
  );
};

const LogOutButton = (() => {
  const logout = useLogout();
  const handleClick = () => logout();
  const sidebarOpen = useSidebarState();

  return (
    <Box
      style={{
        position:'fixed',
        zIndex: 999,
        bottom:1
        // marginBottom: open && '6px',
      }}
    >
      <button style={{ border: 'none', backgroundColor: 'transparent', cursor: 'pointer'}} onClick={handleClick}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '6px 16px',
            margin: sidebarOpen[0] ? '0 12px' : '3px',
            cursor: 'pointer',
            borderRadius: '12px',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                color: '#757575',
                marginLeft: '0px',
                marginRight: '20px',
                letterSpacing: '0.05em',
                fontWeight: '600',
                fontSize: '0.8rem',
              }}
            >
              <TbLogout color="#B12121" size={20} />
            </div>
            <Typography
              variant="subtitle2"
              style={{
                color: '#000000',
                fontWeight: '600',
                borderRadius: '12px',
                fontSize: '16px',
                lineHeight: '1.75',
                letterSpacing: '0.01em',
              }}
            >
            Log out
            </Typography>
          </Box>
        </Box>
      </button>
    </Box>
  );
});
const GuideButton = (() => {
  const sidebarOpen = useSidebarState();

  return (
    <Box
      style={{
        marginBottom: open && '6px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '6px 16px',
          margin: sidebarOpen[0] ? '0 12px' : '3px',
          cursor: 'pointer',
          borderRadius: '12px',
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#757575',
              marginLeft: '0px',
              marginRight: '20px',
              letterSpacing: '0.05em',
              fontWeight: '600',
              fontSize: '0.8rem',
            }}
          >
            <LuHelpingHand size={20}/>
          </div>
          <Typography
            variant="subtitle2"
            style={{
              color: '#000000',
              fontWeight: '600',
              borderRadius: '12px',
              fontSize: '16px',
              lineHeight: '1.75',
              letterSpacing: '0.01em',
            }}
          >
            Guide
          </Typography>
        </Box>
      </Box>
    </Box>
  );
});
export default MenuCustom;


export const TalentManagerForm = ({ talent_id }) => {
  const [selectedTalent] = useStore('selectedTalent', 'selectedTalent', { selected: false, talent: '' });
  const [open, setOpen] = useState(true);
  const sidebarOpen = useSidebarState();
  const permis = usePermissions()?.permissions !== 'talent_manager';
  const resorce = useGetList('resources', { filter: {}, pagination: { perPage: 9999, page: 1 } });
  const { data: dataTalentos } = useGetList('talents', { filter: {}, pagination: { perPage: 9999, page: 1 } });
  const resourcesDefinitions = useResourceDefinitions();
  const resources = Object.keys(resourcesDefinitions).map(
    (name) => resourcesDefinitions[name]
  );
  var FORM_TALENT_SIDEBAR = [
    {
      name: permis ? 'Events' : 'My Events',
      path: 'event',
      icon: BsCalendarEvent,
    },
  ];

  if (resorce?.data) {
    FORM_TALENT_SIDEBAR = [
      ...FORM_TALENT_SIDEBAR,
      {
        name: permis ? 'Resources' : 'Mktg Assets Library',
        path: 'resources',
        icon: MdFolderOpen,
        target: true,
        link: resorce?.data?.[0]?.link
      },

    ];
  }


  const myEffect = keyframes`
  0%{
    transform: translateX(100%);
   
  }
  100%{
    transform: translateX(-100%);
    
  }
`;

  const myEffect2 = keyframes`
0%{
  transform: translateX(0%);
 
}
100%{
  transform: translateX(-100%);

}
`;

  const classAnimation = {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    animation: `${myEffect2} 2s linear 1, ${myEffect} 4s 2s linear infinite`,
  };

  const styledMenuItem = {
    margin: sidebarOpen[0] ? '0px 12px 0 24px' : '0px 3px',
    color: '#757575',
    padding: '6px 16px',
    fontWeight: '600',
    borderRadius: '12px',
    fontSize: '0.85rem',
    lineHeight: '1.75',
    letterSpacing: '0.01em',
    boxSizing: 'border-box',
    '&.RaMenuItemLink-active': {
      backgroundColor: '#eeeeee',
      color: COLORS.light.primary.main,
      '& .MuiListItemIcon-root': {
        color: COLORS.light.primary.main
      }
    }
  };
  return (
    <>
      <Box style={{ marginBottom: open && '6px' }}>
        {/* <ModalSelectTalent list={dataTalentos} /> */}
        {resources.map((resource, index) => {
          const firstIndex = index === 0 ? true : false;
          return (
            <>
              {/* PARA EL DASHBOARD */}
              {firstIndex && (
                <Box
                  sx={{
                    height:sidebarOpen[0]? '80px':'auto',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid',
                    borderColor: '#808080',
                    marginBottom:'10px'
                  }}
                >
                  <MenuItemLink
                    key={resource.name}
                    to={
                      resource.options.customPath
                        ? `/${resource.options.customPath}`
                        : `/${resource.name}`
                    }
                    primaryText={
                      (resource.options && resource.options.label) ||
                    resource.name
                    }
                    leftIcon={resource.icon ? <resource.icon /> : null}
                    sidebarIsOpen={sidebarOpen[0]}
                    sx={{
                      padding: sidebarOpen[0]?'6px 29px':null,
                      color: '#000000',
                      fontWeight:'600',
                      width: '100%',
                      height:'100%',
                      margin: sidebarOpen[0] ? '0 0px' : '0px',       
                      '&.RaMenuItemLink-active': {
                        backgroundColor: '#eeeeee',
                        color: COLORS.light.primary.main,
                        '& .MuiListItemIcon-root': {
                          color: COLORS.light.primary.main,
                        },
                      },
                    }}
                  />
                </Box>
              )}
              {!firstIndex && resource.options.isTitle !== true && resource.name !== 'event_submissions' ? (
                <MenuItemLink
                  key={resource.name}
                  to={
                    resource.options.customPath
                      ? `/${resource.options.customPath}`
                      : `/${resource.name}`
                  }
                  primaryText={ resource.name === 'talents' && resource.options.label === 'Active'? 'Talent': resource.name === 'events' && resource.options.label === 'Active'? 'Events':
                    (resource.options && resource.options.label) ||
                  resource.name
                  }
                  leftIcon={resource.icon ? <resource.icon /> : resource.name === 'talents' ?<MdInterpreterMode/>: resource.name === 'events' ?<TbMicrophone2/>:null}
                  sidebarIsOpen={sidebarOpen[0]}
                  sx={{
                    color: '#000000',
                    padding: '6px 16px',
                    margin: sidebarOpen[0] ? '0 12px' : '3px',
                    fontWeight: '600',
                    borderRadius: '12px',
                    lineHeight: '1.75',
                    letterSpacing: '0.01em',
                    '&.RaMenuItemLink-active': {
                      backgroundColor: '#eeeeee',
                      color: COLORS.light.primary.main,
                      '& .MuiListItemIcon-root': {
                        color: COLORS.light.primary.main,
                      },
                    },
                  }}
                />
              ) : null}
              {/* {resource.name === 'admins' && <GuideButton/>} */}
            </>
          );
        })}
        {/* <MenuItemLink
          id="talents-page"
          key={'talents'}
          target={null}
          to={`${'talents'}`}
          sx={styledMenuItem}
          primaryText={

            <Typography
              sx={['marketing', 'education', 'info', 'resources'].includes('talents') ? {
                color: !sidebarOpen[0] ? '#fff' : '#757575',
                fontWeight: '600',
                borderRadius: '12px',
                fontSize: '0.85rem',
                lineHeight: '1.75',
                letterSpacing: '0.01em',
                boxSizing: 'border-box',
                '&.RaMenuItemLink-active': {
                  backgroundColor: '#eeeeee',
                  color: COLORS.light.primary.main,
                  '& .MuiListItemIcon-root': {
                    color: COLORS.light.primary.main
                  }
                },
                '&:hover': {
                  ...classAnimation
                }
              } : {
                color: !sidebarOpen[0] ? '#fff' : '#757575',
                fontWeight: '600',
                borderRadius: '12px',
                fontSize: '0.85rem',
                lineHeight: '1.75',
                letterSpacing: '0.01em',
                boxSizing: 'border-box',

                '&.RaMenuItemLink-active': {
                  backgroundColor: '#eeeeee',
                  color: COLORS.light.primary.main,
                  '& .MuiListItemIcon-root': {
                    color: COLORS.light.primary.main
                  }
                },
              }
              }
            >
                  Talents
            </Typography>
            // </Box>
          }
          leftIcon={<CiCircleList />}
        /> */}
      </Box>
    </>
  );
};

export const FestivalForm = ({ talent_id }) => {
  const sidebarOpen = useSidebarState();
  const [open, setOpen] = useState(true);
  const FORM_FESTIVAL_SIDEBAR = [
    {
      name: 'Festival Information',
      path: 'festivals',
      icon: BsInfoCircle,
    },
    // {
    //   name: 'Countdown',
    //   path: 'countdown',
    //   icon: CgProfile,
    // }
  ];
  return (
    <>
      <Box style={{
        marginBottom: open && '6px',
      }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0px 12px',
            padding: '6px 9px 6px 15px',
            cursor: 'pointer',
            borderRadius: '12px',
            '&:hover': {
              backgroundColor: '#f5f5f5'
            }
          }}
          onClick={() => setOpen(!open)}
        >
          {
            sidebarOpen[0] && (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TbUsers style={{
                    color: '#757575',
                    marginLeft: '3px',
                    marginRight: '25px',
                    letterSpacing: '0.05em',
                    fontWeight: '600',
                    fontSize: '0.8rem'
                  }} />
                  <Typography
                    variant='subtitle2'
                    style={{
                      color: '#757575',
                      fontWeight: '600',
                      borderRadius: '12px',
                      fontSize: '.9rem',
                      lineHeight: '1.75',
                      letterSpacing: '0.01em',
                    }}
                  >
                    {'Festival'}
                  </Typography>
                </Box>
                {
                  open ? <MdExpandLess style={{ color: '#757575' }} /> : <MdExpandMore style={{ color: '#757575' }} />
                }
              </>
            )
          }
        </Box>
        <Collapse in={!sidebarOpen[0] || open} unmountOnExit>
          {
            FORM_FESTIVAL_SIDEBAR?.map((item, i) => (
              <MenuItemLink
                key={item.path}
                to={`${item.path}/64de33473c70cacd8a1fdbde/edit`}
                primaryText={item.name}
                leftIcon={<item.icon />}
                sidebarIsOpen={open}
                sx={{
                  margin: sidebarOpen[0] ? '0px 12px 0 24px' : '0px 3px',
                  color: '#757575',
                  padding: '6px 16px',
                  fontWeight: '600',
                  borderRadius: '12px',
                  fontSize: '0.85rem',
                  lineHeight: '1.75',
                  letterSpacing: '0.01em',
                  '&.RaMenuItemLink-active': {
                    backgroundColor: '#eeeeee',
                    color: COLORS.light.primary.main,
                    '& .MuiListItemIcon-root': {
                      color: COLORS.light.primary.main
                    }
                  }
                }}
              />
            ))
          }
        </Collapse>
      </Box>
    </>
  );
};
{/* <Collapse in={!sidebarOpen[0] || open} unmountOnExit>
{selectedTalent.selected ? (
  FORM_TALENT_SIDEBAR?.map((item, index) => {
    return (
      <MenuItemLink
        id={`tab-${index}`}
        key={item.path}
        target={item.target ? '_blank' : null}
        to={item.target ? 'https://aberardo.notion.site/Mktg-Assets-Envision-Festival-2024-1e81af5f508e46ffae95473b35091935' : item.noId ? `${item.path}` : `${item.path}`}
        sx={styledMenuItem}
        primaryText={
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            cursor: 'pointer',
            flexWrap: 'wrap',
            borderRadius: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            boxSizing: 'border-box',
          }}>

            <Typography
              sx={['marketing', 'education', 'info', 'resources'].includes(item.path) ? {
                color: !sidebarOpen[0] ? '#fff' : '#757575',
                fontWeight: '600',
                borderRadius: '12px',
                fontSize: '0.85rem',
                lineHeight: '1.75',
                letterSpacing: '0.01em',
                boxSizing: 'border-box',
                '&.RaMenuItemLink-active': {
                  backgroundColor: '#eeeeee',
                  color: COLORS.light.primary.main,
                  '& .MuiListItemIcon-root': {
                    color: COLORS.light.primary.main
                  }
                },
                '&:hover': {
                  ...classAnimation
                }
              } : {
                color: !sidebarOpen[0] ? '#fff' : '#757575',
                fontWeight: '600',
                borderRadius: '12px',
                fontSize: '0.85rem',
                lineHeight: '1.75',
                letterSpacing: '0.01em',
                boxSizing: 'border-box',

                '&.RaMenuItemLink-active': {
                  backgroundColor: '#eeeeee',
                  color: COLORS.light.primary.main,
                  '& .MuiListItemIcon-root': {
                    color: COLORS.light.primary.main
                  }
                },
              }
              }
            >
              {item.name}
            </Typography>
          </Box>
        }
        leftIcon={<item.icon />}
        sidebarIsOpen={open}
      />
    );
  })
) : null
}
</Collapse> */}