import { Typography } from '@mui/material';
import { mainColor } from '../../Theme';


export const FormTitle = ({sx, children})=> {
  return (
    <Typography 
      sx={{
        fontSize: '16px',
        // letterSpacing: '2px',
        color: mainColor,
        fontWeight: '600',
        ...sx
      }} 
      variant='subtitle2'
    >
      {children}
    </Typography>
  );
};