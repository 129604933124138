import React,{useEffect,useState} from 'react';
import { switchStyleIos } from '../../../../Components/StyledComponents/Inputs';
import {Switch} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useInput } from 'react-admin';

export const InputSwitch =({mapOn,setMapOn,source})=>{
  const [oldCoordinates, setOldCoordinates] = useState();
  const {field} = useInput({source});
  const { getValues,setValue } = useFormContext();
  const values = getValues();
  const handleChange = (event) => {
    setMapOn(event.target.checked);
    if (event.target.checked) {
      field.onChange({
        coordinates: oldCoordinates ? oldCoordinates : [0, 0]
      });
    }else{
      field.onChange(null);
    }
  };
  useEffect(()=>{
    const valores = getValues();
    let oldCords = valores && valores.location?.geometry;
    if(oldCords !== null){
      setOldCoordinates(oldCords?.coordinates);
    }else{
      setOldCoordinates(null);
    }

  },[]);

  useEffect(()=>{
    const valores = getValues();
    let mapactivo = valores && valores.location?.geometry === null ? false : true;
    setMapOn(mapactivo);
  },[]);
  return (
    <Switch sx={switchStyleIos} onClick={handleChange} checked={mapOn}/>
  );
};