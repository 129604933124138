import React, { useEffect, useState } from 'react';
import { ReferenceArrayInput } from 'react-admin';
import InputAutocomplete from '../../InputsForm/InputAutocomplete';
import { useFormContext } from 'react-hook-form';
import { ButtonGroup, Button,Select,MenuItem,Skeleton } from '@mui/material';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { COLORS } from '../../../assets/constants/theme';
import { DEEP_LINKING_OPTIONS } from '../../../assets/constants/types';
import { strCapitalize } from '../../../helpers/strCapitilize';
export const InputTabSingleSelector = ({
  setState,
  state,
  choices,
  approvedFields,
  textTransform,
  title,
  watchField,
  optionText = 'text',
  optionValue = 'text',
  source,
  setLinking,
  setValueUrl,
  ...rest
}) => {
  const { watch, setValue, getValues } = useFormContext();
  const valores = getValues();
  const pickUrl = getValues()?.url;
  const destructUrl = (url) => {
    const defaultOption = DEEP_LINKING_OPTIONS[0].text;
    let option = defaultOption;
    let id = '-';
  
    if (url && url?.startsWith('envision://')) {
      console.log('empieza')
      const parts = url?.split('/');
      console.log(parts)
      if (parts?.length === 4) {
        console.log('length');
        option = parts[2];
        id = parts[3]; 
      }else{
        option = parts[2];
      }
    }
    console.log(option,id,'lo que necesito');
    setLinking(option === 'faqs' ? 'FAQs' : strCapitalize(option));
    setValueUrl(id);
  };
  const [buttonPicked, setButtonPick] = useState(undefined);

  const handlerSetValue = (value) => {
    setButtonPick(value);
    if(setState){
      setState(value);
      setValue(source, value === 'Internal' ? true : false);
    }else{
      setValue(source, value);
    }
  };
  useEffect(() => {
    if(setState){
      destructUrl(pickUrl);
    }
  },[]);
  useEffect(() => {
    if(source === 'role'){
      setButtonPick(valores?.role);
    }
    else{
      setButtonPick(valores[source]);
    }
  },[]);
  return (
    <>
      {title && (
        <InputTitle
          textTransform={textTransform}
          sx={{
            display: !watchField
              ? 'block'
              : watch(watchField) === 'true' ||
                approvedFields?.includes(watch(watchField))
                ? 'block'
                : 'none',
          }}
        >
          {title}
        </InputTitle>
      )}

      <ButtonGroup
        variant="outlined"
        sx={{
          marginTop: '20px',
          '& .MuiButtonGroup-grouped': {
            border: '1px solid grey',
          },
        }}
        aria-label="outlined primary button group"
      >
        {choices.map((choice) => (
          source !== 'internal_deeplink' ?(<Button
            sx={{
              color: buttonPicked === choice.text ? 'white' : 'black',
              backgroundColor:
                buttonPicked === choice.text
                  ? COLORS.light.secondary.main
                  : '#fff',
              ':hover': { backgroundColor: COLORS.light.secondary.main },
            }}
            key={choice.id}
            onClick={() => handlerSetValue(choice.text)}
            disabled={valores[source] === choice.id}
          >
            {choice[optionText]}
          </Button>): 
            (<Button
              sx={{
                color: state === choice.text ? 'white' : 'black',
                backgroundColor:
                state === choice.text
                  ? COLORS.light.secondary.main
                  : '#fff',
                ':hover': { backgroundColor: COLORS.light.secondary.main },
              }}
              key={choice.id}
              onClick={() => handlerSetValue(choice.text)}
              {...rest}
            >
              {choice[optionText]}
            </Button>)
        ))}
      </ButtonGroup>
    </>
  );
};

export const InputDropdownSelector = ({
  watchField = 'category',
  resource,
  label,
  title,
  width,
  withChoices,
  source
}) => {
  const { watch, setValue, getValues } = useFormContext();
  const [size, setSize] = useState();
  const [loading, setLoading] = useState(true);
  const valores =getValues();
  const handlerSetValue = (value) => {
    let valor = value.target.value;
    setSize(valor);
    setValue(source, valor);
  };

  useEffect(() => {
    const fetchData = async () => {
      const valores = getValues(source);
      setSize(valores);
      setLoading(false);
    };

    fetchData();
  }, [getValues]);
  if (withChoices) {
    if (loading) {
      return (
        <Skeleton
          variant="rectangular"
          width={width}
          height={40}
          sx={{ borderRadius: '10px' }}
        />
      );
    }

    return (
      <div style={{ width: '100%' }}>
        {title &&
          <InputTitle value={title} sx={{ marginBottom: width ? '7px' : '20px' }} />
        }
        <Select
          size='small'
          value={size || ''} 
          onChange={handlerSetValue}
          sx={{ width: '100%', borderRadius: '10px' }}
        >
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={0.5}>0.5</MenuItem>
        </Select>
      </div>
    );
  } else
    return (
      <ReferenceArrayInput
        source="tags"
        reference={`${resource}/tags`}
        filter={{ category: encodeURIComponent(watch(watchField)) }}
      >
        <InputAutocomplete
          width={width}
          title={title}
          disabled={
            watchField && (watch(watchField) === '' || !watch(watchField))
          }
          isArray
          label={label}
          optionText={'text'}
          optionValue={'text'}
          sx={{ width: '150px' }}
          limitTags={2}
        />
      </ReferenceArrayInput>
    );
};
