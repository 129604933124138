import React from 'react';
import { Create } from 'react-admin';
import FormNotifications from './FormNotifications';
import { transform } from './Transform';

const CreateNotifications = () => {
  return (
    <Create transform={transform} redirect='list' mutationMode={'pessimistic'} sx={{'& .RaCreate-noActions': { marginTop: '0px'},'& .MuiCardContent-root':{paddingX: '50px'}}}>
      <FormNotifications />
    </Create>
  );
};

export default CreateNotifications;