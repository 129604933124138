
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Skeleton, TextField, Tooltip, Typography } from '@mui/material';
import { AiOutlinePlus } from 'react-icons/ai';
import { BiTrashAlt } from 'react-icons/bi';
import { EmailComponent } from './EmailComponent';
import { mainColor } from '../../../../Theme';

export const initialRender = (num, element, initialValue) => {
  let arr = [
    {
      component: React.cloneElement(element, {...element?.props, key: 0}),
      value: initialValue
    }
  ];
  for(let i = 1; i < num; i++){
    let elem = {
      component: React.cloneElement(element, {...element?.props, key: i}),
      value: initialValue
    };
    arr.push(elem);
    continue;
  }
  return arr;
};
 
export const AddButton = ({addText = '+ Add', handle, sx,  ...rest}) => 
  <Button 
    {...rest} 
    onClick={handle} 
    sx={{
      borderRadius: '12px',
      // padding: '5px', 
      // border: `2px solid ${mainColor}`,
      ...sx}}>
    <Typography variant="h7" sx={{textTransform:'none',color: mainColor, fontWeight: '600'}}>{addText}</Typography>
    {/* <AiOutlinePlus  /> */}
  </Button>;

export const RemoveButton = ({handleDelete, componentKey, sx, ...rest}) => 
  <Button 
    {...rest} 
    onClick={() => handleDelete(componentKey)} 
    sx={{
      borderRadius: '12px',
      // border: rest.disabled ? '' : '2px solid #f99190',
      padding: '15px',
      ...sx}}>
    <BiTrashAlt color={rest.disabled ? '' : '#f99190'} />
  </Button>;

export const InputAddTextInputs = ({
  tooltip, 
  onDelete, 
  element, 
  buttonVariant,
  checkVal, 
  values = [], 
  setValues, 
  addText,
  addAction,
  limit = 3, 
  width = '100%',
  disable,
  direction = 'row',
  sx,
  hideAdd,
  hideRemove,
  initialNumber = 1,
  initialValue = null,
  modify,
  gap = '20px'
}) => {
  const [newValues, setNewValues] = useState(values);
  const [arr, setArr] = useState(initialRender(initialNumber, element, initialValue));

  const handle = () => {
    addAction && addAction();
    return setArr([...arr, {component: React.cloneElement(element, {...element?.props, key: arr.length}), value: initialValue}]);
  };

  const handleDelete = (componentKey) => {
    onDelete && onDelete(componentKey);
    setArr(arr.filter((item, i) => { item;
      if((i) !== componentKey) {
        return i > componentKey ? {...item, key: (i - 1)} : item;
      }
    }));
    setNewValues((f) => {
      return f?.filter((item ,i) => {
        if((i) !== componentKey) {
          return i > componentKey ? {...item, key: (i - 1)} : item;
        }
      });
    });
  };
  useEffect(() => {
    if(values.length !== newValues.length) {
      setArr(initialRender(initialNumber, element, initialValue));
      setNewValues(values);
    }
  },[initialNumber, element]);
 
  const elementProps = (element) => {

    return {
      componentKey: element.component.key,
      newValues: newValues,
      handleChange: setNewValues,
      deleteHandle: handleDelete,
      checkVal: checkVal
    };
  }; 

  useEffect(() => setValues && setValues(newValues), [newValues]);
  return(
    <>
      { 
        arr && values && (
          <Tooltip placement="top-end" title={tooltip ? tooltip : ''}>
            <Box 
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                width: width,
                gap: gap
              }}>
              {
                arr ? arr?.map((el, i) => {
                  // Agregue el modify , que lo envia el componente padre para especificar que se trata de agregar mas TMs
                  if((i + 1) === arr.length && (modify === 'talent_managers' ? arr.length !== 999 : arr.length !== limit )) {
                    return <Box sx={{width:'100%',display: 'flex', flexDirection: direction, ...sx}}>
                      {React.cloneElement(el.component, {...elementProps(el)})}
                      <Box sx={{display: 'flex', flexDirection: 'row', gap: '15px'}}>
                        {i > 0 && !hideRemove &&
                        <RemoveButton handleDelete={handleDelete} componentKey={i} /> 
                        }
                        { !hideAdd && <AddButton 
                          variant={buttonVariant}
                          addText={addText}
                          disabled={disable ? values?.length !== arr.length : false} 
                          handle={handle} />}
                        {
                          i <= 0 && <Box sx={{ width: '80px', borderRadius: '12px', padding: '5px'}} />
                        }
                      </Box>
                    </Box>;
                  }
                  return <Box sx={{width:'100%',display: 'flex', flexDirection: direction, ...sx}}>
                    {React.cloneElement(el.component, elementProps(el))}
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: '15px'}}>

                      { !hideRemove && <RemoveButton disabled={(i+1) !== arr.length}  handleDelete={handleDelete} componentKey={i} />}
                      <Box sx={{ width: '80px', borderRadius: '12px', padding: '5px'}} />
                    </Box>
                  </Box>;
                }) : (
                  <Skeleton />
                )
              }
            </Box>
          </Tooltip>
        )
      }
    </>
  );
};
