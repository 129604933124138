export const MIN_MINUTES_EVENTS = 10;

export const styleTitleDate = {
  cursor: 'pointer',
  color: 'rgba(0,0,0,.8)',
  fontWeight: '600',
  lineHeight: '30px',
  position:'sticky'
  // borderLeft: '1px solid rgba(0,0,0,.3)'
};

export const TimeStepsCalendar = {
  minute: 15,
  hour: 1,
  day: 1,
  month: 1,
};

export const itemPropsDefaults = {
  canMove: true,
  canResize: true,
  canChangeGroup: true,
};

export const defaultKeys = {
  groupIdKey: 'id',
  groupTitleKey: 'name',
  itemIdKey: 'id',
  itemTitleKey: 'name',    // key for item div content
  itemDivTitleKey: 'name', // key for item div title (<div title="text"/>)
  itemGroupKey: 'stageFormat',
  itemTimeStartKey: 'start_date',
  itemTimeDuration: 'duration',
  itemTimeEndKey: 'end_date',
};

export const stage_color = {
  '63c5bb4fa79491efa54586b9': { //Village
    solid: '#156141',
    light: '#AFC9BE',
    medium: '#4B876E',
    typography: '#FFFFFF'
  },
  '63c5bb50a79491efa54586bc' : { // El Circo
    solid: '#912579',
    light: '#D9B5D1',
    medium: '#AB5999',
    typography: '#FFFFFF'
  },
  '63c5bb52a79491efa54586bf': { // Templo del Cielo
    solid: '#00B8D9',
    light: '#ACECF7',
    medium: '#44D4ED',
    typography: '#FFFFFF'
  },
  '63c5bb52a79491efa54586c0' : { // Templo de las Estrellas
    solid: '#B18665',
    light: '#E5D6CB',
    medium: '#C4A48A',
    typography: '#FFFFFF'
  },
  '63c5bb52a79491efa54586c1' : { //Village Witches
    solid: '#006BB5',
    light: '#A8CDE6',
    medium: '#3C8FC7',
    typography: '#FFFFFF'
  },
  '63c5bb53a79491efa54586c2' : { //Red Tent
    solid: '#C80F0B',
    light: '#E99F9D',
    medium: '#D95755',
    typography: '#FFFFFF'
  },
  '63c5bb53a79491efa54586c3' : { //Sacred Fire
    solid: '#F47927',
    light: '#FBD1B5',
    medium: '#F7995A',
    typography: '#FFFFFF'
  },
  '63c5bb53a79491efa54586c4' : { //Earth Temple
    solid: '#2E7477',
    light: '#B8D0D1',
    medium: '#5F9597',
    typography: '#FFFFFF'
  },
  '63c5bb46a79491efa54586b4' : { //Sol
    solid: '#FFD70D',
    light: '#FFF2AD',
    medium: '#FFE65F',
    typography: '#855A3A'
  },
  '63c5bb4ea79491efa54586b6' : { //Luna
    solid: '#6533B4',
    light: '#6533B4',
    medium: '#8A64C7',
    typography: '#FFFFFF'
  },
  '63c5bb4fa79491efa54586b8' : { //Lapa
    solid: '#FF1D1D',
    light: '#FFA4A4',
    medium: '#FF6161',
    typography: '#FFFFFF'
  },
  '63c5bb55a79491efa54586c8' : { //Starseed Family Village
    solid: '#1BCBA7',
    light: '#B1EDE1',
    medium: '#51D8BC',
    typography: '#FFFFFF'
  },
  '63c5bb54a79491efa54586c5' :{ //Art Gallery
    solid: '#FF2EA3',
    light: '#FFABDA',
    medium: '#FF6DBF',
    typography: '#FFFFFF'
  },
  '63e926ac48575f4eb89c8f27' :{ //Humans i trust
    solid: '#FABBCD',
    light: '#FAE8ED',
    medium: '#F5D1DB',
    typography: '#FFFFFF'
  },
  '63ebf65e89706db233f9c925' :{ //Momentom -> colores de performance
    solid: '#E1B03C',
    light: '#F5E4BD',
    medium: '#E8C36A',
    typography: '#FFFFFF'
  },
  '63f93cb374d4ae7abdc54cdf': { // Mundo de Luz
    solid: '#8E44AD',
    light: '#B39DDC',
    medium: '#9B59B6',
    typography: '#FFFFFF'
  },
  '65bbb595bdb5dc0ac76a3383': { // Bambu
    solid: '#3498DB',
    light: '#85C1E9',
    medium: '#5DADE2',
    typography: '#FFFFFF'
  },
  '63fa6ab68102f4cf234ed8d5': { // Altar of Shadows
    solid: '#1ABC9C',
    light: '#76D7C4',
    medium: '#48C9B0',
    typography: '#FFFFFF'
  },
  '63f93cb155e36a1aebc36f78': { // La Galeria Workshops
    solid: '#E67E22',
    light: '#F0B27A',
    medium: '#E59866',
    typography: '#FFFFFF'
  }
};

// Generador de colores para calendario
// export function getColorAndBgColor(item, stageColor) {
//   const stageId = item?.stage?.id;
//   if (stageId && stageColor[stageId]) {
//     const stage = stageColor[stageId];
//     const bgColor = stage.light || 'none';
//     const color = stage.light === '#6533B4' ? '#FFFFFF' : '#363636';
//     return { bgColor, color };
//   } else {
//     const randomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);
//     const randomBgColor = randomColor();
//     console.log(randomBgColor);
//     const randomTextColor = randomColor();
//     return { backgroundColor: randomBgColor, color: randomTextColor };
//   }
// }
export const CENTER_COORD = [-83.7680103841464, 9.183611130691833];
