
import AdaptableForm from '../../Components/AdaptableForm/AdaptableForm';
import InputText from '../../Components/InputsForm/InputText';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import InputCheckbox from '../../Components/InputsForm/InputCheckbox';
import { InputTitle } from '../../Components/StyledComponents/InputTitle';
import { PopupShow } from './Components/PopupShow';
import { InputLogos } from '../../Components/InputsForm/InputLogos';
import InputButtonAws from '../../Components/InputsForm/InputButtonAws';
import { SelectInput, required } from 'react-admin';


export const PopupsForm = () => {
  const [info, setInfo] = useState(null);
  const customToast = useCustomToasty();
  //Corregir para no realziar la petición aca
  useEffect(() => {
    axios({
      url: `${process.env.REACT_APP_BASE_PATH}/info`,
      method: 'GET',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      }
    }).then(res => {
      setInfo(res.data.results[0]);
    }).catch(err => {
      throw err;
    });
  },[]);
  
  const data = [
    {
      title: 'Popup',
      inputs: [
        {
          field: <InputText validate={[required()]} title="Popup title" fullWidth source="popup.title" label="" />,
          breakpoints: { xs: 5 }
        },
        {
          field: <InputText validate={[required()]} title="Subtitle" fullWidth source="popup.subtitle" label="" />,
          breakpoints: { xs: 5 }
        },
        {
          field: <InputText validate={[required()]} title="Description" fullWidth source="popup.text" label="" />,
          breakpoints: { xs: 12 }
        },
        {
          field: <InputText validate={[required()]} title="Call to Action Text" fullWidth source="popup.call_to_action_text" label="" />,
          breakpoints: { xs: 5 }
        },
        {
          field: <InputText validate={[required()]} title="Link" fullWidth source="popup.url" label="" />,
          breakpoints: { xs: 5 }
        },
        {
          field: <InputButtonAws sx={{display: 'flex', flexDirection: 'column', gap: '10px'}} label="Background Image" fullWidth source="popup.background_image" noImage />,
          breakpoints: { xs: 12 }
        },
        {
          field: <>
            <InputTitle tooltip={'Indicate how often you want to show the popup in the app.'}>Popup interval</InputTitle>
            <SelectInput fullWidth validate={[required()]} variant="outlined" optionText="value" optionValue='key' source="popup.trigger" label=""  
              choices={[
                {key: 'never', value: 'Never'},
                {key: 'daily', value: 'Once a day'},
                {key: 'weekly', value: 'Once a week'},
                {key: 'biweekly', value: 'Once every 15 days'}
              ]} />
          </>,
          breakpoints: {xs: 12}
        },
        {
          field: <>
            <InputTitle tooltip="This will toggle the popup to show only once upon opening the app.">Toggle Popup</InputTitle>
            <InputCheckbox source="popup.toggle" />
          </>,
          breakpoints: { xs: 12 }
        },
        {
          field: <PopupShow />,
          breakpoints: {xs: 12}
        }
        
      ],
    }
  ];

  const onSubmit = async (data) => {
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      },
      data: data

    })
      .then(res => {
        customToast('success', 'Changes submitted succesfuly.');
      })
      .catch(err => {
        customToast('error', 'There was an error, please try again later.');
      });
  };

  return(
    <AdaptableForm simpleform={{record: info, onSubmit: onSubmit}} data={data} isEdit floating from={false}/>
  );
};