import React from 'react';
import FormFaqs from './FormFaqs';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';

const EditFaqs = () => {
  return (
    <EditFormCustom>
      <FormFaqs isEdit />
    </EditFormCustom>
  );
};

export default EditFaqs;