import { FunctionField, ImageField, TextField, useRedirect } from 'react-admin';
import React, { useContext, useEffect, useState } from 'react';
// Hooks & Utils
import { COLORS } from '../../../assets/constants/theme';
import { calendarContext } from '../../../dataProvider';
// Components
import { fetchData } from '../../../Components/LayoutCustom/MenuCustom';
import { SubmissionsActions } from '../TalentSubmissions/SubmissionsList';
import { Indicator } from '../../../Components/RedesignComponents/Indicator';
import ListCustom from '../../../Components/ListCustom/ListCustom';
// Material & Icons
import { Typography,Chip, TextField as TextFieldMui } from '@mui/material';
import { MdCheckCircle } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { PillInfo } from '../../Talents/TalentsList';

const paramsList = {
  listParams: {
    filterDefaultValues: {pending:true},
    hasCreate: true,
    resource: 'events',
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'show',
  },
};

const SubmissionsList = () => {
  const [filter, setFilter] = useState({pending:true});
  const redirect = useRedirect();
  const { setPendingSubmissions } = useContext(calendarContext);

  useEffect(() => {
    fetchData(setPendingSubmissions);
  },[]);
  console.log(filter);
  return (
    <div >
      <ListCustom
        empty={<Typography>No submissions</Typography>}
        {...paramsList}
        listParams={{
          ...paramsList.listParams,
          filter: filter,
          actions: <SubmissionsActions filter={filter} setFilter={setFilter} from={'events'}  placeholder={'Search by name, description, stage and talents...'}/>,
        }}
        datagridParams={{
          empty: <EmptyEventsListSubmissions />,
          sx: {
            padding:'0px 20px',
            '& .MuiTypography-root':{
              color:'#000000',
              fontWeight:400
            },
            '& .MuiTableSortLabel-root':{
              color:COLORS.table.headerSorteable,
              fontWeight:400
            },
            '& .RaDatagrid-headerCell':{
              color:COLORS.table.headerSorteable,
              width:'16.6%'
            },
            '& .column-imagen': {
              width: '10%',
              textAlign:'center'
            },
            '& .column-name': {
              width: '15%'
            },
            '& .column-category': {
              width: '10%',
              textAlign: 'center',
            },
            '& .column-completed': {
              width: '10%',
              textAlign: 'center',
            },
            '& .column-status': {
              width: '15%',
              textAlign: 'center',
            }, 
            '& .MuiTableCell-body': {
              borderBottom:'0px'
            },
          },
          ...paramsList.datagridParams,
          rowClick: (id, resource, record) => redirect('show', 'events', id)
        }}
      >
        <FunctionField source='imagen' render={(record) =><ImageField
          source="picture.original"
          label='Picture'
          sx={{ '& img': { borderRadius: 200, maxWidth: 50, maxHeight: 45, objectFit: 'cover' } }}
        />  } label='Picture'/>
        <FunctionField label="Name" source='name' render={(record) => {
          if(record?.submission?.name && record?.submission?.status !== 'approved') return (
            <TextField source="submission.name" />
          );
          return (
            <TextField source="name" />
          );
        }} />
        <TextField label="Talent" source="talents[0].name" />
        <FunctionField label="Category"  source="category" render={(record)=>record?.category ? <Chip sx={{width:'auto'}} label={record?.category} /> : null}/>
        <FunctionField label="Complete?"  source="completed" render={(record) => 
          <Typography
            sx={{
              fontSize: '.9em',
              fontWeight: '600',
              color: record?.completed ? '#009b00' : '#ffc990',
              textTransform: 'uppercase',
            }}
          >
            {record?.completed ? <MdCheckCircle color='#21B141' size={26}/> : <IoMdCloseCircle color='grey' size={26}/>}
          </Typography> 
        } />
        <FunctionField label="Last submission status" source="status" 
          render={(record) =>
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              {record?.submission?.status === 'draft'  || record?.submission === null  ? <Indicator /> : (<Indicator data={record?.submission} />)}
            </div>
          }/>
      </ListCustom> 
    </div>
  );
};
export const EmptyEventsListSubmissions = () => {

  return (
    <div style={{width:'100%',height:'20%'}}>
      <div  style={{width:'100%',height:'12%',overflow:'hidden'}}>
        <ListCustom
          listParams={{
            ...paramsList.listParams,
            actions: false,
            empty: false,
          }}
          pagination={false}
          datagridParams={{
            size: 'small',
            sx: {
              '& .column-picture.original': {
                width: '200px',
              },
              '& .column-name': {
                width: '900px',
              },
              '& .column-main_category': {
                width: '200px',
              },
              '& .column-status': {
                width: '300px',
              },
              '& .column-published': {
                width: '300px',
              }
            },
            bulkActionButtons: false,
          }}
        >
          <ImageField
            label=''
            sx={{ '& img': { borderRadius: 200, maxWidth: 39, maxHeight: 39, objectFit: 'cover' } }}
          />  
          <FunctionField label="Name" render={(record) => {
            if(record?.submission?.name && record?.submission?.status !== 'approved') return (
              <TextField  />
            );
            return (
              <TextField  />
            );
          }} />
          <TextField label="Status" />
          <TextField label="Category" />
          <FunctionField label="Submission Status" 
            render={(record) =><p style={{ visibility: 'hidden' }}>123</p>}/>
          <FunctionField
            sortable={false}
            label="Visibility"
            source="123"
            render={(record) => <p style={{ visibility: 'hidden' }}>123</p>}
          />
        </ListCustom>
      </div>
      <Typography sx={{display:'flex',justifyContent:'center',marginTop:'20px'}} >
        No submissions
      </Typography>
    </div>
  );
};
// export const SubmissionsActions = ({filter, setFilter}) => {

// //   const handleFilter = (e) => {
// //     let filtered = {...filter};
// //     let value = e.target.checked;
// //     if(value) return setFilter({...filter, 'pending': value});
// //     delete filtered.pending;
// //     return setFilter(filtered);
// //   };


//   return(
//     <TopToolbar
//       sx={{
//         width: '100%',
//         display: 'flex',
//         alignItems: 'flex-start',
//         justifyContent: 'flex-start',
//         margin:'20px'
//       }} >
//       <Box sx={{
//         width: '100%',
//         display: 'flex',
//         alignItems: 'center',
//       }} mb={1}>
//         <Box component="span" mr={2}>
//           <TextFieldMui label="Search" variant="outlined" onChange={(e) => setFilter({...filter, 'q': e.target.value})} />
//         </Box>
//         <Box component="span" sx={{display:'flex',gap:'10px', flexDirection:'row', alignItems:'center'}}>
//           <Typography>
//             All
//           </Typography>
//           <FormControlLabel sx={{margin:0}} control={<Switch
//             onChange={handleFilter}
//             defaultChecked
//           />} />
//           <Typography>
//             Pending Approval
//           </Typography>
//         </Box>
//       </Box>
//     </TopToolbar>
//   )
// };

export default SubmissionsList;