import {
  useDataProvider,
  useGetList,
  useRedirect,
  useRefresh,
  usePermissions,
  useStore,
  useRecordContext
} from 'react-admin';
import AdaptableForm from '../../Components/AdaptableForm/AdaptableForm';
import AdaptableFormRedesign from '../../Components/AdaptableForm/AdaptableFormRedesign';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { usePosition } from '../../Hooks/usePosition';
import { progressBarFieldsEvents } from '../../assets/constants/RequiredFields';
// Material & Icons
import { MdArrowBack } from 'react-icons/md';
import {Box,Typography} from '@mui/material';
import { transform } from '../Events/Form/Transform';

export const SingleEventForm = ({ item, data, index = 0, isEdit, editSub,deleteResource, ...rest }) => {
  const record = useRecordContext();
  const permis = usePermissions().permissions;
  const fixedPosition = usePosition(index);
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const customToast = useCustomToasty();
  const redirect = useRedirect();

  const isTbEditSubbmission = permis !== 'talent_manager' && editSub && isEdit;
  const isTmEditMain = permis === 'talent_manager' && ['rejected', 'draft', 'pending'].includes(item?.submission?.status) && !editSub;
 
  const handleUpdateEvent = async (newData) => {
    const newDataForTM= {
      ...newData,
      talents_ids: item?.talent_id,
      collaborators_ids: item?.collaborators_ids,
    };
    try {
      let request = await dataProvider.update('events', { id: item.id, data:permis === 'talent_manager'? newDataForTM : newData });
      if (request.data) {
        rest?.onSave && rest?.onSave();
        customToast('success', 'Event updated succesfuly');
        refresh();
      }
    } catch (error) {
      customToast('error', error);

      throw error;
    }
    // try {
    //   let request = await dataProvider.update('events', { id: recordFromModal? newData.id :item.id, data:permis === 'talent_manager'? newDataForTM : newData });
    //   if (request.data) {
    //     if(permis !== 'talent_manager') redirect('list', 'events');
    //     customToast('success', 'Event updated succesfuly');
    //     refresh();
    //   }
    // } catch (error) {
    //   customToast('error', 'There was an error');

    //   throw error;
    // }
  };

  const goBack = () => {
    if(rest.from !== 'talents'){
      redirect('/events');
    }else{
      rest.setViewTable(true);
    }
  };
  console.log(isTbEditSubbmission,'tbedit')
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          padding: '20px 16px',
          // height: '6.5%',
          gap: 1,
        }}
      >
        {
          !rest.noBack && (
            <button
              onClick={() => goBack()}
              style={{
                display: 'flex',
                border: 'none',
                background: 'none',
                cursor: 'pointer',
                alignItems: 'center',
              }}
            >
              <MdArrowBack size={30} />
            </button>
          )
        }
        <Typography fontSize={'32px'} fontWeight={600}>
          {isEdit?'Edit Event - ' +item?.name: 'New Event'}
        </Typography>
      </Box>
      <AdaptableFormRedesign
        resetStore  
        index={index}
        floating={fixedPosition}
        draft="events"
        record={
          isTbEditSubbmission || isTmEditMain ? {...item, ...item?.submission} : item
        }
        simpleform={
          isEdit ? 
            { record: 
            // isEdit ?
            // { ...item, ...item?.submission } :
            // item,
            (isTbEditSubbmission || isTmEditMain) ? {...item, ...item?.submission} : item,

            onSubmit: handleUpdateEvent
            }
            :undefined}
        data={data}
        from={'talents'}
        isEdit={isEdit}
        progressBarFields={progressBarFieldsEvents}
        saveBackground={true}
        showDelete
        deleteResource={deleteResource}
      />
    </>
  );
};
