import React from 'react';
import {useInput , useGetList, useRecordContext} from 'react-admin';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { convertToCostaRicaTime } from '../../helpers/date';
import AlarmIcon from '@mui/icons-material/Alarm';

const SchedulingEvent = ({source}) => {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September', 'October', 'November', 'December'
  ];
  const {field,formState,fieldState} = useInput({source});
  const record = useRecordContext();
  //Formateo de fecha
  const recordDate =  new Date(record?.start_date);
  // Mes y dia
  let month = months[recordDate.getMonth()];
  let day = recordDate.getDate();
  let formattedDate = month + ' ' + (day < 10 ? '0' : '') + day;
  // Hora de inicio y fin
  let startTime = new Date(convertToCostaRicaTime(record?.start_date));
  let endTime = new Date(convertToCostaRicaTime(record?.end_date));
  const openTime = startTime?.getHours() + ':' + startTime?.getMinutes() + ' - ' + endTime?.getHours() + ':' + endTime?.getMinutes();

  const {data:locationInfo,isFetching} = useGetList('locations', {filter: { ids: [field?.value?.id]},});
  const locationData = locationInfo && locationInfo[0];
  console.log(formattedDate , openTime)
  return (
    <div style={{borderRadius:'20px',backgroundColor:'black',display:'flex',flexDirection:'row',justifyContent:'space-between',width:'70%',height:'70%',padding:'0px 20px'}}>
      <div style={{color:'white',height:'100%',width:'100%',display:'flex',flexDirection:'row',gap:20}}>
        <img src={locationData?.picture?.original} alt='locationImg' style={{width:'7em',height:'100%',objectFit:'contain'}}/>
        <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',maxHeight:'100%',justifyContent:'center',alignContent:'start'}}>
          <p style={{fontWeight:600,fontSize:'18px',margin:0}}>{field?.value?.name}</p>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',height:'2.5em',gap:5,margin:0}}><CalendarMonthIcon/><p> {formattedDate}</p></div>
          <div style={{display:'flex',flexDirection:'row',alignItems:'center',height:'2.5em',gap:5,margin:0}}><AlarmIcon/><p>{openTime === 'NaN:NaN - NaN:NaN' ? 'Invalid Date' : openTime}</p></div>
        </div>
      </div>
      <div style={{color:'white',height:'100%',display:'flex',flexDirection:'row',gap:5,alignItems:'center', textAlign:'center',padding:'0px 20px'}}>
        {record?.duration && 
       <>
         <p style={{fontSize:'2.5em',margin:0,fontWeight:600}}>{record?.duration}</p>
         <span style={{fontSize:'1em',marginTop:20}}> min</span>
       </>
        }
      </div>
    </div>
  );
};

export default SchedulingEvent;