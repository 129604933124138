import React, { useEffect, useState } from 'react';
import { useDataProvider, useGetList } from 'react-admin';
import moment from 'moment';
// Hooks & Utils
import { filterSanitizerCombined } from '../../helpers/multiuse';
import { defaultKeys } from '../../assets/constants/Calendar';
import useDebounce from '../../Hooks/useDebounce';
// Components
import FormEvents from '../../Resources/Events/Form/FormEvents';
import DroppableItem from './DropabbleItem';
// Material & Icons & Styles
import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Skeleton,
  Modal,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MdArrowBackIos  } from 'react-icons/md';
import { IoMdSearch } from 'react-icons/io';
import { AiOutlineMenu } from 'react-icons/ai';
import { BiCross, BiEdit } from 'react-icons/bi';
import { RxCross2 } from 'react-icons/rx';
import {
  FaPalette,
  FaMusic,
  FaRunning,
  FaChild,
  FaChalkboardTeacher,
  FaTheaterMasks,
  FaCross,
} from 'react-icons/fa';
import { renderTags } from '../../Resources/Events/CalendarToolbar';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '65%',
  height: '70%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
};

const ModalAddSecondaryItems = ({
  groupList,
  setViewUnscheduled,
  items,
  canvasClicked,
  setCanvasClicked,
  handleAddPrevItem,
  handleDeletePrevItem,
  saveItem,
  scheduledItems
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [itemClicked, setItemClicked] = useState(null);
  const [itemsList, setItemsList] = useState(items);
  const [dropDownMenu, setDropDownMenu] = useState(false);
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [editedItem, setEditedItem] = useState(null);
  const debouncedSearch = useDebounce(search, 600);
  // filter category
  const [originalItemsList, setOriginalItemsList] = useState(items);
  const [value, setValue] = useState([]);
  const [filteredItemsList, setFilteredItemsList] = useState(items);
  const debounceCategory = useDebounce(value, 600);
  const dataProvider = useDataProvider();
  const { data, isFetching } = useGetList('events/categories', {
    pagination: { perPage: 9999, page: 1 },
  });
  const handleFilter = (e, selectedValues) => {
    setValue(selectedValues.map((v) => v.text));
  };

  useEffect(() => {
    const find = scheduledItems?.map((item) => {
      itemsList?.find((i) => i.id === item.id);
    });

    if(find) return setItemsList(scheduledItems);
  },[scheduledItems]);

  const { refetch, isFetching: isFetchingUpdatedItems} = useGetList('events', {
    filter: {no_start_date:false}
  }, {
    onSuccess: (data) => {
      if(data) setOriginalItemsList(data.data);
    },
    enabled: false
  });

  useEffect(() => {
    if (value.length == 0 && search == '') {
      setItemsList(originalItemsList);
      setFilteredItemsList(null);
    } else if (value.length > 1) {
      const filterListWithAllCategories = originalItemsList.filter((item) =>
        value?.includes(item?.category)
      );
      setItemsList(filterListWithAllCategories);
      setFilteredItemsList(filterListWithAllCategories);
    } else {
      const filterListWithCategory = itemsList.filter((item) =>
        value?.includes(item?.category)
      );
      setItemsList(filterListWithCategory);
      setFilteredItemsList(filterListWithCategory);
    }
  }, [scheduledItems, debounceCategory, value]);

  useEffect(() => {
    if (value.length == 0 && search == '') {
      setItemsList(items);
    } else if (filteredItemsList?.length > 1) {
      const filterListItemSearch = filteredItemsList.filter((item) =>
        filterSanitizerCombined(item, search, 'name')
      );
      setItemsList(filterListItemSearch);
    } else {
      const filterListItemSearch = itemsList.filter((item) =>
        filterSanitizerCombined(item, search, 'name')
      );
      setItemsList(filterListItemSearch);
    }
  }, [debouncedSearch]);

  const handleClose = () => {
    setCanvasClicked(null);
    setViewUnscheduled(false);
    itemClicked && handleDeletePrevItem(itemClicked);
  };

  const handleSaveButton = () => {
    const saved = saveItem(
      itemClicked.id,
      canvasClicked.time,
      canvasClicked.groupId
    );
    !saved && handleDeletePrevItem(itemClicked);
    setCanvasClicked(null);
  };

  const handleClickItem = (item) => {
    if (itemClicked) {
      handleDeletePrevItem(itemClicked);
    }
    const itemPrevAdd = {
      ...item,
      [defaultKeys['itemTimeStartKey']]: moment(canvasClicked?.time),
      [defaultKeys['itemTimeEndKey']]: moment(canvasClicked?.time).add(
        15,
        'minutes'
      ),
      [defaultKeys['itemGroupKey']]: canvasClicked?.groupId,
    };

    setItemClicked(itemPrevAdd);
    handleAddPrevItem(itemPrevAdd);
  };

  useEffect(() => {
    itemsList.map((item) =>
      filter.forEach((filt) => {
        if (item?.stage?.id === filt) {
          setItemsList((f) => [...f, item]);
        }
      })
    );

    return () => {
      setItemsList(items);
    };
  }, [filter]);

  const MenuFilter = () => (
    <IconButton onClick={() => setDropDownMenu(!dropDownMenu)}>
      <AiOutlineMenu size={19} color="grey" />
    </IconButton>
  );

  const Deselect = () => (
    <IconButton style={{ marginRight: '10px' }} onClick={() => setFilter([])}>
      <RxCross2 size={15} color="grey" />
    </IconButton>
  );

  const handlerOpenModal = (item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const matches = useMediaQuery('(min-height:850px)');

  const handlerResetFilter = () => {
    setViewUnscheduled(false);
    setValue([]);
    setSearch('');
  };
  const [prevScrollPos, setPrevScrollPos] = useState(-1);
  const [boxHeight, setBoxHeight] = useState('calc(100vh - 79px)');
  const scrollThreshold = 126.4000015258789;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = Math.max(window.scrollY, 0);

      if (prevScrollPos > currentScrollPos) {
        setBoxHeight('calc(100vh - 48px)');
      } else if (
        prevScrollPos < currentScrollPos &&
        currentScrollPos >= scrollThreshold
      ) {
        setBoxHeight('calc(100vh - 0px)');
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);
  // ICONOS
  const handlerIconos = (item) => {
    switch (item?.category) {
    case 'Music':
      return <FaMusic size={20} />;
    case 'Art':
      return <FaPalette size={20} />;
    case 'Performance':
      return <FaTheaterMasks size={20} />;
    case 'Kids & Family':
      return <FaChild size={20} />;
    case 'Workshop':
      return <FaChalkboardTeacher size={20} />;
    case 'Yoga & Movement':
      return <FaRunning size={20} />;
    }
  };
  return (
    
    <Box
      sx={{
        position: 'fixed',
        bottom: 1,
        right: 0,
        width: '100%',
        maxWidth: '300px',
        backgroundColor: '#ffffff',
        borderColor: 'black',
        borderWidth: 1.5,
        borderStyle: 'solid',
        padding: 2,
        overflow: 'hidden',
        height: boxHeight,
        zIndex: 999,
          
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        <div style={{ height: '25%' }}>
          <div
            style={{
              width: '100%',
              height: 'auto',
              display: 'flex',
              alignItems:'center',
              marginBottom:'10px',
              maxHeight: '100px'
            }}
          >
            <IconButton
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              onClick={handleClose}
            >
              <RxCross2 color='black'/>
            </IconButton>
            <Typography fontSize={16} fontWeight={700} textTransform={'uppercase'}>
              Unscheduled Events
            </Typography>
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <TextField
              sx={{ width: '100%' }}
              variant="outlined"
              size="small"
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                style: {
                  borderRadius: '10px',
                },
                startAdornment: <IoMdSearch size={24}/>
              }}
            />
            {/* START */}
            {isFetching ? (
              <Skeleton height="50px" width="100%" />
            ) : (
              <Autocomplete
                multiple
                limitTags={2}
                onChange={handleFilter}
                sx={{
                  width:'100%',
                  overflowY: 'hidden',
                  verticalAlign: 'bottom',
                  marginTop: '2%',
                }}
                getOptionLabel={(option) => option.text}
                options={data}
                renderInput={(params) => (
                  <TextField
                    label="Category"
                    variant="outlined"
                    sx={{ ' & .MuiFormLabel-root':{color:'black'} }}
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        overflow: 'auto',
                      },
                      startAdornment: (
                        <div
                          style={{
                            overflow: 'auto',
                          }}
                        >
                          {params.InputProps.startAdornment}
                        </div>
                      ),
                    }}
                    {...params}
                  />
                )}
                renderTags={renderTags}
              />
            )}
          </Box>

          {/* END */}
        
          {dropDownMenu && (
            <Box sx={{height: 'auto'}}>
              <TextField
                variant="outlined"
                style={{ width: '100%' }}
                label="Venue"
                select
                value={filter}
                // multiple
                // renderInput={(props) => <Chip {...props} />}
                InputProps={{
                  endAdornment: filter.length > 0 ? <Deselect /> : null,
                }}
                SelectProps={{
                  onChange: (e) => console.log(e),
                  renderValue: (props) => console.log(props),
                }}
              >
                {groupList?.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    name={item.name}
                    label={item.name}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
        </div>
        <Box
          sx={{
            height: 'auto',
            // paddingBottom: 3,
            overflowY: 'scroll',
            // '&::-webkit-scrollbar': {
            //   display: 'visib e'
            // }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              gap: 2,
              marginTop: 0,
            }}
          >
            {itemsList.length > 0 && !isFetchingUpdatedItems ? itemsList.map((item) => (
              <DroppableItem
                item={item}
                onClick={() => handleClickItem(item)}
                sx={{
                  boxShadow:
                    itemClicked?.id == item.id
                      ? '#d8d8d8 0px 0px 3px 2px inset'
                      : '',
                  overflow: 'hidden',
                  cursor: 'pointer',
                  width: '40%',
                  height: '90px',
                  borderRadius: '6px',
                  padding: 1,
                  backgroundColor: '#676767',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    position: 'relative'
                  }}
                >
                  <div>
                    <Typography color="#fff" variant="body1">
                      {item?.name.length > 22
                        ? item?.name.slice(0, 22) + '...'
                        : item?.name}
                    </Typography>
                    <Typography color="#fff" variant="body1">
                      {item?.category}
                    </Typography>
                    {/* ESTO A CAMBIAR CON LO NUEVO DEL TM CON MUCHOS TALENTOS */}
                    <Typography color="#fff" variant="body2">
                      {item?.talents[0]?.name.length > 22
                        ? item?.talents[0]?.name.slice(0, 22) + '...'
                        : item?.talents[0]?.name}
                    </Typography>
                  </div>
                  <IconButton
                    sx={{ height: '30px', width: '30px', position: 'absolute', top: 0, right: 0, backgroundColor: 'white', padding: 1, borderRadius: '50%'  }}
                    onClick={() => handlerOpenModal(item)}
                  >
                    <BiEdit fontWeight={600} color="#000000" size="12.5px" />
                  </IconButton>
                </div>
              </DroppableItem>
            ))  : isFetchingUpdatedItems ? (
              <>
                <Skeleton height="25px" width={'100%'} />
                <Skeleton height="25px" width={'100%'} />
                <Skeleton height="25px" width={'100%'} />
              </>
            ) : (
              <Box>
                <Typography>No results found.</Typography>
              </Box>
            )}
            {openModal && (
              <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <FormEvents
                    eventFromUnscheduled={selectedItem}
                    noValidate={true}
                    noTooltip={true}
                    from={false}
                    isEdit={true}
                    recordFromModal={itemClicked}
                    closeModalEdit={setOpenModal}
                    resetFilterCategory={handlerResetFilter}
                    idToaction={itemClicked?.id}
                    noBack
                    onSave={() => {refetch(); setOpenModal(false);}}
                  />
                </Box>
              </Modal>
            )}
          </Box>
        </Box>
      </div>
    </Box>
  );
};

// const ItemSecondaryModal = () => {

//   return (
//     <Box onClick={} sx={{ overflow: 'hidden', cursor: 'pointer', width: '172px', height: '90px', borderRadius: '6px', padding: 1, backgroundColor: 'rgb(33, 150, 243)' }}>
//       <Typography color='#fff' variant='body1'>{item.name}</Typography>
//     </Box>
//   )
// }

export default ModalAddSecondaryItems;
