import { useState,useEffect } from 'react';
import axios from 'axios';
// Hooks & Utils
import { COLORS } from '../../../../assets/constants/theme';
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
import {image3} from '../../../../assets/images/Carousel/index.js';
// Components
import { switchStyleIos } from '../../../../Components/StyledComponents/Inputs';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle.jsx';
import InputText from '../../../../Components/InputsForm/InputText.jsx';
// Material & Icons
import {Typography,Box,Switch,IconButton,Collapse,Skeleton} from '@mui/material';
import { IoChevronDownSharp } from 'react-icons/io5';


export const AnnouncementForm = ({info}) => {
  const [openSection,setOpenSection]= useState(true);
  const [showSection, setShowSection] = useState(undefined);
  const customToast = useCustomToasty();
  
  const data = [
    {
      title: '',
      inputs: [
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={COLORS.light.secondary.main}
                >
                  ANNOUNCEMENT BAR
                </Typography>
                <Typography fontWeight={300} fontSize={'14px'}>
                  Show your audience important announcements.
                </Typography>
              </Box>
              <img
                src={image3}
                style={{
                  height: '15em',
                  objectFit: 'contain',
                  marginTop: '20px',
                }}
                alt="image1"
                loading="lazy"
              />
            </div>
          ),
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <>
              <InputTitle
                value={
                  <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                    EXAMPLE
                  </Typography>
                }
              />
              <div style={{padding:'5px',margin:'15px 0px',backgroundColor:'#DED1A1',width:'30%'}}>
                <Typography fontWeight={600} fontSize={14}>TicketSwap will be our official ticket exchange platform next year</Typography>
              </div>
              <div style={{margin:'15px 0px'}}>
                <InputTitle
                  value={
                    <Typography fontWeight={600} fontSize={'16px'} color={'#000'}>
                        ANNOUNCEMENT
                    </Typography>
                  }
                />
              </div>
              <InputText source="home.announcement_bar.text" fullWidth label="" />
            </>
          ),
          breakpoints: { xs: 8 },
        },
      ],
    },
  ];
  const onSubmit = async (data) => {
    const newData ={home:{announcement_bar:{text:data.home.announcement_bar.text}}};
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      },
      data: newData
    })
      .then(res => {
        customToast('success', 'Changes submitted succesfuly.');
      })
      .catch(err => {
        customToast('error', 'There was an error, please try again later.');
      });
  };

  const handleOpenSection = () => {
    setOpenSection(!openSection);
  };

  const activeShow = () => {
    const newData = {...info, home: {...info?.home, announcement_bar: { show: !showSection}}};
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      data: newData,
    })
      .then((res) => {
        customToast('success', 'Changes submitted successfully.');
        setShowSection(!showSection);
      })
      .catch((err) => {
        customToast('error', 'There was an error, please try again later.');
      });
      
  };
  useEffect(()=>{
    let showTrue = info?.home?.announcement_bar?.show;
    setShowSection(showTrue);
  },[info]);
  return(
    <>
      <Box sx={{width:'100%',padding:'20px 16px', height:'5%', backgroundColor:COLORS.forms.header.background,display:'flex',flexDirection:'row',justifyContent:'space-between' }}>
        <Typography fontSize={'24px'} fontWeight={400} color={'#313131'}>Announcement Bar</Typography>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:15}}>
          {showSection === undefined ?<Skeleton variant="circular" width={40} height={40} />:
            <Switch sx={switchStyleIos} onClick={activeShow} checked={showSection}/>}
          <IconButton onClick={handleOpenSection}>
            <IoChevronDownSharp size={24} />
          </IconButton>
        </div>
      </Box>
      <Collapse in={openSection} timeout={500}>
        <AdaptableFormRedesign simpleform={{record: info, onSubmit: onSubmit}} data={data} isEdit floating from={false} icon={true}/>
      </Collapse>
    </>
  );
};