import React, { useState,useEffect } from 'react';
import { Box, Button, FormHelperText, FormLabel,Input } from '@mui/material';
import { useInput } from 'react-admin';
import useUploadAws from '../../../Hooks/useUploadAws';
import { InputTitle } from '../../StyledComponents/InputTitle';
import { BiImageAdd } from 'react-icons/bi';
import { COLORS } from '../../../assets/constants/theme';
import { useFormContext } from 'react-hook-form';
import { BiTrashAlt } from 'react-icons/bi';
import { InputSubtitle } from '../../StyledComponents/InputSubtitle';
import { useCustomToasty } from '../../../Hooks/useCustomToasty';
const InputRedesignImageAws = ({helperText, validate,type, title,subtitle,width,noOption, tooltip ,widthImage,heightImage,info,source = 'picture' ,sx}) => {
  const { id, field, fieldState } = useInput({ source, validate });
  const [image, setImage] = useState();
  const { uploadAWS } = useUploadAws();
  const {getValues} = useFormContext();
  const customToasty = useCustomToasty();
  const handleClick = async (e) => {
    const isSvg = e?.target?.value?.includes('.svg');
    if(isSvg){
      customToasty('error', 'SVG images are not supported at the moment');
    }else{
      const fileUpload = await uploadAWS(e);
      if (fileUpload.success) {
        if(noOption){
          setImage(fileUpload);
          field.onChange(fileUpload.location);
        }else{
          setImage(fileUpload);
          field.onChange(fileUpload.location);
        }
      }
    }
    
  };
  const onDelete = () => {
    setImage(undefined);
    if (source === 'picture.original') {
      field.onChange(null);
    } else {
      field.onChange(null);
    }
  };
  useEffect(() => {
    let actualImages = getValues(source);
    if(actualImages === null){
      setImage(undefined);
      return;
    }
    setImage({location:actualImages});
  },[info]);
  
  return (
    <Box sx={{ display: 'flex', alignItems: 'start', gap: 2, marginBottom: 3 , }}>
      <Box sx={{height:'200px',width:width ? width :'300px',...sx}}>
        {title && <InputTitle tooltip={tooltip}>{title}</InputTitle>}
        {subtitle && 
        <InputSubtitle >
          {subtitle}
        </InputSubtitle>}
        {image?.location === undefined || image?.location === null  || image?.location === '' ? 
          <Button sx={{
            marginTop:heightImage ? '0px' :'15px',
            color: fieldState.invalid && 'red',
            borderColor: fieldState.invalid && 'red',
            backgroundColor:COLORS.button.background,
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            borderRadius:'10px',
            border:'0px',
            height:heightImage ? heightImage:'9em',
            width:widthImage? widthImage:'10em'
          }} variant='outlined' component="label">
            <BiImageAdd size={30}/>
            {type === 'icon' ? <input hidden  accept="image/jpg, image/jpeg, image/png" type="file" onChange={handleClick}/> :
              <input hidden accept="image/jpg, image/jpeg, image/png" type="file" onChange={handleClick} />}
          </Button> :   
          <Box sx={{
            width: widthImage ? widthImage: '10em',
            height: heightImage ? heightImage:'9em',
            display: 'flex',
            flexDirection: 'row',
            position:'relative',
          }}>
            <img
              src={Array.isArray(image?.location) ? image?.location[0] : image?.location}
              alt={'asd'}
              loading="lazy"
              style={{ width: '100%', height: '100%', objectFit: type === 'icon' ? 'contain' : 'cover' }}
            />
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                padding: '10px',
                display: 'flex',
                textAlign: 'center',
                margin:'10px',
                alignItems: 'center',
                width: '40px',
                height: '40px',
                cursor: 'pointer',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                color: 'black',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  color: '#fff'
                }
              }}
              onClick={onDelete}
            >
              <BiTrashAlt
                size={'20px'}
              />
            </Box>
          </Box>
        }
        {helperText && <FormLabel sx={{marginLeft: '10px'}}>{helperText}</FormLabel>}
        {fieldState.invalid && <FormHelperText sx={{color:'red'}}>The Image is required</FormHelperText>}
      </Box>
    </Box>
  );
};
export default InputRedesignImageAws;