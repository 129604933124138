import { useState,useRef} from 'react';
import { useDataProvider, useGetList, usePermissions, List, Datagrid,TextField, FunctionField, useRefresh, useStore,useResourceContext,useListContext,useRedirect } from 'react-admin';
import { useParams } from 'react-router-dom';
// Hooks & Utils
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { getToken } from '../../helpers/getToken';
import { COLORS } from '../../assets/constants/theme';
import { getDataForCreate, getDataForEditDelete } from '../../assets/constants/dataFields';
import { roleConvert } from '../../helpers/roleConvert';
import { responsiveFilter } from '../../Hooks/useMediaQuery';
// Components
import AdaptableFormForModal from '../../Components/AdaptableFormForModal/AdaptableFormForModal';
import ContactInformationModal from './ContactInformationModal';
import DeleteModal from '../../Components/DeleteModal/DeleteModal';
import { CsvDownloader } from '../../Components/ReportButtons/CsvDownloader';
// Materials & Icons
import { Box, Button, Typography,IconButton, Alert, Popover,Autocomplete,TextField as TextFieldMui } from '@mui/material';
import { HiDotsVertical } from 'react-icons/hi';
import { BiEdit } from 'react-icons/bi';

export const ContactInformation = () => {
  const params = useParams();
  const talent_id = params['*']?.split('/')[1] ?? params?.id;
  const permisos = usePermissions();
  const [selectedTalent] = useStore('selectedTalent', 'selectedTalent', { selected: false, talent: '' });
  const newtalent_id = selectedTalent?.talent;
  const [create, setCreate] = useState(false);
  // Open y set para POPOVER de ... 
  const [openModal, setOpenModal] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [filter ,setFilter]= useState({
    talent_id: permisos.permissions !== 'talent_manager' ? talent_id : newtalent_id,

  });
  const email = getToken()?.email;
  const { data } = useGetList('admins', { filter: { ...filter } });
  const [errors, setErrors] = useState({});

  const handlerModal = (manager) => {
    setSelectedManager(manager);
    setOpenModal(!openModal);
    setCreate(false);
  };
  const handlerModalTocreate = () => {
    setSelectedManager(null);
    setOpenModal(!openModal);
    setCreate(true);
  };
  return (
    <>
      {permisos.permissions === 'talent_manager' ? (
        <>
          <div style={{ marginTop: '20px' }}>
            <Typography
              variant="h5"
              gutterBottom
              fontWeight={400}
              alignSelf={'flex-start'}
            >
              📧 Contact Information
            </Typography>
            <Typography
              variant="subtitle1"
              fontWeight={400}
              sx={{ pb: 2 }}
              alignSelf={'flex-start'}
              gutterBottom
            >
              Use this section to update contact details and add team members to your team
            </Typography>
            <Alert severity="info" >
              <Typography>
                <b>REMEMBER! </b>Each user you create will have the ability to modify and submit your profile and events information.
              </Typography>
            </Alert>
          </div>

          <div style={{ marginTop: 20 }} >
            {openModal && selectedManager ? (
              <ContactInformationModal
                open={openModal}
                setOpen={setOpenModal}
                setErrors={setErrors}
                id={selectedManager.id}
                talentid={talent_id}
                index={selectedManager.index}
                email={email}
                errors={errors}
                manager={selectedManager}
              />
            ) : (
              <ContactInformationModal open={openModal} setOpen={setOpenModal} create={create} talentid={talent_id}
              />
            )}
            <List actions={<ActionsAdmins handlerModalTocreate={handlerModalTocreate} talentId={talent_id} setFilter={setFilter}/>} pagination={false} filter={filter} resource='admins' setFilter={setFilter} 
            >
              <Datagrid
                empty={<Typography>No submissions</Typography>}
                sx={{
                  '& .RaDatagrid-row': {
                    justifyContent: 'space-between',
                  },
                  '& .MuiTableCell-body': {
                    borderBottom:'0px'
                  },
                  '& .RaDatagrid-headerCell': {
                    color: '#7C7C7C',
                    fontWeight: 400
                  },
                  '& .MuiTypography-root':{
                    color:'#000000',
                    fontWeight:400
                  },
                  '& .MuiTableSortLabel-root':{
                    fontSize:'16px',
                    color:COLORS.table.headerSorteable,
                    fontWeight:400
                  },
                  '& .column-name': {
                    width: '30%',
                  },
                  '& .column-email': {
                    width: '30%'
                  },
                  '& .column-role': {
                    width: '30%'
                  }
                }}
                data={data}
                bulkActionButtons={false}
              >
                <FunctionField source="name" label='Member' render={(record, index) => <Typography color={'#000000'} fontWeight={600} fontSize={'0.875rem'}>{record?.name}</Typography>}/>
                <TextField source="email" />
                <FunctionField source="manager_role" label='Role' render={(record, index) => <Typography color={'#000000'} fontWeight={600} fontSize={'0.875rem'}>{record?.manager_role}</Typography>}/>
                <FunctionField label="" render={(record, index) => <AdminActions setOpen={setOpenModal} record={record} openEditModal={handlerModal}/>} />
              </Datagrid>
            </List>
            {/* <Box sx={{ width: '50%' }}>
              <Button sx={{ marginTop: 5, backgroundColor: COLORS.light.primary.main, color: '#fff', '&:hover': { backgroundColor: COLORS.light.primary.main, color: '#fff' } }} variant="filled" startIcon={<FaPlus size="15px" />} onClick={handlerModalTocreate}>ADD ANOTHER CONTACT</Button>
            </Box> */}
          </div>
        </>

      ) : (
        <>
          {openModal && selectedManager ? (
            <ContactInformationModal
              open={openModal}
              setOpen={setOpenModal}
              setErrors={setErrors}
              id={selectedManager.id}
              index={selectedManager.index}
              email={email}
              errors={errors}
              manager={selectedManager}
              talentid={talent_id}
            />
          ) : (
            <ContactInformationModal open={openModal} setOpen={setOpenModal} create={create} talentid={talent_id} />
          )}
          <List actions={<ActionsAdmins handlerModalTocreate={handlerModalTocreate} talentId={talent_id} setFilter={setFilter}/>} pagination={false} filter={filter} resource='admins' setFilter={setFilter} empty={false} >
            <Datagrid
              empty={<EmptyContactList/>}
              sx={{
                '& .RaDatagrid-row': {
                  justifyContent: 'space-between',
                },
                '& .MuiTableCell-body': {
                  borderBottom:'0px'
                },
                '& .RaDatagrid-headerCell': {
                  color: '#7C7C7C',
                  fontWeight: 400
                },
                '& .MuiTypography-root':{
                  color:'#000000',
                  fontWeight:400
                },
                '& .MuiTableSortLabel-root':{
                  fontSize:'16px',
                  color:COLORS.table.headerSorteable,
                  fontWeight:400
                },
                '& .column-name': {
                  width: '30%',
                },
                '& .column-email': {
                  width: '30%'
                },
                '& .column-role': {
                  width: '30%'
                }
              }}
              data={data}
              bulkActionButtons={false}
            >
              <FunctionField source="name" label='Member' render={(record, index) => <Typography color={'#000000'} fontWeight={600} fontSize={'0.875rem'}>{record?.name}</Typography>}/>
              <TextField source="email" />
              <FunctionField source="manager_role" label='Role' render={(record, index) => <Typography color={'#000000'} fontWeight={600} fontSize={'0.875rem'}>{record?.manager_role && record?.manager_role[0]}</Typography>}/>
              <FunctionField label="" render={(record, index) => <AdminActions setOpen={setOpenModal} record={record} openEditModal={handlerModal}/>} />
            </Datagrid>
          </List>
        </>
      )}
    </>

  );
};
export const ActionsAdmins = ({handlerModalTocreate,talentId,setFilter})=>{
  const { filterValues, setFilters } = useListContext();
  return(
    <div style={{display:'flex',flexDirection:'column',gap:20,height:'100%',marginTop:'2em',width:'100%'}}>
      <Box sx={{display:'flex',flexDirection:'column'}}>
        <Box sx={{width: '100%',display:'flex',flexDirection: 'row', justifyContent: 'flex-end',gap:'20px',paddingX:'20px',height:'53px'}}>
          <CsvDownloader minWidth={'(min-width:1060px)'} resource={'admins'} filterValues={filterValues} talent={talentId}/>
          <Button onClick={handlerModalTocreate}
            sx={{
              backgroundColor:COLORS.light.secondary.main,
              fontSize: '.8em', 
              fontWeight: '700',
              height: '100%',
              minWidth: '135px',
              borderRadius:'10px',
              color:'white',
              '&:hover':{
                backgroundColor:COLORS.light.secondary.main
              }
            }} variant="outlined">+ Add</Button>  
        </Box>
        <Box sx={{width: '100%',display:'flex',flexDirection: 'row', justifyContent: 'flex-end'}}>
          <RoleFilter setFilter={setFilter} />
        </Box>
      </Box>
    </div>
  );
};
export const RoleFilter = ({setFilter,resource= 'manager_roles',capitalize})=>{
  const { filterValues, setFilters } = useListContext();
  const [value, setValue] = useState('');
  const { data, isFetching } = useGetList(`admins/${resource}`, { pagination: {perPage:9999, page: 1}, sort: { field: 'rank', order: 'DESC'}});
  const responsive = responsiveFilter();
  const handleFilter = (e,v) => {
    if(resource === 'manager_roles'){
      setFilters({...filterValues, 'manager_role': v?.text});
      setFilter({...filterValues, 'manager_role': v?.text});
      setValue(e?.target?.value);
    }else{
      setFilters({...filterValues, 'role': v?.text});
      // setFilter({...filterValues, 'manager_role': v?.text});
      setValue(e?.target?.value);
    }
    
  };
  return(
    <div style={{width:'100%'}}>
      <Autocomplete 
        fullWidth  
        limitTags={2}
        onChange={handleFilter} 
        sx={{
          marginBottom: '0px',
          height: '50%',
          '& .MuiInputBase-sizeSmall':{
            borderRadius:'50px',
            color:'black',
          },
          '& .MuiInputLabel-root':{
            color:'black',
            fontWeight:400
          },
        }}
        getOptionLabel={(option) => capitalize ? roleConvert(option?.text) : option?.text} 
        options={!data ? [{label:'Loading...', id:0}] : data }
        renderInput={(params) => 
          <TextFieldMui 
            label="Role" 
            variant="outlined" 
            sx={{
              width:responsive,
              height: '100%',
              zIndex: 9999,
              color:'black',
            // margin: '0px',
            }}
            InputProps={{
              ...params.InputProps,
              sx: {
                overflow: 'auto',
                margin: '0px',
              },
              startAdornment: (
                <div style={{
                  overflow: 'auto',
                  margin: '0px',

                }}
                >{params.InputProps.startAdornment}</div>
              ),
            }}
            {...params} 

          />
        }/>
    </div>
   
  );
};

export const SimpleManagerForm = ({ email, index, manager, modal, setOpen, create }) => {
  const [selectedTalent] = useStore('selectedTalent', 'selectedTalent', { selected: false, talent: '' });

  const refresh = useRefresh();
  const permis = usePermissions()?.permissions;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const params = useParams();
  const talent_id = params['*']?.split('/')[1] ?? params?.id;
  const dataProvider = useDataProvider();
  const customToast = useCustomToasty();
  const iam = getToken(localStorage.getItem('auth'));

  const handleSubmitForModal = async (newData) => {
    const newnewData = {
      ...newData,
      manager_role: [newData?.manager_role]
    };
    try {
      let request = await dataProvider.update('admins', { id: manager.id, data: newnewData });
      if (request.data) {
        customToast('success', 'Admin updated succesfuly');
        setOpen(false);
        refresh();
      }
    } catch (error) {
      customToast('error', 'There was an error');

      throw error;
    }
  };
  const handleSubmiteCreateContact = async (newData) => {
    const newnewData = {
      name: newData?.name,
      email: newData?.email,
      phone: newData?.phone,
      role: 'talent_manager',
      talent_id: [permis === 'talent_manager' ? selectedTalent?.talent:talent_id],
      manager_role: [newData?.manager_role]
    };
    console.log(newnewData);
    try {
      let request = await dataProvider.create('admins', { data: newnewData });
      if (request.data) {
        customToast('success', 'Admin updated succesfuly');
        setOpen(false);
        refresh();
      }
    } catch (error) {
      customToast('error', 'There was an error');

      throw error;
    }
  };
  const thisManagerName = permis !== 'talent_manager' ? false : (email === manager?.email ? true : false);
  const datas = getDataForEditDelete(thisManagerName, manager, emailRegex);
  const dataCreate = getDataForCreate(manager, emailRegex);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      {modal && create ? (
        <AdaptableFormForModal modal={modal} create={create} data={dataCreate} simpleform={{ onSubmit: handleSubmiteCreateContact }} message={false}/>
      ) : (
        modal ? (
          <AdaptableFormForModal index={index} simpleform={{ record: manager, onSubmit: handleSubmitForModal }} data={datas} modal={modal} setOpen={setOpen} iam={iam?.id} message={false}/>
        ) : null
      )}

    </Box>
  );


};
export const SideActions = ({ openModal, setOpenModal }) => {

  return (
    <IconButton onClick={() => setOpenModal(true)}>
      <BiEdit size="25px" style={{ color: 'rgba(0,0,0, 0.4)' }} />
    </IconButton>
  );
};
export const AdminActions = ({ record,openEditModal,setOpen,redirectEdit }) => {
  const dataProvider = useDataProvider();
  const [openPopover, setOpenPopover] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const ref = useRef(null);
  const customToast = useCustomToasty();
  const refresh = useRefresh();
  const resource = useResourceContext();  
  const redirect = useRedirect();

  const confirmDelete = async (iddelete) => {
    try {
      let request = await dataProvider.delete(resource, { id: iddelete });
      console.log(request);
      if (request) {
        customToast('success', 'Deleted succesfuly');
        setOpen(false);
        refresh();
      }
    } catch (error) {
      customToast('error', 'There was an error');

      throw error;
    }
  };
  const handleRedirect = () => {
    redirect(`/admins/${record?.id}`);
  };
  return (
    <div key={record.id}>
      <IconButton ref={ref} onClick={() => setOpenPopover(true)}>
        <HiDotsVertical />
      </IconButton>
      <Popover
        open={openPopover}
        anchorEl={ref.current}
        onClose={() => setOpenPopover(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            width: '10em',
          },
          '& .MuiPopover-paper': {
            borderRadius: '10px',
          },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column',alignItems:'flex-start', backgroundColor: '#ECECEC', borderRadius: '10px' }}>
          {openEditModal && <Button sx={{ fontSize: '0.8em',color:'black', justifyContent: 'flex-start',padding:'10px',width:'100%'}} onClick={() => openEditModal(record)} >
            Edit
          </Button>}
          {redirectEdit && <Button sx={{ fontSize: '0.8em',color:'black', justifyContent: 'flex-start',padding:'10px',width:'100%'}} onClick={handleRedirect} >
            Edit
          </Button>}
          <Button sx={{ fontSize: '0.8em',color:COLORS.table.indicators.fontColor.rejected, justifyContent: 'flex-start',padding:'10px',width:'100%'}} onClick={() => setOpenDeleteModal(true)}>
            Remove Member
          </Button>
          {openDeleteModal && (
            <DeleteModal setOpenModal={setOpenDeleteModal} openModal={open} idToAction={record?.id} confirmDelete={confirmDelete} textAction={'Member'}/>
          )}
        </div>
      </Popover>
    </div>
  );
};
export const EmptyContactList= ()=>{
  return (
    <div style={{padding:'10px', display: 'flex',flexDirection:'column', justifyContent: 'center', alignItems: 'center', width: '100%',backgroundColor:'transparent' }}>
      <Typography variant='h6' fontWeight={400}>No contacts associated with this talent</Typography>
      {/* <Typography variant='h6' fontWeight={400}>You can create one here <MdArrowOutward/></Typography> */}
    </div>
  );
};