import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, MenuItem, Modal, Select, Skeleton, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { mainColor } from '../../Theme';
import { useGetList } from 'react-admin';
import { InputTitle } from '../StyledComponents/InputTitle';
import AllAutocomplete from './AllAutocomplete';
import ModalReportComponent from './ModalReportComponent';

export const uppercaseString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase().replace(/_/g, ' ');
};
export const ModalReporte =({handleValidation, handleDownload, resource, defaultFilter = {}, open, setOpen}) => {
  const [filter, setFilter] = useState(defaultFilter);
  const [disable, setDisable] = useState(null);
  const [values, setValues] = useState([]);
  const ref = useRef();
  
  const { data, isLoading } = useGetList('talents', {filter: {}, pagination: {perPage:9999, page: 1}});
  const { data: dataForms, isLoading: isLoadingForms } = useGetList('talents/forms', {filter: {}});

  // Callbacks para setear el filtro a mandar, mas los valores seleccionados del input.
  // Lógica para borraddo de filtros en caso de seleccionar all e inhabilitado de opciones.
  const selection = (arr, field, value) => {
    if(arr?.find(item => item.id === '')){
      setDisable({...disable, [field]: true});
      return null;
    } else {
      setDisable({...disable, [field]: false});
      return arr?.map(item => value ? item[value] : item.id);
    }
  };
  const handleArrFilter = (v, field, value) => {
    let newFilter = {
      ...filter, 
      [field]: selection(v,field, value)
    };
    setFilter(newFilter);
  };
  const selectedHandler = (e, field) => {
    if(Array.isArray(e)){
      if(e?.find(value => value?.id === '' || value === '')) {
        return setValues({...values, [field]: [e?.find(value => value?.id === '')]});
      }
      return setValues({...values, [field]: e});
    }
    if(e.id === '') return setValues({...values, [field]: e});
  }; 

  const setValuesHandler = (e,v, field, value) => {selectedHandler(v, field); handleArrFilter(v, field, value);};

  //Descarga de csv
  const downloadCsv = () => {
    let csvFilter = `?filter=${JSON.stringify(filter)}`;
    return handleDownload(csvFilter);
  };


  //Custom para el csv de talentos, modificar lógica para que sea mas escalable
  useEffect(() => {
    if(Object.keys(defaultFilter)?.length > 0){
      setValues(Object.fromEntries(Object.keys(defaultFilter)?.map((prop, index) => {
        if(Object.values(defaultFilter)?.[index] === null) {
          if(prop === 'form') return [prop, [{id: '', text: 'All forms'}]];
          return [prop, [{id: '', name: 'All talents'}]];
        }
      })
      ));
    }
  },[defaultFilter]);

  const dataArr = [
    {
      input: <AllAutocomplete 
        title="Talents" 
        allTitle={{id: '', name: 'All Talents'}} 
        arr={data} 
        onChange={(e,v) => setValuesHandler(e,v, 'ids')} 
        value={values?.['ids']} 
        disableOptions={disable?.['ids']} 
      />,
      isLoading: isLoading,
      xs: 12
    },
    {
      input: <AllAutocomplete 
        title="Forms" 
        arr={dataForms} 
        onChange={(e,v) => setValuesHandler(e,v, 'form', 'text')} 
        value={values?.['form']} 
        disableOptions={disable?.['form']} 
        allTitle={{id: '', text: 'All Forms'}} 
        getOptionLabel={(option) => uppercaseString(option?.text)} 
      />,
      isLoading: isLoadingForms,
      xs: 12
    }
  ];

  return <ModalReportComponent onSubmit={downloadCsv} setOpen={setOpen} data={dataArr} open={open} ref={ref} />;
};