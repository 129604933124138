import { useForm, useFormContext } from 'react-hook-form';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import axios from 'axios';
import { apiUrl } from '../../dataProvider';
import { DeleteButton, SaveButton, Toolbar, usePermissions, useRecordContext, useRedirect, useResourceContext, useSidebarState } from 'react-admin';
import { Box, Button, CircularProgress, Tooltip } from '@mui/material';
import { COLORS } from '../../assets/constants/theme';
import { useState } from 'react';
import { IoMdSave } from 'react-icons/io';
import { FaSave } from 'react-icons/fa';


export const TalentsFormToolbar = (props) => {
  const {
    floating, 
    draft,
    noTooltip, 
    submitTalent,
    editSub,
    isEdit
  } = props;
  const record = useRecordContext();
  const { permissions: permis } = usePermissions();
  const resource = useResourceContext();
  const redirect = useRedirect();
  const methods = useFormContext();
  const customToast = useCustomToasty();
  const [isLoading, setIsLoading] = useState(false);
  const onError = ({ ...rest }) => {
    customToast('error',rest?.body?.detail);
    setIsLoading(false);
    
  };

  const validationErrors = (err) => {
    if(!isEdit){
      customToast('error', 'Some fields are missing, please check the form.');
      setIsLoading(false);
      return;
    }
    customToast('error', 'If you want to publish this talent you need to complete all required fields, otherwise you must unpublish to save uncompleted fields.');
    setIsLoading(false);
    return;
  };

  const handleData = async () => {
    setIsLoading(true);
    if(!isEdit) return await methods.handleSubmit(submitTalent, validationErrors)();
    let draftData = methods.getValues();
    if(draftData.name === '' || !draftData.name ) {
      setIsLoading(false);
      customToast('error', `The ${draft} name is required.`);
      return;
    }

    /* 
    Este submit es necesario para cuando un TB quiere realizar un save borrando campos pero sin publicarlo. Es por
    eso que es necesario que draft sea false y de esa manera tb puede guardar un talento borrandole campos required

    En caso de que un TB este aprobando un talento es necesario realizar el submit correspondiente y que se revisen todos 
    los campos ya que este estaria aprobando un talento
    */

    if(permis !== 'talent_manager' &&
     draftData?.['_draft'] === false) {
      try {
        let submitRes = await methods.handleSubmit(submitTalent,validationErrors)();
        if(submitRes){
          setIsLoading(false);
          return submitRes;
        }
        setIsLoading(false);
        return submitRes;
      } catch (error) {
        customToast('error', 'There was an error, please try again later.');
        setIsLoading(false);
        return;
      }
    
    }
    try {
      methods.clearErrors();
      const response = await axios({
        url: `${apiUrl}/${draft}/${draftData.id}`,
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Expose-Headers': 'content-range',
          'Authorization': 'Bearer ' + localStorage.getItem('auth'),
          'x-api-key': process.env.REACT_APP_API_KEY ,
        },
        data: 
          permis === 'talent_manager' ?
            {...draftData, sketch: true} :
            (permis !== 'talent_manager') && editSub ?
              {...draftData, approved: true} :
              {...draftData}
      });
        
      if(response.data){
        setIsLoading(false);
        redirect('list', resource);
        customToast('success', 'Changes were saved.');
      }
    } catch (error) {
      setIsLoading(false);

      customToast('error', 'There was an error, please try again later.');
      // throw new Error(error);
    }
  };

  const [openSideBar, setOpenSideBar] = useSidebarState();

  const styledFloating = floating ? {
    position:'fixed',
    bottom: 0,
    right: 0,
    width: `calc(100% - ${openSideBar ? '248px' : '58px'})`,
    transition: 'width 500ms',
  } : {};
  
  return (
    <Box 
      sx={{
        position: 'relative',
        width: '100%'
      }}
    >
      <Toolbar 
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: '20px',
          ...styledFloating,
        }}>
        <Box sx={{display: 'flex', flexDirection:'row', gap: 5, }}>
          {
            permis === 'talent_manager' && (
              <Tooltip slotProps={{tooltip: { sx: {fontSize: '.9em'}}}} placement="top" title={noTooltip ? '' : 'This information will be reviewed by the staff. You will be notified when this process is done or if any change is needed.'}>
                <div>
                  <Button variant="contained" startIcon={methods.formState.isSubmitting ? <CircularProgress size="15px" /> : <FaSave size="15px" />} onClick={methods.handleSubmit(submitTalent, () => customToast('error', ))} {...props} disabled={methods.formState.isSubmitting} label={noTooltip ? 'Save' : 'Submit'} >{noTooltip ? 'Save' : 'Submit'}</Button>
                </div>
              </Tooltip>
            )
          }
          {
            draft && ((permis === 'talent_manager' && !record?.submission || record?.submission?.status === 'draft') || permis !== 'talent_manager') && (
              <Tooltip slotProps={{tooltip: { sx: {fontSize: '.9em'}}}} placement="top" title={permis === 'talent_manager' ? 
                'Save the current changes in case you are you lack required information to submit the document.' : ''}>
                <div>
                  <Button 
                    startIcon={isLoading ? <CircularProgress size={20} sx={{height: '5px'}} /> : <IoMdSave />}
                    disabled={isLoading}
                    sx={{
                    //   backgroundColor: COLORS.light.primary.main,
                      color: '#fff',
                      '&:hover': {
                        backgroundColor: COLORS.light.primary.main,
                        color: '#fff'
                      }
                    }}
                    variant="contained"
                    onClick={handleData}>Save</Button>
                </div>
              </Tooltip>
            )
          }
        </Box>
        <Box>
          {permis !== 'talent_manager' && <DeleteButton 
            confirmContent='Are you sure you want to delete this talent? All events linked with this talent will be deleted.'
            confirmTitle='Delete'
            mutationOptions={{onError}}
            variant="filled"
          />}
        </Box>
      </Toolbar>
    </Box>
  );
};