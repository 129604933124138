import React from 'react';
import { CreateButton,SaveButton } from 'react-admin';
import { COLORS } from '../../assets/constants/theme';

const ActionButtons = ({action,label,onClick,icon,mutationOptions,alwaysEnable,sx,...rest}) => {
  if(action === 'create'){
    return <CreateButton  
      variant="contained" 
      sx={{backgroundColor:COLORS.light.secondary.main,
        textTransform: 'none',
        fontSize: '16px', 
        fontWeight: '500',
        height: '44px',
        minWidth: '135px',
        borderRadius:'10px',
        color:'white',
        '&:hover':{
          backgroundColor:COLORS.light.secondary.main
        }, ...sx}} 
      label={label} 
      icon={icon} />
  }
  if(action === 'save'){
    return <SaveButton sx={{paddingX: '15px',paddingY: '10px', borderRadius: '10px',...sx}} label={label} onClick={onClick} icon={null}  alwaysEnable={alwaysEnable} {...rest}/>;
  }
};

export default ActionButtons;