import { Box,Typography,LinearProgress } from '@mui/material';
import React from 'react';
import { Edit, usePermissions, useRecordContext, useResourceContext } from 'react-admin';
import { ListTabs } from '../../Resources/Talents/Form/TalentsListTabs/ListTabs';
import { CgProfile } from 'react-icons/cg';
import { BsCalendarEvent, BsInfoCircle } from 'react-icons/bs';
import { IoMdMegaphone, IoIosSchool } from 'react-icons/io';
import { FaHandHoldingHeart } from 'react-icons/fa';
import { CiCircleList } from 'react-icons/ci';
import { LastSubmitter } from '../../Resources/Talents/Form/TalentsShowComponents/LastSubmitter';
import { NavbarTalentEdit } from '../RedesignComponents/NavbarTalentEdit';
const EditFormCustom = ({handleForm, children, ...rest }) => {
  const resource = useResourceContext();  
  const { permissions: permis } = usePermissions();

  const tabs = [
    {
      title: 'Profile',
      redirectUrl: 'profile',
      icon: CgProfile,
    },
    {
      title: 'Events',
      redirectUrl: 'event',
      icon: BsCalendarEvent,
    },
    {
      title: 'Contact Information',
      redirectUrl: 'info',
      icon: BsInfoCircle,
    },
    {
      title: 'Marketing & Promo Opportunities',
      redirectUrl: 'marketing',
      icon: IoMdMegaphone,
    },
    
    // {
    //   title: 'AR & Hospitality',
    //   redirectUrl: 'hospitality',
    //   icon: FaHandHoldingHeart,
    // }
  ];

  const superadminTabs = [

    {
      title: 'Online Revenue Opportunities',
      redirectUrl: 'education',
      icon: IoIosSchool,
    }
  ];

  return (
    <Edit actions={false} title={<TitleEdit />} redirect='list' mutationMode={'pessimistic'} {...rest} sx={{'& .RaEdit-noActions':{
      margin:0
    }}}>
      {
        resource === 'talents' ? (
          <Box style={{elevation: 0,boxShadow:'none',padding:'0px 16px',marginTop:20}}>
            {permis !== 'talent_manager' && (
              // <Box fullWidth mt="5" sx={{display: 'flex', justifyContent: 'flex-end',elevation: 0,boxShadow:'none'}} >
              <div style={{display:'flex', flexDirection: 'row'}}>
                <NavbarTalentEdit/>
                {/* <LastSubmitter 
                
                sx={{
                  position: 'relative',
                  top: 0,
                  left: 0,
                  boxShadow: '',
                  backgroundColor: '',
                }}/> */}
              </div>
            )}
            <ListTabs 
              defaultTab={'profile'} 
              handleForm={handleForm} 
              tabs={
                permis === 'superadmin' ? tabs.concat(superadminTabs) : tabs
              } />
            {children}
          </Box>
        ) : (
          <>
            {children}
          </>
        )
      }
    </Edit>
  );
};

const TitleEdit = () => {
  const record = useRecordContext();
  const title = record?.title?.length > 0 ? record.title : record?.name?.length > 0 ? record.name : '';
  return <span>{`Edit "${title}"`}</span>;
};

export default EditFormCustom;