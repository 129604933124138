import { useState, useEffect } from 'react';
import { useRefresh } from 'react-admin';
import axios from 'axios';
// Hooks & Utils
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
// Components
import InputGalleryAws from '../../../../Components/RedesignComponents/Inputs/InputGalleryAws.jsx';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle.jsx';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import { switchStyleIos } from '../../../../Components/StyledComponents/Inputs';
import InputText from '../../../../Components/InputsForm/InputText.jsx';
import InputRedesignImageAws from '../../../../Components/RedesignComponents/Inputs/InputRedesignImageAws.jsx';
import DeleteSectionHC from '../../../../Components/RedesignComponents/Inputs/DeleteSectionHC.jsx';
// Material & Icons
import {
  Typography, Box, Switch, IconButton, Collapse, Skeleton, Button,
} from '@mui/material';
import { IoChevronDownSharp } from 'react-icons/io5';
import { image1 } from '../../../../assets/images/Carousel/index.js';
import { COLORS } from '../../../../assets/constants/theme';
import carouselSimple from '../../../../assets/images/envisionCarousel.png';

export const CarouselForm = ({ info,setInfo }) => {
  const [openSection, setOpenSection] = useState(true);
  const [showSection, setShowSection] = useState(undefined);
  const [loading , setLoading] = useState(false);
  const [formCount, setFormCount]= useState([1]);
  const customToast = useCustomToasty();
  const refresh = useRefresh();

  const handlerForm = ()=>{
    setFormCount([...formCount, 1]);
  };
  const data = [
    {
      title: '',
      inputs: [
        {
          field: (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
              }}
            >
              <Box>
                <Typography
                  fontWeight={700}
                  fontSize={'16px'}
                  color={COLORS.light.secondary.main}
                >
                  CAROUSEL BANNER
                </Typography>
                <Typography fontWeight={300} fontSize={'14px'}>
                  Choose banners to display on your home carousel.
                </Typography>
              </Box>
              <img
                src={image1}
                style={{
                  height: '290px',
                  objectFit: 'contain',
                  marginTop: '20px',
                }}
                alt="image1"
                loading="lazy"
              />
            </div>
          ),
          breakpoints: { xs: 3 },
        },
        {
          field: (
            <>
              <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                <div>
                  <InputTitle
                    value={
                      <Typography
                        fontWeight={600}
                        fontSize={'16px'}
                        color={'#000'}
                      >
                      SAMPLE IMAGES
                      </Typography>
                    }
                  />
                  <div
                    style={{
                      padding: '5px',
                      margin: '15px 0px',
                      width: '40%',
                      display: 'flex',
                      flexDirection: 'row',
                      gap: 2,
                      height:'90px'
                    }}
                  >
                    <img src={carouselSimple} alt="carouselExample" />
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                {formCount.map((item,index)=> {
                  return(
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }} key={index}>
                      <div>
                        <InputTitle
                          value={
                            <Typography
                              fontWeight={600}
                              fontSize={'16px'}
                              color={'#000'}
                            >
                      IMAGE
                            </Typography>
                          }
                        />
                        <InputRedesignImageAws
                          info={info}
                          sx={{ marginTop: '10px',height:'auto'}}
                          noOption={true}
                          source={`home.carousel.items[${index}].picture.original`}
                          label=""
                        />
                      </div>
                      <div>
                        <InputText
                          source={`home.carousel.items[${index}].url`}
                          label=""
                          title={
                            <Typography
                              fontWeight={600}
                              fontSize={'16px'}
                              color={'#000'}
                            >
                      LINK
                            </Typography>
                          }
                        />
                      </div>
                      <div style={{marginTop:'34px'}}>
                        <DeleteSectionHC index={index} formCount={formCount} setFormCount={setFormCount} info={info} setInfo={setInfo} source={'carousel'}/>
                      </div>
                    </div>
                  )})}
                <div>
                  <Button variant="text" onClick={handlerForm}>
                    <span
                      style={{
                        fontWeight: 600,
                        color: COLORS.light.secondary.main,
                      }}
                    >
                  + Add Image
                    </span>
                  </Button>
                </div>
              </div>
            </>
          ),
          breakpoints: { xs: 8 },
        },
      ],
    },
  ];
  const onSubmit = async (data) => {
    const newData ={home:{carousel:{items:[...data.home.carousel.items]}}};
  
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      data: newData,
    })
      .then((res) => {
        customToast('success', 'Changes submitted succesfuly.');
      })
      .catch((err) => {
        customToast('error', 'There was an error, please try again later.');
      });
  };

  const handleOpenSection = () => {
    setOpenSection(!openSection);
  };

  const activeShow = () => {
    const newData = {...info, home: {...info?.home, carousel: { show: !showSection}}};
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      data: newData,
    })
      .then((res) => {
        customToast('success', 'Changes submitted successfully.');
        setShowSection(!showSection);
      })
      .catch((err) => {
        customToast('error', 'There was an error, please try again later.');
      });
      
  };
  useEffect(() => {
    let formCount = info?.home?.carousel?.items?.length;
    setFormCount(Array.from({ length: formCount }, (_, index) => index));
  }, [info]);

  useEffect(() => {
    let showTrue = info?.home?.carousel?.show;
    setShowSection(showTrue);
  }, [info]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          // marginBottom: 10,
          padding: '20px 16px',
          height: '5%',
          backgroundColor: COLORS.forms.header.background,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography fontSize={'24px'} fontWeight={400} color={'#313131' }>
          Carousel
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 15,
          }}
        >
          {showSection === undefined || loading === true ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Switch
              sx={switchStyleIos}
              onChange={activeShow}
              checked={showSection}
            />
          )}
          <IconButton onClick={handleOpenSection}>
            <IoChevronDownSharp size={24} />
          </IconButton>
        </div>
      </Box>
      <Collapse in={openSection} timeout={500}>
        <AdaptableFormRedesign
          simpleform={{ record: info, onSubmit: onSubmit }}
          data={data}
          isEdit
          floating
          from={false}
          icon={true}
          isHome={true}
        />
      </Collapse>
    </>
  );
};
