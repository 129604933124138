import { useResourceContext } from 'react-admin';
import S3 from 'react-aws-s3';
import { useCustomToasty } from './useCustomToasty';
window.Buffer = window.Buffer || require('buffer').Buffer;

const request = async (file, nameTrim, reactS3Client) => {

  try {
    let request = await reactS3Client.uploadFile(file, nameTrim).then(data => {
      return ({...data, success: true});
    }).catch(e => {
      // customToasty('error', 'There was an error in the upload of a file.');
      return ({ ...e, success: false});
    });
    if (request) return request;
  } catch (error) {
    return new Error(error);
  }
};

const useUploadAws = () => {
  const resource = useResourceContext();
  const customToasty = useCustomToasty();

  const configAWS = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
    dirName: resource ? `originals/${resource}` : 'nodirName',
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY
  };

  const uploadAWS = async (e) => {
    let file = e.target.files[0];
    let timeStamp = new Date().getTime();
    let fileName = e.target.files[0].name + timeStamp.toString();
    const reactS3Client = new S3(configAWS);
    const nameTrim = fileName?.replace(/[^a-zA-Z0-9]/g, '');
    const dataFile = await reactS3Client.uploadFile(file, nameTrim).then(data => {
      return ({...data, success: true});
    }).catch(e => {
      return ({ ...e, success: false});
    });
    return dataFile;
  };

  const uploadArrayAws =  async (e, limit = 10, initialLength = 0) => {
    try {
      const reactS3Client = new S3(configAWS);
      let inputsArray = e.target.files;
      if(inputsArray.length + initialLength > limit) return customToasty('error', `You've exceeded the number of ${limit} images allowed for the gallery
      `);

      
      let responseArr = await Promise.all(Object.values(inputsArray)?.reduce((a, item, index) => {
        
        let file = item;
        let timeStamp = new Date().getTime();

        let fileName = e.target.files[0].name + timeStamp.toString();

        const nameTrim = fileName?.replace(/[^a-zA-Z0-9]/g, '') + `-${file.lastModified}`;
        console.log(nameTrim);
        return [...a, request(file, nameTrim, reactS3Client)];
      },[]));

      if(responseArr.find(item => item.success === false)) return console.log('FIND ERROR');
      return responseArr;
    } catch (error) {
      return error;
    }
    
  };

  const uploadBase64Aws = async (base64Data, fileName, extension) => {

    const reactS3Client = new S3(configAWS);
    // const nameTrim = fileName?.replace(/[^a-zA-Z0-9]/g, '');
    const binaryData = Buffer.from(base64Data, 'base64');

    const blob = new Blob([binaryData], {type: `image/${extension}`});

    console.log(blob);

    const dataFile = await reactS3Client.uploadFile(blob, fileName, {contentType: `image/${extension}`}).then(data => {
      return { ...data, success: true };
    }).catch(e => {
      console.log('catch', e);
      return { ...e, success: false };
    });

    return dataFile;
  };

  return { uploadAWS, uploadBase64Aws, uploadArrayAws};
};

export default useUploadAws;