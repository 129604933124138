import moment from 'moment';
import { convertToCostaRicaTime, convertToUtcMinusSix } from '../../../helpers/date';
import { festivalDates } from '../../../dataProvider';

const calculateDaysArr = (start, end) => {
  let arr = [];
  let startDate = moment(start);

  while(!moment(startDate).isAfter(end)) {
    arr.push(moment(startDate).format('YYYY-MM-DD'));
    startDate = moment(startDate).add(1, 'days');
  }
  return arr;
};

// Este transform existe para poder renderizar bien los horarios en el input cuando se estan cambiando. 
// No es necesario para la funcionalidad de la applicación, pero si para mejorar la UX de cuando se seleccionan los valores
export const transform = (data) => {
  // const days =  calculateDaysArr(festivalDates.start_date, festivalDates.end_date);
  // const daysArr = days?.map((day, i) => {
  //   let dayObj = data.open_times[day];
  //   if(dayObj) {
  //     return [  
  //       day,
  //       {
  //         open: dayObj.open ? dayObj.open : moment([dayObj]).format('YYYY-MM-DDTHH:mm'),
  //         close: dayObj.close ? dayObj.close : moment([dayObj]).format('YYYY-MM-DDTHH:mm'),
  //       },
  //     ];
  //   } else {
  //     return [  
  //       day,
  //       {
  //         open: moment(day).format('YYYY-MM-DDTHH:mm'),
  //         close: moment(day).format('YYYY-MM-DDTHH:mm'),
  //       },
  //     ];
  //   }
  // });

  // const mappedOpenTimes = Object.fromEntries(
  //   Object.entries(daysArr).map(([item, value]) => {

  //     return [[value[0]], value[1]];
  //   })
  // );


  // return {
  //   ...data,
  //   open_times: mappedOpenTimes
  // };
  return data;
};