import React from 'react';
import { Create, useRemoveFromStore, useResetStore, useStore } from 'react-admin';
import FormEvents from './FormEvents';
import { transform } from './Transform';

const CreateEvents = () => {
  const remove = useRemoveFromStore();
  return (
    <Create
      mutationOptions={{onSuccess: remove('locations')}} 
      //  transform={transform} 
      redirect='list' 
      mutationMode={'pessimistic'}
    >
      <FormEvents isEdit={false} from='events'/>
    </Create>
  );
};

export default CreateEvents;