import React from 'react';
import FormEvents from './FormEvents';
import EditFormCustom from '../../../Components/EditFormCustom/EditFormCustom';
import { transform } from './Transform';
import { Edit, useRemoveFromStore} from 'react-admin';

const EditEvents = () => {
  const remove = useRemoveFromStore();
  return (
    <Edit  
      mutationOptions={{onSuccess: remove('locations')}} 
      // transform={transform} // Se ejecuta a nivel form
      actions={null}
    >
      <FormEvents isEdit from={'events'}/>
    </Edit>
  );
};

export default EditEvents;