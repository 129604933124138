import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { COLORS } from '../../assets/constants/theme';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const labels = ['Profile', 'Events'];
export function Bardoble({  info,setResource }) {

  const handleclick = (event, activo) => {
    if (activo.length !== 0) {
      setResource(event.chart.data.labels[activo[0].index]);
    }
  };
  function porcentar(numero, total) {
    const porcentaje = (numero * 100) / total;
    return parseFloat(porcentaje.toFixed(1));
  }
  // Porcentaje de completados:
  const dataCompleted = [
    porcentar(info.talents.total - info.talents.count_incomplete, info.talents.total),
    porcentar(info.events.total - info.events.count_incomplete, info.events.total)
  ];

  // Porcentaje de incompletados:
  const dataMiss = dataCompleted.map((percentage) => 100 - percentage);

  const data = {
    labels,
    datasets: [
      {
        label: 'Completed',
        data: dataCompleted,
        backgroundColor: COLORS.light.secondary.main,
        borderRadius: 0,
      },
      {
        label: 'Incompleted',
        data: dataMiss,
        backgroundColor: COLORS.light.secondary.light,
        borderRadius: 3,
      },
    ],
  };
  const options = {
    onClick: handleclick,
    maintainAspectRatio: false,
    onHover: (event, chartElement) => {
      if(chartElement.length > 0) {
        event.native.target.style.cursor = 'pointer';
      }
      if(chartElement.length === 0) {
        event.native.target.style.cursor = 'default';
      }
    },
    plugins: {
      responsive: true,
      title: {
        display: false,
      },
      legend: {
        position: 'bottom',
        align:'end',
        labels: {
          font: {
            size: '11px',
          },
          boxWidth: 10,
        }, // Esto oculta la leyenda
      },  
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
            return `${datasetLabel}: ${tooltipItem.formattedValue}%`;
          },
        },
      }
      
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          font: {
            size: '13px', // Esto cambia el tamano del 'LABELS'
          },
        },
      },
      y: {
        display: false, // Esto oculta los valores en el eje Y
        stacked:true,
      },
    },
  };

  return <Bar options={options} data={data} />;
}
