import React, { useEffect, useRef } from 'react';
import {
  RichTextInput,
  RichTextInputToolbar,
  LevelSelect,
  FormatButtons,
  ListButtons,
  QuoteButtons,
  ClearButtons,
  AlignmentButtons,
  LinkButtons,
} from 'ra-input-rich-text';
import { useInput } from 'react-admin';
import InputCustomToolbar from './InputCustomToolbar';
import BlogRenderer from '../BlogRenderer';
import InputCustomImage from './InputCustomImage';
import useUploadAws from '../../Hooks/useUploadAws';

const InputRichText = ({ ...rest }) => {
  const source = rest.source;
  const {field} = useInput({ source });
  const { uploadBase64Aws } = useUploadAws();

  const handleImages = () => {
    if(field.value.includes('<img src="data:image/jpeg;base64')) {
      let str = field.value;
      let start = str.lastIndexOf('<img src="data:image/jpeg;base64');
      let substractedString = str.substring(start, str.length);
      let end = substractedString.indexOf('">');
      let binaryData = substractedString.substring('<img src="data:image/jpeg;base64'.length, end);
     
      uploadBase64Aws(binaryData, 'image', 'jpeg').then(res => {
        let newString = str.replace(`<img src="data:image/jpeg;base64${binaryData}">`, `<img style="width:100%;padding:3% 6%" src="${res.location}" alt="data:image/jpeg;base64,${binaryData}"/>`);
        field.onChange(newString);
      });
    }

  };

  
  useEffect(() => {
    handleImages();
  },[]);
  return (
    <RichTextInput
      id='blog-rich-text-input-ref'
      toolbar={
        <RichTextInputToolbar>
          <LevelSelect />
          <FormatButtons />
          <AlignmentButtons />
          <ListButtons />
          <QuoteButtons />
          <ClearButtons />
          <LinkButtons />
          <InputCustomToolbar source={rest.source}/>
        </RichTextInputToolbar>
      }
      {...rest}
    />
  );
};

export default InputRichText;