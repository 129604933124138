import { SelectInput, regex, required, useInput, useRecordContext } from 'react-admin';
import { InputTitle } from '../StyledComponents/InputTitle';
import { Autocomplete, Box, Button, FormHelperText, IconButton, MenuItem, Modal, Paper, Select, TextField, createFilterOptions } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { styledBox } from '../ReportButtons/ModalReportComponent';
import countryCodes from 'country-codes-list';
import InputText from './InputText';
import { MdOutlineArrowDropDown } from 'react-icons/md';
const formatPrefix = (e) => {
  return e?.split('+')[1];
};

export const InputPhoneNumber = ({thisManagerName, source, manager, ...rest}) => {
  const [open, setOpen] = useState(false);

  const originalCountryCodes = countryCodes.customList('countryCode', '[{countryCode}] {countryNameEn}: +{countryCallingCode}');

  const myCountryCodesObject = {
    empty: '', 
    ...originalCountryCodes,
  };

  const record = useRecordContext();
  // Le agregue al regex, ya que cuando se hace un CLEAR o EMPTY se envia como 'undefined-null' (por ahora)
  const regexNumber = /^(?:(?:\d+|undefined-|null)-)?(?:\d+|undefined-|null)$/;

  const [prefix, setPrefix] = useState(record?.[source] ? record?.[source]?.split('-')[0] : null);
  const [value, setValue] = useState(record?.[source] ? record?.[source]?.split('-')[1] : null);
  const { field,fieldState } = useInput({source,
    validate: [ regex(regexNumber, 'The phone must contain only numbers')]
  });

  const handlePhone = (e) => {
    let phone = e.target.value;
    setValue(phone);
    field.onChange(formatPrefix(prefix?.value) + '-' + phone);
  };

  const handlePrefix = (e) => {
    if(e === 'clear'){
      setPrefix(null);
      field.onChange(undefined);
      return; 
    }else{
          setPrefix(e);

      field.onChange(formatPrefix(e.value) + '-' + value);
    }
    
  };

  const choices = useMemo(() => {
    let arr = Object.values(myCountryCodesObject)?.map((item, index) => {
      return {
        key: Object.keys(myCountryCodesObject)[index],
        value: item
      };
    });
    return arr;
  },[myCountryCodesObject]);

  let initialValue = useMemo(() => {
    if(record && choices) {
      let found = choices.find((item, index) => {
        let pref = item?.value?.split('+')[1];
        if (pref === prefix) {
          return item;
        }
      });
      if(found){
        setPrefix(found);
        return found;
      }
    }
    return null;
  },[choices]);
  console.log(field.value)
  return (
    <>
      <InputTitle>{thisManagerName ? 'Your Phone' : 'Talent Manager\'s phone'}</InputTitle>
      <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start',marginTop:'8px'}}>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
          <Button 
            onClick={() => setOpen(true)}
            sx={{height: '40px', display: 'flex', alignItems: 'center', padding: '0 30px', marginRight: '10px', width: 'auto'}}
            variant="outlined" 
            endIcon={<MdOutlineArrowDropDown size="30px" />}
          >
            { prefix === null || prefix?.key === 'empty'  || prefix?.key === 'undefined-null' || prefix?.key === 'undefined-'? (
             ''
            ) :   
            <>
            <img
              alt=""
              style={{'width':'30px', 'height':'30px', 'margin-right':'10px'}}
              src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${prefix?.key}.svg`}/> 
            <InputTitle sx={{fontSize: '1.1em'}}>+{formatPrefix(prefix?.value)}</InputTitle>
          </>}
          </Button>
          <TextField
            variant="outlined"
            {...field}
            {...fieldState}
            //   defaultValue={manager.phone}
            value={value}
            sx={{margin: '0px'}}
            InputLabelProps={null}
            source="phone"
            label=''
            fullWidth
            onChange={handlePhone}
            {...rest}
          />
        </Box>
        {fieldState.error && <FormHelperText sx={{color: 'red'}}>{fieldState.error.message}</FormHelperText>}
        <PrefixModal initialValue={prefix} onChange={handlePrefix} value={field.value} data={choices} open={open} setOpen={setOpen} />
      </Box>
    </>
  );
};

export const PrefixModal = ({initialValue, open, setOpen, data, onChange}) => {
  const record = useRecordContext();
  const handleChange = (e,v) => {
    console.log( v?.value.length)
    if(v?.value.length === 0){
      console.log('hola')
      setOpen(false);
      onChange && onChange('clear');
    }
    setOpen(false);
    onChange && onChange(v);
  };

  const filterOptions = createFilterOptions({
    stringify: ({ key, value, id }) =>  value
  });
  return(
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box sx={{...styledBox, maxWidth: '400px', height:'auto', minHeight: '50vh', padding:'20px'}}>
        <InputTitle>Country Prefix Number</InputTitle>
        <Autocomplete
          isOptionEqualToValue={(e,v) => e.value === v.value}
          onChange={handleChange}
          open={true}
          // defaultValue={initialValue}
          filterOptions={filterOptions}
          ListboxProps={{
            sx: {
              borderRadius: 'none',
              '&::-webkit-scrollbar': {
                width: '5px',
                // borderRadius: '12px',
              },
            }
          }}
          PaperComponent={(params) => <Paper sx={{height: 'auto', marginTop: '20px'}} {...params} />}
          fullWidth
          getOptionLabel={(option) => {
            return (
              <>
              {option.key === 'empty'? <>      
                {"Clear"}
              </> :<>
              <img
                  alt=""
                  style={{'width':'30px', 'height':'30px', 'margin-right':'10px'}}
                  src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${option.key}.svg`}/> 
                  
                {option.value}
                </>
                }
              </>
            );
          }}
          options={data}
          renderInput={(params) => <TextField variant="outlined"  {...params} />}
        />
      </Box>
    </Modal>
  );
};