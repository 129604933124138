import { useState,useEffect } from 'react';
import axios from 'axios';
// Hooks & Utils
import { useCustomToasty } from '../../../../Hooks/useCustomToasty';
import { COLORS } from '../../../../assets/constants/theme';
// Components
import InputDate from '../../../../Components/InputsForm/InputDate';
import { InputColorPicker } from '../../../../Components/InputsForm/InputColorPicker';
import { InputRichTextCountdown } from '../../../../Components/InputsForm/InputRIchTextCountdown';
import InputRedesignImageAws from '../../../../Components/RedesignComponents/Inputs/InputRedesignImageAws';
import AdaptableFormRedesign from '../../../../Components/AdaptableForm/AdaptableFormRedesign';
import { switchStyleIos } from '../../../../Components/StyledComponents/Inputs';
import InputText from '../../../../Components/InputsForm/InputText';
// Material & Icons
import {Typography,Box,Switch,IconButton,Collapse,Skeleton} from '@mui/material';
import {image1,image3,image4,image5} from '../../../../assets/images/Countdown/index';
import { IoChevronDownSharp } from 'react-icons/io5';

export const CountdownForm = ({info}) => {
  const [openSection,setOpenSection]= useState(true);
  const [showSection, setShowSection] = useState(undefined);
  const customToast = useCustomToasty();

  const data = [
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}> TITLE/SUBTITLE</Typography>
              <Typography fontWeight={300} fontSize={'14px'} >The counter&apos;s main titles</Typography>
            </Box>
            <img src={image1}  style={{width:'100%',height:'100%',objectFit:'contain'}} alt='image1' loading='lazy'/>
          </div>,
          breakpoints: { xs: 3 }
        },
        {
          field: <InputRichTextCountdown variant="outlined" fullWidth source="home.countdown.title" label={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}> TITLE</Typography>} />,
          breakpoints: { xs: 3.5 }
        },
        {
          field: <InputRichTextCountdown fullWidth source="home.countdown.subtitle" label={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}> SUBTITLE</Typography>} />,
          breakpoints: { xs: 3.5}
        },
      ]
    },
    {
      title: '',
      inputs: [
        {
          field:<div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>BACKGROUND & FONT</Typography>
              <Typography fontWeight={300} fontSize={'14px'} >Countdown background and font color</Typography>
            </Box>
            <img src={image5} style={{width:'100%',height:'100%',objectFit:'contain'}} alt='image5' loading='lazy' />
          </div>,
          breakpoints: { xs: 3 }
        },
        {
          field: <InputRedesignImageAws noImage fullWidth source="home.countdown.background_image.original" label="Background Image" />,
          breakpoints: { xs: 3.5 }
        },
        {
          field: <InputColorPicker source="home.countdown.styles.font_color" label='FONT COLOR' />,
          breakpoints: { xs: 3.5 }
        },
      ]
    },
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>COUNTER</Typography>
              <Typography fontWeight={300} fontSize={'14px'} >Counter&apos;s main properties</Typography>
            </Box>
            <img src={image4} style={{width:'100%',height:'100%',objectFit:'contain'}} alt='image4' loading='lazy' />
          </div>,
          breakpoints: { xs: 3 },
        },
        {
          field: <InputDate fullWidth inputTime source="home.countdown.end_date" title={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}> END DATE</Typography>} label='' />,
          breakpoints: { xs: 3.5 }
        },
        {
          field: <InputColorPicker source="home.countdown.styles.background_color" label='BACKGROUND COLOR' />,
          breakpoints: { xs: 3.5 }
        },
      ]
    },
    {
      title: '',
      inputs: [
        { 
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>CTA</Typography>
              <Typography fontWeight={300} fontSize={'14px'} >Counter&apos;s main CTA</Typography>
            </Box>
            <img src={image3} content='' style={{width:'100%',height:'100%',objectFit:'contain'}} alt='image3' loading='lazy' />
          </div>,
          breakpoints: { xs: 3 }
        },
        {
          field: <InputText fullWidth source="home.countdown.call_to_action_text" label='' title={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}> CTA TEXT</Typography>} />,
          breakpoints: { xs: 3.5 }
        },
        {
          field: <InputText title={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}> CTA LINK</Typography>} fullWidth source="home.countdown.call_to_action_link" label='' />,
          breakpoints: { xs: 3.5 }
        },
      ]
    },
  ];
  const onSubmit = async (data) => {
    const newData ={home:{countdown:{...data.home.countdown}}};
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
        'x-api-key': process.env.REACT_APP_API_KEY ,
      },
      data: newData
    })
      .then(res => {
        customToast('success', 'Changes submitted succesfuly.');
      })
      .catch(err => {
        customToast('error', 'There was an error, please try again later.');
      });
  };

  const handleOpenSection = () => {
    setOpenSection(!openSection);
  };

  const activeShow = () => {
    const newData = {...info, home: {...info?.home, countdown: { show: !showSection}}};
    axios({
      url: process.env.REACT_APP_BASE_PATH + '/info',
      method: 'PUT',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Expose-Headers': 'content-range',
        Authorization: 'Bearer ' + localStorage.getItem('auth'),
        'x-api-key': process.env.REACT_APP_API_KEY,
      },
      data: newData,
    })
      .then((res) => {
        customToast('success', 'Changes submitted successfully.');
        setShowSection(!showSection);
      })
      .catch((err) => {
        customToast('error', 'There was an error, please try again later.');
      });
      
  };
  useEffect(()=>{
    let showTrue = info?.home?.countdown?.show;
    setShowSection(showTrue);
  },[info]);

  return(
    <>
      <Box sx={{width:'100%',padding:'20px 16px', height:'5%', backgroundColor:COLORS.forms.header.background,display:'flex',flexDirection:'row',justifyContent:'space-between' }}>
        <Typography fontSize={'24px'} fontWeight={400} color={'#313131'}>Countdown</Typography>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center', gap:15}}>
          {showSection === undefined ?<Skeleton variant="circular" width={40} height={40} />:
            <Switch sx={switchStyleIos} onClick={activeShow} checked={showSection}/>}
          <IconButton onClick={handleOpenSection}>
            <IoChevronDownSharp size={24} />
          </IconButton>
        </div>
      </Box>
      <Collapse in={openSection} timeout={500}>
        <AdaptableFormRedesign simpleform={{record: info, onSubmit: onSubmit}} data={data} isEdit floating from={false} icon={true}/>
      </Collapse>
    </>
  );
};