import React, { Fragment } from 'react';
// import { AppBar, ToggleThemeButton } from 'react-admin';
import { Box, Typography,AppBar } from '@mui/material';
import { darkTheme, lightTheme } from '../../Theme';


export const AppbarCustom = (props) => {
  return (
    <AppBar {...props}>
      <Box flex="1">
        <Typography variant="h6" id="react-admin-title"></Typography>
      </Box>
      {/* <ToggleThemeButton
        lightTheme={lightTheme}
        darkTheme={darkTheme}
      /> */}
    </AppBar>
  );
};
export const MyAppBar = (props) => {
  return null;
};