import { Box, Typography } from '@mui/material';
import { useDrag } from 'react-dnd';


const DroppableItem = ({item, children, ...rest}) => {


  function snapToGrid(x, y) {
    const snappedX = Math.round(x / 5) * 5;
    const snappedY = Math.round(y / 5) * 5;
    return [snappedX, snappedY];
  }
  
  const [{ isDragging, }, drag] = useDrag({
    previewOptions: {

    },
    item: { 
      ...item,
      envType: item.type,
      type: 'ITEM',
      id: item.id,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      initialClientOffset: monitor.getInitialClientOffset()
    }),
    options: {
      snapToGrid: 5, // specify the grid size for snapping
      snapToGridOffset: [0, 0], // specify the offset if needed
      snapToGridCallback: snapToGrid // specify the snapping function
    }
  });


  return(
    <Box
      ref={drag}
      style={{opacity: isDragging ? 0.5 : 1 }}
      {...rest}
    >
      <Typography color='#fff' variant='body1'>{children}</Typography>
    </Box>
  );
};

export default DroppableItem;