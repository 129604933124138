import React, { useState } from 'react';
import ListCustom from '../../Components/ListCustom/ListCustom';
import {
  TextField,
  FunctionField,
  useDataProvider,
  useResourceContext,
  useRefresh,
} from 'react-admin';
import { ListActions } from '../../Components/ListActions/ListActions';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import DeleteModal from '../../Components/DeleteModal/DeleteModal';
import { AdminActions } from '../TalentForm/ContactInformation';
import { COLORS } from '../../assets/constants/theme';

const AdminsList = () => {
  const dataProvider = useDataProvider();
  const resource = useResourceContext();
  const customToast = useCustomToasty();
  const refresh = useRefresh();

  const [open, setOpen] = useState(false);
  const [idToAction, setIdToAction] = useState(null);
  const paramsList = {
    listParams: {
      hasCreate: true,
      actions: <ListActions searcheable={true} csv label={'Add'} filterRol={true} placeholder={'Search by name and email...'}/>,
    },
    datagridParams: {
      bulkActionButtons: false,
    },
  };

  const confirmDelete = async (iddelete) => {
    try {
      let request = await dataProvider.delete(resource, { id: iddelete });
      console.log(request);
      if (request) {
        customToast('success', 'Deleted succesfuly');
        setOpen(false);
        refresh();
      }
    } catch (error) {
      customToast('error', 'There was an error');

      throw error;
    }
  };

  return (
    <div >
      <ListCustom {...paramsList}
        datagridParams={{
          sx: {
            padding:'0px 20px',
            zIndex:100,
            '& .MuiTypography-root':{
              color:'#000000',
              fontWeight:400
            },
            '& .MuiTableSortLabel-root':{
              color:COLORS.table.headerSorteable,
              fontWeight:400
            },
            '& .column-question': {
              width: '50%',
              textAlign: 'left',
            },
            '& .column-category': {
              width: '50%',
              textAlign: 'right',
            },
            '& .MuiTableCell-body': {
              borderBottom:'0px',
            },
          },
          ...paramsList.datagridParams,
        }}
      >
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="role" />
        <FunctionField label="" render={(record, index) => <AdminActions setOpen={setOpen} record={record} redirectEdit={true}/>} />
      </ListCustom>
      {open && (
        <DeleteModal setOpenModal={setOpen} openModal={open} idToAction={idToAction} confirmDelete={confirmDelete}/>
      )}
    </div>
  );
};

export default AdminsList;
