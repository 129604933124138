import React from 'react';
import { Create } from 'react-admin';
import FormActivities from './FormActivities';

const CreateActivities = () => {
  return (
    <Create redirect='list'  mutationMode={'pessimistic'}>
      <FormActivities from={'activities'}/>
    </Create>
  );
};

export default CreateActivities;