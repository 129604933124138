import React, {useState} from 'react';
import { FilterButton, useGetList, useListContext } from 'react-admin';
// Hooks & Utils
import {strCapitalize} from '../../helpers/strCapitilize';
import ActionButtons from '../StyledComponents/ActionButtons';
import { responsiveFilter } from '../../Hooks/useMediaQuery';
// Components
import { CsvDownloader } from '../ReportButtons/CsvDownloader';
import AllAutocomplete from '../ReportButtons/AllAutocomplete';
import { RoleFilter } from '../../Resources/TalentForm/ContactInformation';
import { renderPopper } from '../../Resources/Events/CalendarToolbar';
// Material & Icons
import { Autocomplete, Box, Skeleton, TextField } from '@mui/material';
import { IoMdSearch } from 'react-icons/io';

export const  ListActions = ({
  filterButton,
  searcheable,
  children,
  from,
  filter,
  filterRol,
  label,
  csv,
  placeholder,
  categories, // Esto es para el filtro de categorias de Articles > content
  ...rest
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: '#fff',
        zIndex: 999,
        position: 'sticky',
        top: 0,
        paddingBottom: '12px',
        ...rest.sx,
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection:'column',
          alignItems: 'flex-start',
          paddingX: 3,
          gap:2,
          ...rest.sx,
        }}
      >
        <Box sx={{display:'flex',flexDirection:'row',gap:5,marginBottom:2,width:'100%'}}>
          <div style={{width:'100%',display:'flex',flexDirection:'row', alignItems: 'center',marginTop:20,justifyContent: 'space-between'}}>
            {searcheable && (
              <AdminFilterSearch sx={{ width: '350px',borderRadius: '10px',}} placeholder={placeholder}/>
            )}
            <div style={{width:'auto',display:'flex',alignItems: 'center',height:'50px',gap:20,marginLeft:50}}>
              {searcheable && csv && (
                <CsvDownloader
                  minWidth={'(min-width:1060px)'}
                  resource="admins"
                />
              )}
              {rest.hasCreate &&
                ActionButtons({ action: 'create', label: label })}
            </div>
          </div>
        </Box>
      
        {filter && <FilterPill categories={categories} from={from}/>}
        {filterRol && <RoleFilter resource='roles' capitalize={true}/>}
        {filterButton && <FilterButton />}
        {children}
      </Box>
      
      {from === 'locations' && (
        <FilterType filterType={true} filterGeo={true}/>
      )}
    </Box>
  );
};
// SEARCH COMPONENT:
export const AdminFilterSearch = ({ sx,placeholder }) => {
  const [value, setValue] = React.useState('');
  const { setFilters,filterValues } = useListContext();
  const handleFilter = (e) => {
    setFilters({...filterValues, q: e.target.value });
    setValue(e.target.value);
  };

  return (
    <TextField
      sx={{ width: '100%', backgroundColor: 'white', ...sx }}
      value={value}
      variant="outlined"
      hiddenLabel={true}
      onChange={handleFilter}
      placeholder={placeholder}
      InputProps={{
        style: {
          borderRadius: '10px',
          fontSize: '14px',
        },
        startAdornment: <IoMdSearch size={24} />,
      }}
    />
  );
};
// NOTIFICATION - ACTIVITIES - CONTENT , FILTRO DE STATUS
export const FilterPill = ({ sx,from='notification',categories }) => {
  const { setFilters, filterValues } = useListContext();
  const [bool, setBool] = useState([]);
  const handleChange = (e, v) => {
    let newFilters = { ...filterValues };
    const value = v;
    console.log(value,'valor');
    setBool(value);
    if (!value) {
      delete newFilters[from === 'notification' ? 'status':'categories'];
      return setFilters(newFilters);
    }
    if(from === 'notification' ){

      setFilters({ ...newFilters, status: value?.map(item => item.toLowerCase()) });
    }else if(from === 'activities' ){
      setFilters({ ...newFilters, type: [encodeURIComponent(value)] });
    }
    else {
      setFilters({ ...newFilters, categories: value?.map(item => item.text) });
    }
    
  };
  // Posibles Status de notificaciones :
  const status = ['Sent', 'Canceled', 'Scheduled'];
  const { data, isFetching } = useGetList(`${from}/categories`, { pagination: {perPage:9999, page: 1}, sort: { field: 'rank', order: 'DESC'}}, {enabled: from !== 'notification'});
  return (
    <div style={{width:'100%'}}>
      <AllAutocomplete
        width={responsiveFilter()}
        value={bool}
        limitTags={2}
        allTitle={null}
        sx={{
          marginBottom: '0px',
          height: '50%',
          '& .MuiInputBase-sizeSmall':{
            borderRadius:'50px',
            color:'black',
          },
          '& .MuiInputLabel-root':{
            color:'black',
            fontWeight:400
          },
        }}
        onChange={handleChange}
        getOptionLabel={(option) => from === 'notification' || from === 'content' || from === 'activities' ? strCapitalize(option): strCapitalize(option?.text)}
        arr={categories ? categories : from === 'notification'? status: data}
        PopperComponent={renderPopper}
        renderInput={(params) => 
          <TextField label={from === 'notification' ? 'Status': from === 'activities' ? 'Type':'Category'} variant="outlined" {...params} />
        }
      />

    </div>
    
  );
};
// LOCATIONS, filtros
export const FilterType = ({ sx,filterType,filterGeo }) => {
  const { setFilters, filterValues } = useListContext();
  const [bool, setBool] = useState([]);
  const [boolGeo,setBoolGeo]=useState(null);
  const responsive = responsiveFilter();
  const handleChangeType = (e, v,from) => {
    let newFilters = { ...filterValues };
    const value = v;
    setBool(value);
    if (!value) {
      delete newFilters['type'];
      return setFilters(newFilters);
    }
    setFilters({...newFilters, 'type': v?.map(item => encodeURIComponent(item.text))});
  };
  const handleChangeGeo = (e, v,from) => {
    let newFilters = { ...filterValues };
    const value = v;
    setBoolGeo(value);
    if (!value) {
      delete newFilters['geo_tagged'];
      return setFilters(newFilters);
    }
    setFilters({ ...newFilters, geo_tagged: value?.toLowerCase() === 'yes' ? true : false });
  };
  
  const {data,isFetching} = useGetList('locations/types', { pagination: {perPage:9999, page: 1}, sort: { field: 'rank', order: 'DESC'}});
  const geoArr = ['Yes','No'];
  return (
    <Box sx={{ 
      display:'flex',
      flexDirection:'row',
      width:'100%',
      padding:'0px 20px',
      columnGap:'20px',
    }}
    >
      <div>
        {filterType === true ? 
          isFetching ? 
            <Skeleton height="50px" width="100%" /> 
            :
            <AllAutocomplete
              width={responsive}
              value={bool}
              limitTags={2}
              allTitle={null}
              sx={{ marginBottom: '0px',
                height: '50%',
                '& .MuiInputBase-sizeSmall':{
                  borderRadius:'50px',
                  color:'black',
                },
                '& .MuiInputLabel-root':{
                  color:'black',
                  fontWeight:400
                },}}
              onChange={handleChangeType}
              getOptionLabel={(option) =>option.text}
              arr={data}
              PopperComponent={renderPopper}
              renderInput={(params) => 
                <TextField label={'Type'} variant="outlined" {...params} />
              }
            // label={<span style={{color:'black'}}>Type</span>}
            />:null}
      </div>
      <div>
        {filterGeo === true ? 
          <Autocomplete
            onChange={handleChangeGeo}
            InputProps={{
              fontSize: '.9em',
              borderRadius: '50px',
            }}
            sx={{ marginBottom: '0px',
              '& .MuiInputBase-sizeSmall':{
                borderRadius:'50px',
                color:'black',
                maxHeight:'45px'
              },
              '& .MuiInputLabel-root':{
                color:'black',
                fontWeight:400
              },}}
            getOptionLabel={(option) => option} 
            options={geoArr} 
            renderInput={(params) => 
              <TextField 
                label="Geo-tagged" 
                variant="outlined" 
                sx={{
                  width:responsive,
                  height: '100%',
                  zIndex: 9999,
                  color:'black',
                // margin: '0px',
                }}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    overflow: 'auto',
                    margin: '0px',
                  },
                  startAdornment: (
                    <div style={{
                      overflow: 'auto',
                      margin: '0px',
      
                    }}
                    >{params.InputProps.startAdornment}</div>
                  ),
                }}
                {...params} 
      
              />}
          />:null}
      </div>
    </Box>
  );
};