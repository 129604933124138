import { Box, IconButton, Popover } from '@mui/material';
import React, { useState } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Typography } from '@mui/material';
import { mainColor } from '../../Theme';

export const TooltipIcon = ({message}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton aria-describedby={id} color='secondary' onClick={handleClick}>
        <HelpOutlineIcon sx={{color: mainColor, fontSize: '21px'}} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <Box sx={{
          maxWidth: '320px',
          backgroundColor: '#fff',
          padding: '15px 18px'
        }}>
          <Typography variant='subtitle2' style={{fontWeight: '400', color: '#757575'}}>{message}</Typography>
        </Box>
      </Popover>
    </>
  );
};