import { Box, FormLabel, TextField, Tooltip } from '@mui/material';
import { InputAddTextInputs } from '../../Resources/Talents/Form/TalentsListComponents/InputAddTextInputs';
import { required, useInput, useRecordContext } from 'react-admin';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm, useFormContext } from 'react-hook-form';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { convertToCostaRicaTime, convertToUtcMinusSix } from '../../helpers/date';
import { InputTitle } from '../StyledComponents/InputTitle';
import { useFetcher } from 'react-router-dom';



export const InputArrayTextInput = ({
  validate, 
  requiredFields,
  source,
  limit,
  direction = 'column',
  obj,
  label,
  addText,
  elements = [
    <TextField 
      sx={{width: '100%'}}
      variant="outlined" 
    />],
  sx,
  enumerate,
  buttonDirection = 'column',
  title,
  initialValue,
  ...rest
}) => {
  const record = useRecordContext();

  Object.byString = function(record, key) {
    key = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    key = key.replace(/^\./, '');           // strip a leading dot
    let string = key.split('.');
    for (let i = 0, n = string.length; i < n; ++i) {
      let obj = string[i];
      if (record && (obj in record)) {
        record = record[obj];
      } else {
        return;
      }
    }
    return record;
  };
  const [arr, setArr] = useState([]);
  const { control } = useForm({
    defaultValues: Object.byString(record, source)
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: source
  });

  useEffect(() => {
    if(Object.byString(record, source)) {
      let newArr = Object.byString(record, source);
      let updatedArr = newArr?.reduce((a, b, index) => {
        let obj = {
          key: index,
          value: b
        };

        return [...a, obj];
      },[]);
      setArr(updatedArr);
    }
  },[record]);

  return (
    <Box mt="20px">
      {  arr && (
        <InputAddTextInputs 
          initialValue=''
          addText={addText}
          buttonVariant="outlined"
          direction={buttonDirection}
          onDelete={remove}
          element={
            <SourceTextField  
              fullWidth 
              defaultValue={initialValue ?? Object.byString(record, source)}
              obj={obj}
              variant="outlined"
              elements={elements}
              source={source}
              requiredFields={requiredFields}
              direction={direction}
              fields={fields}
              sx={sx}
              arr={arr}
              label={label}
              // control={control}
              enumerate={enumerate}
              title={title}
            />
          } 
          initialNumber={arr?.length}
          limit={limit}
          values={arr}
          setValues={setArr}
          label=""
          {...rest}
        />
      )}
    </Box>
  );
};

export const SourceTextField = ({
  componentKey,
  elements,
  direction = 'row',
  obj = null,
  sx,
  label,
  title,
  defaultValue,
  enumerate,
  source,
  requiredFields,
  fields,
}) => {
  const [errorFields, setErrorFields] = useState({});
  const { control } = useFormContext();
  
  const elementProps = useCallback((item, field, error) => {
    const { fieldName, time } = item.props;

    let def = time ?
      moment(convertToCostaRicaTime(defaultValue?.[componentKey]?.[fieldName])) :
      item.props.fieldName ?
        defaultValue?.[componentKey]?.[fieldName] :
        defaultValue?.[componentKey];

    const handleChange = (e, fieldName, time) => {
      field.onChange(e.target.value);
    };
    
    return {
      // key: Math.ceil(Math.random() * 1000),
      defaultValue: def,
      label: label ?? item.props.label,
      onChange: (e) => handleChange(e, fieldName, time),
      helperText: errorFields[fieldName] ?? error?.message,
      error: error,
      ...item.props,
      // ...field
    };
  },[errorFields]);

  if (obj) {
    return (
      <>
        {
          elements && (
            <Box key={componentKey} mt="10px">
              {enumerate &&  <InputTitle sx={{marginBottom: '10px'}} textTransform={null}>{title} {parseFloat(componentKey) + 1}</InputTitle>}
            
              <Box
                key={fields[parseFloat(componentKey)]?.id}
                sx={{
                  display: 'flex',
                  flexDirection: direction,
                  // gap: '10px',
                  width: '100%',
                  // marginBottom: '20px',
                  ...sx
                }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>

                  {/* <div key={fields[parseFloat(componentKey)]?.id}> */}
                  {
                    elements?.map((item, 
                      index) => {
                      const { fieldName, time } = item.props;
                      return (
                        <Controller 
                          rules={{
                            required: requiredFields.includes(fieldName),
                          }}
                          name={`${[source]}.${[parseFloat(componentKey)]}.${fieldName}`}
                          control={control}
                          render={
                            ({
                              field, 
                              fieldState: {error}
                            }) => {
                              return  React.cloneElement(item, {...elementProps(item, field, error)});
                            }}
                        />
                      );
                    })
                  }
                  {/* </div> */}
                </LocalizationProvider>
              </Box>
            </Box>
          )
        }
      </>
    );
  }

  if (!obj) {
    return (
      <>
        {
          elements && (
            <Box key={componentKey}>
              {enumerate &&  <InputTitle sx={{marginBottom: '10px'}} textTransform={null}>{title} {parseFloat(componentKey) + 1}</InputTitle>}
          
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: direction,
                  gap: '10px',
                  width: '100%',
                  marginBottom: '20px',
                  ...sx
                }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  {
                    elements?.map((item, 
                      index) => {
                      return (
                        <Controller 
                          rules={{
                            required: requiredFields ? 'This field is required.' : undefined
                          }}
                          name={`${[source]}.${[parseFloat(componentKey)]}`}
                          control={control}
                          render={
                            ({
                              field,
                              fieldState: {error}

                            }) => {
                              return  React.cloneElement(item, {...elementProps(item, field, error)});
                            }}
                        />
                      );
                    })
                  }
                </LocalizationProvider>
              </Box>
            </Box>
          )
        }
      </>
    );
  }
};
