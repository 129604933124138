import { Box,Typography } from '@mui/material';
import moment from 'moment';
import { useRecordContext } from 'react-admin';
import React, { useState } from 'react';
import { PillInfo } from '../../TalentsList';

const styledHeaderSubmitter = {
  padding: '20px',
  fontSize: '.9em',
  textAlign: 'start',
  fontColor: 'black',
  width: 'auto',
  gap: '50px',
  marginBottom: '10px',
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
  color: '#757575',
  backgroundColor: '#808080',
  boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 15px',
};
const styleSpan={
  fontWeight: '600',
  color: '#000000',
};


export const LastSubmitter = ({sx, lastSubmitter, lastModified}) => {
  const record = useRecordContext();
  const message = 'Last edited by: ';
  return (
    <Box
      sx={{...styledHeaderSubmitter, ...sx}}
    >
      {/* <PillInfo status={record?.submission?.status} sx={{gap: '10px', flexDirection: 'row-reverse'}} /> */}
      {lastSubmitter || record?.last_modified_by ? <Typography>{message}<span style={styleSpan}>{lastSubmitter ?? record?.last_modified_by}</span></Typography>:null}
      {lastModified || record?.last_modified ? <Typography>Last modified: <span style={styleSpan}>{lastModified ?? moment(record?.last_modified).format('MMMM Do YYYY')}</span></Typography>:null}
      {/* {(lastSubmitter || record?.last_modified_by) ? message + (lastSubmitter ?? record?.last_modified_by) : null}  */}
      
      {/* {(lastModified || record?.last_modified) ?  'Last modified: ' + (lastModified ?? moment(record?.last_modified).format('MMMM Do YYYY')) : null} */}
    </Box>
  );
};