import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { Box, Button, Modal,Popover } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../../../Hooks/useOnClickOutside';
import CreateIcon from '@mui/icons-material/Create';
import { usePermissions, useRedirect,CreateButton } from 'react-admin';
import { easeInLeft, opacityIn } from '../../../../assets/animations';
import { SimpleTalentForm } from './SimpleTalentForm';
import { COLORS } from '../../../../assets/constants/theme';
import ContentAdd from '@mui/icons-material/Add';
export const modalBoxStyle = {
  width: 'auto',
  height: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${opacityIn} 500ms ease`,
  overflow: 'auto',
  borderRadius:'10px'
};

export const AddTalentModal = () => {
  const[open, setOpen] = useState(false);
  const [transition, setTransition] = useState(false);
  const ref = useRef(false);
  const buttonRef = useRef(null);
  const { permissions } = usePermissions();
  const redirect = useRedirect();

  const buttonClass = {
    width:'100%',
    fontSize: '1em',
    textTransform: 'none',
    padding:'10px 25px',
    color:'white',
    fontWeight: '600',
    justifyContent: 'flex-start',
    alignItems: 'center',
    right: 0,
    position: 'relative',
    animation: transition && `${easeInLeft} 500ms ease`,
    top: 0,
    border:'none',
    '&:hover': {backgroundColor:'#1E4E4E',border:'none',}
  };

  const openInviteTalentModal= () => {
    setTransition(true);
    setOpen(false);
  };

  return(
    <>
      {permissions !== 'talent_manager' && (
        <div ref={buttonRef} > 
          <Button onClick={() => setOpen(true)} 
            sx={{
              textTransform: 'none',
              backgroundColor:COLORS.light.secondary.main,
              fontSize: '16px', 
              fontWeight: '500',
              height: '44px',
              minWidth: '135px',
              borderRadius:'10px',
              color:'white',
              display:'flex',
              flexDirection:'row',
              gap:'10px',
              marginRight:'5px',
              '&:hover':{
                backgroundColor:COLORS.light.secondary.main
              }
            }} variant="outlined"><ContentAdd sx={{height: '17px', width: '17px'}}/><span>Add</span></Button>
          <Popover
            sx={{
              '& .MuiPaper-root': {
                right: '40px',
                width: '18em',
              },
              '& .MuiPopover-paper': {
                borderRadius: '10px',
                marginTop: '10px',
              }
            }}
            open={open}
            anchorEl={buttonRef.current}
            onClose={() => setOpen(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}

          >
            <div style={{display:'flex',flexDirection:'column',backgroundColor:COLORS.light.secondary.main,borderRadius:'10px'}}>
              <Button onClick={() => redirect('create', 'talents')} sx={buttonClass} variant="outlined" startIcon={<CreateIcon sx={{height: '20px', width: '20px'}} />}>
              Create talent from scratch
              </Button>
              <Button onClick={openInviteTalentModal} sx={buttonClass} variant="outlined" startIcon={<PersonAddAlt1Icon sx={{height: '20px', width: '20px'}} />}>
              Invite a talent 
              </Button>
            </div>
          </Popover>
          <Modal
            open={transition}
            onClose={() => setTransition(false)}
          >
            <Box
              sx={modalBoxStyle}
            >
              <SimpleTalentForm setOpen={setOpen} setTransition={setTransition} transition={transition} />
            </Box>
          </Modal>
        </div>
      )}
    </>
  );


};
// return (
//   <>
//     {permissions !== 'talent_manager' && (
//       <>
//         <Button onClick={() => setOpen(true)} 
//           sx={{
//             backgroundColor:COLORS.light.secondary.main,
//             fontSize: '.8em', 
//             fontWeight: '700',
//             height: '40px',
//             minWidth: '135px',
//             borderRadius:'10px',
//             color:'white',
//             '&:hover':{
//               backgroundColor:COLORS.light.secondary.main
//             }
//           }} variant="outlined">+ Add</Button>
//         {
//           open && (
//             <Modal
//               ref={ref}
//               open={open}
//               onClose={() => setOpen(false)}
//             >
//               <Box
//                 sx={modalBoxStyle}
//               >
//                 <Box
//                   sx={{
//                     width: 'auto',
//                     height: 'auto',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     alignItems: 'center',
//                     justifyContent: 'center',
//                     gap: '20px',
                    
//                   }}
//                 >
//                   {transition ? (
//                     <>
//                       <Box sx={{ display: 'flex', flexDirection: 'column', padding: '50px', gap: '20px'}}>

//                         <Typography 
//                           sx={{ 
//                             animation: transition && `${easeInLeft} 500ms ease`,
//                             width: '30%',
//                             borderBottom: `1px ${mainColor} solid`,
//                             padding: '5px', 
//                             textTransform: 'uppercase', 
//                             fontSize: '18px',
//                             fontWeight: '600', 
//                             color: mainColor
//                           }}>Add talents</Typography>
//                         <Button onClick={() => redirect('create', 'talents')} sx={buttonClass} variant="outlined" startIcon={<UploadIcon sx={{height: '25px', width: '25px'}} />}>
//                       Create talent from scratch
//                         </Button>
//                         <Button onClick={() => setTransition(!transition)} sx={buttonClass} variant="outlined" startIcon={<PersonAddAlt1Icon sx={{height: '25px', width: '25px'}} />}>
//                       Invite a talent to complete their profile
//                         </Button>
//                       </Box>
//                     </>
//                   ) : (
//                     <>
//                       <Box sx={{
//                         height: '560px', 
//                         // minHeight: '450px', 
//                         overflow: 'scroll'
//                       }}>

//                         <SimpleTalentForm setOpen={setOpen} setTransition={setTransition} transition={transition} />
//                       </Box>
//                     </>
//                   )}
//                 </Box>
//               </Box>
//             </Modal>
//           )
//         }
//       </>
//     )}

//   </>
// );