
import moment from 'moment';
import { festivalDates } from '../dataProvider';

export const convertToCostaRicaTime = (utcTime) => {
  const costaRicaDate = moment.utc(utcTime).subtract(6, 'hours').format('YYYY-MM-DDTHH:mm:ss');
  return costaRicaDate;
};

export const convertToUtcMinusSix = (costaRicaTime) => {
  const costaRicaDate = moment.utc(costaRicaTime).add(6, 'hours').format('YYYY-MM-DDTHH:mm:ss');
  return costaRicaDate;
};

export const convertEndDateToDuration = (startDate, endDate) => {
  let x = new moment(startDate);
  let y = new moment(endDate);
  let duration = moment.duration(x.diff(y)).as('minutes');
  let hours = Math.floor(duration / 60);
  let minutes = Math.ceil(duration % 60);
  return [hours, minutes];
};

export const dateFormat = (date) => {
  const formatedDate = moment(date).format('MMMM Do YYYY, h:mm:ss a');
  return formatedDate;
};
export const formatOnlyDate = (date) => {
  const formattedDate = moment(date).format('M/DD/YYYY');
  return formattedDate;
};
export const customValidationStartDate = (e, date) => {
  // const firstDate = moment(festivalDates.start_date);
  // const lastDate = moment(festivalDates.end_date);
  const firstDate = moment('2024-03-04T00:00:00');
  const lastDate = moment('2024-03-11T23:59:59');
  console.log(lastDate);
  if(!e) {
    return undefined;
  }
  if(moment(e).isBefore(firstDate) || moment(e).isAfter(lastDate)){
    // return `Start date must be between ${firstDate.format('DD/MM/YYYY')} date and ${lastDate.format('DD/MM/YYYY')} date`;
    return 'Start date must be between March 4th and March 11th, 2024';

  }
  return undefined;
};

export const getDateTime = (date) => {
  const formatedTime = moment(date).format('h:mm:ss a');
  return formatedTime;
};

export const getDiffArray = (startDate, endDate, diff = 'days') => {
  let end_date = moment(endDate).format('YYYY-MM-DD');
  let arr = [startDate.format('YYYY-MM-DD')];
  while (moment(arr[arr.length - 1], 'YYYY-MM-DD').isBefore(end_date)) {
    if(moment(arr[arr.length - 1]).isSame(end_date)) {
      // Esto genera que en el ultimo dia se repita, ya que se agrega el endDate + el dia 11.
      // arr.push(endDate.format('YYYY-MM-DD'));
      break;
    }
    arr.push(startDate.add(1, diff).format('YYYY-MM-DD'));
  }
  return arr;

};
