import { Button, Modal } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import { useOnClickOutside } from '../../Hooks/useOnClickOutside';
import React from 'react';
import BlankProfile from '../../assets/images/blank-profile-pic.png';

export const ImageButton = ({sx, image, size = '10px'}) => {
  const [open, setOpen] = React.useState(false);
  const ref = React.useRef(false);
  const handleResizeImg = () => {
    setOpen(false);
  };
  useOnClickOutside(ref, handleResizeImg);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{
          position: 'absolute',
          top: 11,
          left: 11,
          backgroundColor:'#fff',
          opacity: '.3',
          borderRadius: '50%',
          display: 'flex',
          padding: '20px',
          height: '100px',
          width: '100px',
          '&:hover': {
            color: 'white',
            backgroundColor: '#606060'
          },
          ...sx
        }}>
        <ImageIcon 
        
          sx={{
            fontSize: size
          }} />
      </Button>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
      >
        <img 
          ref={ref}
          src={image?.length > 1 ? image : BlankProfile} 
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
            objectFit: 'cover', 
            maxHeight: '800px', 
            maxWidth: '1000px'
          }}/>
      </Modal>
    </>
  );

};