import moment from 'moment';
import { MIN_MINUTES_EVENTS } from '../assets/constants/Calendar';

const formatFunction = (item, key) => (
  { 
    ...item, 
    start_date: moment().add(key, 'hour'),
    end_date: moment().add(key+1, 'hour'),
  }
);

export const formattedItemsCalendar = (items, props, keys) => {
  return items.map((item, key) => (
    { 
      ...item, 
      [keys.itemTimeStartKey]: moment(item[keys.itemTimeStartKey]).subtract(6, 'hour'),
      [keys.itemTimeEndKey]: moment(item[keys.itemTimeEndKey]).subtract(6, 'hour'),
      [keys.itemGroupKey]: item?.stage?.id,
      ...props
    }
  ));
};
'';

export const checkCanMove = (item, itemList, dragTime, moveVenueId, keys, isResize) => { //si es resize, dragTime es el final
  const durationFromStart = item?.[keys.itemTimeEndKey] ? item?.[keys.itemTimeEndKey] : ((item[keys.itemTimeDuration] * 60 * 1000) + item[keys.itemTimeStartKey]);
  const endTimeMoved = isResize ? dragTime : dragTime + (durationFromStart - item[keys.itemTimeStartKey]);

  const startOverlayResize = isResize ? item[keys.itemTimeStartKey] : dragTime;
  const minTime = isResize ? ((dragTime - item.start_date) / 1000 / 60) > MIN_MINUTES_EVENTS : true;

  if(minTime){
    return !itemList.some((e) => { //checkeo si no hay ninguno que cumpla la condicion -> puede mover/resize
      const isSameVenue = e[keys.itemGroupKey] == moveVenueId;
      const isSameItem = item.id === e.id;
      
      if(isSameVenue){
        const checkStartTime = (dragTime > e[keys.itemTimeStartKey] && dragTime < e[keys.itemTimeEndKey]); //si el dragTime esta dentro de otro
        const checkEndTime = (endTimeMoved > e[keys.itemTimeStartKey] && endTimeMoved <= e[keys.itemTimeEndKey]);//si el endTimeMove esta dentro de otro
        const overlayTime = (startOverlayResize <= e[keys.itemTimeStartKey] && endTimeMoved >= e[keys.itemTimeEndKey]); //si contiene a otro o lo sobrepasa
        return (isSameVenue && !isSameItem && (checkStartTime || checkEndTime || overlayTime));
      }
  
    });
  }else{
    return false;
  }

};

export const animateScroll = (scrollRef, invert) => {
  const width = (invert ? -1 : 1) * parseFloat(scrollRef.current.style.width);
  const duration = 600;

  console.log('scrollRef', scrollRef.current.getBoundingClientRect());

  const startTime = performance.now();
  let lastWidth = 0;
  const animate = () => {
    let normalizedTime = (performance.now() - startTime) / duration;
    if (normalizedTime > 1) {
      normalizedTime = 1;
    }
    const calculatedWidth = Math.floor(
      width * 0.7 * (1 + Math.cos(Math.PI * (normalizedTime - 1)))
    );
    scrollRef.current.scrollLeft += calculatedWidth - lastWidth;
    lastWidth = calculatedWidth;
    if (normalizedTime < 1) {
      requestAnimationFrame(animate);
    }
  };
  requestAnimationFrame(animate);
};