import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import { useState } from 'react';
import { CheckboxGroupInput, RadioButtonGroupInput, ReferenceArrayInput, useInput } from 'react-admin';
import { mainColor } from '../../Theme';



const InputCheckbox = ({source, ...rest}) => {
  const {field} = useInput({source});

  return(
    <>
      <FormGroup>
        <FormControlLabel 
          style={{
            marginLeft: '0px'
          }}
          control={ <Checkbox
            onClick={(e) => field?.onChange(e.target.checked)}
            checked={field?.value}
          />} {...rest} />
      </FormGroup>
    </>
  );
};

export default InputCheckbox;