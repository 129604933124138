import React, { useState,useEffect } from 'react';
import { Box, Button, FormHelperText, FormLabel, Typography } from '@mui/material';
import { Title, useGetRecordId, useInput, useResourceContext } from 'react-admin';
import useUploadAws from '../../Hooks/useUploadAws';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
import { useFormContext } from 'react-hook-form';
const InputButtonAws = ({helperText, actionText, noImage, validate, title,subtitle, source = 'picture' ,...rest}) => {
  const { id, field, fieldState } = useInput({ source, validate });
  const imageFieldSource = field?.value?.length > 0 ? { key: field.name, location: field.value } : null;
  const [image, setImage] = useState(title === 'Background Image' ? undefined : imageFieldSource);
  const { uploadAWS } = useUploadAws();
  const {getValues} = useFormContext();
  const handleClick = async (e) => {
    const fileUpload = await uploadAWS(e);
    if (fileUpload.success) {
      console.log(fileUpload);
      setImage(fileUpload);
      if(source === 'icon'){
        field.onChange({url: fileUpload.location});
      }else{
        field.onChange({original:fileUpload.location});
      }
    }
  };
  useEffect(()=>{
    let actualImages = getValues('home.countdown.background_image.original');
    setImage(actualImages);
  },[]);
  return (
    <Box>
      <Box sx={{...rest}}>
        {title && <InputTitle>{title}</InputTitle>}
        {subtitle && 
        <InputSubtitle {...rest}>
          {subtitle}
        </InputSubtitle>}
        <Button sx={{
          color: fieldState.invalid && 'red',
          borderColor: fieldState.invalid && 'red',
        }} variant='outlined' component="label">
          {actionText ?? 'Upload Image'}
          {source === 'icon' ? <input hidden accept="image/png" type="file" onChange={handleClick} /> :
            <input hidden accept="image/*" type="file" onChange={handleClick} />}
          
        </Button>
        {helperText && <FormLabel sx={{marginLeft: '10px'}}>{helperText}</FormLabel>}
        {fieldState.invalid && <FormHelperText sx={{color:'red'}}>The Image is required</FormHelperText>}
      </Box>
      {
        image && !noImage && (
          <img
            {...field}
            src={title === 'Background Image'? image :image?.location}
            alt={title === 'Background Image'? 'alt' :image?.key }
            loading="lazy"
            style={source ===  'icon.url' ? { width: '10%', height: '10%', objectFit: 'contain', } :{ width: '60%', height: '60%', objectFit: 'contain', } }
          />
        )
      }
      {
        image && title === 'Background Image' && (
          <img
            {...field}
            src={title === 'Background Image'? image :image?.location}
            alt={title === 'Background Image'? 'alt' :image?.key }
            loading="lazy"
            style={source ===  'icon.url' ? { width: '10%', height: '10%', objectFit: 'contain', } :{ width: '60%', height: '60%', objectFit: 'contain', } }
          />
        )
      }
    </Box>
  );
};

export default InputButtonAws;