import React, { useEffect, useState } from 'react';
import {  FunctionField, ImageField, TextField, TextInput, usePermissions,  useRedirect, useRefresh, useResourceContext,useStore,useUpdate } from 'react-admin';
import { useQuery } from 'react-query';
import ListCustom from '../../Components/ListCustom/ListCustom';
import { mainColor } from '../../Theme';
import { ListActions } from '../../Components/CustomFilterButton/CustomFilterButton';
import dataProvider from '../../dataProvider';
import JoyForTM from '../../Components/JoyComponent/JoyForTM';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import { switchStyleIos } from '../../Components/StyledComponents/Inputs';
import { Indicator } from '../../Components/RedesignComponents/Indicator';
import { COLORS } from '../../assets/constants/theme';
// Material & Icons
import { Box, Chip, Typography,Button, Modal,Switch,Alert,IconButton,Tooltip} from '@mui/material';
import { MdPersonSearch,MdCheckCircle } from 'react-icons/md';
import { IoMdCloseCircle } from 'react-icons/io';
import { AiFillEyeInvisible } from 'react-icons/ai';
import { AiFillEye } from 'react-icons/ai';
const eventsFilters = [
  <TextInput sx={{width: '100%'}} label="Search" source="q" alwaysOn variant='outlined' />,
];
const paramsList = {
  listParams: {
    hasCreate: true,
    actions: <ListActions from={'talents'}/>,
    sort: {
      field: 'last_modified',
      order: 'DESC'
    }
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'show',
  },

  // tabs: [ {
  //   redirectUrl: 'pending',
  //   title: 'Profile Submissions',
  //   icon: PendingActionsIcon
  // },{
  //   redirectUrl: 'main',
  //   title: 'Talents Main',
  //   icon: TaskAltIcon
  // }, 
  // ]
};


const TalentsList = () => {
  // ESTADOS GLOBALES
  const [selectedTalent, setSelectedTalent] = useStore('selectedTalent', {selected:false, talent:''});
  const permis = usePermissions().permissions;
  const [redirectModal, setRedirectModal] = useState({bool: false, id: null});
  const [filter, setFilter] = useState({});
  const refresh = useRefresh();
  const redirect = useRedirect();
  React.useEffect(() => {
    refresh();
  },[filter]);
  const handlePopup = (status, id) => {
    setSelectedTalent({selected: true, talent: id});
    // if(permis === 'talent_manager') return redirect('/profile');
    if(status === 'pending' && permis !== 'talent_manager') return setRedirectModal({bool: true, id: id});
    return 'edit';
  };
  return (
    <div>
      {permis === 'talent_manager' && <div style={{width:'100%',padding:'10px 15px 20px 20px'}}><Typography fontSize={30} fontWeight={600}>My Talents</Typography></div>}
      <ListCustom 
        filter={filter}
        empty={<Typography>No submissions</Typography>}
        setFilter={setFilter}
        defaultTab={'pending'}
        {...paramsList}
        // kevin: agregue esto para pasarle los permisos actuales al filter
        listParams={{
          ...paramsList.listParams,
          actions: <ListActions permission={permis} from={'talents'} placeholder={'Search by name, slogan, title and bio...'}/>
        }}
        datagridParams={{
          empty: <EmptyTalentsList filter={filter} />,
          sx: {
            padding:'0px 20px',
            zIndex:100,
            '& .MuiTypography-root':{
              color:'#000000',
              fontWeight:400
            },
            '& .MuiTableSortLabel-root':{
              color:COLORS.table.headerSorteable,
              fontWeight:400
            },
            '& .column-imagen': {
              width: '10%',
              textAlign: 'center',
            },
            '& .column-name': {
              width: '20%'
            },
            '& .column-maincategory': {
              width: '15%',
              textAlign: 'center',
            },
            '& .column-id': {
              width: '10%'
            },
            '& .column-completed': {
              width: '17.5%',
              textAlign: 'center',
            },
            '& .column-status': {
              width: '17.5%',
              textAlign: 'center',
            },
            '& .column-draft': {
              width: '10%',
              textAlign: 'center',
            } ,
            '& .MuiTableCell-body': {
              borderBottom:'0px',
            },
          },
          ...paramsList.datagridParams,
          rowClick: (id, resource, record,e) => handlePopup(record?.submission?.status, id)
          
        }}
      >
        <FunctionField  label="Picture" source="imagen" render={(record) => <ImageField
          source="picture.original"
          label='Picture'
          sx={{ '& img': { borderRadius: 200, maxWidth: 50, maxHeight: 45, objectFit: 'cover' } }}
        />}/>
       
        <TextField source="name" />
        <FunctionField label="Category"  source="maincategory" render={(record)=> record?.main_category ? <Chip sx={{width:'auto'}} label={record?.main_category} /> : null}/>
        <span label=""  style={{visibility: 'hidden'}}></span>
        <FunctionField label="Completed?"  source="completed" render={(record) => 
          <Typography
            sx={{
              fontSize: '.9em',
              fontWeight: '600',
              color: record?.completed ? '#009b00' : '#ffc990',
              textTransform: 'uppercase',
            }}
          >
            {record?.completed ? <MdCheckCircle color='#21B141' size={26}/> : <IoMdCloseCircle color='grey' size={26}/>}
          </Typography> 
        } />
        <FunctionField label="Last submission status" source="status" 
          render={(record) => 
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              {record?.submission?.status === 'draft'  || record?.submission === null || record?.submission?.status === 'not_submitted' ? <Indicator /> : (<Indicator data={record?.submission} />)}
            </div> 
          }/>
        {permis !== 'talent_manager' && <FunctionField label="Visibility" source="draft" render={(record) =>   record.completed === true ? (<SideActions {...record} />)  : (<SideActions {...record} disabled={true}/>)} />}
      </ListCustom> 
      <RedirectModal resource="talents" open={redirectModal.bool} id={redirectModal.id} setOpen={setRedirectModal} />
    </div>
  );
};

// En redisenio se cambio por > INDICATOR.JSX
export const PillInfo = ({sx, noIcon, propColor, children, status}) => {

  let color = ['pending', 'draft'].includes(status) ? '#ffc990' :
    status === 'approved' ? '#009b00' :
      status === 'rejected' ? 'red' :
        '#89d1ee';

  // let Icon = ['pending', 'draft'].includes(status) ? <PendingActionsIcon style={{width: '20px', height: '20px', color: color}} /> :
  //   status === 'approved' ? <TaskAltIcon style={{width: '20px', height: '20px', color: color}}  /> :
  //     status === 'rejected' ? <DoDisturbAltIcon style={{width: '20px', height: '20px', color: color}} /> : null;
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height:'33px',
        justifyContent: 'flex-start',
        color: propColor ? propColor : color,
        // border: `2px solid ${propColor ?? color}`,
        borderRadius: '12px',
        padding: '5px',
        maxWidth: '112px',
        gap: '20px',
        ...sx,
      }}
    >
      <Alert variant="filled" severity="success">
        {children ?? status ? status : ''}
      </Alert>
      {/* <Typography
        sx={{
          fontSize: '.9em',
          fontWeight: '600',
          color: propColor ?? color,
          textTransform: 'uppercase',
        }}
        
      >
      </Typography>  */}
      {/* {!noIcon && Icon && React.cloneElement(Icon)} */}
    </Box>
  );
};


export const EmptyTalentsList = ({filter}) => {
  return(
    <Box  textAlign="center" m={'100px 1px 1px 1px'}>
      <MdPersonSearch color={mainColor} size="120px" />
      <Typography variant="h4" color={mainColor} paragraph>
        {filter?.status?.length === 1 ? 'No submissions pending' : 'No talents found.'}
      </Typography>
    </Box>
  );
};


export const SideActions = (record,disabled) => {
  const { id, _draft, completed } = record;
  const resource = useResourceContext();
  const refresh = useRefresh();
  const permis = usePermissions()?.permissions;
  const customToast = useCustomToasty();
  const [checked,setChecked] = useState(!_draft);
  const [refetch] = useUpdate(resource, {id: id, data: {draft: !_draft},previousData: record}, {
    onError: (err) => {
      customToast('error', err);
    },
    onSuccess: (data) => {
      if(data['_draft'])  return customToast('success', 'Talent has been unpublished');
      return customToast('success', 'Talent published succesfuly');
    }
  }); 

  // const { refetch } = useQuery(['publish'], () => dataProvider.update(`${resource}`, {id: id, data: {draft: !_draft}}), 
  //   {
  //     enabled: false,
  //     onSuccess: ({data}) => {
  //       if(data['_draft']) return customToast('success', 'Talent published succesfuly');
  //       return customToast('success', 'Talent has been unpublished');
  //     }
  //   }
  // );
  const handleRefetch = (e) => {
    e.stopPropagation();
  };
  const handleRefetchSwitch = (e) => {
    e.stopPropagation();
    setChecked(!checked);
    refetch();
  };
  return(
    completed === true ?
      <IconButton disabled={permis === 'talent_manager'} onClick={handleRefetch} sx={{':hover':{
        backgroundColor: 'transparent',
      }}}>
        <Switch sx={switchStyleIos}   onChange={handleRefetchSwitch} checked={checked}  disabled={completed === false ? true : false}/>
      </IconButton>
      :
      (
        <Tooltip title="Complete the talent first to publish it" placement='bottom-end'>
          <IconButton disabled={permis === 'talent_manager'} onClick={handleRefetch} sx={{':hover':{
            backgroundColor: 'transparent',
          }}}>
            <Switch sx={switchStyleIos}   onChange={handleRefetchSwitch} checked={checked}  disabled={completed === false ? true : false}/>
          </IconButton>
        </Tooltip>
      )

    
  );
};

export const RedirectModal = ({open, setOpen, id, resource}) => {

  const redirect = useRedirect();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30%',
    height:'auto',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 24,
    p: 4,
  };

  const titleStyle = {
    width: '100%',
    borderBottom: `1px ${mainColor} solid`,
    padding: '5px', 
    textTransform: 'uppercase', 
    fontSize: '18px',
    fontWeight: '600', 
    color: mainColor,
  };

  const redirectSubmission = () => {
    return redirect('show', resource, id);
  };

  const redirectEdit = () => {
    return redirect('edit', resource, id);
  };

  return (
    <Modal sx={{height: 'auto'}} open={open} onClose={() => setOpen({bool: false, id: null})}>
      <Box sx={style}>
        <Typography sx={titleStyle}>Pending Submission</Typography>
        <Typography
          sx={{
            fontWeight: '400',
            color: '#757575'
          }}
        >
          This talent currently has submitted data pending of reviewal.
          Its important to note that if the aforementioned submission gets approved, any changes you edit will be overriden.
        </Typography>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          gap: '20px',
          marginTop: '10px'
        }}>
          <Button onClick={redirectSubmission} variant="contained">Review Submission</Button>
          <Button onClick={redirectEdit} variant="outlined">Continue to edit</Button>
        </Box>
      </Box>
    </Modal>
  );
};


export default TalentsList;

