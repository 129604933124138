import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { COLORS } from '../../assets/constants/theme';
ChartJS.register(ArcElement, Tooltip, Legend);


const options = {
  plugins: {
    responsive: true,
    legend: {
      position: 'bottom',
      labels: {
        font: {
          size: '13px',
        },
        boxWidth: 20,
      }, // Esto oculta la leyenda
    },
    tooltip:{
      enabled:true,
    },
    title: {
      display: true,
      text: 'Forms', 
      font: {
        size: 15, 
        weight: '600', 
      },
      color:'black',
    },
  },
};



export function PiechartForms({ completed, missing }) {

  const dataCompletados = {
    labels: ['Complete', 'Incomplete'],
    datasets: [
      {
        label: 'Forms',
        data: [completed, missing],
        backgroundColor: [
          COLORS.light.secondary.main,
          COLORS.light.secondary.light,
        ],
        borderColor: [
          COLORS.light.secondary.main,
          COLORS.light.secondary.light,
        ],
        borderWidth: 1,
      },
    ],
  };

  return <Pie data={dataCompletados} options={options} />;
}
