import { Button } from '@mui/material';
import { AsideActionButton } from '../CssProperties';
import { AiFillDelete } from 'react-icons/ai';

export const asideActionButton = {
  'font-size': '.8rem',
  'font-weight': '600',
  'text-transform': 'uppercase',
  'place-self': 'center',
  'align-items': 'center',
};

export const AsideDelete = ({color = '#f99190', children}) => (
  <Button
    sx={{...asideActionButton, color: color}}
    startIcon={<AiFillDelete sx={{color: '#f99190'}} fontSize="20px"/>}
  >
    {children}
  </Button>
);