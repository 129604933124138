import { useStore } from 'react-admin';
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import ModalItemClicked from './ModalItemClicked';
import { stage_color } from '../../assets/constants/Calendar';
// ICONOS
import {
  FaPalette,
  FaMusic,
  FaRunning,
  FaChild,
  FaChalkboardTeacher,
  FaTheaterMasks,
} from 'react-icons/fa';
const CalendarItem = ({ item, itemContext, getItemProps, getResizeProps, keys, timelineContext, ratio = 1 }) => {
  const [selectedEvent,setSelectedEvent]=useStore('selectedEvent',{
    id: null,
    selected:false
  });
  const [itemResalted, setItemResalted] = useState(false);
  const [modalOpen, setModalOpen] = useState(itemContext.selected);
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const [formatDate, setFormatDate] = useState({
    start_date: moment(item[keys.itemTimeStartKey]).format('HH:mm'),
    end_date: moment(item[keys.itemTimeStartKey]).add(item.duration, 'minutes').format('HH:mm')
  });
  const itemProps = getItemProps();
  const ref = useRef(true);
  const modalRef = useRef(true);
  const { border, ...styles } = itemProps.style;

  const defaultsDates = {
    start_date: moment(item[keys.itemTimeStartKey]).format('HH:mm'),
    end_date: moment(item[keys.itemTimeStartKey]).add(item.duration, 'minutes').format('HH:mm')
  };

  const handleClose = () => {
    itemContext.selected = false;
  };

  useEffect(() => {
    if (itemContext.dragging) {
      const endTimeDrag = itemContext.dragTime + (item[keys.itemTimeEndKey] - item[keys.itemTimeStartKey]);
      const format_date = {
        start_date: moment(itemContext.dragTime).format('HH:mm'),
        end_date: moment(endTimeDrag).format('HH:mm')
      };
      setFormatDate(format_date);
    } else if (itemContext.resizing) {
      setFormatDate(prev => ({ ...prev, end_date: moment(itemContext.resizeTime).format('HH:mm') }));
    } else if (itemContext.selected) {
      setFormatDate(defaultsDates);
    }
  }, [itemContext]);

  const handleItemResalted = () => {
    if (selectedEvent.id === item.id) {
      setItemResalted(true);
    } else {
      setItemResalted(false);
    }
  };

  useEffect(() => {
    setModalOpen(itemContext.selected);
    handleItemResalted();
  }, [itemContext.selected, selectedEvent.id, item.id]);

  useEffect(() => {
    if (modalOpen) {
      setItemResalted(false);
      setSelectedEvent({ id: null, selected: false });
    }
  }, [modalOpen]);
  // Selector de iconos
  
  const handlerIconos = (item, size = 20, color = 'white') => {
    switch (item?.category) {
    case 'Music':
      return <FaMusic color={color} size={size}/>;
    case 'Art':
      return <FaPalette color={color} size={size}/>;
    case 'Performance':
      return  <FaTheaterMasks color={color} size={size}/>;
    case 'Kids & Family':
      return <FaChild color={color} size={size}/>;
    case 'Workshop':
      return <FaChalkboardTeacher color={color} size={size}/>;
    case 'Yoga & Movement':
      return <FaRunning color={color} size={size}/>;
    default:
      return <FaMusic border={'5px solid black'} color={color} size={size} />;
    }

  };
  const boxShadowStyle = modalOpen
    ? 'rgba(0, 0, 0, 0.6) 0px 5px 15px'
    : itemResalted
      ? '0 0 20px rgba(46, 116, 119, 0.8), 0 0 30px rgba(46, 116, 119, 0.6), 0 0 40px rgba(46, 116, 119, 0.4)'
      : stage_color[item?.stage?.id]?.light === '#6533B4'
        ? 'rgba(0, 0, 0, 0.3) 0px 5px 15px'
        : 'none';

  return (
    <Box id={item.id} ref={ref} sx={{
      ...styles,
      borderRadius: '6px', 
    }}

    >
      <div
        {...itemProps}
        style={{ backgroundColor: 'rgb(33, 150, 243)', overflow: 'hidden', borderRadius: '6px', border: 'none', boxShadow: boxShadowStyle }}
        // onMouseEnter={itemProps.onMouseDown}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

        <Box
          sx={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: stage_color[item?.stage?.id]?.light ? stage_color[item?.stage?.id]?.light : 'none',
            color:stage_color[item?.stage?.id]?.light  === '#6533B4' ? '#FFFFFF' :'#363636',
            border: 'none',
            padding: '4px 10px 0 10px',
            zIndex: 9239,
            // boxShadow:'inset 0px 0px 20px -8px rgba(97,97,97,1)',
            gap: `calc(${ratio} * 3px`
          }}
        >
          <Typography fontWeight={700} variant="subtitle1" sx={{ lineHeight: '10px', fontSize: `calc(${ratio} * 1em)` }}>{itemContext.title}</Typography>
          {/* Icono para la category.*/}
          {/* <Box
            sx={{
              position: 'absolute',
              bottom: 5,
              right: 5,
              height: `calc(${ratio} * 20px)`,
              width: `calc(${ratio} * 20px)`,
              backgroundColor: '#dedede',
              borderRadius: '50%',
              display: 'flex',
              flexDirection: 'row',
              gap: '20px',
              alignItems:'center',
              justifyContent: 'center'
            }}
          >
            <Typography fontWeight={600} variant="body2" sx={{ alignSelf: 'end', fontSize: `calc(${ratio} * 0.1)` }}>{handlerIconos(item && item, Math.floor(ratio * 10))}</Typography>
          </Box> */}

          {
            item?.talents?.length > 0 && item?.talents[0]?.name !== itemContext.title && (
              <Typography variant="subtitle1" sx={{ lineHeight: '10px', fontSize: `calc(${ratio} * 0.8em)` }}>{item?.talents[0]?.name ?? ''}</Typography>
            )
          }
          <Typography fontWeight={600} variant="body2">{`${formatDate?.start_date} - ${formatDate?.end_date}`}</Typography>
          
        </Box>
        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
      </div>
      {
        (modalOpen && !itemContext.dragging && !itemContext.resizing) && (
          <ModalItemClicked clickRef={ref} itemClicked={item} />
        )
      }
    </Box>
  );
};

export default CalendarItem;