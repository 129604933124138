import React, { useState } from 'react';
import { required, DateTimeInput,ReferenceArrayInput, AutocompleteArrayInput,useRedirect, useRecordContext } from 'react-admin';
// Hooks & Utils
import { DEEP_LINKING_OPTIONS } from '../../../assets/constants/types';
import { INPUTS_VARIANT } from '../../../assets/constants/Form';
import { COLORS } from '../../../assets/constants/theme';
// Components
import {InputTabSingleSelector,InputDeepLinking} from '../../../Components/RedesignComponents/Inputs/index';
import InputSelect from '../../../Components/InputsForm/InputSelect';
import { autoCompleteInput } from '../../../Components/StyledComponents/Inputs';
import { InputTitle } from '../../../Components/StyledComponents/InputTitle';
import { InputSubtitle } from '../../../Components/StyledComponents/InputSubtitle';
import AdaptableFormRedesign from '../../../Components/AdaptableForm/AdaptableFormRedesign';
import InputBoolean from '../../../Components/InputsForm/InputBoolean';
import InputText from '../../../Components/InputsForm/InputText';
// Material & Icons
import {Box, Typography,Button, TextField,Select, MenuItem } from '@mui/material';
import { MdArrowBack } from 'react-icons/md';
import { convertToCostaRicaTime, convertToUtcMinusSix } from '../../../helpers/date';


const FormNotifications = ({ isEdit }) => {
  const redirect = useRedirect();
  const record = useRecordContext();
  const recordScheduled = record?.status;
  const [sendNow, setSendNow] = useState(false);
  const [type, setType] = useState(isEdit ? record?.internal_deeplink === true ? 'Internal' : 'External' : undefined);
  const [linking, setLinking] = useState(undefined);
  const [valueUrl, setValueUrl] = useState(undefined);
  console.log(linking, 'linking');
  console.log(valueUrl, 'valueUrl');
  const handleSelectChange = (event) => {
    setLinking(event.target.value);
  };
  const data = [
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}> CONTENT</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 }
        },
        {
          field: <InputText
            required={true}
            title={'Title'}
            label=""
            subtitle=''
            source='title'
            fullWidth
            validate={[required()]}
            disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true}
          />,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        },
        {
          field: <InputText
            required={true}
            title={'Body'}
            subtitle={'What are you trying to say with this notification'}
            label=""
            source='body'
            fullWidth
            inputSx={{height:'100px'}}
            validate={[required()]}
            disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true}
          />,
          breakpoints: { xs: 8.5 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        }, 
      ]
    },
    {
      title:'',
      inputs : [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>LINKING</Typography>
              <Typography fontWeight={300} fontSize={'14px'} sx={{marginTop:'20px'}}>You can send links in your notification. You can either link to internal content in the app or to external.</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 },
        },
        {
          field:
          <InputTabSingleSelector
            setLinking={setLinking}
            setValueUrl={setValueUrl}
            setState={setType}
            state={type}
            label=""
            title='TYPE'
            choices={[{id:1,text:'Internal'},{id:2,text:'External'}]}
            fullWidth
            source="internal_deeplink"
            validate={[required()]}
            disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true}
          />,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 4 }
        },
        {
          field: <></>,
          breakpoints: { xs: 2 }
        },
        { 
          noShow:type === 'Internal' ? false : true,
          field: <>
            <InputTitle sx={{marginTop:'30px'}}>INTERNAL LINKING</InputTitle>
            <InputSubtitle>If your notification links to an internal page in the app you need to specify it</InputSubtitle>
          </>,
          breakpoints: { xs: 8.5 }
        },   
        {
          noShow:type === 'Internal' ? false : true,
          field: <></>,
          breakpoints: { xs: 3 }
        },
        {
          noShow:type === 'Internal' ? false : true,
          field: 
          linking &&(
            <Select disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true} value={linking ? linking : undefined} onChange={handleSelectChange} size='small' fullWidth sx={{marginTop:'9px',  borderRadius: '10px',}}>
              {DEEP_LINKING_OPTIONS.map(option => (
                <MenuItem key={option.id} value={option.text}>{option.text}</MenuItem>
              ))}
            </Select>) ,
          breakpoints: { xs: 4}
        },
        {
          noShow:type === 'Internal' ? false : true,
          field: <InputDeepLinking
            linking={linking}
            valueUrl={valueUrl}
            optionValue={'text'}
            optionText={'text'}
            fullWidth
            source="url"
            title={'Type'} label={''}
            disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true}
          />,
          breakpoints: { xs: 4}
        },
        {
          noShow:type === 'External' ? false : true,
          field: <InputText
            title={<Typography fontWeight={600} fontSize={'16px'} color={'#000'} sx={{marginTop:'30px'}}>URL</Typography>}
            subtitle={'Link to external content'}
            label=""
            source='url'
            fullWidth
            disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true}
          />,
          breakpoints: { xs: 3.5 }
        },
        {
          field: <></>,
          breakpoints: { xs: 3.5 }
        },
      ]
    },
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}> SCHEDULE</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 }
        },
        {
          field: <>
            <InputTitle sx={{fontWeight: 600, fontSize: '16px', color: '#000'}}>SEND AT</InputTitle>
            <InputSubtitle sx={{visibility:'hidden'}}>subtitle</InputSubtitle>
            <DateTimeInput
              format={(e) => convertToCostaRicaTime(e)}
              parse={(e) => convertToUtcMinusSix(e)}
              variant={INPUTS_VARIANT}
              InputProps={{
                sx: {
                  borderRadius: '10px'
                }
              }} 
              subtitle={'What are you trying to say with this notification'}
              label=""
              source='send_at'
              disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true}
              fullWidth
            />
          </>,
          breakpoints: { xs: 4 }
        },
        {
          field: <InputBoolean
            title={<Typography fontWeight={600} fontSize={'16px'} color={'#000'}>SEND NOW</Typography>}
            label=""
            subtitle={<Typography sx={{visibility:'hidden'}}>subtitle</Typography>}
            source='send_now'
            value={sendNow}
            onChange={() => setSendNow(prev => !prev)}
            fullWidth
            isColumn={true}
            disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true}
          />,
          breakpoints: { xs: 4 }
        },
      ]
    },
    {
      title: '',
      inputs: [
        {
          field: <div style={{ width:'100%', height:'100%',display:'flex', flexDirection:'column',justifyContent:'space-between'}}>
            <Box>
              <Typography fontWeight={700} fontSize={'16px'} color={COLORS.light.secondary.main}>AUDIENCE</Typography>
              <Typography fontWeight={300} fontSize={'14px'} sx={{marginTop:'20px'}}>If you want to send this notification to a specific group of users.You can segment according to artists and events.</Typography>
            </Box>
          </div>,
          breakpoints: { xs: 3 },
        },
        {
          field: 
         <>   
           <InputTitle sx={{fontWeight: 600, fontSize: '16px', color: '#000'}}>FAVORITE ARTISTS</InputTitle>
           <InputSubtitle sx={{visibility:'hidden'}}>subtitle</InputSubtitle>
           <ReferenceArrayInput perPage={100} fullWidth  source='filter.favourite_talents' reference="talents" >
             <AutocompleteArrayInput fullWidth optionText={'name'} label='' variant='outlined' sx={autoCompleteInput}  disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true}/>
           </ReferenceArrayInput>
         </>,
          breakpoints: { xs: 4 }
        },
        { 
          field: 
          <>   
            <InputTitle sx={{fontWeight: 600, fontSize: '16px', color: '#000'}}>FAVORITE EVENTS</InputTitle>
            <InputSubtitle sx={{visibility:'hidden'}}>subtitle</InputSubtitle>
            <ReferenceArrayInput perPage={100} fullWidth  source='filter.favourite_events' reference="events">
              <AutocompleteArrayInput fullWidth optionText={'name'} label='' variant='outlined' sx={autoCompleteInput}  disabled={!isEdit ? false :recordScheduled === 'scheduled' ? false : true}/>
            </ReferenceArrayInput>
          </>,
          breakpoints: { xs: 4 }
        },
        // {
        //   field: <InputBoolean
        //     source='test'
        //     fullWidth
        //   />,
        //   breakpoints: { xs: 8 }
        // }
      ]
    },
  ];
  const goBack = () => {
    redirect('/notifications');
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          marginBottom: 5,
          padding: '20px 16px',
          // height: '6.5%',
          gap: 1,
        }}
      >
        <button
          onClick={() => goBack()}
          style={{
            display: 'flex',
            border: 'none',
            background: 'none',
            cursor: 'pointer',
            alignItems: 'center',
          }}
        >
          <MdArrowBack size={30} />
        </button>
        <Typography fontSize={'32px'} fontWeight={600}>
          {isEdit ?'Edit Notification - '+ record?.title : 'New Notification'}
        </Typography>
      </Box>
      <AdaptableFormRedesign
        isEdit={isEdit}
        data={data}
        from={false}
        icon={false}
        saveBackground={true}
        sx={{width:'100px' }}
      />
    </>
  );
};

export default FormNotifications;