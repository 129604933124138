import { Box, Typography,LinearProgress } from '@mui/material';
import { mainColor } from '../../Theme';
import { useEffect, useMemo,useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAbsolute } from '../../Hooks/usePosition';

function checkValue (key, _callback) {
  // Checks if the value exists in the form
  if((!_callback(key) || _callback(key) === '' || _callback(key).length === 0)) return true;
  return false;
}

Object.objString = function(obj, key, _callback) {
  // Checks if the key is equal to the required keys. if the key is a nested obj, it will search within the obj till it finds its key, else
  let nestedKey = key.includes('.') ? key.split('.') : [key];
  if(Object.keys(obj).find(item => item === key || item === nestedKey[1])) return checkValue(key, _callback);
  if(!key || !obj || !obj[nestedKey[0]]) return false;
  if(!Object.keys(obj[nestedKey[0]]).find(item => item === nestedKey[1])) {
    return false;
  } else {
    return Object.objString(obj[nestedKey[0]], key, _callback);
  }

};

export const LineCompleteSticky = ({progressBarFields, elementId}) => {
  const fixedPosition = useAbsolute(elementId);

  const methods = useFormContext();
  const size = '110px';
  const radius = 50;
  const per = (2 * Math.PI * radius);
  const percentage = useMemo(() => {
    let total = progressBarFields.length;
    let keys = methods.getValues();
    Object.keys(keys)?.map((key, index) => {
      if(progressBarFields?.find(item => Object.objString(Object.fromEntries([[key, methods.getValues(key)]]), item, methods.getValues))) return total -= 1;
    });
    return total;
  },[methods]);

  const progressStyle = {
    fill: 'none',
    stroke: mainColor,
    strokeWidth: '10px',  
    strokeDasharray: `calc((((${percentage} / ${progressBarFields.length}) * 100) * ${per}) / 100) 999`,    
    strokeDashoffset: '0px',
    transition: 'stroke-dasharray 0.7s linear 0s',
  };
  const circleStyle = {
    fill: '#fff',
    stroke: '#D2D2D2',
    strokeWidth:'10px',
  };

  const progressSvg = {
    transform: 'rotate(-90deg) scaleY(-1)',
    width: size,
    height: size,
    borderRadius: '50%',
    // backgroundColor:'#fff',
    boxShadow: '0px 0px 20px -8px rgba(97,97,97,1)'
  };

  const textStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 99999,
    fontSize: '1.2vw',
    fontWeight: '600',
    color: percentage === 9 ? mainColor : 'black'
  };

  return (
    <Box sx={{position: 'sticky', zIndex: 999, display: 'flex', alignItems: 'center',width: '100%' }}>
      <Box sx={{width:'100%',mr: 1 }}>
        <LinearProgress sx={{marginTop:'20px',borderRadius:'10px',height:'15px'}} value={Math.floor((percentage / progressBarFields.length) * 100)} variant='determinate'/>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography sx={{fontSize:'1.5em',fontWeigh:600}}>{Math.floor((percentage / progressBarFields.length) * 100)}<span style={{fontSize: '1vw'}}>%</span></Typography>
      </Box>
    </Box>
  );
};