import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useState } from 'react';
// OTRA LIBRE
import Map, { Marker } from 'react-map-gl';
import { CENTER_COORD } from '../../../../assets/constants/Calendar';
import { useFormContext } from 'react-hook-form';
import { useInput } from 'react-admin';
import { Typography,Button,TextField,NumberINput } from '@mui/material';
import { COLORS } from '../../../../assets/constants/theme';

const VenueMap = () => {
  const { getValues,setValue } = useFormContext();
  const form = getValues();
  const {field } = useInput({ source: 'location.geometry.coordinates', defaultValue: [0,0]});
  const location = form?.location;
  const coordinates = location?.geometry?.coordinates;
  const [lng, setLng] = useState(coordinates !== null && form?.location?.geometry?.coordinates[0]);
  const [lat, setLat] = useState(coordinates !== null && form?.location?.geometry?.coordinates[1]);
  const titleForPin = form?.name;
  const iconPin = form?.icon?.url;
  const { input } = useInput({ source: 'location.geometry.coordinates' });

  function addMarker(event) {
    const { lng, lat } = event.lngLat;
    field.onChange([lng, lat]);
    setValue('location.geometry.coordinates', [lng, lat]);
  }
  const handleLngChange = (event) => {
    const value = event.target.value;
    let newLng = 0; 
    if (value == '') {
      setLng(value);
      field.onChange([newLng, lat]);
    }else if(value > 90){
      newLng = 90;
      setLng(newLng);
      field.onChange([newLng, lat]);
    }else if(value < -90){
      newLng = -90;
      setLng(newLng);
      field.onChange([newLng, lat]);
    }
    else{
      let newLng = parseFloat(value);
      setLng(value);
      field.onChange([newLng, lat]);
    }
  };

  const handleLatChange = (event) => {
    const value = event.target.value;
    let newLat = 0; 
    if (value == '') {
      setLat(value);
      field.onChange([lng, newLat]);
    }else if(value > 90){
      newLat = 90;
      setLat(newLat);
      field.onChange([lng, newLat]);
    }else if(value < -90){
      newLat = -90;
      setLat(newLat);
      field.onChange([lng, newLat]);
    }
    else{
      let newLat = parseFloat(value);
      setLat(value);
      field.onChange([lng, newLat]);
    }
  };
  const handleDeleteIcon = (event) => {
    setLng(0);
    setLat(0);
    field.onChange([0, 0]);
  };
  // Simulacion de coordenadas, a eliminar !
  const playaHermosaCoordinates = [-83.76655090787587, 9.186858765543533];
  const locations = [
    { id: 1, latitude: playaHermosaCoordinates[1] + 0.005, longitude: playaHermosaCoordinates[0] + 0.005,name: 'Location 1' },
    { id: 2, latitude: playaHermosaCoordinates[1] - 0.003, longitude: playaHermosaCoordinates[0] - 0.003,name: 'Location 2' },
    { id: 3, latitude: playaHermosaCoordinates[1] + 0.002, longitude: playaHermosaCoordinates[0] - 0.002,name: 'Location 3' },
  ];
  // 
  useEffect(() => {
    const coordinates = getValues()?.location?.geometry?.coordinates;
    if(coordinates){
      setLng(coordinates[0] === 0 ? '': coordinates[0]);
      setLat(coordinates[1] === 0 ? '': coordinates[1]);
    }
  },[coordinates]);
  return (
    <>
      <div style={{display:'flex',flexDirection:'row',gap:20}}>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:10}}>
          <Typography fontSize={'14.4px'} color={'rgba(0, 0, 0, 0.92)'}>Longitud</Typography>
          <CustomTextInput label="" value={lng} helperText="Helper text for my field" inputProps={input} onChange={handleLngChange}/>
        </div>
        <div style={{display:'flex',flexDirection:'row',alignItems:'center',gap:10}}>
          <Typography fontSize={'14.4px'} color={'rgba(0, 0, 0, 0.92)'}>Latitud</Typography>
          {/* <TextInput 
            InputProps={{
              sx: {
                padding: 0
               
              }}} 
            sx={{'& .MuiInputBase-input':{width:'8em',padding:0,margin:0,widh:'50px'},padding:0,margin:0}} 
            helperText='' 
            source={'location.geometry.coordinates[1]' }
            label='' 
            variant='standard' 
            onChange={handleLatChange} /> */}
          <CustomTextInput label="" value={lat} inputProps={input} onChange={handleLatChange}/>
        </div>
      </div>
      <Map
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        mapLib={import('mapbox-gl')}
        initialViewState={{
          longitude:lng ? lng : CENTER_COORD[0],
          latitude: lat ? lat : CENTER_COORD[1],
          zoom: 15,
        }}
        style={{ height: '50vh', width: '50vw',marginTop:'10px' }}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        onClick={addMarker}
        // Atribuciones de Mapbox
        attributionControl={false}
      >
        <div style={{position:'absolute',bottom:'0px',right:'0px',zIndex:100}}>
          <Button variant='contained' onClick={handleDeleteIcon}
            sx={{
              backgroundColor:COLORS.table.indicators.fontColor.rejected, 
              '&.Mui-disabled': {
                backgroundColor:COLORS.table.indicators.fontColor.rejected, 
                opacity: 0.5,
                color: 'white',
              },}} 
            disabled={coordinates && coordinates[0] === 0 && coordinates[1] === 0 ? true : false}
          >
          Remove Pin
          </Button>
        </div>
        {/* <AttributionControl/> */}
        {
          field.value ? (
            <>
              <Marker
                anchor="bottom"
                longitude={lng ? lng : field.value[0]}
                latitude={lat ? lat : field.value[1]}
              >
                {iconPin ? (
                  <LocationPin pin={true} src={iconPin} titleForPin={titleForPin}/>
                ) : (
                  <LocationPin titleForPin={titleForPin}/>
                )}
              </Marker>
            </>
          ) : null}
          {/* En caso de que tengamos una lista de Ubicaciones de todos las locations, se renderizara de esta manera */}
        {/* {locations?.map((location) => (
          <Marker
            key={location.id}
            anchor="bottom"
            longitude={location.longitude}
            latitude={location.latitude}
          >
            <LocationPin titleForPin={location.name}/>
          </Marker>
        ))} */}
      </Map>
    </>
  );
};

const LocationPin = ({ size = 30, pin, src, titleForPin}) => {
  const pinStyle = {
    cursor: 'pointer',
    fill: '#348feb',
    stroke: 'none',
  };

  return (
    <>
      {pin === true ? (
        <div style={{padding:1,margin:0,display:'flex',flexDirection:'column',alignItems:'center',gap:2}}>
          <img src={src} style={{ width: size, height: size }} />
          {titleForPin &&<span style={{color:'white',fontSize:'14px',backgroundColor:'#000000AD',padding:5,borderRadius:'10px'}}>{titleForPin}</span>}
        </div>
      ) : (
        <div style={{padding:1,margin:0,display:'flex',flexDirection:'column',alignItems:'center',gap:2}}>
          {titleForPin &&<span style={{color:'white',fontSize:'14px',backgroundColor:'#000000AD',padding:5,borderRadius:'10px'}}>{titleForPin}</span>}
          <svg height={size} viewBox="0 0 24 24" style={pinStyle}>
            {/* <path d={CIRCLE} /> */}
            <circle cx="10" cy="10" r="6" />
          </svg>
        </div>
        
      )}
    </>
  );
};
const CustomTextInput = ({ label, value, inputProps,onChange,errors  }) => {
  
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography fontSize={'14.4px'} color={'rgba(0, 0, 0, 0.92)'}>{label}</Typography>
      <TextField
        type='number'
        fullWidth
        variant="standard"
        value={value}
        onChange={onChange}
        InputProps={{
          InputProps: {
            sx: {
              '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
                appearance: 'none',
                margin: 0,
              },
            },
          },
          sx: {
            padding: 0,
          },
          ...inputProps
        }}
        sx={{ '& .MuiInputBase-input': { padding: 0, margin: 0 } }}
      />
    </div>
  );
};

export default VenueMap;
