import { transformSlug } from '../../../helpers/transforms';

export const transform = (data) => {
  const slug = transformSlug(data?.name);
  return(
    {
      ...data,
      envision_festival: data.envision_festival ?? false,
      ethos_instructor: data.ethos_instructor ?? false,
      slug
    }
  );
};