import {
  Box,
  CircularProgress,
  Grid,
  Tooltip,
} from '@mui/material';
import React from 'react';
import {
  SimpleForm,
  useEditContext,
  useGetOne,
  usePermissions,
} from 'react-admin';
import { FormTitle } from '../StyledComponents/FormTitle';
import { AdaptableToolbar } from './AdaptableToolbar';
import { AdaptableTooltips } from './AdaptableTooltips';
import { CompleteSticky } from '../../Resources/TalentForm/CompleteSticky';


const AdaptableForm = ({
  simpleform,
  data,
  direction = 'row',
  rowSpacing = 1,
  index,
  from,
  isEdit,
  idToaction,
  progressBarFields,
  recordFromModal,
  deleteResource,
  ...rest
}) => {
  const { isLoading } = useEditContext();
  const permis = usePermissions()?.permissions;
  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  
  // const defaultValor = useGetOne('events', {id: idToaction});
  // const dataEditar= defaultValor?.data;
  return (
    <>
      <AdaptableTooltips from={from} permis={permis} isEdit={isEdit} />
      <SimpleForm
        id={`${index}'adaptable-simple-form'`}
        sx={{paddingBottom: '100px', position: 'relative'}}
        // RecordFromModal, es para cargar los datos del evento que viene del modal EDIT unscheduledEvents
        defaultValues={recordFromModal ? recordFromModal : null}
        toolbar={
          <AdaptableToolbar
            {...rest}
            progressBarFields={progressBarFields}
            floating={recordFromModal ?false : rest.floating}
            
          />
        }
        {...simpleform}
        sanitizeEmptyValues
      >
        {progressBarFields && <CompleteSticky elementId={`${index}'adaptable-simple-form'`} progressBarFields={progressBarFields} />}
        <Box
          id={index}
          sx={{
            maxWidth: 1400,
            width: '100%',
          }}
        >
          {data?.map((item, index) => {
            if (item?.noShow) return <div></div>;
            return (
              <Grid
                container
                direction={'column'}
                sx={{
                  borderBottom:
                    index !== data?.length - 1 && '1px solid rgba(0,0,0, .15)',
                  marginY: 2,
                }}
                key={`AdaptableFormKey-${index}`}
              >
                <Grid
                  item
                  xs={2}
                  sx={{
                    display: 'flex',
                    alignItems: 'start',
                    justifyContent: 'start',
                  }}
                >
                  <FormTitle
                  >
                    {item?.title?.toUpperCase()}
                  </FormTitle>
                </Grid>
                <Grid
                  sx={{
                    alignSelf: 'flex-end',           
                    justifyContent: 'space-between',
                  }}
                  container
                  rowSpacing={rowSpacing}
                  columnSpacing={2}
                  xs={10}
                >
                  {item.title && (
                    <Grid
                      key={Math.floor(Math.random() * 100 * 2 * 341)}
                      sx={{ width: '100%', height: '3vw' }}
                      item
                    />
                  )}

                  {item.inputs.map((input, key) => (
                    <Tooltip
                      placement={input?.placement ?? 'top'}
                      title={input?.tooltip ? input?.tooltip : ''}
                    >
                      <Grid
                        rowSpacing={12}
                        key={key}
                        sx={{
                          margin: '10px 30px',
                        }}
                        item
                        {...input?.breakpoints}
                      >
                        {input?.field}
                      </Grid>
                    </Tooltip>
                  ))}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </SimpleForm>
    </>
  );
};

export default AdaptableForm;
