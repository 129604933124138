export const filterSanitizer = (item, search, attr = 'name') => {
  const nameSanitizer = item[attr]?.toLowerCase();
  const searchSanitizer = search.toLowerCase();
  console.log(
    nameSanitizer.includes(searchSanitizer)
  )
  return nameSanitizer.includes(searchSanitizer);
  
};
// Agregue este que se hace uso en el 'SEARCH' de UNSCHEDULED EVENTS 
export const filterSanitizerCombined = (item, search, attr = 'name') => {
  const nameSanitizer = item[attr]?.toLowerCase();
  const searchSanitizer = search.toLowerCase();

  if (nameSanitizer.includes(searchSanitizer)) {
    return true;  
  }
  // Tambien busca las coincidencias de talentos
  if (Array.isArray(item.talents)) {
    const talentsMatched = item.talents.some(talent => {
      const talentName = talent?.name?.toLowerCase();
      return talentName.includes(searchSanitizer);
    });

    if (talentsMatched) {
      return true;  
    }
  }

  return false;  
};
