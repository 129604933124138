import { useGetList, useRecordContext } from 'react-admin';
import CardComponent from '../../../../Components/ShowComponents/CardComponent';
import { Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { mainColor } from '../../../../Theme';
import ImageIcon from '@mui/icons-material/Image';
import { ImageButton } from '../../../../Components/ShowComponents/ImageButton';
import moment from 'moment';
import { convertToCostaRicaTime } from '../../../../helpers/date';
import { useMemo } from 'react';
import { CardSubtitle, findFields, findValue } from './ProfileCard';
import { FieldTypo } from './FieldTypo';
import { SelectFieldNames } from './SelectField';
import { eventModel } from './TalentModel';
import { InputTitle } from '../../../../Components/StyledComponents/InputTitle';


export const EventsCard = ({editedFields, isLoading, switchBody, sx}) => {
  const record = useRecordContext();
  console.log(record);
  const pendingBody = useMemo(() => {
    if(!switchBody) return eventModel(record);
    return record;
    
  },[switchBody]);
  return (
    <>
      {
        pendingBody && (
          <CardComponent route="events" id={pendingBody.id} actions sx={{flexDirection: 'column', ...sx}}>
            {
              isLoading ? (
                <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                  <Skeleton width="100%" height="30px" />
                  <Skeleton width="100%" height="30px" />
                  <Skeleton width="100%" height="30px" />
                </Box>
              ) : (
                <TableShow editedFields={editedFields} switchBody={switchBody} row={pendingBody} />
              )
            }
          </CardComponent>
        )
      }
    </>
  );
};

export const TableShow = ({editedFields, row, cols, switchBody}) => {
  console.log(editedFields,'editados');
  console.log(row,'son rows')
  console.log(editedFields?.includes('category'),'el body switch')
  return(
    <Box sx={{
      width: '100%',
      display:'flex',
      flexDirection:'row',
    }}>
      
      <div style={{height:'100%',width:'20%'}}>
        <InputTitle arr={['pendingBody.picture.original']}>Event Image</InputTitle>
        <Box 
          style={{
            position:'relative',
            boxSizing: 'content-box', 
            height: '150px',
            width:'150px',
          }}>
          <img 
            src={row?.picture?.original?.length > 1 ? row?.picture?.original : null} 
            style={{
              verticalAlign: 'bottom',
              height: '100%', 
              width:'100%',
              objectFit: 'cover',
              display: row?.picture?.original ? 'block' : 'block',
            }} />
          <ImageButton size="25px" sx={{color: '#fff', backgroundColor: editedFields?.includes('picture') ? '#3395ff' : '#606060', padding: '20px', height: 'auto', width: 'auto'}} image={row?.picture?.original} />
        </Box>
      </div>

      <div style={{width:'75%',display:'flex',flexDirection:'column',gap:30}}>

        <div style={{width:'100%'}}>
          <InputTitle arr={['pendingBody.picture.original']}>Event Name</InputTitle>
          {!editedFields?.includes('options.name') && <FieldTypo switchBody={switchBody} switchLabel={editedFields?.includes('name')} value={findValue('name', row)} field="name" longMsg/>}
          {
            editedFields?.includes('options.name') && (
              <SelectFieldNames
                switchBody={switchBody} 
                switchLabel={editedFields?.includes('options.name')} 
                value={findValue('options', row)} 
                field="name" 
                label="Event Names"
                defaultValue={findValue('name', row)}
              />
            )
          }
        </div>

        <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          <div>
            <InputTitle>Category</InputTitle>
            <FieldTypo type='pill' switchBody={switchBody} switchLabel={editedFields?.includes('category')} value={findValue('category', row)} field="category" longMsg/>
          </div>
          <div>
            <InputTitle>Tags</InputTitle>
            <FieldTypo type='pill' switchBody={switchBody} switchLabel={editedFields?.includes('tags')} value={findValue('tags', row)} field="tags" longMsg/>
          </div>
        </div>

        <div style={{width:'100%',display:'flex',flexDirection:'column',gap:30}}
        >
          {/* <InputTitle arr={['name', 'category', 'tags']}>Descriptions</InputTitle> */}
          <div style={{width:'100%'}}>
            <InputTitle arr={['pendingBody.picture.original']}>What To Expect</InputTitle>
            <FieldTypo switchBody={switchBody} maxWidth={null} longMsg switchLabel={editedFields?.includes('what_to_expect')} value={findValue('what_to_expect', row)} field="what_to_expect" />
          </div>
          <div style={{width:'100%'}}>
            <InputTitle arr={['pendingBody.picture.original']}>Description</InputTitle>
            <FieldTypo switchBody={switchBody}  maxWidth={null} longMsg switchLabel={editedFields?.includes('description')} value={findValue('description', row)} field="description" />
          </div>
          <div style={{width:'100%'}}>
            <InputTitle arr={['pendingBody.picture.original']}>Good to Know</InputTitle>
            <FieldTypo switchBody={switchBody}  maxWidth={null} longMsg switchLabel={editedFields?.includes('good_to_know')} value={findValue('good_to_know', row)} field="good_to_know" />
          </div>

        </div>
      </div>
    </Box>
  );
};