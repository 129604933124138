import React, { useContext, useEffect, useState } from 'react';
import { FunctionField, ImageField, TextField, useRedirect,} from 'react-admin';
//Hooks & Utils
import { COLORS } from '../../../assets/constants/theme';
import { calendarContext } from '../../../dataProvider';
import { responsiveFilter } from '../../../Hooks/useMediaQuery';
//Components
import { Indicator } from '../../../Components/RedesignComponents/Indicator';
import ListCustom from '../../../Components/ListCustom/ListCustom';
import { fetchData } from '../../../Components/LayoutCustom/MenuCustom';
import {MainCategoryFilter} from '../../../Components/CustomFilterButton/CustomFilterButton';
// Material & Icons
import { Box, Chip, Typography, TextField as TextFieldMui } from '@mui/material';
import { MdCheckCircle } from 'react-icons/md';
import { IoMdCloseCircle,IoMdSearch } from 'react-icons/io';
const paramsList = {
  listParams: {
    filterDefaultValues: {pending:true},
    hasCreate: true,
    resource: 'talents',
  },
  datagridParams: {
    bulkActionButtons: false,
    rowClick: 'show',
  },
};

const SubmissionsList = () => {
  const [filter, setFilter] = useState({pending:true});
  const redirect = useRedirect();
  const { setPendingSubmissions } = useContext(calendarContext);

  useEffect(() => {
    fetchData(setPendingSubmissions);
  },[]);
  // useEffect(() => {
  //   if(filter?.q === '' || filter?.q === undefined || !filter?.q){
  //     if(filter?.main_category?.length === 0 || filter?.main_category?.length === undefined ){
  //       console.log('entre al; use')
  //       setFilter({pending:true});
  //     }
  //   }else{
  //     if(filter?.main_category?.length === 0 || filter?.main_category?.length === undefined ){
  //       console.log('entre al; use');
  //       setFilter({q:filter?.q, pending:true});
  //     }
  //   }
   
  // },[filter?.main_category]);
  // console.log(filter?.q,'FILTROS')
  return (
    <div >
      <ListCustom
        empty={<Typography>No submissions</Typography>}
        {...paramsList}
        listParams={{
          ...paramsList.listParams,
          filter: filter,
          actions: <SubmissionsActions filter={filter} setFilter={setFilter} placeholder={'Search by name, slogan, title and bio...'}/>,
        }}
        datagridParams={{
          empty: <EmptyTalentsListSubmissions  />,
          sx: {
            padding:'0px 20px',
            '& .MuiTypography-root':{
              color:'#000000',
              fontWeight:400
            },
            '& .MuiTableSortLabel-root':{
              color:COLORS.table.headerSorteable,
              fontWeight:400
            },
            '& .RaDatagrid-headerCell':{
              width:'15%'
            },
            '& .column-imagen': {
              width: '10%',
              textAlign:'center'
            },
            '& .column-namename': {
              width: '20%'
            },
            '& .column-main_category': {
              width: '20%',
              textAlign: 'center',
            },
            '& .column-id': {
              width: '10%'
            },
            '& .column-completed': {
              width: '20%',
              textAlign: 'center',
            },
            '& .column-status': {
              width: '20%',
              textAlign: 'center',
            },
            '& .MuiTableCell-body': {
              borderBottom:'0px'
            },
          },
          ...paramsList.datagridParams,
          rowClick: (id, resource, record) => redirect('show', 'talents', id)
        }}
      >
        <FunctionField  label="Picture" source="imagen" render={(record) => <ImageField
          source="picture.original"
          label='Picture'
          sx={{ '& img': { borderRadius: 200, maxWidth: 50, maxHeight: 45, objectFit: 'cover' } }}
        /> }/>
        <FunctionField source='namename'  label={<span style={{color:COLORS.table.headerSorteable,
          fontWeight:400}}>Name</span>} render={(record) => {
          if(record?.submission?.name && record?.submission?.status !== 'approved') return (
            <TextField  source="submission.name" />
          );
          return (
            <TextField  source="name" />
          );
        }} />
        <FunctionField label="Category"  source="main_category" render={(record)=>record?.submission.main_category ? <Chip source="submission.main_category" sx={{width:'auto'}} label={record?.submission.main_category} /> : null}/>
        <TextField label=""  source="id"sx={{visibility:'hidden'}} />
        <FunctionField label="Completed?"  source="completed" render={(record) => 
          <Typography
            sx={{
              fontSize: '.9em',
              fontWeight: '600',
              color: record?.completed ? '#009b00' : '#ffc990',
              textTransform: 'uppercase',
            }}
          >
            {record?.completed ? <MdCheckCircle color='#21B141' size={26}/> : <IoMdCloseCircle color='grey' size={26}/>}
          </Typography> 
        } />
        <FunctionField label="Last submission status" source="status" 
          render={(record) => 
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              {record?.submission?.status === 'draft'  || record?.submission === null  ? <Indicator /> : (<Indicator data={record?.submission} />)}
            </div>}/>
      </ListCustom> 
    </div>
  );
};

export const SubmissionsActions = ({filter, setFilter,from,placeholder}) => {
  const responsive= responsiveFilter();
  // const handleFilter = (e) => {
  //   let filtered = {...filter};
  //   let value = e.target.checked;
  //   if(value) return setFilter({...filter, 'pending': value});
  //   delete filtered.pending;
  //   return setFilter({...filtered, status: ['pending', 'approved', 'rejected']});
  // };
  const handleCategory = (e, v) => {
    let filtered = {...filter};
    if (!v || v.length === 0) {
      delete filtered[from === 'events' ? 'category' :'main_category'];
      return setFilter({...filtered});
    }
    if(from === 'events'){
      return setFilter({...filtered, pending: true, 'category': v?.map(item => encodeURIComponent(item.text))});
    }else{
      return setFilter({...filtered, pending: true, 'main_category': v?.map(item => encodeURIComponent(item.text))});
    }
  };
  
  return(
    <Box sx={{display:'flex',flexDirection:'column', width: '100%',marginBottom:'20px',paddingLeft:'20px'}}>
      <div style={{marginTop:20}}>
        <TextFieldMui  
          sx={{  width: '350px',borderRadius: '10px',}} 
          hiddenLabel={true}
          placeholder={placeholder}  
          variant="outlined" 
          onChange={(e) => setFilter({...filter, 'q': e.target.value})}  
          InputProps={{
            style: {
              borderRadius: '10px',
              fontSize: '14px',
            },
            startAdornment: <IoMdSearch size={24} />,
          }}/>
      </div>
      <Box sx={{width: '100%', display: 'flex',alignItems: 'center', justifyContent: 'flex-start', height: '50px',marginTop:2}}>
        <div style={{width:'auto',display:'flex',alignItems: 'center',height:'50px',gap:20}}>
          <MainCategoryFilter _callback={handleCategory}  sx={{height:'100%'}} filter={filter} from={from} responsive={responsive}/>
        </div>
      </Box>
    </Box>
  );
};
export const EmptyTalentsListSubmissions = () => {

  return (
    <div style={{width:'100%',height:'20%'}}>
      <div style={{width:'100%',height:'10%',overflow:'hidden'}}>
        <ListCustom
          listParams={{
            ...paramsList.listParams,
            actions: false,
          }}
          pagination={false}
          datagridParams={{
            size: 'small',
            sx: {
              '& .column-picture.original': {
                width: '200px',
              },
              '& .column-name': {
                width: '900px',
              },
              '& .column-main_category': {
                width: '200px',
              },
              '& .column-status': {
                width: '300px',
              },
              '& .column-published': {
                width: '300px',
              },
            },
            bulkActionButtons: false,
          }}
        >
          <ImageField
            sortable={false}
            source="123"
            label=""
            sx={{
              '& img': {
                borderRadius: 200,
                maxWidth: 39,
                maxHeight: 39,
                objectFit: 'cover',
              },
            }}
          />
          <FunctionField
            sortable={false}
            label="Name"
            render={(record) => {
              if (
                record?.submission?.name &&
                record?.submission?.status !== 'approved'
              )
                return <TextField source="12312313" />;
              return <TextField source="123" />;
            }}
          />
          <TextField sortable={false} label="Status" source="123" />
          <TextField sortable={false} label="Category" source="123" />

          <FunctionField
            sortable={false}
            label="Visibility"
            source="123"
            render={(record) => <p style={{ visibility: 'hidden' }}>123</p>}
          />
        </ListCustom>
      </div>
      <Typography sx={{display:'flex',justifyContent:'center',marginTop:'20px'}} >
        No submissions
      </Typography>
    </div>
  
  );
};

export default SubmissionsList;