import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Tooltip,
  Typography,
  Modal
} from '@mui/material';
import React,{useState} from 'react';
import {
  DeleteButton,
  SaveButton,
  SimpleForm,
  Toolbar,
  useDataProvider,
  useEditContext,
  usePermissions,
  useRecordContext,
  useRefresh,
  useResetErrorBoundaryOnLocationChange,
  useResourceContext,
  useSidebarState,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { useCustomToasty } from '../../Hooks/useCustomToasty';
import axios from 'axios';
import { apiUrl } from '../../dataProvider';
import { FormTitle } from '../StyledComponents/FormTitle';
import { BiTrashAlt } from 'react-icons/bi';
import ActionButtons from '../StyledComponents/ActionButtons';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const AdaptableForm = ({
  draft,
  floating,
  noTooltip,
  publish,
  form,
  csvPosition,
  simpleform,
  data,
  showDelete,
  direction = 'row',
  rowSpacing = 1,
  index,
  create,
  setOpen,
  iam,
  message
}) => {
  const { isLoading } = useEditContext();
  const customToast = useCustomToasty();
  const permis = usePermissions()?.permissions;
  const record = useRecordContext();
  const [openModal, setOpenModal] =useState(false)
  const dataProvider = useDataProvider();
  const refresh = useRefresh();

  if (isLoading) {
    return (
      <Box
        sx={{
          width: '100%',
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const MyToolbar = () => {
    const methods = useFormContext();
    const dataProvider = useDataProvider();
    const resource = useResourceContext();
    const refresh = useRefresh();
    
    const handleData = async () => {
      let draftData = methods.getValues();
      if(draftData.name === '' || !draftData.name ) {

        customToast('error', 'The name is required.');
        return;
      }
      try {

        const response = await axios({
          url: `${apiUrl}/${draft}/${draftData.id}`,
          method: 'PUT',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Expose-Headers': 'content-range',
            'Authorization': 'Bearer ' + localStorage.getItem('auth') ,
            'x-api-key': process.env.REACT_APP_API_KEY ,
          },
          data: draftData
        });
     
        if(response.data){

          refresh();
          customToast('success', 'Changes were saved.');
        }
      } catch (error) {
        customToast('error', 'There was an error, please try again later.');
        throw new Error(error);
      }
    };

    const handleAction = () => {
      dataProvider.update(`${resource}`, {id: record?.id, data: {draft: !record?._draft}}).then(res => {
        if(record?._draft) {
          customToast('success', 'Talent saved as draft');
        } else {
          customToast('success', 'Talent published succesfuly');
        }
        refresh();
      }).catch(err => {
        customToast('error', 'There was an error, please try again later.');
        throw new Error(err);
      });
    };

    const onError = ({ ...rest }) => {
      customToast('error',rest?.body?.detail);
    };

    const [openSideBar, setOpenSideBar] = useSidebarState();


    const styledFloating = floating ? {
      position: floating ? 'fixed' : 'absolute',
      bottom: 0,
      right: 0,
      width: `calc(100% - ${openSideBar ? '248px' : '58px'})`,
      transition: 'width 500ms',
      animation: '1s ease-in',
      backgroundColor: 'rgba(0,0,0, 0)'
    } : {};

    const floatingSubmitName = floating ? (noTooltip ? `Save event ${index + 1}` : `Submit event ${index + 1}`) : (noTooltip ? 'Save' : create?'Create':'Save');

    return (
      <Box 
        sx={{
          position: 'relative',
          width: '100%',
        }}
      >
        <Toolbar 
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            gap: '20px',
            backgroundColor: '#ffffff',
            ...styledFloating
          }}>
          <Tooltip slotProps={{tooltip: { sx: {fontSize: '.9em'}}}} placement="top" title={message ? 'This information will be reviewed by the staff. You will be notified when this process is done or if any change is needed.' : ''}>
            <div style={{width:'100%', display: 'flex',flexDirection: 'row',justifyContent: 'end',height:'64px'}}>
              {/* <SaveButton label={floatingSubmitName} /> */}
              <ActionButtons action={'save'} label={iam?'Save':'Invite'} icon={null} sx={{width:'130px',height:'80%'}}/>
            </div>  
          </Tooltip>
          {
            draft && permis === 'talent_manager' && (
              <Tooltip slotProps={{tooltip: { sx: {fontSize: '.9em'}}}} placement="top" title={message ? 'Save the current changes in case you are you lack required information to submit the document.':'' }>
                <div>
                  <Button  variant="filled" >Save</Button>
                </div>
              </Tooltip>
            )
          }
          <Box>
            {iam !== simpleform?.record?.id && showDelete && <Button color="error" onClick={()=>setOpenModal(true)} startIcon={<BiTrashAlt />}>Delete</Button>}
          </Box>
        </Toolbar>
      </Box>
    );
  };
  // DELETE DELETE
  const confirmDelete = async(recordToDelete) => {
    setOpenModal(true);
    try {
      let request = await dataProvider.delete('admins', {id:simpleform?.record?.id});
      console.log(request);
      if(request) {
        customToast('success', 'Deleted succesfuly');
        setOpen(false);
        refresh();
      }
    } catch (error) {
      customToast('error', 'There was an error');

      throw error;
    }
  };
  return (
    <>
      {create ? 
        <SimpleForm
          toolbar={<MyToolbar />}
          {...simpleform}
          sanitizeEmptyValues
          sx={{
            width:900,
            //   height: "auto",
          }}
        >
          <Box
            id={index}
            sx={{
              width: '100%',
            }}
          >
            {data?.map((item, index) => {
              if (item?.noShow) return <div></div>;
              return (
                <Grid
                  container
                  direction={direction}
                  sx={{
                    justifyContent: 'flex-start',
                    borderBottom:
                    index !== data.length - 1 && '1px solid rgba(0,0,0, .15)',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                  key={`AdaptableFormKey-${index}`}
                >
                  <Grid item xs={0}>
                    <FormTitle
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      {item?.title?.toUpperCase()}
                    </FormTitle>
                  </Grid>
                  <Grid
                    columnSpacing={0}
                    xs={10}
                  >
                    {item.title && (
                      <Grid
                        key={Math.floor(Math.random() * 100 * 2 * 341)}
                        sx={{ width: '100%', height: '2vw' }}
                        item
                      />
                    )}

                    {item.inputs.map((input, key) => (
                      <Tooltip
                        placement={input?.placement ?? 'top'}
                        title={input?.tooltip ? input?.tooltip : ''}
                      >
                        {input.field.type === 'div' ? (
                          <Grid
                            key={key}
                            item
                            sx={{width:'100%',marginBottom:'1.5em'}}
                          >
                            {input?.field}
                          </Grid>
                        ) : (
                          <Grid
                            key={key}
                            sx={{
                              padding: '0px 10px',
                            }}
                            item
                            {...input?.breakpoints}
                          >
                            {input?.field}
                          </Grid>
                        )}
                      </Tooltip>
                    ))}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </SimpleForm>
        :   
        <SimpleForm
          toolbar={<MyToolbar />}
          {...simpleform}
          sanitizeEmptyValues
          sx={{
            width:900,
            //   height: "auto",
          
          }}
        >
          {/* MODAL MODAL */}
          {/* {openModal && 
     <Modal open={openModal}
       onClose={()=>setOpenModal(false)}
       aria-labelledby="modal-modal-title"
       aria-describedby="modal-modal-description"
     >

       <Box sx={style}>
         <Typography id="modal-modal-title"  component="h4">
         Are you sure you want to delete this user? 
         </Typography>
         <Box id="modal-modal-description" sx={{display: 'flex',justifyContent: 'space-evenly', mt: 6 }}>
           <Button sx={{backgroundColor: '#153B34', color: '#fff', '&:hover': {backgroundColor: '#153B34', color: '#fff'}}}variant="filled" onClick={()=>confirmDelete(record)}>Confirm</Button>
           <Button sx={{backgroundColor: '#153B34', color: '#fff', '&:hover': {backgroundColor: '#153B34', color: '#fff'}}}variant="filled" onClick={()=>setOpenModal(false)}>
            Cancel
           </Button>
         </Box>
       </Box>
     </Modal>} */}
          {/* MODAL MODAL END */}
          <Box
            id={index}
            sx={{
              width: '100%',
            }}
          >
            {data?.map((item, index) => {
              if (item?.noShow) return <div></div>;
              return (
                <Grid
                  container
                  direction={direction}
                  sx={{
                    justifyContent: 'flex-start',
                    borderBottom:
                    index !== data.length - 1 && '1px solid rgba(0,0,0, .15)',
                    width: '100%',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                  }}
                  key={`AdaptableFormKey-${index}`}
                >
                  
                  <Grid item xs={0}>
                    <FormTitle
                      sx={{
                        marginTop: 1,
                      }}
                    >
                      {item?.title?.toUpperCase()}
                    </FormTitle>
                  </Grid>
                  <Grid
                    columnSpacing={0}
                    xs={10}
                  >
                    {item.title && (
                      <Grid
                        key={Math.floor(Math.random() * 100 * 2 * 341)}
                        sx={{ width: '100%', height: '2vw' }}
                        item
                      />
                    )}

                    {item.inputs.map((input, key) => {
                      return(
                        <Tooltip
                          placement={input?.placement ?? 'top'}
                          title={input?.tooltip ? input?.tooltip : ''}
                        >
                          {input.field.type === 'div' ? (
                            <Grid
                              key={key}
                              item
                              sx={{width:'100%',marginBottom:'1.5em'}}
                            >
                              {input?.field}
                            </Grid>
                          ) : (
                            <Grid
                              key={key}
                              sx={{
                                padding: '0px 10px',
                              }}
                              item
                              {...input?.breakpoints}
                            >
                              {input?.field}
                            </Grid>
                          )}
                        </Tooltip>
                      )})}
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </SimpleForm>}
    
    </>
  );
};

export default AdaptableForm;