import { Toolbar, useGetList, useListContext, useRedirect,useSidebarState,usePermissions } from 'react-admin';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
// Hooks & Utils
import { mainColor } from '../../Theme';
import { COLORS } from '../../assets/constants/theme';
import { festivalDates } from '../../dataProvider';
import { convertToCostaRicaTime } from '../../helpers/date';
import { EVENTS_TOOLTIPS } from '../TalentForm/Tooltips';
import { responsiveFilter } from '../../Hooks/useMediaQuery';
// Components
import { CsvDownloader } from '../../Components/ReportButtons/CsvDownloader';
import { CompletedFilter } from '../../Components/CustomFilterButton/CustomFilterButton';
import AllAutocomplete from '../../Components/ReportButtons/AllAutocomplete';
import { AdditionalFilters, PostFilterButton } from '../../Components/CustomFilterButton/CustomFilterButton';
import { StatusFilter } from '../../Components/CustomFilterButton/CustomFilterButton';
// Material & Icons
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box, Button,Chip, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Skeleton, TextField, Typography,IconButton,Tooltip, Autocomplete } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MdCalendarToday,MdHelpOutline  } from 'react-icons/md';
import { AiOutlinePlus } from 'react-icons/ai';
import { FaTable,FaRegCalendarTimes } from 'react-icons/fa';

export const renderTags = (tagValue, getTagProps, rest) =>{
  return <Typography sx={{color: mainColor, fontWeight: '600'}} variant="h7">+{tagValue.length}</Typography>;
};

export const renderPopper = (params) => <Popper sx={{minWidth: '190px', fontSize: '.9em'}} {...params} />;


export const CalendarToolbar = ({
  venues, 
  setViewUnscheduled, 
  viewCalendar, 
  setViewCalendar, 
  setFilterState,
  filterState,
  placeholder
}) => {
  const {permissions:permis } = usePermissions();
  const isLargeScreen = useMediaQuery('(min-width: 1600px)');
  const redirect = useRedirect();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { setFilters, filterValues } = useListContext();
  const sideBarOpen = useSidebarState()[0];
  const responsive = responsiveFilter();
  const options = viewCalendar ? [
    'Stage',
    'Talent',
    'Category',
    'Remove Filters'
  ] : [
    'Date',
    'Stage',
    'Talent',
    'Scheduled',
    'Category',
    'Status',
    'Remove Filters'
  ];

  useEffect(() => {
    if(viewCalendar){
      setSelectedFilters([ 
        'Stage',
        'Talent',
        'Category',
        'Remove Filters'
      ]);
    } else {
      setSelectedFilters([
        'Date',
        'Stage',
        'Talent',
        'Scheduled',
        'Category',
        'Status',
        'Remove Filters',
        'Completed'
      ]);
    }
  },[viewCalendar]);

  const handleSelectFilter = (opt) => {
    if(opt === 'Remove Filters') return setSelectedFilters([]);
    setSelectedFilters((f) => {
      let found = f?.find(item => opt === item);
      if(found) return f?.filter(item => item !== opt);
      return [...f, opt];
    });
  };

  const handleFilter = (e) => {
    switch(viewCalendar){
    case (e === 'Date'):
      setFilterState((f) => ({...f, filterDate: null}));
      break;
    case (e === 'Stage'):
      setFilterState((f) => ({...f, filterVenues: null}));
      break;
    case (e === 'Talent'):
      setFilterState((f) => ({...f, filterTalents: null}));
      break;
    case (e === 'Category'):
      setFilterState((f) => ({...f, filterCategorys: null}));
      break;
    case (e === 'Scheduled'):
      setFilterState((f) => ({...f, filterScheduled: null}));
      break;
    case (e === 'Status'):
      setFilterState((f) => ({...f, filterStatus: null}));
      break;
    case (e === 'Name'):
      setFilterState((f) => ({...f, filterName: null}));
      break;
    }
  };

  const elementProps = (element) => {
    return {
      viewCalendar: viewCalendar,
      filterValues: filterValues,
      selectedFilter: element,
      handleSelectFilter: handleSelectFilter,
      handleFilter: viewCalendar ? handleFilter : null,
     
    };
  };
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Toolbar sx={{position: 'relative',display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'space-between', backgroundColor: '#fff','&.RaToolbar-desktopToolbar':{padding: '0px 0px 0px 0px'}}}>
        {/* <Box 
          sx={{
            width: '100%',
            height: '80px',
            backgroundColor: COLORS.forms.header.background,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingX: 3,
            marginBottom: '15px',
          }}
        >
      
        </Box> */}
        <Box sx={{display: 'flex', flexDirection :'row', alignItems: 'center', width: '100%',paddingX: '20px',marginBottom:2}}>
          {/* <ButtonAddFilter onSelect={handleSelectFilter} options={options}/> */}
          {permis !== 'talent_manager' && 
          <div style={{width:'100%',display:'flex',flexDirection:'row', alignItems: 'center',marginTop:20,justifyContent:'space-between'}}>
            <PostFilterButton filterState={filterState} setFilterState={setFilterState} sx={{minWidth: '350px',maxWidth: '350px'}} isLargeScreen={isLargeScreen} placeholder={placeholder}/>
            <div style={{width:'auto',display:'flex',alignItems: 'center',height:'44px',gap:20,marginLeft:50}}>
              <Button
                startIcon={viewCalendar ?<FaTable style={{ width: '15px' }} /> : <MdCalendarToday style={{ width: '15px' }} />}
                onClick={() => setViewCalendar(!viewCalendar)}
                size='small'
                sx={{ width: '100%', maxWidth:viewCalendar ? '190px' :'220px', display: 'flex', alignItems: 'center',height: '100%',backgroundColor:COLORS.light.secondary.main,color:'white',fontSize: '15px',fontWeight: '500',borderRadius:'10px',textTransform: 'none',padding:2,border:`0.5px solid ${COLORS.light.secondary.main}`,
                  '&:hover':{
                    backgroundColor:COLORS.light.secondary.main,
                    borderColor:'black'
                  },}}
              >
                { viewCalendar ? 'View Table' : 'View Full Schedule '}
              </Button>
              {
                viewCalendar ? (
                  <Button 
                    size='small'
                    variant='outlined'
                    sx={{ width: '260px', display: 'flex', alignItems: 'center',height: '100%',fontWeight:500,color:'#000000',borderRadius:'10px',textTransform: 'none',fontSize:'15px'}}
                    startIcon={<FaRegCalendarTimes style={{ width: '15px' }} />}
                    onClick={() => setViewUnscheduled(true)}>Unscheduled Events</Button>
                ) : null
              }
              {viewCalendar === true ? null :
                <CsvDownloader minWidth={'(min-width:1060px)'}  from="events" filterValues={filterValues}/>
              }
              <Button onClick={() => redirect('create', 'events')} variant="contained" startIcon={<AiOutlinePlus size="15px" />} 
                sx={{backgroundColor:COLORS.light.secondary.main,
                  textTransform: 'none',
                  fontSize: '15px', 
                  fontWeight: '500',
                  height: '44px',
                  minWidth: '135px',
                  borderRadius:'10px',
                  color:'white',
                  border:`0.5px solid ${COLORS.light.secondary.main}`,
                  '&:hover':{
                    backgroundColor:COLORS.light.secondary.main,
                    borderColor:'black'
                  },}}>Add</Button>
            </div>
          </div>
          }
        </Box>
        <Box
          sx={{
            width:'100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            paddingX: 3,
            gap: sideBarOpen ? 1 : 2,
          }}>
          {
            selectedFilters && selectedFilters?.map((item, index) => {
              // if(item === 'Date') return React.cloneElement(<FilterItem />,{
              //   ...elementProps('Date'),
              //   children: 
              //       viewCalendar ? (
              //         <DatePicker 
              //           slotProps={{ 
              //             textField: { 
              //               variant: 'outlined',
              //               minWidth: '150px'
              //             }
              //           }}
              //           minDate={moment(festivalDates?.start_date)}
              //           maxDate={moment(festivalDates?.end_date)}
              //           onChange={(e,v) => {
              //             setFilterState((f) => ({...f, filterDate: convertToCostaRicaTime(e)}));
              //           }}
              //         />
              //       ) : (
              //         <></>
              //         // <CustomDateInput isLargeScreen={isLargeScreen} sideBarOpen={sideBarOpen}/>
              //       )
              // });
              if(item === 'Stage' && viewCalendar) return React.cloneElement(<FilterItem setFilters={setFilters} />,{
                ...elementProps('Stage'),
                children: (
                  <StagesAutocomplete setFilters={setFilters} viewCalendar={viewCalendar} setSelectedVenues={setFilterState} venues={venues} isLargeScreen={isLargeScreen} sideBarOpen={sideBarOpen}/>
                ) 
              });
              if(item === 'Talent') return React.cloneElement(<FilterItem />,{
                ...elementProps('Talent'),
                children: (
                  <TalentsAutocomplete setSelectedTalents={setFilterState} setFilters={setFilters} viewCalendar={viewCalendar} isLargeScreen={isLargeScreen} sideBarOpen={sideBarOpen}/>
                ) 
              });
              if(item === 'Category' && viewCalendar === false) return React.cloneElement(<FilterItem />,{
                ...elementProps('Category'),
                children: (
                  <CategoryAutocomplete setSelectedCategory={setFilterState} setFilters={setFilters} viewCalendar={viewCalendar} isLargeScreen={isLargeScreen} sideBarOpen={sideBarOpen} />
                ) 
              });
              if(item === 'Completed' && viewCalendar === false) return React.cloneElement(<FilterItem />,{
                ...elementProps('Completed'),
                children: (
                  <CompletedFilter responsive={isLargeScreen ? '300px' : sideBarOpen ? '190px' : '200px'}/>
                ) 
              });
              if(item === 'Status') return React.cloneElement(<FilterItem />,{
                ...elementProps('Status'),
                children: (
                  <StatusFilter responsive={isLargeScreen ? '300px' : sideBarOpen ? '190px' : '200px'}/>
                ) 
              });
              if(item === 'Scheduled') return React.cloneElement(<FilterItem />,{
                ...elementProps('Scheduled'),
                children: (
                  <UnscheduledEventsFilter setSelectedTalents={setFilterState} isLargeScreen={isLargeScreen} sideBarOpen={sideBarOpen}/>
                ) 
              });
              // if(item === 'Category' && viewCalendar) return React.cloneElement(<FilterItem />,{
              //   ...elementProps('Category'),
              //   children: (
              //     <CategoryEventsFilter setSelectedCategorys={setFilterState} isLargeScreen={isLargeScreen} sideBarOpen={sideBarOpen} />
              //   ) 
              // });
              if(item === 'Additional') return React.cloneElement(<FilterItem />, {
                ...elementProps('Additional'),
                children: (
                  <AdditionalFilters />
                )
              });
            })
          }
        </Box>
        {viewCalendar && <Box sx={{marginBottom: '15px',paddingX: 3,width:'100%',display:'flex',flexDirection:'row',alignItems:'center',color:'grey'}}>
          <Tooltip title={EVENTS_TOOLTIPS['calendarGuide']}>
            <IconButton onClick={()=>console.log('help')}>
              <MdHelpOutline size={20}/>
            </IconButton>
          </Tooltip>
          <Typography>Help</Typography>
        </Box>}
      </Toolbar>

    </LocalizationProvider>
  );
};


export const ButtonAddFilter = ({onSelect, options, ...rest}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <Popper
        sx={{
          zIndex: 9999,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => {
                        onSelect && onSelect(option);
                        handleMenuItemClick(event, index);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const FilterItem = (props) => {
  
  const {
    viewCalendar,
    selectedFilter, 
    children,
    filterValues
  } = props;
  
  const { setFilters } = useListContext();
  const clearFilter = () => {
    if(!viewCalendar) {
      let newFilters = {...filterValues};
      if(selectedFilter === 'Stage') {
        delete newFilters.stage_id;
        setFilters({...newFilters});
      }
      if(selectedFilter === 'Date'){
        delete newFilters.end_date_to;
        delete newFilters.start_date_from;
        setFilters({...newFilters});
      }
      if(selectedFilter === 'Scheduled'){
        delete newFilters.scheduled;
        setFilters({...newFilters});
      }
      if(selectedFilter === 'Talents'){
        delete newFilters.talent_id;
        setFilters({...newFilters});
      }
      if(selectedFilter === 'Category'){
        delete newFilters.category;
        setFilters({...newFilters});
      }
    }
  };
  
  return(
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width:'auto'
        // maxWidth: viewCalendar?'190px':null
      }}
    >
      {/* <AiOutlineCloseCircle 
        onClick={() =>{
          handleSelectFilter(selectedFilter);
          handleFilter && handleFilter(selectedFilter);
          clearFilter();
        }}
        style={{
          width: '20px',
          height: '20px',
          marginRight: '10px',
          cursor: 'pointer',
        }} /> */}
      {React.cloneElement(children, {...props})}
    </Box>
  );
};

export const CustomDateInput = ({ isLargeScreen,sideBarOpen,...rest}) => {

  const { setFilters } = useListContext();
  return (
    <>
      <LocalizationProvider  {...rest} dateAdapter={AdapterMoment}>
        <DatePicker
          {...rest}
          onChange={(e) => {
            setFilters({...rest.filterValues,
              start_date_from: convertToCostaRicaTime(e),
              end_date_to: moment.utc(e).add(20, 'hours').format('YYYY-MM-DDTHH:mm')
  
            });
          }} 
          minDate={moment(festivalDates?.start_date)}
          maxDate={moment(festivalDates?.end_date)}
          slotProps={{ 
            textField: { 
              variant: 'outlined' ,
            }
          }} />
      </LocalizationProvider>
    </>
  );

};

export const StagesAutocomplete = ({sideBarOpen,isLargeScreen,setFilters, setSelectedVenues, viewCalendar, venues, filterValues}) => {
  const [values, setValues] = useState([]);
  const { data, isLoading: loadingVenues } = useGetList('locations', { filter: { event_ready: true}, sort: { field: 'rank', order: 'DESC'}},{
    enabled: !venues
  });

  const collection = useMemo(() => {
    if(venues) return venues;
    return data;
  },[venues, data]);

  const handler = (e,v) => {
    setValues(v);
    setSelectedVenues && setSelectedVenues((f) => ({...f, filterVenues: v?.map(item => item?.id)}));
    !viewCalendar && setFilters({...filterValues, 'stage_id': v?.map(item => item?.id)});
  };

  return(
    collection ? (
      <AllAutocomplete 
        width={isLargeScreen ? '300px' : sideBarOpen ? '190px' : '200px'}
        limitTags={2}
        value={values}
        allTitle={null}
        sx={{ 
          marginBottom: '0px',
          height: '50%',
          '& .MuiInputBase-sizeSmall':{
            borderRadius:'50px',
            color:'black',
          },
          '& .MuiInputLabel-root':{
            color:'black',
            fontWeight:400
          },}}
        onChange={handler}
        getOptionLabel={(option) => option.name}
        arr={collection}
        PopperComponent={renderPopper}
        renderInput={(params) => 
          <TextField label="Stages" variant="outlined" fullWidth {...params} />
        }
        // renderTags={renderTags}
      />
    ) : (
      <Skeleton height="50px" width="100%" />
    )
  );
};

export const TalentsAutocomplete = ({isLargeScreen,setFilters,sideBarOpen, setSelectedTalents, viewCalendar, talents, filterValues, ...rest}) => {
  const [values, setValues] = useState([]);
  const { data } = useGetList('talents', { filter: {}, sort: { field: 'rank', order: 'DESC'}, pagination: {perPage: 9999, page: 1}},{
    enabled: !talents
  });

  const handler = (e,v) => {
    setValues(v);
    setSelectedTalents && setSelectedTalents((f) => ({...f, filterTalents: v?.map(item => item?.id)}));
    !viewCalendar && setFilters({...filterValues, 'talent_id': v?.map(item => item?.id)});
  };

  return(
    data ? (
      <AllAutocomplete 
        width={isLargeScreen ? '300px' : sideBarOpen ? '190px' : '200px'}
        value={values}
        limitTags={2}
        allTitle={null}
        sx={{ 
          marginBottom: '0px',
          height: '50%',
          '& .MuiInputBase-sizeSmall':{
            borderRadius:'50px',
            color:'black',
          },
          '& .MuiInputLabel-root':{
            color:'black',
            fontWeight:400
          },}}
        onChange={handler}
        getOptionLabel={(option) => option.name}
        arr={data}
        PopperComponent={renderPopper}
        renderInput={(params) => 
          <TextField label={rest.selectedFilter} variant="outlined" {...params} />
        }
        // renderTags={renderTags}
      />
      
    ) : (
      <Skeleton height="50px" width="100%" />
    )
  );
};

export const CategoryAutocomplete = ({sideBarOpen,isLargeScreen,setFilters, setSelectedCategory, viewCalendar, talents, filterValues, ...rest}) => {
  const [values, setValues] = useState([]);
  const { data } = useGetList('talents/categories', { filter: {}, sort: { field: 'rank', order: 'DESC'}, pagination: {perPage: 9999, page: 1}},{
    enabled: !talents
  });

  const handler = (e,v) => {
    setValues(v);
    setSelectedCategory && setSelectedCategory((f) => ({...f, filterCategory: v?.map(item => encodeURIComponent(item?.text))}));
    !viewCalendar && setFilters({...filterValues, 'category': v?.map(item => encodeURIComponent(item?.text))});
  };
  return(
    data ? (
      <AllAutocomplete 
        width={isLargeScreen ? '300px' : sideBarOpen ? '190px' : '200px'}
        value={values}
        limitTags={2}
        allTitle={null}
        sx={{
          marginBottom: '0px',
          height: '50%',
          '& .MuiInputBase-sizeSmall':{
            borderRadius:'50px',
            color:'black',
          },
          '& .MuiInputLabel-root':{
            color:'black',
            fontWeight:400
          },
        }}
        onChange={handler}
        getOptionLabel={(option) => option.text}
        arr={data}
        PopperComponent={renderPopper}
        renderInput={(params) => 
          <TextField label={rest.selectedFilter} variant="outlined" {...params} />
        }
      />
    ) : (
      <Skeleton height="50px" width="100%" />
    )
  );
};

export const UnscheduledEventsFilter = ({sideBarOpen,isLargeScreen}) => {
  const { setFilters, filterValues } = useListContext();
  const [bool, setBool] = useState(null);
  const handleChange = (e,v) => {
    let newFilters = {...filterValues};
    const value = v;
    setBool(value);
    if(!value){
      delete newFilters['scheduled'];
      return setFilters(newFilters);
    }
    setFilters({...newFilters, 'scheduled': value === 'Scheduled'});
  };

  return(
    <Autocomplete
      onChange={handleChange} 
      sx={{
        marginBottom: '0px',
        // height:'50%',
        '& .MuiInputBase-sizeSmall':{
          borderRadius:'50px',
          color:'black',
          maxHeight:'45px'
        },
        '& .MuiInputLabel-root':{
          color:'black',
          fontWeight:400
        },
      // outline: '1px red solid'
      }}
      getOptionLabel={(option) => option} 
      options={['Scheduled', 'Not Scheduled']} 
      renderInput={(params) => 
        <TextField 
          label="Scheduled" 
          variant="outlined" 
          sx={{
            width:isLargeScreen ? '300px' : sideBarOpen ? '190px' : '200px',
            height: '100%',
            zIndex: 9999,
            color:'black',
          // margin: '0px',
          }}
          InputProps={{
            ...params.InputProps,
            sx: {
              overflow: 'auto',
              margin: '0px',
            },
            startAdornment: (
              <div style={{
                overflow: 'auto',
                margin: '0px',

              }}
              >{params.InputProps.startAdornment}</div>
            ),
          }}
          {...params} 

        />}
    />
    // <AllAutocomplete 
    //   width={isLargeScreen ? '300px' : sideBarOpen ? '190px' : '250px'}
    //   value={bool}
    //   sx={{
    //     marginBottom: '0px',
    //     height: '50%',
    //     '& .MuiInputBase-sizeSmall':{
    //       borderRadius:'50px',
    //       color:'black',
    //     },
    //     '& .MuiInputLabel-root':{
    //       color:'black',
    //       fontWeight:400
    //     },
    //   }}
    //   arr={['Unscheduled events', 'Scheduled Events']}
    //   PopperComponent={renderPopper}
    //   Paper
    //   getOptionLabel={(option) => option}
    //   label="Scheduled Status"
    //   allTitle={null}
    //   onChange={handleChange}
    //   multiple={false}
    //   // element={<Radio />}
    // />
  );
};
export const CategoryEventsFilter = ({sideBarOpen,isLargeScreen,setFilters, setSelectedCategorys, viewCalendar, filterValues}) => {
  const [values, setValues] = useState([]);
  const { data } = useGetList('events/categories', { filter: {}, sort: {}, pagination: {perPage: 9999, page: 1}});
  const handler = (e,v) => {
    setValues(v);
    setSelectedCategorys && setSelectedCategorys((f) => ({...f, filterCategorys: v?.map(item => item?.text)}));
    !viewCalendar && setFilters({...filterValues, 'category': v?.map(item => item?.id)});
  };
  return(
    data ? (
      <AllAutocomplete 
        width={isLargeScreen ? '300px' : sideBarOpen ? '190px' : '200px'}
        value={values}
        allTitle={null}
        onChange={handler}
        getOptionLabel={(option) => option.text}
        arr={data}
        PopperComponent={renderPopper}
        renderInput={(params) => 
          <TextField label="Category" variant="outlined" fullWidth {...params} />
        }
        renderTags={renderTags}
      />
    ) : (
      <Skeleton height="50px" width="100%" />
    )
  );
};