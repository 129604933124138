import { Autocomplete, Box, FormHelperText, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { regex, useInput, useRecordContext,useGetOne } from 'react-admin';
import { useFormContext } from 'react-hook-form';
import moment from 'moment';
import { convertEndDateToDuration } from '../../helpers/date';
import { InputTitle } from '../StyledComponents/InputTitle';
import { InputSubtitle } from '../StyledComponents/InputSubtitle';
const HOURS  = Array.from({length: 24}, (_, index) => index);
const MINUTES  = Array.from({length: 60}, (_, index) => index);

export const InputDuration = ({hours, minutes, label, source, ...rest}) => {
  const {field, fieldState: {error}} = useInput({
    source , 
    validate: [(e) => e < 5 ? 'Event Duration must be equal or greater than 5' : undefined]});
  const record = useRecordContext();
  const {getValues, setValue} = useFormContext();
  const initialDuration = convertEndDateToDuration(getValues('start_date', getValues('end_date')));
  const [duration, setDuration] = useState({hours: HOURS[initialDuration[0]] ?? 0, minutes: MINUTES[initialDuration[1]] ?? 15});

  useEffect(() => {
    let fullDuration = (parseFloat(duration.hours) * 60) + parseFloat(duration.minutes);
    field.onChange(fullDuration);
    let startDate = getValues('start_date');
    let newEndDate = moment(startDate).add(fullDuration, 'minutes');
    setValue('end_date', newEndDate);
  },[duration]);

  if(hours) {
    return (
      <>
        <InputTitle>{label}</InputTitle>
        <Autocomplete
          sx={{
            width: '100%', 
            borderRadius: '10px',
            '-webkit-border-radius': '10px', 
            '& .MuiAutocomplete-inputRoot': {
              borderRadius: '10px'
            },
          }}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
  
          }}
          value={field?.duration?.hours}
          defaultValue={record?.duration ? HOURS[Math.floor(record?.duration / 60)] : null}
          onChange={(e,v) => setDuration({...duration, hours: v})}
          options={HOURS}
          getOptionLabel={(option) => option.toString()}
          renderInput={(params) => <TextField 
            label="" placeholder="Hours" variant="outlined" {...params}/>}
          {...rest}
        />
      </>
    );
  }
  if(minutes) {
    return (
      <>
        
        <Autocomplete
          sx={{width: '100%', '& .MuiAutocomplete-inputRoot': {
            borderRadius: '10px'
          },}}
          variant="outlined"
          onChange={(e,v) => setDuration({...duration, minutes: v})}
          value={field?.duration?.minutes}
          defaultValue={record ? MINUTES[Math.ceil(record?.duration % 60)] : MINUTES[14]}
          InputLabelProps={{
            shrink: true,
          }}
          label="Minutes"
          options={MINUTES}
          getOptionLabel={(option) => option.toString()}
          renderInput={(params) => <TextField label="" placeholder="Minutes" variant="outlined" {...params}/>}
          {...rest}
        />
      </>
    );
  }
  console.log(error);
  return(
    <Box sx={{width: '100%', margin: '10px 0px'}}>
      <InputTitle sx={{marginBottom: '20px'}}>{label}</InputTitle>
      <Box sx={{display: 'flex', flexDirection: 'row' , gap: '120px'}}>
        <Autocomplete
          sx={{
            width: '100%', 
            borderRadius: '10px',
            // margin: '0px 30px',

            '-webkit-border-radius': '10px', 
            '& .MuiAutocomplete-inputRoot': {
              borderRadius: '10px'
            },
          }}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
            
          }}
          value={field?.duration?.hours}
          defaultValue={record?.duration ? HOURS[Math.floor(record?.duration / 60)] : null}
          onChange={(e,v) => setDuration({...duration, hours: v})}
          options={HOURS}
          getOptionLabel={(option) => option.toString()}
          renderInput={(params) => <TextField 
            label="Hours" placeholder="Hours" variant="outlined" {...params}/>}
          {...rest}
        />
        <Autocomplete
          sx={{
            width: '100%', 
            // margin: '0px 30px',
            '& .MuiAutocomplete-inputRoot': {
              borderRadius: '10px'
            },}}
          variant="outlined"
          onChange={(e,v) => setDuration({...duration, minutes: v})}
          value={field?.duration?.minutes}
          defaultValue={record ? MINUTES[Math.ceil(record?.duration % 60)] : MINUTES[14]}
          InputLabelProps={{
            shrink: true,
          }}
          
          
          label="Minutes"
          options={MINUTES}
          getOptionLabel={(option) => option.toString()}
          renderInput={(params) => 
            <>
              <TextField error={error} label="Minutes" placeholder="Minutes" variant="outlined" {...params}/>
              {error?.message && <FormHelperText sx={{color:'red'}}>{error?.message}</FormHelperText>}
            </>}
          {...rest}
        />
      </Box>
    </Box>

  );
};

export const InputDurationMinutes = ({ title,subtitle, source, ...rest}) => {
  const record = useRecordContext();
  const {getValues, setValue} = useFormContext();
  const regexNumber = /^(?!-)[0-9]+$/;
  const {field, fieldState} = useInput({
    source , 
    validate: [
      (e) => {
        if(e !== null && e < 5){
          return 'Event Duration must be equal or greater than 5';
        } 
        return undefined;
      }, 
      regex(regexNumber, 'The duration must be a number')
    ],
 
  });

  const initialDuration = convertEndDateToDuration(getValues('start_date', getValues('end_date')));
  const [duration, setDuration] = useState(record?.duration ? record?.duration : getValues().duration);
  useEffect(() => {
    field.onChange(duration);
    let startDate = getValues('start_date');
    let newEndDate = moment(startDate).add(duration, 'minutes');
    setValue('end_date', newEndDate);
  },[duration]);
  // console.log(fieldState);
  return(
    <Box sx={{width: '100%', margin: '0px 10px 0px 0px'}}>
      {title && <InputTitle>{title}</InputTitle>}
      {subtitle && 
        <InputSubtitle>
          {subtitle}
        </InputSubtitle>}
      <Box sx={{display: 'flex', flexDirection: 'row' , gap: '120px',marginBottom:'20px'}}>
        <TextField type="tel" onChange={(e) => setDuration(e.target.value)} sx={{width: '100%', ...rest.sx}} label="" variant="outlined" helperText={fieldState?.error?.message} {...field} {...fieldState}  {...rest} />
      </Box>
    </Box>

  );
};