import React from 'react';
import { Confirm } from 'react-admin';

const ConfirmCustom = ({ ...rest }) => {
  return (
    <Confirm
      {...rest}
    />
  );
};

export default ConfirmCustom;